import { InjectionToken, Injector, Type } from '@angular/core';

export class AppClientContext
{
  public applicationName: string = "";
  public appEnvironment: string = "";
  public isProductionEnvironment: boolean = false;

  public propertyBag: Map<string, any>;
  public headerValues: Map<string, string> = new Map<string, string>();

  //public entityConfigDict: Map<string, EntityConfig>;

  protected static instance: AppClientContext;

  public static get Instance(): AppClientContext
  {
    if (this.instance === null || this.instance === undefined)
    {
      this.instance = new AppClientContext();
    }
    return this.instance;
  }

  /**
 * This is the angular Injector - used for dependency injection.  Used by the getServiceInstance method.
 */
  private injector: Injector;

  public setInjector(injector: Injector): void
  {
    this.injector = injector;
  }

  constructor() { }

  // ********************************************************
  // #region General Methods
  // ********************************************************

  public getServiceInstance<T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T): T
  {
    try
    {
      return this.injector.get(token, notFoundValue);
    }
    catch (e)
    {
      throw ("Error occurred getting service instance:" + token);
    }
  }

  // #endregion

  // *****************************************
  // Busy related properties
  // *****************************************

  private busyDataServices: Map<string, string> = new Map<string, string>();

  private __isBusy: boolean = false;

  public get isBusy(): boolean
  {
    if (this.__isBusy || this.busyDataServices.size > 0) { return true; }
    else { return false; }
  }
  public set isBusy(value: boolean)
  {
    this.__isBusy = value;
  }

  public maxBusySeconds: number = 10;

  private _akkyRedirectUrl: string = "https://extranet.rar.devl2.akky.proyectos.dvl.nic.com.mx";

  public get akkyRedirectUrl(): string
  {
    return this._akkyRedirectUrl;
  }
  public set akkyRedirectUrl(value: string)
  {
    this._akkyRedirectUrl = value;
  }

  // #endregion

  // ********************************************************
  // #region Busy Related Methods
  // ********************************************************

  /**
      *
      * Notifies Client context that the data service is busy.
      This is used by the isBusy logic - to determine if the busy animation should show.
      */
  public setDataServiceBusy(dataServiceTokenName: string): void
  {
    if (!this.busyDataServices.has(dataServiceTokenName))
    {
      this.busyDataServices.set(dataServiceTokenName, dataServiceTokenName);
    }

    this.clearIsBusyIfStuck();
  }

  /**
      *
      * Notifies Client context that the data service is no longer busy.
      This is used by the isBusy logic - to determine if the busy animation should show.
      */
  public setDataServiceDone(dataServiceTokenName: string): void
  {
    if (this.busyDataServices.has(dataServiceTokenName))
    {
      this.busyDataServices.delete(dataServiceTokenName);
      this.isBusy = false;
    }
  }

  private clearIsBusyIfStuck(): void
  {
    // If  there aren't any busy data services, then we're not stuck - so no need to keep testing.
    if (this.busyDataServices.size == 0)
    {
      return;
    }
  }

  // #endregion
}


