import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@shared-services/translate.service';
import { ApiServiceBase } from './api-service.base';
import { UrlQueryDef } from './url-query-definition.base';

export class EntityDataServiceBase extends ApiServiceBase
{
  constructor(protected http: HttpClient,
    public apiUrl: string,
    protected translateService: TranslateService)
  {
    super(http);

    this.apiUrl = apiUrl;
  }

  // *********************************************************
  // #region Support Methods
  // *********************************************************

  public _getQueryDef(queryDef: UrlQueryDef): UrlQueryDef
  {
    if (queryDef == null) 
    {
      queryDef = new UrlQueryDef();
    }

    return queryDef;
  }

  protected getStandardConfig(config: any = undefined): UrlQueryDef
  {
    if (!config)
    {
      config = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Language': this.translateService.languageCode }) };
    }

    return config;
  }

  // #endregion

}
