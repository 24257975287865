import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { EDomainServiceType, EDomainType } from "@core-constants/domain-data.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EGtmEvent } from "@core-constants/gtm-const";
import { PageHelper } from "@core-constants/page-helper.const";
import { ECartItemOperation } from "@core-constants/shopping-cart.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { DomainServiceItemPlus, ICoverageOption } from "@core-models/shopping-cart-plus.model";
import { GtmTrackingService } from "@shared-services/gtm-tracking.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { DomainServiceTemplate } from "../templates/domain-service-template";

@Component({
  selector: "app-domain-services-accordion",
  templateUrl: "./domain-services-accordion.component.html",
  styleUrls: ["./domain-services-accordion.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DomainServiceAccordionComponent
{
  public readonly operationTypeTransfer: ECartItemOperation = ECartItemOperation.Transfer;

  public maxCoverage: number;
  public showHelpModal: boolean = false;
  public helpHTML: string = "";

  @Input() public domainServices: DomainServiceItemPlus[];
  @Input() public parentId: number;
  @Input() public parentEDomainType: EDomainType;
  @Input() public isDomainAdded: boolean;

  @Input() public set parentCoverage(coverage: number)
  {
    this.maxCoverage = coverage;
  }

  constructor(protected translateService: TranslateService,
    protected toastService: ToastService,
    protected shoppingCartDataService: ShoppingCartDataService,
    protected checkoutManager: CheckoutManager,
    protected tokenManager: TokenManager) { }

  public get hasDomainServicesAdded(): boolean
  {
    return this.domainServices?.find(x => x.isAdded) != undefined;
  }

  public get isMobile(): boolean
  {
    return window.innerWidth <= PageHelper.MobileWidth;
  }

  public filterCoverage(item: DomainServiceItemPlus): ICoverageOption[]
  {
    let coverage = this.maxCoverage;

    if (item.maxCoverage != null)
    {
      coverage = item.maxCoverage + (this.isDomainAdded ? this.maxCoverage : 0);
    }

    return item.coverageOptions.filter(x => x.value <= coverage);
  }

  public isRenewActive(item: DomainServiceItemPlus): boolean
  {
    return item.maxCoverage != null && (item.maxCoverage + (this.isDomainAdded ? this.maxCoverage : 0)) >= 12;
  }

  public isRegisterActive(item: DomainServiceItemPlus): boolean
  {
    return item.maxCoverage == null || (item.maxCoverage + (this.isDomainAdded || this.isGobEduCloseDomain() || item.synchronization ? this.maxCoverage : 0)) >= 12;
  }

  public isGobEduCloseDomain(): boolean
  {
    return this.parentEDomainType == EDomainType.gob_mx || this.parentEDomainType == EDomainType.edu_mx;
  }

  public getAmount(item: DomainServiceItemPlus): number
  {
    return item.coverageId > 0 ? item.rate.finalAmount : item.coverageOptions.find(x => x.id > 0).rate.finalAmount;
  }

  public addService(domainService: DomainServiceItemPlus): void
  {
    this.shoppingCartDataService.addDomainService(this.parentId, domainService.id, domainService.coverageId).subscribe({
      next: () =>
      {
        this.checkoutManager.addDomainService(this.parentId, domainService.id);
        this.toastService.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
      },
      error: (response: HttpErrorResponse) =>
      {
        this.errorMessage(response);
      }
    });
  }

  public addDomainServiceRenewal(domainService: DomainServiceItemPlus): void
  {
    if (this.isDomainAdded || domainService.maxCoverage != null)
    {
      this.shoppingCartDataService.addDomainServiceRenewal(domainService.id, domainService.objectId, domainService.coverageId).subscribe({
        next: () => 
        {
          this.checkoutManager.addDomainService(this.parentId, domainService.id);
          this.toastService.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
        },
        error: (response: HttpErrorResponse) =>
        {
          this.errorMessage(response);
        }
      });
    }
  }

  public onRemoveClicked(domainService: any): void
  {
    this.shoppingCartDataService.removeDomainServiceItem(this.parentId, domainService.id).subscribe({
      next: () => 
      {
        this.checkoutManager.removeDomainService(this.parentId, domainService.id);
        this.toastService.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [domainService], domainService.rate.finalAmount);
      },
      error: () =>
      {
        this.toastService.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  public onItemCoverageChange(product: any, $event): void
  {
    const coverageId: number = $event.value;

    this.shoppingCartDataService.addDomainService(this.parentId, product.id, coverageId).subscribe({
      next: () => 
      {
        this.checkoutManager.setDomainServiceCoverage(this.parentId, product.id, coverageId);
      }
    });
  }

  public closeHelpModal(): void
  {
    this.showHelpModal = false;
  }

  public onShowHelpModalClick(type: EDomainServiceType): void
  {
    const domainServiceTemplate = new DomainServiceTemplate(this.translateService);

    this.helpHTML = domainServiceTemplate.DomainServiceHelpMessge.get(type);

    this.showHelpModal = true;
  }

  public errorMessage(response: HttpErrorResponse): void
  {
    if (response.status === 500 && response?.error?.message)
    {
      this.toastService.setErrorToast(response.error.message);
    }
    else
    {
      this.toastService.setErrorToast(ErrorMessageConst.OperationFailed);
    }
  }
}
