
export namespace PageHelper
{
  export const MobileWidth: number = 767;
  export const TabletWidth: number = 992;
}

//#region Enums

export const enum EToastrPosition
{
  TopRightClass = "top-right",
  TopLeftClass = "top-left",
  BottomRightClass = "bottom-right",
  BottonLeftClass = "bottom-left"
}

//#endregion
