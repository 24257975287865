import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { EToastrPosition } from '@core-constants/page-helper.const';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: '[toaster-component]',
  styleUrls: [`./toaster.component.css`],
  templateUrl: `./toaster.component.html`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0
        }),
        style({
          opacity: 1
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1
        }),
        style({
          opacity: 0
        })
      ])))
    ])
  ],
  preserveWhitespaces: false
})
export class ToasterComponent extends Toast implements AfterViewInit
{
  @ViewChild('toastDiv') public toastDiv: ElementRef = undefined;
  public toastType: string = "error";

  constructor(protected toastService: ToastrService,
    public toastPackage: ToastPackage,
    private changeDetectorRef: ChangeDetectorRef)
  {
    super(toastService, toastPackage);
  }

  public ngAfterViewInit(): void
  {
    const toastElement: HTMLElement = this.toastDiv?.nativeElement?.parentElement?.parentElement;
    const overlayContainerElement: HTMLElement = toastElement?.parentElement;

    if (this.toastDiv.nativeElement.parentElement.classList.contains('success'))
    {
      this.toastType = "success";
      this.changeDetectorRef.detectChanges();
    }
    else if (this.toastDiv.nativeElement.parentElement.classList.contains('warning'))
    {
      this.toastType = "warning";
      this.changeDetectorRef.detectChanges();
    }
    else
    {
      this.toastType = "error";
      this.changeDetectorRef.detectChanges();
    }

    this.cleanPositionClasses(overlayContainerElement);
    this.assignContainerPosition(overlayContainerElement, toastElement);
  }

  public cleanPositionClasses(element: HTMLElement): void
  {
    element.classList.remove(EToastrPosition.TopRightClass);
    element.classList.remove(EToastrPosition.TopLeftClass);
    element.classList.remove(EToastrPosition.BottomRightClass);
    element.classList.remove(EToastrPosition.BottonLeftClass);
  }

  // Set the position of the toast container
  public assignContainerPosition(container: HTMLElement, toast: HTMLElement): void
  {
    if (toast.classList.contains(EToastrPosition.TopRightClass))
    {
      container.classList.add(EToastrPosition.TopRightClass);
      return;
    }

    if (toast.classList.contains(EToastrPosition.TopLeftClass))
    {
      container.classList.add(EToastrPosition.TopLeftClass);
      return;
    }

    if (toast.classList.contains(EToastrPosition.BottomRightClass))
    {
      container.classList.add(EToastrPosition.BottomRightClass);
      return;
    }

    if (toast.classList.contains(EToastrPosition.BottonLeftClass))
    {
      container.classList.add(EToastrPosition.BottonLeftClass);
      return;
    }
  }
}
