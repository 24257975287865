import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class EntityEvent
{
  constructor(
    public entityName: string,
    public eventName: string,
    public args?: any[]) { }

  public getBroadcastKey(): string
  {
    return EntityEvent.createBroadcastKey(this.entityName, this.eventName);
  }

  public static createBroadcastKey(entityName: string, eventName: string): string
  {
    return `${entityName}:${eventName}`;
  }
}

interface IBroadcastEvent
{
  key: any;
  data?: any;
}

export class BroadcastService
{
  private persistentBroadcastDict = new Map<string, EntityEvent>();

  private _eventBus: Subject<IBroadcastEvent>;

  private static instance: BroadcastService;

  private constructor()
  {
    this._eventBus = new Subject<IBroadcastEvent>();
  }

  public static get Instance(): BroadcastService
  {
    if (this.instance === null || this.instance === undefined)
    {
      this.instance = new BroadcastService();
    }
    return this.instance;
  }

  // ********************************************************************
  // #region Broadcast Methods
  // ********************************************************************

  private _broadcast(eventName: string, ...args: any[]): void
  {
    const key = eventName;
    const data = args;

    this._eventBus.next({ key, data });
  }

  public broadcast(eventName: string, ...args: any[]): void
  {
    this._broadcast(eventName, args);
  }


  private _broadcastEntityEvent(entityEvent: EntityEvent): void
  {
    const key = entityEvent.getBroadcastKey();
    const data = entityEvent.args;

    this._eventBus.next({ key, data });
  }

  public broadcastEntityEvent(entityName: string, eventName: string, ...args: any[]): void
  {
    const entityEvent: EntityEvent = new EntityEvent(entityName, eventName, args);

    this._broadcastEntityEvent(entityEvent);
  }

  public broadcastPersistentEntityEvent(entityName: string, eventName: string, ...args: any[]): void
  {
    const entityEvent: EntityEvent = new EntityEvent(entityName, eventName, args);

    this.persistentBroadcastDict.set(entityEvent.getBroadcastKey(), entityEvent);

    this._broadcastEntityEvent(entityEvent);
  }

  public on<T>(key: any): Observable<T>
  {
    return this._eventBus.asObservable()
      .pipe(
        filter(event => event.key === key),
        map(event => <T>event.data)
      );
  }

  // #endregion

  // ********************************************************************
  // #region Misc Methods
  // ********************************************************************

  /**
      * Gets an event that was previously published.  If it does not exist, returns null.
      */

  public getPublishedEvent(entityName: string, eventName: string): EntityEvent
  {
    const broadcastKey = EntityEvent.createBroadcastKey(entityName, eventName);

    if (this.persistentBroadcastDict.has(broadcastKey))
    {
      let entityEvent = this.persistentBroadcastDict.get(broadcastKey);

      if (entityEvent == undefined)
      {
        entityEvent = null;
      }

      return entityEvent;
    }
    else
    {
      return null;
    }
  }

  /**
      * Sets (saves) an event for later retreival.  If it already exists, replace with the new value.
      *  Supports updating the persisted event without broadcasting it.
      */

  public setPublishedEvent(entityName: string, eventName: string, ...args: any[]): EntityEvent
  {
    const entityEvent: EntityEvent = new EntityEvent(entityName, eventName, args);
    this.persistentBroadcastDict.set(entityEvent.getBroadcastKey(), entityEvent);

    return entityEvent;
  }

  // #endregion

  // ********************************************************************
  // #region Clear Methods
  // ********************************************************************

  /**
      * Clears the specified persited event.
      * 
      * Fired by crud controller on add, delete, displayList methods
      */

  public clear(eventName: string, entityName: string): void
  {
    this.persistentBroadcastDict.forEach((entityEvent) =>
    {
      if (entityEvent.eventName == eventName && entityEvent.entityName == entityName)
      {
        this.persistentBroadcastDict.delete(entityEvent.getBroadcastKey());
      }
    });
  }

  /**
  * Clears all persited events.
  */

  public clearAll(): void
  {
    this.persistentBroadcastDict.clear();
  }

  public clearAllByEventName(eventName: string): void
  {
    this.persistentBroadcastDict.forEach((entityEvent) =>
    {
      if (entityEvent.eventName == eventName)
      {
        this.persistentBroadcastDict.delete(entityEvent.getBroadcastKey());
      }
    });
  }

  public clearAllByEntityName(entityName: string): void
  {
    this.persistentBroadcastDict.forEach((entityEvent) =>
    {
      if (entityEvent.entityName == entityName)
      {
        this.persistentBroadcastDict.delete(entityEvent.getBroadcastKey());
      }
    });
  }

  // #endregion
}


