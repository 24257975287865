import { EDomainType } from "@core-constants/domain-data.const";
import { LanguageConst } from "@core-constants/language.const";
import { TranslateService } from "@shared-services/translate.service";

export class CloseDomainRequirementTemplate
{
    constructor(private translateService: TranslateService) { }

    // ********************************************************************
    //#region EDU MX HTML REQUIREMENTS
    // ********************************************************************

    private _edumxHTML_ES: string = `
                     <div class="d-flex justify-content-center py-3">
                          <strong class="fs-l msb pb-0 black text-center">Requisitos para la activación de un dominio .EDU.MX</strong>
                      </div>

                      <ol type="1">
                          <div class="row d-flex flex-row justify-content-start align-items-center">
                              <div class="col-11 text-start fs-s pb-0">
                                  <li><span class="pb-1">El nombre de dominio debe de estar derivado de la denominación oficial del plantel
                                          educativo que lo solicita: Ejemplo: INSTITUTO SAN ISAURO CCT.02PJN0839M, Nombre de dominio:
                                          isanisauro.edu.mx, isi.edu.mx, institutosanisauro.edu.mx.</span></li>
                              </div>
                          </div>
                          <br>
                          <div class="row d-flex flex-row justify-content-start align-items-center">
                              <div class="col-11 text-start fs-s pb-0">
                                  <li>
                                      <span class="pb-1">El nombre del Registrante (Titular), debe ser el nombre de la institución
                                          educativa que solicita el registro del nombre de dominio incluyendo la Clave de Centro de
                                          Trabajo(CCT). Ejemplo: INSTITUTO SAN ISAURO CCT.02PJN0839M. Puedes consultar esta clave y el
                                          nombre oficial del plantel educativo en este <u> <a
                                                  href="https://www.siged.sep.gob.mx/SIGED/escuelas.html" target="_blank"
                                                  class="green">enlace</a></u>.
                                      </span>
                                  </li>
                              </div>
                          </div>
                          <br>
                          <div class="row d-flex flex-row justify-content-start align-items-center">
                              <div class="col-11 text-start fs-s pb-0">
                                  <li>
                                      <span class="pb-1">Completar la solicitud de registro digital. Akky enviará un enlace donde quien esté
                                          representando a la institucion educativa deberá:</span>
                                      <ol type="A">
                                          <div class="row d-flex flex-row align-items-center pt-1">
                                              <li>Adjuntar la documentación (Identificación oficial, INE, Pasaporte o Cédula Profesional).
                                              </li>
                                          </div>
                                          <div class="row d-flex flex-row align-items-center pt-1">
                                              <li>Firmar la solicitud digital donde la firma debe ser lo más similar posible a la
                                                  identificación que adjunte. </li>
                                          </div>
                                      </ol>
                                  </li>
                              </div>
                          </div>
                          <br>
                      </ol>`;

    private _edumxHTML_EN: string = `
                          <div class="d-flex justify-content-center py-3">
                              <strong class="fs-l msb pb-0 black text-center">Requirements for activating a .EDU.MX domain</strong>
                          </div>

                          <ol type="1">
                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                  <div class="col-11 text-start fs-s pb-0">
                                      <li><span class="pb-1">The domain name must be derived from the official name of the educational
                                              establishment that requests it: Example: INSTITUTO SAN ISAURO CCT.02PJN0839M, Domain name:
                                              isanisauro.edu.mx, isi.edu.mx, institutosanisauro.edu.mx.</span></li>
                                  </div>
                              </div>
                              <br>
                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                  <div class="col-11 text-start fs-s pb-0">
                                      <li><span class="pb-1">The name of the Registrant, must be the name of educational institution requesting
                                              the registration Of the domain name including the Work Center Code (CCT). Example: INSTITUTO SAN
                                              ISAURO CCT.02PJN0839M. You can check this key and the official name of the educational establishment
                                              in this <u><a href="https://www.siged.sep.gob.mx/SIGED/escuelas.html" target="_blank"
                                                      class="green">link</a></u>.</span></li>
                                  </div>
                              </div>
                              <br>
                              <div class="row d-flex flex-row justify-content-start align-items-center">
                                  <div class="col-11 text-start fs-s pb-0">
                                      <li><span class="pb-1">Complete the digital registration application. Akky will send a link where whoever is
                                              representing the educational institution should:</span>
                                          <ol type="A">
                                              <div class="row d-flex flex-row align-items-center pt-1">
                                                  <li>Attach the documentation (Official identification, INE, Passport or ProfessionalID).</li>
                                              </div>
                                              <div class="row d-flex flex-row align-items-center pt-1">
                                                  <li>Sign the digital application where the signature must be as similar as possible to the
                                                      identification you attach.</li>
                                              </div>
                                          </ol>
                                      </li>
                                  </div>
                              </div>
                              <br>
                          </ol>
     `;

    public get _edumxHTML(): string
    {
        if (this.translateService.languageCode == LanguageConst.ES)
        {
            return this._edumxHTML_ES;
        }
        else
        {
            return this._edumxHTML_EN;
        }
    }

    // #endregion

    // ********************************************************************
    //#region GOB MX HTML REQUIREMENTS
    // ********************************************************************

    private _gobmxHTML_ES: string = `

    <div class="d-flex justify-content-center py-3">
        <strong class="fs-l msb pb-0 black text-center">Requisitos para la activación de un dominio GOB.MX</strong>
    </div>

    <ol type="1">
        <div class="row d-flex flex-row justify-content-start align-items-center">
            <div class="col-11 text-start fs-s pb-0">
                <li><span class="pb-1">El nombre del Registrante (Titular), debe ser el nombre de la dependencia
                        gubernamental que solicita el registro del nombre de dominio. Ejemplo: H. Ayuntamiento de
                        Monterrey, N.L.</span></li>
            </div>
        </div>
        <br>
        <div class="row d-flex flex-row justify-content-start align-items-center">
            <div class="col-11 text-start fs-s pb-0">
                <li><span class="pb-1">Completar la solicitud de registro digital. Akky enviará un enlace donde quien
                        esté representando a la dependencia deberá:</span>
                    <ol type="A">
                        <div class="row d-flex flex-row align-items-center pt-1">
                            <li>Adjuntar la documentación (Nombramiento emitido por la dependencia a nombre del firmante
                                e identificación oficial.)</li>
                        </div>
                        <div class="row d-flex flex-row align-items-center pt-1">
                            <li>Firmar la solicitud digital donde la firma debe ser lo más similar posible a la
                                identificación que adjunte</li>
                        </div>
                    </ol>
                </li>
            </div>
        </div>
        <br>
    </ol>
      `;

    private _gobmxHTML_EN: string = `
<div class="d-flex justify-content-center py-3">
    <strong class="fs-l msb pb-0 black text-center">Requirements for activating a .GOB.MX domain.</strong>
</div>
<ol type="1">
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">The name of the Registrant (Holder), must be the name of the government agency
                    requesting the registration of the domain name. Example: H. Monterrey City Council, N.L.</span>
            </li>
        </div>
    </div>
    <br>
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">Complete the digital registration application. Akky will send a link where
                    whoever is representing the agency should:</span>
                <ol type="A">
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Attach the documentation (Offical appointment issued by the agency on behalf of the
                            signer and official identification).</li>
                    </div>
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Sign the digital application where the signature must be as similar as possible to the
                            identification you attach.</li>
                    </div>
                </ol>
            </li>
        </div>
    </div>
    <br>
</ol>
     `;

    public get _gobmxHTML(): string
    {
        if (this.translateService.languageCode == LanguageConst.ES)
        {
            return this._gobmxHTML_ES;
        }
        else
        {
            return this._gobmxHTML_EN;
        }
    }

    // #endregion

    // ********************************************************************
    //#region NET MX HTML REQUIREMENTS
    // ********************************************************************

    private _netmxHTML_ES: string = `<div class="d-flex justify-content-center py-3">
    <strong class="fs-l msb pb-0 black text-center">Requisitos para la activación de un dominio NET.MX</strong>
</div>

<ol type="1">
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">El nombre del Registrante (Titular), debe ser el nombre de la razón social del
                    proveedor de servicios de telecomunicaciones.</span></li>
        </div>
    </div>
    <br>
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">Completar la solicitud de registro digital. Akky enviará un enlace donde quien
                    esté representando a la dependencia deberá:</span>
                <ol type="A">
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Adjuntar la documentación (Identificación oficial, INE, Pasaporte) y Folio Electrónico
                            otorgado por el IFT.</li>
                    </div>
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Firmar la solicitud digital donde la firma debe ser lo más similar posible a la
                            identificación que adjunte.</li>
                    </div>
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Realizar el pago correspondiente.</li>
                    </div>
                </ol>
            </li>
        </div>
    </div>
    <br>
</ol>`;

    private _netmxHTML_EN: string = `
<div class="d-flex justify-content-center py-3">
    <strong class="fs-l msb pb-0 black text-center">Requirements for activating a .NET.MX domain</strong>
</div>

<ol type="1">
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">The name of the Registrant (Holder), must be the name of the business name of the telecomunications service provider.</span></li>
        </div>
    </div>
    <br>
    <div class="row d-flex flex-row justify-content-start align-items-center">
        <div class="col-11 text-start fs-s pb-0">
            <li><span class="pb-1">Complete the digital registration application. Akky will send a link where
                    whoever is representing the agency should:</span>
                <ol type="A">
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Attach the documentation (Official Identification, INE, Passport) and Electronic folio
                            granted by IFT.</li>
                    </div>
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Sign the digital application where the signature must be as similar as possible to the
                            identification you attach.</li>
                    </div>
                    <div class="row d-flex flex-row align-items-center pt-1">
                        <li>Make corresponding payment.</li>
                    </div>
                </ol>
            </li>
        </div>
    </div>
    <br>
</ol>`;

    public get _netmxHTML(): string
    {
        if (this.translateService.languageCode == LanguageConst.ES)
        {
            return this._netmxHTML_ES;
        }
        else
        {
            return this._netmxHTML_EN;
        }
    }

    // #endregion

    public CloseDomainRequirementMessage = new Map<EDomainType, string>([
        [EDomainType.edu_mx, this._edumxHTML],
        [EDomainType.gob_mx, this._gobmxHTML],
        [EDomainType.net_mx, this._netmxHTML]
    ]);
}
