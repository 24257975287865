import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-secure-purchase-message',
  templateUrl: './secure-purchase.component.html',
  styleUrls: ['./secure-purchase.component.css']
})
export class SecurePurchaseComponent
{
  @Input() public mobile: boolean = true;
  @Input() public desktop: boolean = true;
}


