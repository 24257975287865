export namespace SesionStorageConst
{
  export const TokenKey = 'auth-token';
  export const RefreshTokenKey = 'auth-refreshtoken';
  export const UserKey = 'auth-user';
  export const HubspotutkCookie = 'hubspotutk';
  export const TokenHeaderKey = 'Authorization';
  export const CartCookieKey = 'cart-cookie';
  export const CartItems = 'cart-items';
  export const CartItemsGrouped = 'cart-items-grouped';
  export const CartItemsCount = 'cart-items-count';
  export const CartTotal = 'cart-total';
  export const CartVat = 'cart-vat';
  export const CartCoupon = 'cart-coupon';
  export const OrderId = 'order-id';
  export const UserAccessMethod = 'userAccessMethod';
  export const UserId = 'user-id';
  export const SFAccount = 'sf-account';
  export const AkkyTrxId = 'akky-trx-id';

  export const MSVCToken = 'auth-msvc-token';
  export const MSVCRefreshToken = 'auth-msvc-refresh-token';
}

export namespace LocalStorageConst
{
  export const SelectedLang = "selectedLang";
}
