import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { RouteHelper } from "@core-helpers/route.helper";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit 
{
  constructor(protected routeHelperService: RouteHelper) { }

  public ngOnInit(): void { }

  public redirectToHelp(): void
  {
    window.open(`${this.routeHelperService.ecommerceURL}/ayuda/ayuda`, '_blank');
  }
}
