import { EContactPersonType, EContactType } from "@core-constants/contact-data.const";
import { EDomainServiceType, EDomainType } from "@core-constants/domain-data.const";
import { ECreditCardType, EPaymentMethodType } from "@core-constants/payment-data.const";
import { EServiceType } from "@core-constants/product-service.const";
import { ECartItemOperation, ECartItemStatus } from "@core-constants/shopping-cart.const";

export class PaymentOrderDto
{
  public token: string;
  public cartId: string;
  public userId: string;
  public couponId: string;
  public paoId: number;
  public rfc: InvoiceDto;
  public paymentInfo: PaymentInfoDto;
  public items: ItemPaymentDto[];
}

export class Invoice
{
  public type: string;
  public billingRfc: string;
  public tradeName: string;
}

export class InvoiceDto
{
  public billingRfc: string;
  public tradeName: string;
  public postalCode: string;
  public fiscalRegime: string;
}

export class Rate
{
  public grossAmount: number;//Shouldnt be used in frontend
  public finalAmount: number;
  public offerAmount?: number;
  public message: string;
}

export class ItemPaymentDto
{
  public name: string;
  public isAllowToPublishRegistrantInfo: boolean;
  public isAllowToPublishContactInfo: boolean;
  public coverageId: number;
  public type: EServiceType; //Domain = 0, service
  public domainType: EDomainType;
  public encryptedAuthInfo?: string;
  public domainServices: DomainServicePaymentDto[];
  public variousServices: VariousServicePaymentDto[];
  public contacts: ContactPaymentDto[];
}

export class DomainServicePaymentDto
{
  public id: number;
  public name: string;
  public status: ECartItemStatus; //Registration = 0, Renewal
  public operationId: ECartItemOperation;
  public coverageId: number;
  public type: EDomainServiceType;
}

export class VariousServicePaymentDto
{
  public id: number;
  public name: string;
  public status: ECartItemStatus; //Registration = 0, Renewal
  public operationId: ECartItemOperation;
}

export class ContactPaymentDto
{
  public id: string;
  public name: string;
  public contactType: EContactType; // AdministrativeContact = 1, TechnicalContact, BillingContact, Registrant,
  public contactPersonType: EContactPersonType; // NaturalPerson = 1, LegalPerson
  public hasBeenUsedAsRegistrant: boolean;
}

export class PaymentInfoDto
{
  public paymentType: EPaymentMethodType; // CreditCard =1, BankDeposit, Paypal, NA
  public creditCartPaymentInfo: CreditCartPaymentInfoDto;
  public payPalPaymentInfoDto: PayPalPaymentInfoDto;
}

export class CreditCartPaymentInfoDto
{
  public userName: string;
  public creditCardNumber: string;
  public cvv: string;
  public expireYear: string;
  public expireMonth: string;
  public mIF?: number | undefined = undefined;
  public mIFBank?: string;
  public automaticRenewal: boolean;
  public deviceSessionId: string;
  public creditCardType: ECreditCardType; //AmericanExpress = 0, MasterCard, Visa
  public amount: number;
}

export class PayPalPaymentInfoDto
{
  public orderId: string;
  public status: string;
  public incPaymentReference: string;
}
