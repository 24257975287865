import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[rfc]'
})
export class RFCInputDirective implements OnInit
{
  protected input: HTMLInputElement;

  @Output() public onRFC: EventEmitter<string>;

  constructor(protected elementRef: ElementRef)
  {
    this.input = this.elementRef.nativeElement as HTMLInputElement;
    this.onRFC = new EventEmitter<string>();
  }
  public ngOnInit(): void { }

  public get length(): number
  {
    return this.input.value.length;
  }

  @HostListener('keypress', ['$event'])
  public onKeyPress(e: KeyboardEvent): boolean
  {
    const rfc = this.input.value;

    if (rfc.length > 13)
    {
      const selectedText = window.getSelection().toString();

      if (selectedText.length == rfc.length)
      {
        return true;
      }
      return false;
    }

    return true;
  }

  @HostListener('keyup')
  public onKeyUp(): boolean
  {
    const rfc = this.input.value;

    if (rfc.length >= 12)
    {
      this.onRFC.emit(this.input.value);
    }

    this.onRFC.emit("");

    return true;
  }
}
