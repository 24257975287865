import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EStepsUrls } from '@core-constants/step.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { TokenManager } from '@core-managers/token.manager';
import { UserTokenStorage } from '@shared-base/generic-clases.base';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ValidConfirmationStepGuard 
{
  constructor(private router: Router,
    public userAccessDataService: UserAccessDataService,
    public tokenManager: TokenManager) { }

  public canActivate(): Observable<boolean>
  {
    const user: UserTokenStorage = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();
    const orderId: number = this.tokenManager.getOrderId();

    if (user && token && orderId)
    {
      return this.userAccessDataService.$validateSession();
    }
    else
    {
      this.router.navigate([EStepsUrls.Step1]);
      return of(false);
    }
  }
}
