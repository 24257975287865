import { EGtmEvent, GtmConst } from '@core-constants/gtm-const';
import { EServiceTypeName, ServiceDataConst } from '@core-constants/product-service.const';
import { IGtmTrack } from '@core-models/gtm.model';
import { IProduct, IProductEvent } from '@core-models/product.model';
import { GroupedShoppingCart, ShoppingCartPlusItem } from '@core-models/shopping-cart-plus.model';
import { Tools } from '@shared-utils/tools.util';
import sha256 from 'crypto-js/sha256';
import { IPurchaseSuggestionRate, IShoppingCartStoredSuggestionViewModel } from '@core-models/purchase-suggestion.model';

declare let dataLayer: any[];

export class GtmTrackingService
{
  private static readonly domainNameService: string = "Dominio ";

  public static registerEvent(track: IGtmTrack, paymentType: string, products?: ShoppingCartPlusItem): void
  {
    const data = {
      event: 'transaction',
      payment_type: paymentType,
      ecommerce: {
        purchase: {
          actionField: {
            id: track.transactionId,
            revenue: track.value
          },
          products
        }
      }
    };
    dataLayer.push(data);
  }

  public static registerGenericEvent(eventName: string): void
  {
    const data = {
      event: eventName
    };
    dataLayer.push(data);
  }

  public static registerEventCheckout(data: any): void
  {
    dataLayer.push(data);
  }

  public static addToCartEvent(event: EGtmEvent, items: any): void
  {
    dataLayer.push({ ecommerce: null });

    const data = {
      event,
      ecommerce: items
    };
    this.registerEventCheckout(data);
  }

  public static sendAcquisitionEvent(): void
  {
    this.registerGenericEvent('adquisicion_trigger');
  }

  public static sendEcommerceEvent(transactionId: string, value: number, paymentType: string, itemList: any[]): void
  {
    this.registerEvent({
      transactionId,
      value
    }, paymentType, this.mapProducts(this.buildItemList(itemList)));
  }

  public static buildItemList(productList: any[]): IProduct[]
  {
    const products: IProduct[] = [];
    productList.forEach(product =>
    {
      products.push(this.buildItem(product));
    });
    return products;
  }

  private static buildItem(item: any): any
  {
    return {
      id: item.item_id,
      name: item.item_name,
      price: item.price,
      quantity: item.quantity,
      category: item.item_category.concat('/').concat(item.item_category2),
      isPublic: true
    };
  }

  public static mapProducts(products: IProduct[]): any
  {
    return products.map(this.mapProduct);
  }

  private static mapProduct(product: IProduct, index: number): any
  {
    return {
      name: product.name,
      id: product.id,
      price: product.price,
      currency: 'MXN',
      quantity: product.quantity,
      category: product.category,
      index
    };
  }

  public static loginEvent(event: EGtmEvent, email: string): void
  {
    const data = {
      event,
      method: 'email',
      user_id: sha256(email).toString()
    };
    dataLayer.push(data);
  }

  public static removeFromCartEvent(event: EGtmEvent, items: GroupedShoppingCart[], value: number): void
  {
    this.sendDataLayerEcommerceEvent(event, items, value);
  }

  public static sendDataLayerEcommerceEvent(event: EGtmEvent, cartList: any[], value: number): void
  {
    let productList: IProduct[] = [];
    productList = this.collectGTMCartItems(cartList);
    this.collectGTMDomainServices(cartList, productList);
    const items: any = this.mapProducts(productList);

    dataLayer.push({ ecommerce: null });
    const data = {
      event,
      value,
      ecommerce: { items }
    };
    dataLayer.push(data);
  }

  /**
 * Recolecta en una lista de tipo IProduct los items principales alojados en el carrito de compras
 * @param items
 * @returns
 */
  public static collectGTMCartItems(items: any[]): IProduct[]
  {
    const productList: IProduct[] = [];
    if (!Tools.isNullOrEmpty(items))
    {
      items.map(item =>
      {
        if (item.product.type == EServiceTypeName.Service)
        {
          productList.push({
            id: item.product.serviceData.additionalServicePlan,
            name: ServiceDataConst.AdditionalServicePlan.get(Number(item.product.serviceData.additionalServicePlan)),
            listId: item.product.serviceData.additionalServicePkg,
            listName: ServiceDataConst.AdditionalServicePkg.get(Number(item.product.serviceData.additionalServicePkg)),
            coverage: this.formatCoverage(item.product.coverageOptions.find(i => i.id == item.product.coverageId).value),
            price: item.product.coverageOptions.find(i => i.id == item.product.coverageId).rate.finalAmount,
            category: ServiceDataConst.AdditionalServicePkg.get(Number(item.product.serviceData.additionalServicePkg)),
            variant: ServiceDataConst.AdditionalServicePlan.get(Number(item.product.serviceData.additionalServicePlan)),
            discount: item.product.couponAmount,
            isPublic: true,
            quantity: 1
          });


        }
        else
        {
          productList.push({
            id: item.product.type == EServiceTypeName.Domain ? "Dominio" : item.product.type == EServiceTypeName.DiverseService ? "Servicio Diverso" : "Servicio de Dominio",
            name: item.product.type == EServiceTypeName.Domain ? item.product.fullName : item.product.name,
            price: item.product.coverageOptions.find(i => i.id == item.product.coverageId).rate.finalAmount,
            coverage: this.formatCoverage(item.product.coverageOptions.find(i => i.id == item.product.coverageId).value),
            listId: item.product.id,
            listName: item.product.domainType,
            category: item.product.type,
            discount: item.product.couponAmount,
            variant: item.product.name,
            isPublic: true,
            quantity: 1
          });
        }
      });
    }
    return productList;
  }

  /**
 * Recolecta en una lista de tipo IProduct los servicios de dominio agregados al carrito de compras
 * @param items
 * @param productList
 * @returns
 */
  public static collectGTMDomainServices(items: any[], productList: IProduct[]): IProduct[]
  {
    if (!Tools.isNullOrEmpty(items))
    {
      items.map(item =>
      {
        if (item.domainServices === null) { return; }
        if (item.domainServices === undefined) { return; }
        if (!Tools.isNullOrEmpty(item.domainServices))
        {
          item.domainServices.map(subItem =>
          {
            if (subItem.isAdded)
            {
              const product: IProduct = {
                id: "Servicio de dominio",
                name: subItem.name,
                price: subItem.rate.finalAmount,
                category: subItem.type,
                coverage: this.formatCoverage(subItem.coverageOptions.find(i => i.id == subItem.coverageId).value),
                variant: subItem.type,
                quantity: 1,
                isPublic: false
              };
              productList.push(product);
            }
          });
        }
      });
    }
    return productList;
  }

  public static formatCoverage(coverage: number): string
  {
    let formatedCoverage: string = "";
    if (coverage >= 12)
    {
      formatedCoverage = (coverage / 12) + "y";
    }
    else
    {
      formatedCoverage = coverage + "m";
    }
    return formatedCoverage;
  }

  public static errorMessageEvent(errorMessageText: string): void
  {
    const data = {
      event: EGtmEvent.ErrorMessage,
      error_message_text: errorMessageText,
      error_message_location: window.location.href
    };
    dataLayer.push(data);
  }

  public static addSuggestionToCartEvent(listItems: IShoppingCartStoredSuggestionViewModel[], eventLocation: string, isDomain: boolean, coverage: IPurchaseSuggestionRate): void
  {
    const productList: IProductEvent[] = [];

    listItems.map(item =>
    {

      let product : IProductEvent;
      if (isDomain)
      {
        product = {
          id: item.suggestionId,
          name: this.domainNameService + item.serviceToOffer.split("=")[1],
          price: coverage?.finalAmount,
          discount: coverage?.offerAmount ? (coverage?.offerAmount - coverage?.finalAmount) : 0,
          quantity: 1,
          category: "Registro Dominio",
          category3: this.formatCoverage(item.coverageMonths),
          listName: "Sugerencias Checkout",
          listId: "Sugerencias",
          variant: "Registro Dominio",
          isPublic: true,
          addedByAI: false,
          addedBySuggestion: true,
          eventLocation: eventLocation
        };
      }
      else
      {
        const arrServiceOffer = item.serviceToOffer.split(",");

        const resultService = arrServiceOffer.map(e =>
        {
          const keyVal = e.split('=');

          if (keyVal[0] == 'ASP_ID')
          {
            return { ASP_ID: keyVal[0], value: keyVal[1] };
          }
          else
          {
            return { APL_ID: keyVal[0], value: keyVal[1] };
          }
        });

        product = {
          id: item.suggestionId,
          name: ServiceDataConst.AdditionalServicePlan.get(Number(resultService.find(x => x.APL_ID).value)),
          price: coverage?.finalAmount,
          discount: coverage?.offerAmount ? (coverage?.offerAmount - coverage?.finalAmount) : 0,
          quantity: 1,
          category: ServiceDataConst.AdditionalServicePkg.get(Number(resultService.find(x => x.ASP_ID).value)),
          category3: this.formatCoverage(item.coverageMonths),
          listName: ServiceDataConst.AdditionalServicePkg.get(Number(resultService.find(x => x.ASP_ID).value)),
          listId: "Sugerencias Checkout",
          variant: ServiceDataConst.AdditionalServicePlan.get(Number(resultService.find(x => x.APL_ID).value)),
          isPublic: true,
          addedByAI: false,
          addedBySuggestion: true,
          eventLocation: eventLocation
        };
      }

      productList.push(product);

      this.addToCartEvent(EGtmEvent.AddToCart, this.mapProductsEvent(productList));
    });
  }

  private static mapProductsEvent(products: IProductEvent[]): any
  {
    return { items: products.map(this.mapProductEvent) };
  }

  private static mapProductEvent(product: IProductEvent, index: number): any
  {
    return {
      item_name: product.name,
      item_id: product.id,
      price: product.price,
      currency: 'MXN',
      quantity: product.quantity,
      discount: product.discount,
      item_brand: GtmConst.TrackingBrand,
      item_category: product.category,
      item_category2: product.coverage,
      item_category3: product.category3,
      item_variant: product.variant,
      item_list_name: product.listName,
      item_list_id: product.listId,
      addedByAI: product.addedByAI ?? false,
      added_by_suggestion: product.addedBySuggestion ?? false,
      event_location: product.eventLocation,
      index
    };
  }
}
