import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenManager } from "@core-managers/token.manager";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class DomainDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService,
    protected tokenManager: TokenManager)
  {
    super(http, 'domains', translateService);
  }

  public search(domain: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const domains: string[] = [domain];

    const token = this.tokenManager.getToken();
    const lang = this.translateService.languageCode;

    const body = {
      domainSearchList: domains
    };
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.post(this.createUrl('search'), body, queryDef.getRequestConfigWithAuthorizationToken(token, lang)).pipe(share());

    return observable;
  }
}
