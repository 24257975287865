import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterComponent } from '@core-components/toaster/toaster.component';
import { AddLanguageInterceptor } from '@shared-interceptors/language.interceptor';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { ShareModule } from './_shared/share.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { MainComponent } from './layouts/main/main.component';
import { MaintenanceComponent } from './layouts/maintenance/maintenance.component';
import { NavBarResponsiveComponent } from './layouts/navbar/navbar.component';
import { PayPalCancelComponent } from './layouts/payment/paypal-cancel/paypal-cancel.component';
import { PayPalSuccessComponent } from './layouts/payment/paypal-success/paypal-success.component';
import { UserAccessComponent } from './layouts/user-access/user-access.component';
import { BillingPaymentStepComponent } from './steps/billing-payment/billing-payment.component';
import { CreditCardComponent } from './steps/billing-payment/credit-card/credit-card.component';
import { ConfirmationStepComponent } from './steps/confirmation/confirmation.component';
import { DomainServicesStepComponent } from './steps/domains-services/domains-services.component';
import { PurchaseSuggestionItemComponent } from './steps/domains-services/purchase-suggestion-item/purchase-suggestion-item.component';
import { DomainServiceAccordionComponent } from './steps/domains-services/shopping-cart-item/domain-services-accordion/domain-services-accordion.component';
import { ShoppingCartItemComponent } from './steps/domains-services/shopping-cart-item/shopping-cart-item.component';
import { ShoppingCartSuggestionItemComponent } from './steps/domains-services/shopping-cart-item/suggestion-item/shopping-cart-suggestion-item.component';
import { ContactFormComponent } from './steps/registration-data/contact-form/contact-form.component';
import { ContactSelectionComponent } from './steps/registration-data/contact-selection/contact-selection.component';
import { RegistrationDataStepComponent } from './steps/registration-data/registration-data.component';
import { Environment } from '@environments';
import { IndividualSuggestionItemComponent } from './steps/domains-services/shopping-cart-item/suggestion-item/individual-suggestion-item/individual-suggestion-item.component';
import { UpgradeSuggestionItemComponent } from './steps/domains-services/shopping-cart-item/suggestion-item/upgrade-suggestion-item/upgrade-suggestion-item.component';
import { AddAuthorizationMSVCToken } from '@shared-interceptors/msvc-interceptor';
import { UserAccessDataService } from './_core/data-services/security/user-access.data-service';
import { catchError, of } from 'rxjs';

export const Options: Partial<IConfig> | (() => Partial<IConfig>) = null;
/* eslint-disable */
export function appInitialializerFactory(userAccessDataService: UserAccessDataService)
{
  return () => userAccessDataService.getMSVCInitialToken()
    .pipe(
      catchError(() => of(null))
    );
}
/* eslint-disable */

@NgModule({
  declarations: [
    AppComponent,
    NavBarResponsiveComponent,
    FooterComponent,
    MainComponent,
    MaintenanceComponent,
    PayPalSuccessComponent,
    PayPalCancelComponent,

    DomainServicesStepComponent,
    RegistrationDataStepComponent,
    BillingPaymentStepComponent,
    ConfirmationStepComponent,

    DomainServiceAccordionComponent,
    ShoppingCartItemComponent,
    ShoppingCartSuggestionItemComponent,
    PurchaseSuggestionItemComponent,

    CreditCardComponent,
    UserAccessComponent,
    ContactFormComponent,
    ContactSelectionComponent,
    IndividualSuggestionItemComponent,
    UpgradeSuggestionItemComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaV3Module,
    FormsModule,
    ShareModule,

    BrowserAnimationsModule,
    NgxMaskModule.forRoot(Options),
    ToastrModule.forRoot({
      toastComponent: ToasterComponent
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialializerFactory,
      multi: true,
      deps: [UserAccessDataService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddLanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthorizationMSVCToken,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: Environment.RecaptchaV3SiteKey
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

