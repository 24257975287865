import { ECreditCardType } from "@core-constants/payment-data.const";

export interface ICreditCard
{
  minLength: number;
  maxLength: number;
  cvvDigits: number;
  image: string;
  pattern: RegExp | null;
}

export class CreditCardData
{
  public holder: string = "";
  public cardNumber: string = "";
  public expiryDate: string = "";
  public expiryMonth: number = 0;
  public expiryYear: number = 0;
  public cvv: string = "";
  public type: ECreditCardType = ECreditCardType.Unknown;
}

export class PayPalData
{
  public orderId: string;
  public status: string;
  public incPaymentReference: string;
}

export class TransactionItem
{
  public name: string;
  public unitAmount: UnitAmount;
  public quantity: string;
  public description?: string;
}

export class UnitAmount
{
  public currencyCode: string;
  public value: number;
}
