import { RegexConst } from "@core-constants/regex.const";
import { ICreditCard } from "@core-models/payment.model";
import { DropDownItem } from "@shared-base/generic-clases.base";

export enum EPaymentMethodType
{
  CreditCard = 1,
  BankDeposit,
  Paypal,
  NA,
  OpenPayCard,
  OpenPayPayNet
}

export const enum ECreditCardType
{
  AmericanExpress = 0,
  Mastercard,
  Visa,
  Unknown
}

export enum ECreditCardTypeName
{
  AmericanExpress = 'AmericanExpress',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
  Unknown = 'Unknown'
}

export const enum ECreditCardError
{
  InvalidLength = 'InvalidLength',
  InvalidNumber = 'InvalidNumber',
  InvalidDate = 'InvalidDate',
  InvalidCVV = 'InvalidCVV',
  None = 'None'
}

export namespace PaymentConst
{
  export const CreditCardConfig: Record<ECreditCardTypeName, ICreditCard> =
  {
    AmericanExpress: {
      minLength: 15,
      maxLength: 15,
      image: 'assets/payment/amex.svg',
      cvvDigits: 4,
      pattern: RegexConst.AMEXPattern
    },
    Mastercard: {
      minLength: 16,
      maxLength: 16,
      image: 'assets/payment/mastercard.svg',
      cvvDigits: 3,
      pattern: RegexConst.MCPattern
    },
    Visa: {
      minLength: 16,
      maxLength: 16,
      image: 'assets/payment/visa.svg',
      cvvDigits: 3,
      pattern: RegexConst.VisaPattern
    },
    Unknown: {
      minLength: 15,
      maxLength: 16,
      image: 'assets/payment/card-default.svg',
      cvvDigits: 3,
      pattern: null
    }
  };


  export const BanksNames = new Map<string, string>([
    ['afirme', 'Afirme'],
    ['amexp', 'American Express'],
    ['banamex', 'Banamex'],
    ['banbajio', 'BanBajio'],
    ['azteca', 'Banco Azteca'],
    ['invex', 'Banco Invex'],
    ['bbva', 'BBVA'],
    ['banjercito', 'Banjercito'],
    ['banorte', 'Banorte'],
    ['banregio', 'Banregio'],
    ['falabella', 'Falabella'],
    ['hsbc', 'HSBC'],
    ['inbursa', 'Inbursa'],
    ['liverpool', 'Liverpool'],
    ['mifel', 'Mifel'],
    ['santander', 'Santander'],
    ['scotiabank', 'Scotiabank']
  ]);

  export const CreditCardErrorMessages = new Map<ECreditCardError, string>([
    [ECreditCardError.InvalidLength, "Longitud inválida"],
    [ECreditCardError.InvalidCVV, "CVV inválido"],
    [ECreditCardError.InvalidNumber, "Número inválido"],
    [ECreditCardError.InvalidDate, "Fecha invalida"]

  ]);

  export const CreditCardIdentifier = new Map<ECreditCardTypeName, number>([
    [ECreditCardTypeName.AmericanExpress, ECreditCardType.AmericanExpress],
    [ECreditCardTypeName.Mastercard, ECreditCardType.Mastercard],
    [ECreditCardTypeName.Visa, ECreditCardType.Visa],
    [ECreditCardTypeName.Unknown, ECreditCardType.Unknown]
  ]);

  export class MIFPayment
  {
    public static readonly Months: DropDownItem[] = [
      { value: undefined, label: 'Seleccionar' },
      { value: 3, label: '3' },
      { value: 6, label: '6' }
    ];

    public static getBankName(value: string): string
    {
      if (value)
      {
        return BanksNames.get(value) ?? value;
      }

      return undefined;
    }
  }
}
