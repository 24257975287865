import { Injectable } from "@angular/core";
import { PaymentsDataService } from "@core-data-services/payments.data-service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OrderDataResolver  
{
  constructor(private service: PaymentsDataService) { }

  public resolve(): Observable<any> | Promise<any> | any
  {
    return this.service.getOrder();
  }
}
