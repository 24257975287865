import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenManager } from "@core-managers/token.manager";
import { Environment } from '@environments';
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { LoginDto, RefreshTokenDto, UserDto, ValidateSessionDto } from '@shared-base/generic-clases.base';
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { HttpHeadersConst } from '@core-constants/http-headers.const';
import { IRefreshTokenMSCVDto, ITokenMSVCResponse } from '@core-models/msvc-tokens.model';

@Injectable({ providedIn: 'root' })
export class UserAccessDataService extends EntityDataServiceBase
{
  private handshakeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, "user-access", translateService);
  }

  public rfcList(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const userId = this.tokenManager.getUser().id;
    const token = this.tokenManager.getToken();

    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    return this.http.get(this.createUrl("list/rfc", userId), queryDef.getRequestConfigWithAuthorizationToken(token))
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public rfcFiscalRegimeList(rfc: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    return this.http.get(this.createUrl("list/fiscal-regime", rfc), queryDef.getRequestConfigWithAuthorizationToken(token))
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public login(loginData: LoginDto): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    return this.http.post(this.createUrl("login"), loginData, queryDef.getRequestConfig())
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public logout(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    queryDef.addParam("language", this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    return this.http.delete(this.createUrl("logout"), queryDef.getRequestConfigWithAuthorizationToken(token))
      .pipe(share(), map((response: any) =>
      {
        return response;
      }));
  }

  public signUp(entity: UserDto): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.post(this.createUrl("singup"), entity, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public refreshToken(refreshTokenDto: RefreshTokenDto): Observable<any>
  {
    const subject = this.http.post(this.createUrl("refresh"), refreshTokenDto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public $validateSession(): Observable<boolean>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const validateSessionDto: ValidateSessionDto =
    {
      token: this.tokenManager.getToken()
    };

    const observable = this.http.post(this.createUrl("session"), validateSessionDto, queryDef.getRequestConfig())
      .pipe(share(), catchError(() => { return of(false); }), map((response: any) =>
      {
        return response ? true : false;
      }));

    this._handleObservableResponse(observable);

    return observable;
  }

  public validateSession(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());


    const validateSessionDto: ValidateSessionDto =
    {
      token: this.tokenManager.getToken()
    };

    const subject = this.http.post(this.createUrl("session"), validateSessionDto, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public redirectToAkkyOnLogin(postBackUrl: string = ""): void
  {
    this.redirectToAkky(postBackUrl, false);
  }

  public redirectToAkkyOnLogout(postBackUrl: string = ""): void
  {
    this.redirectToAkky(postBackUrl, true);
  }

  private redirectToAkky(postBackUrl: string = "", isLogout: boolean = false): void
  {
    const f = document.createElement('form');
    f.action = `${Environment.PortalAkkyURL}session`;
    f.method = 'POST';

    if (isLogout === false)
    {
      const auth = document.createElement('input');
      auth.type = 'hidden';
      auth.name = 'Authorization';
      auth.value = 'Bearer ' + this.tokenManager.getToken();

      f.appendChild(auth);

      if (postBackUrl != "")
      {
        const redirect = document.createElement('input');
        redirect.type = 'hidden';
        redirect.name = 'RedirectUrl';
        redirect.value = postBackUrl;

        f.appendChild(redirect);
      }
    }
    else
    {
      const logout = document.createElement('input');
      logout.type = 'hidden';
      logout.name = 'IsLogout';
      logout.value = "true";

      f.appendChild(logout);
    }

    document.body.appendChild(f);
    f.submit();
  }

  public getMSVCToken(): Observable<any>
  {
    const subject = this.http.get(this.createUrl("authorization/token")).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getMSVCInitialToken(): Observable<any>
  {
    return this.http.get(this.createUrl("authorization/token")).pipe(
      map((response: ITokenMSVCResponse) =>
      {
        this.tokenManager.saveMSVCToken(response);
        return response;
      })
    );
  }

  public getMSVCRefreshToken(dto: IRefreshTokenMSCVDto): Observable<any>
  {
    const subject = this.http.post(this.createUrl("authorization/refresh-token"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getInitialHandshake(): Observable<any>
  {
    return this.handshakeSubject.asObservable();
  }

  public initialHandshake(): Observable<any>
  {
    return this.http.get(this.createUrl("authorization/handshake")).pipe(
      map(response =>
      {
        this.handshakeSubject.next(true);
        return true;
      })
    );
  }
}

