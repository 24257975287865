export namespace RegexConst
{
  export const  DoubleOnly = new RegExp(/^[0-9]{0,13}(\.[0-9]{0,2}){0,1}$/g);
  export const  IntegerOnly = new RegExp(/^[0-9]{0,9}$/g);
  export const  PhoneNumber = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
  export const  SpecialChars = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
  export const  RFC = new RegExp(/^[a-zA-Z]{3,4}[0-9]{6}([a-zA-Z0-9]{3})?$/);
  export const  Integer = new RegExp(/[\d /]+/);
  export const  AMEXPattern = new RegExp(/^3[47]/);
  export const  MCPattern = new RegExp(/^5[1-5]|^22/);
  export const  VisaPattern = new RegExp(/^4/);
  export const  CardExpiryDate = new RegExp(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/);
  export const  CardExpiryDateMonth = new RegExp(/^(1[012]|[1-9])$/);
  export const  CardExpiryDateYear = new RegExp(/^[0-9]{2}$/);
  export const  Domain = new RegExp(/[^a-zA-Z 0-9.-]+/g);
  export const Numeric = new RegExp(/^\d+$/g);
  
}
