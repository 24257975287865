import { Injectable, OnDestroy } from "@angular/core";
import { SecurityDataService } from '@core-data-services/security/security.data-service';
import { IRecaptchaV3Response, VerifyRecaptchaV3Dto } from '@core-models/security.model';
import { TranslateService } from '@shared-services/translate.service';
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable, Subject, switchMap, takeUntil } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CaptchaV3Service implements OnDestroy
{
  private unsubscribe$ = new Subject<void>();
  private tokenRecaptchaV3: string = '';
  private actionRecaptchaV3: string = '';

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private securityDataService: SecurityDataService,
    private translateService: TranslateService
  ) { }

  public executeAndVerify(action: string): Observable<IRecaptchaV3Response>
  {
    this.actionRecaptchaV3 = action;

    return this.recaptchaV3Service.execute(this.actionRecaptchaV3)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token: string) =>
        {
          this.tokenRecaptchaV3 = token;
          const verifyDto = this.getVerifyRecaptchaV3Dto();
          return this.onRecaptchaV3Verify(verifyDto);
        })
      );
  }

  private getVerifyRecaptchaV3Dto(): VerifyRecaptchaV3Dto
  {
    return {
      token: this.tokenRecaptchaV3,
      action: this.actionRecaptchaV3,
      language: this.translateService.languageCode
    };
  }

  private onRecaptchaV3Verify(verifyDto: VerifyRecaptchaV3Dto): Observable<IRecaptchaV3Response>
  {
    return this.securityDataService.verify(verifyDto)
      .pipe(
        takeUntil(this.unsubscribe$)
      );
  }

  public ngOnDestroy(): void
  {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
