<div class="fixed-top">
  <nav id="index-navbar"
    class="akky-navbar navbar-expand-lg d-flex align-items-center w-100 pt-2 px-1 py-md-4 navbar-white">

    <!-- AKKY logo-->
    <span id="akky-logo" class="navbar-brand p-0 ms-0 ms-md-3 ms-lg-4 clickable" (click)="redirectToHome()">
      <span class="icon brand-image akky-logo"></span>
    </span>

    <!-- menu items -->
    <div class="d-flex ms-auto menu-items-container">
      <!-- HELP -->
      <div class="nav-item align-self-center mx-2 mx-xl-3 mt-1 d-none d-sm-block">
        <div class="icon-content" title="{{'Ayuda' | translate}}" (click)="redirectToHelp()">
          <span class="icon icon-standard-size i-question-circle"></span>
        </div>
      </div>

      <!-- BELL-->
      <div *ngIf="isLogedIn" class="nav-item align-self-center mx-2 mx-xl-3 mt-1 d-none d-sm-block icon-content"
        (click)="redirectToMessages()">
        <span class="icon icon-standard-size i-bell"></span>
      </div>

      <!-- USER NOT LOGUED-->
      <div *ngIf="!isLogedIn" class="nav-item align-self-center mx-2 mx-xl-3" id="navBarLogin"
        (click)="showUserAccessModal = true;">
        <button class="btn d-flex align-items-center login-button btn-info">
          <span class="d-none d-sm-block me-2 text text-uppercase login_mobile">{{'iniciar sesión' | translate}}</span>
          <span class="icon i-person-white"></span>
        </button>
      </div>

      <!-- LOGED USER -->
      <div *ngIf="isLogedIn" #userItem id="userItem" class="nav-item align-self-center mx-0 mx-sm-2 mx-xl-3 d-sm-block"
        [class.d-none]="!isLogedIn" (click)="dropUserMenu = !dropUserMenu; dropLanguages = false;">
        <span title="User" class="dropdown-toggle navbarUserInitialsDiv">
          {{userNameInitials}}
        </span>
        <span class="icon i-chevron-down" title="User"></span>

        <ul class="dropdown-menu  px-3 py-2" [class.show]="dropUserMenu">
          <li class="py-3 nav-dropdown-option" (click)="onControlPanelClicked()">{{'Panel de control' | translate}}</li>
          <li class="py-3 nav-dropdown-option" (click)="onMyProfileClicked()">{{'Mi perfil' | translate}}</li>
          <li class="py-3 nav-dropdown-option d-flex align-items-center last" (click)="onLogout()">
            {{'Cerrar sesión' | translate}}
            <span class="icon i-sign-out"></span>
          </li>
        </ul>
      </div>

      <!-- CART -->
      <div id="navBarCart" class="nav-item align-self-center mx-2 mt-1">
        <div title="Carrito" class="cart" (click)="goToCart()">
          <span class="icon icon-standard-size i-cart"></span>
          <span class="count-badge badge">
            {{ cartItemsCount }}
          </span>
        </div>
      </div>

      <!-- LANG -->
      <div class="nav-item align-self-center mx-1 mx-sm-2 mx-xl-3 language-options" #langItem id="langItem"
        (click)=" dropLanguages = !dropLanguages; dropUserMenu = false;">
        <span title="Idioma" class="dropdown-toggle language-code-text text-uppercase"> {{selectedLanguage.code}}
        </span>
        <ul class="dropdown-menu px-3 py-2" [class.show]="dropLanguages">
          <li *ngFor="let lang of languages; let last = last; trackBy:identify" class="py-3 nav-dropdown-option"
            [class.last]="last" (click)="onSelectLanguage(lang)">{{lang.name}}</li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<!-- Login and register modal -->
<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1" [class.show]="showUserAccessModal">
  <div class="modal-dialog modal-dialog-centered user-access-modal p-0 d-flex justify-content-center m-0 m-sm-auto">
    <div class="modal-content user-access-modal-content p-0">
      <div class="modal-body p-0 h-100">
        <app-user-access [enabled]="showUserAccessModal" [params]="params"
          (onCloseForm)="showUserAccessModal= false"></app-user-access>
      </div>
    </div>
  </div>
</div>