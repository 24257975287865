import { Injectable } from "@angular/core";
import { ContactsDataService } from "@core-data-services/contacts.data-service";
import { CouponDataService } from "@core-data-services/coupons.data-service";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { ShoppingCartPlus } from "@core-models/shopping-cart-plus.model";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class RegistrationDataResolver  
{
  constructor(private service: ContactsDataService,
    private checkoutManager: CheckoutManager,
    private couponDataService: CouponDataService,
    private shoppingCartDataService: ShoppingCartDataService) { }

  public resolve(): Observable<any> | Promise<any> | any
  {
    this.shoppingCartDataService.getCartSubscription().subscribe({
      next: (response: ShoppingCartPlus) => 
      {
        this.checkoutManager.initializeData(response);
        const couponToApply = this.checkoutManager.hasCouponToApply ? this.checkoutManager.couponToApply : "";

        if (this.checkoutManager.coupon == "" && couponToApply != "")
        {
          this.couponDataService.apply(couponToApply);
        }
      }
    });

    return this.service.getList();
  }
}
