<form [formGroup]="creditCardForm" id="creditCardForm" class="mt-4">
  <div class="row p-0 m-0">
    <!-- Name -->
    <div class="col-md-6 col-12 px-0 pe-md-2 my-2 my-md-0">
      <div class="form-group w-100 floating-label">
        <input [ngClass]="{ 'is-invalid': submitted && creditCardFormControls.cardHolder?.errors }"
          formControlName="cardHolder" placeholder=" " type="text" id="cardHolder" class="form-control form-input"
          name="cardHolder" role="textbox" autocomplete="off" maxlength="500" (input)="onCardHolderChanged($event)"
          required>
        <label for="cardHolder" class="text-uppercase up-label">{{'Nombre del tarjetahabitante' | translate}}</label>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: creditCardFormControls.cardHolder, name: 'cardHolder', submitted: submitted}"></ng-container>
    </div>
    <input hidden type="text" id="deviceIdHiddenFieldName" value="" />

    <!-- Card number -->
    <div class="col-md-6 col-12 px-0 ps-md-2 my-2 my-md-0">
      <div class="form-group w-100 floating-label ">
        <div class="prefix-icon-input">
          <img [src]="creditCardData[cardType].image" width="30" height="20" alt="card" class="img-fluid">
        </div>
        <input
          [ngClass]="{ 'is-invalid': submitted && (creditCardFormControls.cardNumber?.errors || cardError !== 'None')}"
          creditCardNumber [unmaskedValue]="unmaskedCreditCard" placeholder=" " id="cardNumber" name="cardNumber"
          formControlName="cardNumber" type="text" role="textbox"
          mask="{{ cardType !== 'AmericanExpress' ? 'XXXX XXXX XXXX 0000' : 'XXXX XXXX XXXX 000' }}"
          [hiddenInput]="hideCardNumber" class="form-control form-input ps-5" autocomplete="off"
          (onCreditCardNumber)="onCardNumberChanged()" (onCreditCardTypeName)="onCardType($event)"
          (onCreditCardError)="onCardError($event)" required>
        <label for="cardNumber" class="text-uppercase up-label">{{'Número de tarjeta' | translate}}</label>
        <!-- Eye icon -->
        <div class="suffix-icon-input">
          <span class="clickable" (click)="onHideCardNumberClick()">
            <span class="icon" [ngClass]="{'i-eye-slash': hideCardNumber,'i-eye-on': !hideCardNumber}"></span>
          </span>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: creditCardFormControls.cardNumber, name: 'cardNumber', submitted: submitted}"></ng-container>
    </div>
  </div>

  <div class="row p-0 m-0">
    <!-- Card images -->
    <div class="col-md-6 col-12 p-0 m-0 my-3">
      <img src="assets/payment/visa.svg" width="50" height="30" alt="visa" class="img-fluid">
      <img src="assets/payment/mastercard.svg" width="50" height="30" alt="mastercard" class="img-fluid mx-3">
      <img src="assets/payment/amex.svg" width="50" height="30" alt="american-express" class="img-fluid">
    </div>

    <div class="row col-md-6 col-12 p-0 m-0 my-2">
      <!-- Expiration -->
      <div class="col-md-6 col-12 px-0 px-md-2 my-2">
        <div class="form-group w-100 floating-label ">
          <input
            [ngClass]="{ 'is-invalid': submitted && (creditCardFormControls.dateExpiry?.errors || dateError !== 'None')}"
            expiryDate placeholder="MM/AA" id="dateExpiry" formControlName="dateExpiry" type="text" role="textbox"
            class="form-control form-input" maxlength="5" mask="00/00" pattern="^(0[1-9]|1[0-2])?([0-9]{2})$"
            (onExpiryDate)="onExpiryDateChanged($event)" (onExpiryDateError)="onDateExpiryError($event)" required>
          <label for="dateExpiry" class="text-uppercase up-label">{{'Vencimiento' | translate}}</label>
        </div>
        <ng-container
          *ngTemplateOutlet="tooltipErrorTemplate; context: {control: creditCardFormControls.dateExpiry, name: 'dateExpiry', submitted: submitted}"></ng-container>
      </div>

      <!-- CVV -->
      <div class="col-md-6 col-12 px-0 ps-md-2 my-2">
        <div class="form-group w-100 floating-label">
          <input [ngClass]="{ 'is-invalid': submitted && (creditCardFormControls.cvv?.errors || cvvError !== 'None') }"
            cvvCard creditCardType="{{cardType}}" placeholder=" " id="cvv" formControlName="cvv" type="password"
            role="textbox" class="form-control form-input" minlength="3" maxlength="5" pattern="^[\d-]+$"
            (onCVV)="onCVVChanged($event)" (onCVVError)="onCVVError($event)" required>
          <label for="cvv" class="text-uppercase up-label">{{'CVV' | translate}}</label>
        </div>
        <ng-container
          *ngTemplateOutlet="tooltipErrorTemplate; context: {control: creditCardFormControls.cvv, name: 'cvv', submitted: submitted}"></ng-container>
      </div>
    </div>
  </div>
</form>

<!-- error tooltip -->
<ng-template #tooltipErrorTemplate let-control="control" let-submitted="submitted" let-name="name">
  <div class="error-container" *ngIf="submitted">
    <small>{{getErrorMessage(control, name)}}</small>
  </div>
</ng-template>