import { Pipe, PipeTransform } from '@angular/core';
import { IContact } from '@core-models/contacts.model';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Pipe({
  name: 'contactAddress',
  pure: false
})
export class ContactAddressPipe implements PipeTransform
{
  constructor(public translateService: TranslateService) { }

  public transform(contact: IContact): string
  {
    if (!contact) { return ""; }

    if (contact.addressHelper)
    {
      return this.getWithTemplate(contact);
    }

    return this.getWithoutTemplate(contact);
  }

  public getWithTemplate(contact: IContact): string
  {
    let addressArray: string[] = [];

    const postalCode: string = contact.addressHelper?.dependantLocality ?? contact.addressHelper?.postalCode ?? contact.postalCode;
    const street: string = contact.addressHelper?.street ?? contact.addressHelper?.streetNumberAndName ?? contact.address1;

    const aditionalAddress: string = contact.addressHelper?.additionalAddress ?? "";

    let number: string = contact.addressHelper?.number ?? "";
    const internalNumber: string = contact.addressHelper?.interiorNumber ?? "";

    if (internalNumber)
    {
      number = `-${number}${internalNumber}`;
    }

    addressArray.push(street ?? "");
    addressArray.push(aditionalAddress ?? "");
    addressArray.push(number ?? "");
    addressArray.push(contact.addressHelper?.district ?? "");
    addressArray.push(contact.addressHelper?.neighborhood ?? "");
    addressArray.push(contact.addressHelper?.city ?? "");
    addressArray.push(contact.state ?? "");
    addressArray.push(postalCode ?? "");
    addressArray.push(contact.idCountry ?? "");

    addressArray = addressArray.filter(x => x != "");

    if (!Tools.isNullOrEmpty(addressArray))
    {
      return addressArray.join(", ") + ".";
    }

    return "";
  }

  public getWithoutTemplate(contact): string
  {
    let addressArray: string[] = [];

    const street: string = contact.address1;

    addressArray.push(street);
    addressArray.push(contact.city ?? "");
    addressArray.push(contact.state ?? "");
    addressArray.push(contact.postalCode ?? "");
    addressArray.push(contact.idCountry ?? "");

    addressArray = addressArray.filter(x => x != "");

    if (!Tools.isNullOrEmpty(addressArray))
    {
      return addressArray.join(", ") + ".";
    }

    return "";
  }
}
