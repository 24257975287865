import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments';

@Injectable({ providedIn: 'root' })
export class RouteHelper
{
  private _domainName = this.document.location.hostname.replace("www", "").split('.').slice(-2).join('.');

  private defaultEcommerceURLTest: string = "https://akkyqa-01.mx";
  private defaultCartURLTest: string = "http://checkout.akkyqa-01.mx";

  constructor(@Inject(DOCUMENT) private document: any) { }

  public get domainName(): string
  {
    return this._domainName;
  }

  public get ecommerceURL(): string
  {
    return Environment.Local === true ? this.defaultEcommerceURLTest : `https://${this.domainName}`;
  }

  public get shoppingCartWebURL(): string
  {
    return Environment.Local === true ? this.defaultCartURLTest : `https://checkout.${this.domainName}`;
  }

  public get noticeOfPrivacyURL(): string
  {
    return `${this.domainName}/acerca/politicas-de-servicios`;
  }
}
