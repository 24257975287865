import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { TokenManager } from '@core-managers/token.manager';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class AdditionalSettingsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService,
    protected tokenManager: TokenManager)
  {
    super(http, 'settings', translateService);
  }

  public getOpenpayConection(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.get(this.createUrl('openpay'), queryDef.getRequestConfig());

    return subject;
  }

  public getShoppingCartConfig(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.get(this.createUrl('c-settings'), queryDef.getRequestConfig());

    return subject;
  }
}

