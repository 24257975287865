import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { EGtmLocationEvent } from '@core-constants/gtm-const';
import { SuccessMessageConst } from '@core-constants/success-message.const';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { UpdateSuggestionResponse } from '@core-models/add-to-cart.model';
import { IPurchaseSuggestionRate, IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { IRecaptchaV3Response } from '@core-models/security.model';
import { IItemProductLite } from '@core-models/shopping-cart-plus.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { GtmTrackingService } from '@shared-services/gtm-tracking.service';
import { ToastService } from '@shared-services/toast.service';
import { finalize } from 'rxjs';
import { ShoppingCartSuggestionItemBase } from '../base/shopping-cart-suggestion-item';

@Component({
  selector: 'app-individual-suggestion-item',
  templateUrl: './individual-suggestion-item.component.html',
  styleUrls: ['./individual-suggestion-item.component.css']
})
export class IndividualSuggestionItemComponent extends ShoppingCartSuggestionItemBase
{
  @Output() public onAddSuggestion = new EventEmitter<string>();

  private readonly actionRecaptchaV3: string = "addIndividualSuggestionToggle";

  @Input('suggestion') public set setSuggestion(value: IShoppingCartIndividualStoredSuggestionPlus)
  {
    super.suggestion = value;
  }

  @Input('itemProduct') public set setItemProduct(value: IItemProductLite)
  {
    super.itemProduct = value;
  }

  @Input('parentId') public set setParentId(value: number)
  {
    this.parentId = value;
  }

  constructor(
    private shoppingCartDataService: ShoppingCartDataService,
    private toast: ToastService,
    private checkoutManager: CheckoutManager,
    private captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef
  ) { super(); }

  public onExecuteAndVerifyRecaptcha(): void
  {
      this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.addSuggestionToCart();
          }
          else
          {
            this.toast.setErrorToast(response.message);
          }
        }
      });
  }

  public onCheckChanges($event: boolean): void
  {
    this.suggestion.individualFeatures.isAdded = $event;
    $event === true ? this.addSuggestionToCart() : this.removeSuggestionFromCart();
  }

  public get suggestionRate(): IPurchaseSuggestionRate
  {
    return this.suggestion.coverage.length > 0 ? this.suggestion.coverage.find(x => x.id === this.suggestion.coverageId)?.rate : null;
  }

  private addSuggestionToCart(): void
  {
    if (this.addedIsChanging) { return; }

      this.addedIsChanging = true;
      this.isAdded = true;

      const itemId = this.parentId;
      const suggestionId = this.suggestion.suggestionId;

      this.shoppingCartDataService.addIndividualSuggestion(itemId, suggestionId)
      .pipe(finalize(() => this.addedIsChanging = false))
      .subscribe({
        next: ( { operationResult }: UpdateSuggestionResponse) =>
        {
          if (operationResult)
          {
            GtmTrackingService.addSuggestionToCartEvent([this.suggestion], EGtmLocationEvent.IndividualSuggestion, this.isDomain, this.suggestionRate);

            if(this.isDomain)
            {
              const shoppingCartId = this.shoppingCartDataService.getShoppingCartId;
              BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart,shoppingCartId);
              this.toast.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
              return;
            }

            this.suggestion.individualFeatures.isAdded = true;
            this.checkoutManager.addIndividualSuggestion(itemId, suggestionId);
          }
          else
          {
            this.suggestion.individualFeatures.isAdded = false;
            this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
          }
        },
        error: (response: HttpErrorResponse) =>
        {
          this.suggestion.individualFeatures.isAdded = false;
          this.isAdded = false;
          this.toast.setErrorToast(response?.error.description);
        }
      });
  }

  private removeSuggestionFromCart(): void
  {
    if (this.addedIsChanging) { return; }

    this.addedIsChanging = true;
    const itemId = this.parentId;
    const suggestionId = this.suggestion.suggestionId;
    this.shoppingCartDataService.removeIndividualSuggestion(itemId, suggestionId)
    .pipe(finalize(() => this.addedIsChanging = false))
    .subscribe({
      next: ({ operationResult }: UpdateSuggestionResponse) =>
      {
        if (operationResult)
        {
          this.suggestion.individualFeatures.isAdded = false;
          this.checkoutManager.removeIndividualSuggestion(itemId, suggestionId);
        }
        else
        {
          this.suggestion.individualFeatures.isAdded = true;
          this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
        }
      }
    });
  }
}
