import { EDomainServiceType } from "@core-constants/domain-data.const";
import { LanguageConst } from "@core-constants/language.const";
import { TranslateService } from "@shared-services/translate.service";

export class DomainServiceTemplate
{
  constructor(private translateService: TranslateService) { }

  // ********************************************************************
  //#region DomainAdvancedSecurity
  // ********************************************************************

  private _domainAdvancedSecurityHTML_ES: string = `
      <span>
	    <div class="black font-weight-bold fs-xl col-12 pb-2">Seguridad Avanzada</div>
	    <div class="col-12 fs-l text-justify black pb-2">Al contratar este servicio proteges el dominio de Modificaciones, Transferencias y Eliminaciones no deseadas.</div>
	    <div class="col-12 fs-l text-justify black pb-2">
		    Con este servicio, las operaciones de modificación, solicitud de transferencias y solicitudes de eliminación podrán realizarse solamente después de haber concluido el proceso de validación mediante
            <a href="https://www.akky.mx/ayuda/temas-de-ayuda?tema=58" class="green none-underline underline">documentos</a>, cada vez que sea necesario realizar alguna de ellas te enviamos una clave que te permite realizarlas mientras se encuentre activa la sesión en nuestro sitio.
	    </div>
      </span>`;

  private _domainAdvancedSecurityHTML_EN: string = `
      <div>
	    <div class="black font-weight-bold fs-xl col-12 pb-2">Advanced Security</div>
	    <div class="col-12 fs-l text-justify black pb-2">By contracting this service, you protect the domain from unwanted Modifications, Transfers and Eliminations.</div>
	    <div class="col-12 fs-l text-justify black pb-2">
		    With this service, the modification operations, the transfers request and the requests of elimination, can be done only after the validation process has been completed by means of
            <a href="https://www.akky.mx/ayuda/temas-de-ayuda?tema=58" class="green none-underline underline">documents</a>, whenever it is necessary to carry out any of them, we will send you a key that allows you to carry them out while the session on our site is active.
        </div>
	  </div>`;

  public get _domainAdvancedSecurityHTML(): string
  {
    if (this.translateService.languageCode == LanguageConst.ES)
    {
      return this._domainAdvancedSecurityHTML_ES;
    }
    else
    {
      return this._domainAdvancedSecurityHTML_EN;
    }
  }

  // #endregion

  // ********************************************************************
  //#region SMSAlertsHTML
  // ********************************************************************

  private _smsAlertsHTML_ES: string = `
      <div>
	    <div class="black font-weight-bold fs-xl col-12 pb-2">Alertas SMS</div>
	    <div class="col-12 fs-l text-justify black pb-2">A través de este servicio podrás estar informado de las operaciones realizadas a los nombres de dominios de tu elección mediante mensajes SMS, cada vez que sean realizadas cualquiera de las operaciones que a continuación mencionamos:</div>
	    <ul>
		    <li class="col-12 fs-l text-justify black pb-2">Modificación de dominios.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Eliminación de dominios.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Transferencias (Dominios de Akky hacia otros Registrars Acreditados).</li>
		    <li class="col-12 fs-l text-justify black">Avisos de Pago.</li>
	    </ul>
	    <div class="col-12 fs-l text-justify black pb-2">El Servicio se encuentra disponible únicamente para números de teléfonos celulares registrados en la República Mexicana</div>
     </div>`;

  private _smsAlertsHTML_EN: string = `
      <div>
	    <div class="black font-weight-bold fs-xl col-12 pb-2">SMS Alerts</div>
	    <div class="col-12 fs-l text-justify black pb-2">Through this service, you can be informed of the operations carried out on the domain names of your choice by SMS messages, each time any of the operations that follow those mentioned are carried out:</div>
	    <ul>
		    <li class="col-12 fs-l text-justify black pb-2">Domains modification.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Domains elimination.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Transfers (Akky Domains to other Accredited Registrars).</li>
		    <li class="col-12 fs-l text-justify black">Payment Notices.</li>
	    </ul>
	    <div class="col-12 fs-l text-justify black pb-2">The Service is available for registered mobile phone numbers in the Mexican Republic</div>
      </div>`;

  public get _smsAlertsHTML(): string
  {
    if (this.translateService.languageCode == LanguageConst.ES)
    {
      return this._smsAlertsHTML_ES;
    }
    else
    {
      return this._smsAlertsHTML_EN;
    }
  }

  // #endregion

  // ********************************************************************
  //#region CustomDNS
  // ********************************************************************

  private _customDNS_ES: string = `
    <div>
	  <div class="black font-weight-bold fs-xl col-12 pb-2">DNS Personalizado</div>
	  <div class="col-12 fs-l text-justify black pb-2">Al contratar este servicio podrás realizar la configuración personalizada de los registros DNS de acuerdo a tus necesidades, por ejemplo:</div>
	  <ul>
		  <li class="col-12 fs-l text-justify black pb-2">Redireccionar el dominio hacia tu proveedor de hospedaje web.</li>
		  <li class="col-12 fs-l text-justify black pb-2">Configurar los servidores de correos electrónicos para servicios externos como Google Workspace.</li>
		  <li class="col-12 fs-l text-justify black pb-2">Ingresar registros SPF, los cuales identifican los servidores de correo que pueden enviar mensajes en nombre de tu dominio.</li>
		  <li class="col-12 fs-l text-justify black pb-2">Usar tu dominio con servicios en la nube como Office 365 y más.</li>
		  <li class="col-12 fs-l text-justify black pb-2">Los tipos de registros que puedes configurar son: A, AAAA, MX, CNAME, SRV, TXT.</li>
	  </ul>
	  <div class="col-12 fs-l text-justify black pb-2">Akky incluye de forma automática la protección de seguridad de DNS (DNSSEC). La cual verifica el proceso de consulta del dominio y asegura que los visitantes efectivamente llegan a tu sitio web. (Solamente para extensiones de dominio que lo soportan)</div>
    </div>`;

  private _customDNS_EN: string = `
      <div>
	    <div class="black font-weight-bold fs-xl col-12 pb-2">Custom DNS</div>
	    <div class="col-12 fs-l text-justify black pb-2">By hiring this service, you will be able to make the personalized configuration of the DNS records according to your needs, for example:</div>
	    <ul>
		    <li class="col-12 fs-l text-justify black pb-2">Redirect the domain to your web hosting provider. </li>
		    <li class="col-12 fs-l text-justify black pb-2">Set up email servers for external services like Google Workspace.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Enter SPF records, which identify the mail servers that can send messages on behalf of your domain.</li>
		    <li class="col-12 fs-l text-justify black pb-2">Use your domain with cloud services like Office 365 and more.</li>
		    <li class="col-12 fs-l text-justify black pb-2">The types of records you can configure are: A, AAAA, MX, CNAME, SRV, TXT.</li>
	    </ul>
	    <div class="col-12 fs-l text-justify black pb-2">Akky automatically includes DNS security (DNSSEC). This verifies the process of consulting a domain and assures the visitors that they are effectively reaching your website. (This only applies to domain extensions that support it)</div>
      </div>`;

  public get _customDNS(): string
  {
    if (this.translateService.languageCode == LanguageConst.ES)
    {
      return this._customDNS_ES;
    }
    else
    {
      return this._customDNS_EN;
    }
  }

  // #endregion

  public DomainServiceHelpMessge = new Map<EDomainServiceType, string>([
    [EDomainServiceType.Security, this._domainAdvancedSecurityHTML],
    [EDomainServiceType.SMS, this._smsAlertsHTML],
    [EDomainServiceType.DNS, this._customDNS]
  ]);
}
