import { EAppEventName } from "@core-constants/app-event-names.const";
import { ELogoutType } from "@core-constants/logout-events.const";
import { EStepValidationError, StepConst } from "@core-constants/step.const";
import { BroadcastService } from "@shared-services/broadcast.service";
import { ToastService } from "@shared-services/toast.service";
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';

export class HttpCartError
{
    constructor(
        protected shoppingCartDataService: ShoppingCartDataService,
        protected toastService: ToastService
    ) { }

    public handleCartError(errorCode: string): void
    {
        const toastErrorMessage = StepConst.StepValidationErroMessage.get(errorCode as EStepValidationError);

        switch (errorCode)
        {
            case EStepValidationError.InvalidSession:
            BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.InvalidToken);
            return;

            case EStepValidationError.InvalidProducts:
            this.shoppingCartDataService.getCart();
            this.toastService.setWarningToast(toastErrorMessage);
            return;

            case EStepValidationError.InvalidCart:
            this.toastService.setWarningToast(toastErrorMessage);
            window.location.reload();
            return;

            case EStepValidationError.InvalidDomains:
            this.shoppingCartDataService.getCart();
            this.toastService.setWarningToast(toastErrorMessage);
            return;

            default:
            this.toastService.setErrorToast(toastErrorMessage);
        }
    }
}