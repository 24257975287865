export namespace PurchaseConst
{
  export class CoverageLabels
  {
    public static readonly yearsLabels = { one: 'año', many: 'años' };
    public static readonly monthsLabels = { one: 'mes', many: 'meses' };
  }

  export class PurchaseTypes
  {
    public static readonly Acquisition = 'Adquisición';
    public static readonly Others = 'Renovación / Otros';
  }
}
