import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenManager } from '@core-managers/token.manager';
import { DomainContactInfo, IContact } from '@core-models/contacts.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class ContactsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'contacts', translateService);
  }

  public getList(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const user = this.tokenManager.getUser();

    const observable = this.http.get(this.createUrl('list', user.id), queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public update(contact: IContact): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.put(this.createUrl(), contact, queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public create(contact: IContact): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.post(this.createUrl(), contact, queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public removeRedisContactDomain(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const token = this.tokenManager.getToken();

    const dto = {
      cartId: this.tokenManager.getCartCookie()
    };

    const observable = this.http.post(this.createUrl("remove/domain-contacts"), { body: dto, headers: queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode) }).pipe(share());

    return observable;

  }

  public addRedisContactDomain(contact: DomainContactInfo): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    contact.token = this.tokenManager.getToken();
    contact.cartId = this.tokenManager.getCartCookie();

    const observable = this.http.post(this.createUrl('domain-contacts-order'), contact, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;

  }

  public getRedisContactDomain(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    queryDef.addParam("cartId", this.tokenManager.getCartCookie());

    const observable = this.http.get(this.createUrl('domain-contacts-order'), queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }
}

