export const enum ECartItemStatus
{
  Registration,
  Renewal
}

export const enum ECartItemOperation
{
  Registration = 0,
  PendingPayment,
  Renewal,
  Recovery,
  RecoveryDeleted,
  Transfer,
  Upgrade
}

export namespace CartDataConst
{
  export const CartItemOperationPurchaseSummaryLabels = new Map<ECartItemOperation, string>([
    [ECartItemOperation.Registration, 'Registro de dominio'],
    [ECartItemOperation.Renewal, 'Renovación de dominio'],
    [ECartItemOperation.Transfer, 'Extensión por transferencia de dominio'],
    [ECartItemOperation.Recovery, 'Extensión por recuperación en renovación'],
    [ECartItemOperation.RecoveryDeleted, 'Extensión por recuperación en renovación'],
    [ECartItemOperation.PendingPayment, 'Registro de dominio']
  ]);

  export const CartItemOperationRecoveryRegistrationLabels = new Map<ECartItemOperation, string>([
    [ECartItemOperation.Recovery, 'Extensión por recuperación en registro'],
    [ECartItemOperation.RecoveryDeleted, 'Extensión por recuperación en registro']
  ]);

  export const CartItemOperationStep1Labels = new Map<ECartItemOperation, string>([
    [ECartItemOperation.Registration, 'Registro'],
    [ECartItemOperation.Renewal, 'Renovación'],
    [ECartItemOperation.Transfer, 'Transferencia'],
    [ECartItemOperation.PendingPayment, 'Pendiente de pago'],
    [ECartItemOperation.Upgrade, 'Mejora'],
    [ECartItemOperation.RecoveryDeleted, 'Recuperación'],
    [ECartItemOperation.Recovery, 'Recuperación']
  ]);
}
