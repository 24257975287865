<app-navbar></app-navbar>

<app-processing [show]="showLoadingMask"></app-processing>

<div class="basic-content">
  <!-- TABS -->
  <div id="checkout-steps"
    class="principal-container tabs-row d-flex flex-wrap justify-content-center align-items-center">

    <div *ngFor="let tab of tabOptions; let i = index" class="tab-option text-center p-0 me-1"
      [class.disabled]="i > selectedIndex || selectedIndex === tabOptions.length -1" [routerLink]="tab.url"
      routerLinkActive="active-tab">
      <!-- Tabs -->
      <div [class.clickable-option]="i < selectedIndex" class="d-flex align-items-center px-0">
        <span class="number-indicator"> {{i+1}} </span>
        <span class="fw-bold title-tab">{{tab.title | translate}}</span>
        <div class="invisible" *ngIf="selectedIndex === i"></div>
        <span class="arrow-tab-indicator" [class.ps-2]="i === tabOptions.length-1"
          [class.i-chevron-right]="selectedIndex !== i+1 && selectedIndex !== i"></span>
      </div>
    </div>
  </div>

  <!-- SUMMARY  -->
  <div *ngIf="!isConfirmationStep" class="row p-0 m-0 w-100 d-lg-none">
    <div class="col-12 p-0 m-0">
      <app-purchase-summary [coupon]="enabledCoupon" [edit]="selectedIndex !== 0"
        [openServices]="false"></app-purchase-summary>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="container-fluid p-0">
    <div class="container p-0">
      <div class="mx-0 mx-xl-5 px-0 px-lg-2 px-xl-3 px-xxl-5">
        <!-- Step content -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>

<app-chat></app-chat>