<ng-container>
  <div class="d-flex p-0 m-0 align-items-center justify-content-start">
    <div class="flex-grow-1 d-flex align-items-center">
      <span class="i-green-check p-2 me-2"
            *ngIf="isAdded"></span>
      <span *ngIf="title"
            class="fs-md word-break">{{title}}</span>
    </div>
    <span *ngIf="finalAmount"
          class="price fw-medium ms-2 fs-md">{{finalAmount | currency}} MXN</span>
  </div>
</ng-container>