<ng-container *ngIf="items && items.length > 0">

  <label *ngIf="label" for="{{ parentId }}" class="floating-label">{{ label | translate }} </label>

  <div class="dropdown" [id]="parentId">
    <button [id]="parentId" type="button" data-bs-toggle="dropdown" aria-expanded="false" [disabled]="disabled"
      class="btn custom-dropdown d-flex" [class.w-100]="isFullWidth" [class.error]="showError">
      <span class="overflow-elipsis flex-grow-1">{{selectedOption?.label | translate}}</span>
      <span class="icon i-dropdown-arrow" [class.disabled-icon]="disabled"></span>
    </button>

    <ul class="dropdown-menu p-0" [class.w-100]="isFullWidth" [class.show]="showOptions">
      <li *ngFor="let item of items">
        <div (keydown.enter)="onSelectOption(item)" (click)="onSelectOption(item); $event.preventDefault()"
          [class.active]="item.value === selectedOption?.value" [class.wide]="coverage"
          class="dropdown-item text-wrap d-flex justify-content-between" [class.disabled]="disabled"
          [class.clickable]="!disabled">

          <!-- label -->
          <span class="fs-m no-wrap" [class.fw-bold]="item.value === selectedOption?.value">
            {{ item.label | translate }}
          </span>

          <!-- Coverage -->
          <small *ngIf="coverage" class="ms-2 text-end">
            <strong class="d-block">
              {{ item.rate.finalAmount | currency }} MXN
            </strong>
            <del *ngIf="item.rate.offerAmount" class="d-block gray fs-s">
              {{ item.rate.offerAmount | currency }} MXN
            </del>
          </small>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
