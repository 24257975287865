import { Component, OnInit } from '@angular/core';
import { LanguageConst } from '@core-constants/language.const';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit
{
  public pageTitle: string = "Akky | Estamos mejorando nuestros servicios";

  public day: string = "Lunes";
  public month: string = "Julio";
  public hour: string = "10:00 PM";
  public number: number = 25;

  public langCode: string = LanguageConst.ES;
  public backgroundImage = "/maintenance/back.svg";

  constructor(protected translateService: TranslateService,
    protected localStorageService: LocalStorageService) { }

  public ngOnInit(): void { }

  public ngOnLanguageChange(code: string = this.langCode): void
  {
    const _code = code.toLowerCase();
    this.langCode = _code;
    this.setLanguage();
  }

  private setLanguage(): void
  {
    this.translateService.use(this.langCode);
  }
}


