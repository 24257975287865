import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { GroupedShoppingCart } from '@core-models/shopping-cart-plus.model';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantitySelectorComponent implements OnInit, OnDestroy
{
  private _defaultDebounceTime = 1000;
  private _quantity: number = 1;
  public maxItems: number = 0;

  @Input() public currentItem: GroupedShoppingCart;
  @Input() public show: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public idNumber: number = 0;
  @Output() public onQuantityChanges = new EventEmitter<number>();

  public clicker = new Subject();
  public clicker$ = this.clicker.asObservable();
  public quantityReachLimit = new Subject();
  public quantityReachLimit$ = this.quantityReachLimit.asObservable();

  constructor(public checkoutManager: CheckoutManager, private destroyRef$: DestroyRef) { }

  public get quantity(): number
  {
    return this._quantity;
  }

  @Input() public set quantity(qnt: number)
  {
    this._quantity = qnt;
  }

  public ngOnInit(): void
  {
    this.maxItems = this.checkoutManager.itemsLimit;
    this.clicker$.pipe(debounceTime(this._defaultDebounceTime))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe(() =>
    {
      this.onQuantityChanges.emit(this.quantity);
    });

    this.quantityReachLimit$.pipe(
      debounceTime(this._defaultDebounceTime)).
      subscribe(() =>
      {
        this.updateMaxCurrentItemOnReachingLimit();
      }
    );
  }

  public onValueChanges(qnty: number): void
  {
    this._quantity = qnty;
    this.clicker.next(true);
  }

  public onDecrementQuantityClicked(): void
  {
    this._quantity === 1 ? '' : this._quantity--;
    this.clicker.next(true);
  }

  public onIncrementQuantityClicked(): void
  {
    if (this.checkoutManager.cartItemsCount === this.maxItems)
    {
        this.clicker.next(true);
        return;
    }

    if (this._quantity >= this.maxItems)
    {
      this.quantityReachLimit.next(true);
      return;
    }

    this._quantity < this.maxItems ? this._quantity++ : '';
    this.clicker.next(true);
  }

  private get currentCartMaxQuantity(): number
  {
    return (this.checkoutManager.itemsLimit - this.checkoutManager.cartItemsCount);
  }

  private updateMaxCurrentItemOnReachingLimit(): void
  {
    const isItemUpgrade = this.currentItem.product.suggestions?.some(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);
    const currentCounterItem = isItemUpgrade ?
      this.currentItem.product.suggestions.find(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade).individualFeatures.quantity :
      this.currentItem.counter;
    const currentMaxItems = this.currentCartMaxQuantity + currentCounterItem;
    this._quantity = currentMaxItems;
    this.onQuantityChanges.emit(this.quantity);
  }

  public ngOnDestroy(): void
  {
    this.clicker.complete();
    this.quantityReachLimit.complete();
  }
}
