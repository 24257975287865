<div class="suggestion-card row p-0 m-0"
     [class.active]="isAdded">
    <div class="col-12 d-flex my-2 align-items-start">
        <div class="flex-grow-1">
            <span *ngIf="title"
                  class="fs-m fw-medium word-break-all">{{title}}</span>
        </div>
        <div *ngIf="label"
             class="offer-radius bg-aqua white fs-xs px-2 py-1 ms-0 ms-md-3">
            {{label}}
        </div>
    </div>
    <div class="col-12 d-flex my-1 align-items-center justify-content-start">
        <app-input-toggler [parentId]="buttonId"
                           [value]="isAdded"
                           [title]="toggleTitle"
                           [disabled]="addedIsChanging"
                           (onValueChanges)="onCheckChanges($event)"></app-input-toggler>
        <span *ngIf="subtitle"
              class="fs-md ms-2">{{subtitle}}</span>
    </div>
    <div class="col-12 d-flex my-2 justify-content-end align-items-center">
        <span *ngIf="finalAmount"
              class="price fw-bold fs-m">{{finalAmount | currency}} MXN</span>
    </div>
</div>