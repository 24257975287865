import { Component, Input } from '@angular/core';
import { DomainDataConst } from '@core-constants/domain-data.const';
import { EServiceCategory, EServiceType, EServiceTypeName } from '@core-constants/product-service.const';
import { CartDataConst, ECartItemOperation } from '@core-constants/shopping-cart.const';
import { DomainDataService } from '@core-data-services/domains.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { DomainServiceItemPlus, GroupedShoppingCart, ShoppingCartPlusItem, VariousServiceItemPlus } from '@core-models/shopping-cart-plus.model';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { Tools } from '@shared-utils/tools.util';

@Component({
  selector: 'app-purchase-summary-item',
  templateUrl: './purchase-summary-item.component.html',
  styleUrls: ['./purchase-summary-item.component.css']
})
export class PurchaseSummaryItemComponent
{
  private _item: GroupedShoppingCart;

  constructor(protected shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    protected domainDataService: DomainDataService,
    protected toast: ToastService,
    protected tokenManager: TokenManager,
    protected checkoutManager: CheckoutManager) { }

  // #region Properties

  public get item(): GroupedShoppingCart
  {
    return this._item;
  }

  @Input() public set item(value: GroupedShoppingCart)
  {
    this._item = value;

    const isUpgrade = value.product.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
    if( isUpgrade )
    {
      this._item = { ...this.item, product: { ...this.item.product, suggestions: this.item.product.suggestions.filter(x => !x.individualFeatures.isValidUpgrade) } };
      const suggestion = value.product.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
      this.updateVisualItem(suggestion);
    }
  }

  public get itemProduct(): ShoppingCartPlusItem
  {
    return this._item.product;
  }

  public get finalAmount(): number
  {
    return this.itemProduct.rate.finalAmount * this.item.counter * this.itemProduct.quantity;
  }

  public get counter(): number
  {
    return this.item.counter;
  }

  public get suggestions(): IShoppingCartIndividualStoredSuggestionPlus[]
  {
    return this.itemProduct.suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);
  }

  public get isFinalAmount0(): boolean
  {
    return this.finalAmount === 0;
  }

  public get isDomain(): boolean
  {
    return this.itemProduct.type === EServiceTypeName.Domain;
  }

  public get isDomainRenewalDeleted(): boolean
  {
    return this.isDomain && this.itemProduct.isNotChargeableDomain && this.itemProduct.synchronization;
  }

  public get isUserServiceRegistration(): boolean
  {
    return this.itemProduct.type == EServiceTypeName.Service && this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  public get isEmtpyDomainName(): boolean
  {
    return this.isDomain && this.itemProduct.name === '';
  }

  public get hasDomainServices(): boolean
  {
    return this.getDomainServices()?.length > 0;
  }

  public get hasVariousServices(): boolean
  {
    return this.itemProduct.variousServices?.length > 0;
  }

  public get isAddonCumulative(): boolean
  {
    return this._isAddon && this.itemProduct.isAccumulable && (this.itemProduct.operationId == ECartItemOperation.Renewal || this.itemProduct.operationId == ECartItemOperation.Registration);
  }

  private get _isCloseDomain(): boolean
  {
    return this.isDomain && DomainDataConst.CloseDomains.includes(this.itemProduct.domainType) === true;
  }

  private get _isAddon(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Addon;
  }

  private get _isSynchronization(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Service && this.itemProduct.synchronization === true;
  }
  // #endregion

  // #region Display methods

  public hasSuggestions(): boolean
  {
    return !Tools.isNullOrEmpty(this.itemProduct.suggestions);
  }

  public getDisplayMessageRate0(): string
  {
    return this._isCloseDomain ? "Pendiente documentación" : "Sin costo";
  }

  public getSelectedCoverage(): number
  {
    const coverage = this.itemProduct?.coverageOptions?.find(x => x.id == this.itemProduct.coverageId);
    return coverage.value;
  }

  public showCoverageMonths(): boolean
  {
    if (!Tools.isNullOrEmpty(this.itemProduct?.coverageOptions))
    {
      return this._isSynchronization ? true : this.itemProduct.coverageOptions.find(x => x.id == this.itemProduct.coverageId)?.value > 0;
    }
    return false;
  }

  public getProductName(): string
  {
    let name: string = this._isSynchronization ? this.itemProduct.description : this.itemProduct.name;

    if (this.isAddonCumulative)
    {
      return `${name} (x ${this.itemProduct.quantity})`;
    }

    if (this.isDomain)
    {
      name = this.isEmtpyDomainName === true ? "" : this.itemProduct.fullName;
    }

    return name;
  }

  public getOperationTypeName(): string
  {
    let name: string = "";

    const operationId = this.itemProduct.operationId;
    const serviceCategory = this.itemProduct.description;

    if (serviceCategory != "" && +serviceCategory == EServiceCategory.RegistrationRestoreExtension)
    {
      name = CartDataConst.CartItemOperationRecoveryRegistrationLabels.get(operationId);
    }
    else
    {
      name = CartDataConst.CartItemOperationPurchaseSummaryLabels.get(operationId);
    }

    return name;
  }

  public getOperationTypeDescription(): string
  {
    const operationId = this.itemProduct.operationId;

    return operationId !== ECartItemOperation.Recovery && operationId !==
      ECartItemOperation.RecoveryDeleted ? this.itemProduct.domainType : "";
  }

  // #endregion

  // #region domain services methods

  public getDomainServices(): DomainServiceItemPlus[]
  {
    return this.itemProduct.domainServices.filter(x => x.isAdded === true);
  }

  public getDomainServiceSelectedCoverage(item: DomainServiceItemPlus): number
  {
    const coverage = item?.coverageOptions?.find(x => x.id == item.coverageId);
    return coverage.value;
  }

  // #endregion

  // #region various services methods

  public showCoverage(item: VariousServiceItemPlus): boolean
  {
    return item.operationId != ECartItemOperation.RecoveryDeleted && item.operationId != ECartItemOperation.Recovery && item.operationId != ECartItemOperation.PendingPayment && item.operationId != ECartItemOperation.Transfer;
  }

  public getVariousServiceCoverageModifier(item: VariousServiceItemPlus): string
  {
    return item.operationId === ECartItemOperation.Upgrade || this._isSynchronization || this._isAddon ? "months" : "";
  }

  public getVariousServiceFinalAmount(item: VariousServiceItemPlus): number
  {
    return item?.coverage?.rate?.finalAmount;
  }

  // #endregion

  private updateVisualItem(suggestion: IShoppingCartIndividualStoredSuggestionPlus | undefined): void
  {
    if(suggestion)
    {
      const { coverage, coverageId } = suggestion;
      const { displayName, features, quantity } = suggestion.individualFeatures;

      const rate = suggestion.coverage.find(x => x.id === coverageId)?.rate;

      const newProduct: ShoppingCartPlusItem = {
          ...this.item.product,
          name: displayName,
          fullName: displayName,
          featureDetail: { imgRoute: '', iconRoute: '', features },
          coverageOptions: [...coverage],
          coverageId,
          rate
      };

      this._item.counter = quantity;

      this._item = { ...this.item, product: newProduct };
    }
  }
}
