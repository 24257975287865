import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { Environment } from '@environments';
import { AssetsService } from '@shared-services/assets.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit
{
  constructor(public assetsService: AssetsService, private userAccessDataService: UserAccessDataService)
  {
    this.userAccessDataService.getInitialHandshake().subscribe({
      next: (response) =>
      {
        if (response)
        {
          this.getSettings();
        }
      }
    });
  }

  public ngOnInit(): void { }

  public getSettings(): void
  {
    this.assetsService.setBlobUrl = `${Environment.AzureStorageConfigAccountName}/${Environment.AzureStorageConfigAssetsContainer}`;
  }
}

