import { EContactPersonType, EContactType } from "@core-constants/contact-data.const";

export interface IContact
{
  idUser: string;
  language: string;
  id: string;
  idContactPersonType: string;
  idContactOrganizationIndustry: string;
  industryOther: string;
  idContactOrganizationSize: string;
  rfc: string;
  name: string;
  contactPersonName: string;
  contactPersonPosition: string;
  email: string;
  idCountry: string;
  state: string;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  address3: string;
  phoneCode: string;
  voiceNumber: string;
  voiceExtension: string;
  shortName: string;
  hasBeenUsedAsRegistrant: boolean;
  cctName: string;
  addressHelper: IAddressHelper;
}

export interface IAddressHelper
{
  street: string;
  number: string;
  interiorNumber: string;
  neighborhood: string;
  streetNumberAndName: string;
  additionalAddress: string;
  district: string;
  dependantLocality: string;
  autonomusCommunity: string;
  postalCode: string;
  city: string;
  state: string;
}

export class ContactInfoHelper
{
  public id: number;
  public name: string;
  public contactPersonType: EContactPersonType; // NaturalPerson = 1, LegalPerson
  public hasBeenUsedAsRegistrant: boolean;
}

export class DomainContactItem
{
  public id: string;
  public name: string;
  public contactPersonType: EContactPersonType; // NaturalPerson = 1, LegalPerson
  public hasBeenUsedAsRegistrant: boolean;
  public contactType: EContactType; // Admin = 1, Technical
}

export class DomainContactInfo
{
  public cartId: string;
  public token: string;
  public domainContacts: DomainContactItem[];
  public publishRegistrantInfo: boolean;
  public publishContactInfo: boolean;
  public isCheckedDomainContacts: boolean;
}

export class AddressHelperDto implements IAddressHelper
{
  public street: string;
  public number: string;
  public interiorNumber: string;
  public neighborhood: string;
  public streetNumberAndName: string;
  public additionalAddress: string;
  public district: string;
  public dependantLocality: string;
  public autonomusCommunity: string;
  public postalCode: string;
  public city: string;
  public state: string;
}

export class CreateContactDto implements IContact
{
  public idUser: string;
  public language: string;
  public id: string;
  public idContactPersonType: string;
  public idContactOrganizationIndustry: string;
  public industryOther: string;
  public idContactOrganizationSize: string;
  public rfc: string;
  public name: string;
  public contactPersonName: string;
  public contactPersonPosition: string;
  public email: string;
  public idCountry: string;
  public state: string;
  public city: string;
  public postalCode: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public phoneCode: string;
  public voiceNumber: string;
  public voiceExtension: string;
  public shortName: string;
  public hasBeenUsedAsRegistrant: boolean;
  public cctName: string;
  public addressHelper: IAddressHelper;
}

