import { isPlatformBrowser } from "@angular/common";
import { Component, DestroyRef, EventEmitter, Inject, Input, Output, PLATFORM_ID } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EGtmLocationEvent } from "@core-constants/gtm-const";
import { EServiceCode } from "@core-constants/product-service.const";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { IDomainCoverage } from "@core-models/domain.model";
import { IPurchaseSuggestionItemAdded, IPurchaseSuggestionRate, IShoppingCartGeneralStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { IRecaptchaV3Response } from '@core-models/security.model';
import { ShoppingCarBaseContent } from "@core-models/shopping-cart-plus.model";
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { GtmTrackingService } from "@shared-services/gtm-tracking.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-purchase-suggestion-item',
  templateUrl: './purchase-suggestion-item.component.html',
  styleUrls: ["./purchase-suggestion-item.component.css"]
})
export class PurchaseSuggestionItemComponent
{
  private _item: IShoppingCartGeneralStoredSuggestionPlus;
  public isAdded: boolean = false;

  public actionRecaptchaV3: string = "addSuggestionToggle";

  @Output() public onAddSuggestion = new EventEmitter<IPurchaseSuggestionItemAdded>();

  constructor(@Inject(PLATFORM_ID) private platformId,
    private shoppingCartDataService: ShoppingCartDataService,
    protected toast: ToastService,
    protected translateService: TranslateService,
    protected checkoutManager: CheckoutManager,
    protected captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef) { }

  @Input() public set item(value: IShoppingCartGeneralStoredSuggestionPlus)
  {
    this._item = value;
  }

  public get item(): IShoppingCartGeneralStoredSuggestionPlus
  {
    return this._item;
  }

  public get parentId(): string
  {
    return `${this.item.suggestionId}-purchase-suggestion`;
  }

  public get iconRoute(): string
  {
    return this._item.featureIconUrl;
  }

  public get label(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase()).textLabel;
  }

  public get title(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase())?.title;
  }

  public get toggleTitle(): string
  {
    return this.isAdded === true ? "Eliminar" : "Agregar a carrito";
  }

  public get subtitle(): string
  {
    return this._item.translations.find(x => x.language === this.translateService.languageCode.toUpperCase()).subtitle;
  }

  public get finalAmount(): number
  {
    return this._item.coverage.rate.finalAmount;
  }

  private get _isService(): boolean
  {
    return this._item.serviceToOffer.includes(EServiceCode.ASP) && this._item.serviceToOffer.includes(EServiceCode.APL);
  }

  private get _isDomain(): boolean
  {
    return this._item.serviceToOffer.includes(EServiceCode.DOT);
  }

  public get suggestionRate(): IPurchaseSuggestionRate
  {
    return this.item.coverage?.rate;
  }

  private getDomainDto(): IDomainCoverage[]
  {
    const domainList: IDomainCoverage[] = [];
    domainList.push({ domain: this._item.serviceToOffer.slice(this._item.serviceToOffer.indexOf("=") + 1), coverage: this._item.coverageMonths });
    return domainList;
  }

  private addDomainToCart(): void
  {
    const body: IDomainCoverage[] = this.getDomainDto();

    this.shoppingCartDataService.addDomains(body).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        const addedPurchaseSuggestion: IPurchaseSuggestionItemAdded =
        {
          cartId: response.cartId,
          suggestionId: this._item.suggestionId
        };

        this.onAddSuggestion.emit(addedPurchaseSuggestion);

        GtmTrackingService.addSuggestionToCartEvent([this.item], EGtmLocationEvent.GeneralSuggestion, true, this.suggestionRate);
      },
      error: () =>
      {
        this.isAdded = false;
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  private addServiceToCart(): void
  {
    this.shoppingCartDataService.addService(this._item.serviceToOffer, 1, this._item.coverageMonths).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        const addedPurchaseSuggestion: IPurchaseSuggestionItemAdded =
        {
          cartId: response.cartId,
          suggestionId: this._item.suggestionId
        };

        this.onAddSuggestion.emit(addedPurchaseSuggestion);

        GtmTrackingService.addSuggestionToCartEvent([this.item], EGtmLocationEvent.GeneralSuggestion, false, this.suggestionRate);
      },
      error: () =>
      {
        this.isAdded = false;
        this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
      }
    });
  }

  public onCheckChanges($event: boolean): void
  {
    this.isAdded = $event;

    if (this.isAdded === true)
    {
      if (isPlatformBrowser(this.platformId))
      {
        this.onExecuteAndVerifyRecaptcha();
      }
    }
  }

  public onExecuteAndVerifyRecaptcha(): void
  {
     this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
     .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.onAddedServiceOrDomainSuggestion();
          }
          else
          {
            this.toast.setErrorToast(response.message);
          }
        }
      });
  }

  public onAddedServiceOrDomainSuggestion() : void
  {
    if (this._isService)
    {
      this.addServiceToCart();
    }
    else if (this._isDomain)
    {
      this.addDomainToCart();
    }
  }
}
