export const ErrorMessageConst = {
  ExpiredSession: 'Sesión expirada',
  OperationFailed: "No se pudo completar la operación",
  LoginRequired: "Inicia sesión para continuar",
  EmptyCart: "Carrito vacío",
  OnlyOneCloseDomain: "Solo es posible realizar la solicitud de registro de un tipo de clasificación .edu.mx, .gob.mx y .net.mx a la vez.",
  ExistingDomainItem: "El dominio ya está en el carrito.",
  ErrorDeletingItem: "No se pudo eliminar el servicio del carrito",
  InvalidDomainName: "Ingresa un nombre de dominio válido",

  RegistrantRequired: "Por favor, confirma que los datos del Registrante (Titular) y Contactos son correctos y completos.",
  MoralRegistratRequired: "Es necesario que el Registrante (Titular) de los dominios .EDU.MX, .GOB.MX Y .NET.MX sea persona moral.",
  CCTRequired: "El CCT es requerido para la solicitud de registro de un dominio .EDU.MX",
  RegitrationTermsCheck: "Debes marcar que entiendes la importancia de los datos del registrante (Titular) y Contactos.",
  CreateContactFailed: "Ocurrió un error al agregar un contacto",
  UpdateContactFailed: "Ocurrió un error al editar un contacto",

  CouponRequired: "Introduce el cupón que deseas aplicar",

  BillingAndPamentPolicies: "Para continuar debes aceptar las políticas.",
  InterestFreeInfoRequired: "Por favor selecciona el banco y el periodo de meses sin intereses",
  PaymentCardIncompletetData: "Por favor proporciona los datos completos de la tarjeta de crédito/débito",
  PaymentCardIncorrectData: "Por favor verifica que los datos de la tarjeta de crédito/débito sean correctos",
  PaymentInvoiceIncorrectData: "Por favor proporciona los datos de facturación correctos",
  PaymentTradeNameRequired: "Indica: Nombre o razón social",
  PaymentBillingRFCRequired: "Indica: RFC",
  PaymentZipCodeRequired: "Indica: Código postal",
  PaymentFiscalRegimeRequired: "Indica: Régimen Fiscal",

  InvalidStep: "No es posible continuar el proceso de compra en más de un dispositivo o pestaña de navegador simultáneamente.",
  InvalidPristine: "Carrito modificado: Los servicios en el carrito se han actualizado con los cambios más recientes.",
  InvalidToken: "Token de autenticación inválido. Inicia sesión para continuar",
  InvalidItems: "No hay items en el carrito",
  InvalidProducts: "Algunos productos de tu selección del carrito tuvieron que eliminarse porque ya no eran válidos. Por favor revisa tu resumen de compra.",
  InvalidDomains: "Las modificaciones de este tipo de dominio no están disponibles temporalmente.",
  InvalidCart: "Las modificaciones de este tipo de dominio no están disponibles temporalmente."
};
