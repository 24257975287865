import { Injectable } from '@angular/core';
import { TranslateService } from '@shared-services/translate.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { GtmTrackingService } from './gtm-tracking.service';

@Injectable({ providedIn: 'root' })
export class ToastService
{
  public toastRef: any;

  private readonly _baseConfig: IndividualConfig = {
    disableTimeOut: false,
    timeOut: 10000,
    tapToDismiss: false,
    toastClass: 'success',
    closeButton: true,
    positionClass: 'bottom-right',
    extendedTimeOut: 0,
    progressBar: false,
    progressAnimation: 'increasing',
    enableHtml: false,
    titleClass: '',
    messageClass: '',
    easing: '',
    easeTime: '',
    onActivateTick: false,
    newestOnTop: false,
    payload: undefined
  };

  constructor(protected toastr: ToastrService,
    protected translateService: TranslateService)
  {
    toastr.toastrConfig.maxOpened = 3;
    toastr.toastrConfig.tapToDismiss = false;
  }

  public showToast(message: string, type: string, startData: string = "", endData: string = "", closeButton: boolean = true): void
  {
    if (message)
    {
      message = `${startData} ${this.translateService.getElement(message)} ${endData}`;

      const config = { ...this._baseConfig };

      config.toastClass = type;
      config.closeButton = closeButton;

      this.toastRef = this.toastr.show(message, undefined, config);
    }
  }

  public setSuccessToast(message: string, startData: string = "", endData: string = ""): void
  {
    this.showToast(message, "success", startData, endData);
  }

  public setErrorToast(message: string, startData: string = "", endData: string = ""): void
  {
    this.showToast(message, "error", startData, endData);
    GtmTrackingService.errorMessageEvent(message);
  }

  public setWarningToast(message: string, startData: string = "", endData: string = "", closeButton: boolean = true): void
  {
    this.showToast(message, "warning", startData, endData, closeButton);
  }
}
