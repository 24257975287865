export namespace HttpHeadersConst
{
  export const ContentType: string = 'Content-Type';
  export const CacheControl: string = 'Cache-Control';
  export const Authorization: string = 'Authorization';
  export const AccessControlAllowOrigin: string = 'Access-Control-Allow-Origin';
  export const AcceptLanguage: string = 'Accept-Language';
  export const AkkyTrxId: string = 'Akky-Trx-Id';
  export const MSVCToken = 'Authorization-MSVC';
}
