import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class CouponDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected translateService: TranslateService,
    protected checkoutManager: CheckoutManager,
    protected tokenManager: TokenManager)
  {
    super(http, 'coupons', translateService);
  }

  public apply(couponName: string): void
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const dto =
    {
      coupon: couponName,
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie()
    };

    const observable = this.http.post(this.createUrl('validate'), dto, queryDef.getRequestConfigWithAuthorizationToken(dto.token, this.translateService.languageCode)).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);
    observable.subscribe({
      next: (response: any) =>
      {
        if (response)
        {
          this.checkoutManager.setCoupon(response.coupon, response.value);
        }
      },
      error: (response: HttpErrorResponse) =>
      {
        this.checkoutManager.clearCoupon();

        const errorMessage = (response && response.error) ? response && response.error.message : "Revisa que lo hayas introducido correctamente";

        this.checkoutManager.setCouponError(errorMessage);
      }
    });
  }

  public delete(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const dto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie()
    };

    const observable = this.http.put(this.createUrl('delete'), dto, queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }
}

