import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from '@shared-services/local-storage.service';

@Component({
  selector: 'app-paypal-cancel',
  templateUrl: './paypal-cancel.component.html',
  styleUrls: ['./paypal-cancel.component.css']
})
export class PayPalCancelComponent implements OnInit
{
  public pageTitle: string = "Pago Cancelado Akky";

  constructor(protected localStorageService: LocalStorageService,
    private titleService: Title)
  { }

  public ngOnInit(): void
  {
    this.initTitle();
  }

  private initTitle(): void
  {
    this.titleService.setTitle(this.pageTitle);
  }

  public closeWindow(): void
  {
    window.close();
  }
}
