import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenManager } from '@core-managers/token.manager';
import { PaymentOrderDto } from '@core-models/payment-order.model';
import { EntityDataServiceBase } from '@shared-base/entity-data-service.base';
import { PayPalOrderDto, PaymentSettingsDto, UTMOrderDataDto } from '@shared-base/generic-clases.base';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { TranslateService } from '@shared-services/translate.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class PaymentsDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'payments', translateService);
  }

  public getPaymentSettings(): Observable<any>
  {
    const dto: PaymentSettingsDto = {
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie()
    };
    const config = new UrlQueryDef();
    config.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    config.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.post(this.createUrl('info/billing-and-payment'), dto, config.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public getOrder(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const token = this.tokenManager.getToken();
    const orderId: string = String(this.tokenManager.getOrderId());

    const observable = this.http.get(this.createUrl('order', orderId), queryDef.getRequestConfigWithAuthorizationToken(token)).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public confirmAndPay(data: PaymentOrderDto): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.post(this.createUrl('confirm/payment'), data, queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(observable);

    return observable;
  }

  public createPayPalOrder(data: PayPalOrderDto): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.post(this.createUrl('create/paypal-order'), data, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  public capturePayPalOrder(data: any): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.put(this.createUrl('capture/paypal-order'), data, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  public updateUTMOrder(data: UTMOrderDataDto): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.put(this.createUrl('tracking/utm'), data, queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }
}

