import { DropDownItem } from "@shared-base/generic-clases.base";

export namespace ServiceDataConst
{
  export const OxAddOn: number[] = [EAddonType.StorageOX, EAddonType.ModuleSecurity, EAddonType.ModuleProductivity];

  export const CapacityEmailOXOptionsGB: DropDownItem[] = [
    { value: 1, label: "1 GB" },
    { value: 5, label: "5 GB" },
    { value: 10, label: "10 GB" },
    { value: 20, label: "20 GB" },
    { value: 50, label: "50 GB" },
    { value: -1, label: "Personalizar" }
  ];
  export const AdditionalServicePkg = new Map<EAdditionalServiceId, string>([
    [EAdditionalServiceId.DomainAdvancedSecurity, 'Seguridad Avanzada de dominio'],
    [EAdditionalServiceId.InternetPresence, 'Presencia en Internet'],
    [EAdditionalServiceId.SMSAlerts, 'Alertas SMS'],
    [EAdditionalServiceId.CustomDNS, 'DNS Personalizado'],
    [EAdditionalServiceId.WebsiteBuilder_PVSite, 'Creador de sitios Web (Sitio PV)'],
    [EAdditionalServiceId.EmailRedirect, 'Redireccionamiento correo'],
    [EAdditionalServiceId.WebsiteRedirect, 'Redireccionamiento web'],
    [EAdditionalServiceId.FreeWebsite, 'Sitio Web Gratuito'],
    [EAdditionalServiceId.PremiumEmail, 'Email Premium'],
    [EAdditionalServiceId.OnlineStore, 'Tienda en Linea (ePages)'],
    [EAdditionalServiceId.WebsiteBuilderEPages, 'Creador de Sitios Web'],
    [EAdditionalServiceId.SSLCertificate, 'Certificado SSL'],
    [EAdditionalServiceId.Hosting, 'Hosting'],
    [EAdditionalServiceId.DigitalMarketing, 'Marketing Digital'],
    [EAdditionalServiceId.WebsiteBuilderWordPress, 'Creador de Sitios (WordPress'],
    [EAdditionalServiceId.EmailOpenxchange, 'Email Openxchange'],
    [EAdditionalServiceId.Microsoft, 'Microsoft']
  ]);

  export const AdditionalServicePlan = new Map<number, string>([
    [1, 'Email Plus'],
    [2, 'Email Premium'],
    [3, 'Tienda en linea - Inicia'],
    [4, 'Tienda en linea - Mejora'],
    [5, 'Tienda en linea - Crece'],
    [6, 'Tienda en linea - Sin Limites'],
    [7, 'Sitio Web - Basico Contigo'],
    [8, 'Sitio Web - Plus Contigo'],
    [9, 'Tienda en linea - Plus Contigo'],
    [10, 'Sin Plan'],
    [11, 'PositiveSSL DV'],
    [12, 'PositiveSSL Multidominio DV'],
    [13, 'PositiveSSL Multidominio EV'],
    [14, 'PositiveSSL Wildcard EV'],
    [15, 'SSL GeoTrust True BusinessID OV'],
    [16, 'PositiveSSL EV'],
    [17, 'SSL GeoTrust True BusinessID Wildcard OV'],
    [18, 'SSL GeoTrust True BusinessID Multidominio OV'],
    [19, 'Hosting - Basico'],
    [20, 'Hosting - Plus'],
    [21, 'Hosting - Premium'],
    [22, 'Sitio Web - Basico por ti'],
    [23, 'Sitio Web - Plus por ti'],
    [24, 'Tienda en Linea - Plus por ti'],
    [25, 'Email - Basico'],
    [26, 'Tienda en Linea - Basico Contigo'],
    [27, 'Tienda en Linea - Basico por ti'],
    [28, 'Branding'],
    [29, 'Kit Redes Basico'],
    [30, 'Guia Ads en Buscadores'],
    [31, 'Suite Digital para tu Negocio'],
    [32, 'Paquete Post Adicionales'],
    [33, 'Paquete Historias Destacadas'],
    [34, 'Servicio Diseño de Logo'],
    [35, 'Sitio WordPress - Basico'],
    [36, 'Sitio WordPress - Plus'],
    [37, 'Tienda WordPress - Basico'],
    [38, 'Tienda WordPress - Plus'],
    [39, 'RapidSSL DV'],
    [40, 'RapidSSL WildCard DV'],
    [41, 'Thawte Multidominio DV'],
    [42, 'Thawte Multidominio EV'],
    [43, 'Thawte EV'],
    [44, 'Office 365 E3'],
    [45, 'Microsoft 365 E3'],
    [46, 'Microsoft 365 F3'],
    [47, 'Office 365 E3 Trial'],
    [48, 'Office LTSC Professional Plus 2021'],
    [49, 'Project Plan 1'],
    [50, 'Power BI Pro'],
    [51, 'E-mail 5 GB'],
    [52, 'E-mail 10 GB'],
    [53, 'E-mail 50 GB'],
    [54, 'Sitio Web - Hazlo tu mismo'],
    [55, 'Microsoft 365 Empresa Basico'],
    [56, 'Microsoft 365 Empresa Estandar']
  ]);

  export const CapacityOptionsGB = [
    { value: 1, label: "1 GB" },
    { value: 5, label: "5 GB" },
    { value: 10, label: "10 GB" },
    { value: 20, label: "20 GB" },
    { value: 50, label: "50 GB" },
    { value: -1, label: "Personalizar" }
  ];
}


export const enum EServiceType
{
  Domain = 0,
  Service,
  DiverseService,
  Addon
}

export const enum EServiceTypeName
{
  Domain = "domain",
  Service = "service",
  DiverseService = "diverseService",
  Addon = "addOn"
}

export const enum EAdditionalServiceId
{
  DomainAdvancedSecurity = 1,
  InternetPresence,
  SMSAlerts,
  CustomDNS,
  WebsiteBuilder_PVSite,
  EmailRedirect,
  WebsiteRedirect,
  FreeWebsite,
  PremiumEmail,
  OnlineStore,
  WebsiteBuilderEPages,
  SSLCertificate,
  Hosting,
  DigitalMarketing,
  WebsiteBuilderWordPress,
  EmailOpenxchange,
  Microsoft
}

export const enum EAddonType
{
  StorageOX = 1,
  ModuleSecurity = 2,
  ModuleProductivity = 3
}

export const enum EServiceCode
{
  ASP = 'ASP',
  APL = 'APL',
  DOT = 'DOT'
}

export const enum EServiceCategory
{
  Registration = 1,
  Renewal,
  Transfer,
  TransferExtension,
  AdministrativePayment,
  RegistrationAddSVCPKG,
  RenewalPKGServAdd,
  AdjustmentPKGServAdd,
  Recovery,
  RecoveryDeletedStatus,
  RegistrationRestoreExtension,
  RenewRestoreExtension,
  Application,
  RegistrationPresenceServices,
  RenewalPresenceServices,
  AdjustmentPresenceServices,
  RegistrationAddon = 17,
  RenewalAddon
}
