import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EDomainType } from '@core-constants/domain-data.const';
import { HttpHeadersConst } from '@core-constants/http-headers.const';
import { EServiceType } from '@core-constants/product-service.const';
import { ECartStep } from '@core-constants/step.const';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from "@core-managers/token.manager";
import { AddCartDomainsDto, AddCartServiceDto, AddDomainRenewalDto, AddDomainServiceDto, AddDomainServiceRenewalDto, AddIndividualSuggestionDto, UpdateSuggestionUpgradeCoverageDto, UpdateSuggestionUpgradeQuantityDto } from '@core-models/add-to-cart.model';
import { IDomainCoverage } from '@core-models/domain.model';
import { EPurchaseSuggestionLocation } from '@core-models/purchase-suggestion.model';
import { RemoveDomainRenewalDto, RemoveDomainServiceDto, RemoveIndividualSugestionDto, RemoveItemDto } from '@core-models/remove-from-cart.model';
import { ShoppingCartPlus } from '@core-models/shopping-cart-plus.model';
import { UpdateDomainService, UpdateDomainServicesDto } from '@core-models/update-cart.model';
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShoppingCartDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected router: Router,
    protected tokenManager: TokenManager,
    private checkoutManager: CheckoutManager,
    protected translateService: TranslateService)
  {
    super(http, 'cart', translateService);
  }

  private getCartId(): string
  {
    let cartIdResult = '';
    const cartId = this.tokenManager.getCartCookie();

    if (cartId !== null)
    {
      cartIdResult = cartId;
    }

    return cartIdResult;
  }

  public get getShoppingCartId(): string
  {
    return this.getCartId();
  }

  // ********************************************************************************
  // #region List
  // ********************************************************************************

  public getCart(): void
  {
    const subject = this.getCartSubscription();

    if (subject)
    {
      subject.subscribe({
        next: (response: ShoppingCartPlus) =>
        {
          this.checkoutManager.initializeData(response);
        }
      });
    }
  }

  public getCartSubscription(): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const cartId = this.tokenManager.getCartCookie();

    if (cartId != "")
    {
      const dto =
      {
        token: this.tokenManager.getToken(),
        cartId
      };

      const subject = this.http.post(this.createUrl("plus"), dto, queryDef.getRequestConfig()).pipe(share());

      this._loadingMaskHandleObservableResponse(subject);

      return subject;
    }

    return null;
  }

  public getPurchaseSuggestions(location: EPurchaseSuggestionLocation): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const cartId = this.getCartId();
    const token = this.tokenManager.getToken();

    const body =
    {
      cartId: cartId,
      location: location
    };

    const subject = this.http.post(this.createUrl("purchase-suggestions"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }
  // #endregion

  // ********************************************************************************
  // #region Add to cart
  // ********************************************************************************


  public addService(serviceData: any, quantity: number = 1, serviceCoverage?: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    quantity == 0 ? quantity = 1 : '';

    const addServiceDto: AddCartServiceDto = {
      serviceData,
      cartId,
      quantity,
      language,
      serviceCoverage
    };

    const subject = this.http.post(this.createUrl("services/presence"), addServiceDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public addDomainService(domainId: number, serviceId: number, coverageId: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: AddDomainServiceDto = {
      serviceId,
      coverageId,
      domainId,
      cartId: this.tokenManager.getCartCookie()
    };

    const subject = this.http.post(this.createUrl("add/domain-service"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public addDomains(domains: IDomainCoverage[]): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();
    const language = this.translateService.languageCode;

    const addCartDomainsDto: AddCartDomainsDto = {
      domainList: domains,
      cartId,
      language
    };

    const subject = this.http.post(this.createUrl("domains"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }
  public addDomainServiceRenewal(serviceId: number, objectId: number, coverageId: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: AddDomainServiceRenewalDto = {
      serviceId,
      coverageId,
      objectId,
      cartId: this.getCartId()
    };

    const subject = this.http.post(this.createUrl("add/renewal/domain-service"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public addIndividualSuggestion(cartItemId: number, suggestionId: string, quantity: number = undefined, coverageMonths: number = undefined): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    const addCartDomainsDto: AddIndividualSuggestionDto = {
      cartId,
      cartItemId,
      suggestionId,
      quantity,
      coverageMonths
    };

    const subject = this.http.post(this.createUrl("add/individual-suggestion"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(this.translateService.languageCode));

    return subject;
  }

  public addDomainRenewal(name: string, domainType: EDomainType, coverageId: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: AddDomainRenewalDto = {
      name,
      coverageId,
      domainType,
      cartId: this.getCartId()
    };

    const subject = this.http.post(this.createUrl("add/domain-renewal"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  // #endregion

  // ********************************************************************************
  // #region remove from cart
  // ********************************************************************************

  public removeDomainRenewal(name: string, domainType: EDomainType): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: RemoveDomainRenewalDto = {
      name,
      domainType,
      cartId: this.getCartId(),
      stepCalledFrom: ECartStep.DomainServices
    };

    const subject = this.http.post(this.createUrl("remove/domain-renewal"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;
  }

  public removeItem(id: number, serviceType: EServiceType): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();

    const dto: RemoveItemDto =
    {
      cartId: this.getCartId(),
      id,
      serviceType,
      stepCalledFrom: ECartStep.DomainServices,
      token: this.tokenManager.getToken()
    };

    const subject = this.http.post(this.createUrl("remove/item"), dto, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    this._handleObservableResponse(subject);

    return subject;
  }

  public removeDomainServiceItem(domainId: number, serviceId: number): Observable<any>
  {
    const dto: RemoveDomainServiceDto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      domainId,
      serviceId
    };

    const subject = this.http.post(this.createUrl("remove/domain-service"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public removeIndividualSuggestion(cartItemId: number, suggestionId: string): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const cartId = this.getCartId();

    const addCartDomainsDto: RemoveIndividualSugestionDto = {
      cartId,
      cartItemId,
      suggestionId
    };

    const subject = this.http.post(this.createUrl("remove/individual-suggestion"), addCartDomainsDto, queryDef.getRequestConfigWithAuthorizationToken(this.translateService.languageCode));

    return subject;

  }
  // #endregion

  public updateDomainServices(domainId: number, domainServices: UpdateDomainService[]): Observable<any>
  {
    const dto: UpdateDomainServicesDto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      domainId,
      domainServices
    };

    const subject = this.http.post(this.createUrl("update/domain-services"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public updateCoverage(id: number, serviceType: EServiceType, coverageId: number): Observable<any>
  {
    const dto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.getCartId(),
      id,
      coverageId,
      serviceType,
      stepCalledFrom: ECartStep.DomainServices
    };

    const subject = this.http.put(this.createUrl("update/item-coverage"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public updateAddonQuantity(id: number, serviceType: EServiceType, quantity: number): Observable<any>
  {
    const dto =
    {
      language: this.translateService.languageCode,
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie(),
      id,
      quantity,
      serviceType
    };

    const subject = this.http.put(this.createUrl("update/addon-quantity"), dto);

    this._handleObservableResponse(subject);

    return subject;
  }

  public updateDomainName(id: number, serviceType: EServiceType, domainName: string): Observable<any>
  {
    const dto =
    {
      token: this.tokenManager.getToken(),
      cartId: this.tokenManager.getCartCookie(),
      id,
      domainName,
      serviceType
    };

    const subject = this.http.put(this.createUrl("update/domain"), dto).pipe(share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public updateServiceByQuantityGroup(quantityGroup: number, coverageId: number, quantity: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const token = this.tokenManager.getToken();
    const cartId = this.getCartId();

    const body =
    {
      token: token,
      cartId: cartId,
      coverageId: coverageId,
      quantity: quantity,
      quantityGroup: quantityGroup,
      stepCalledFrom: ECartStep.DomainServices
    };

    const subject = this.http.put(this.createUrl("update/quantity-group"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    this._handleObservableResponse(subject);

    return subject;
  }

  public deleteServiceByQuantityGroup(quantityGroup: number): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    const token = this.tokenManager.getToken();
    const cartId = this.tokenManager.getCartCookie();

    const body =
    {
      token: token,
      cartId: cartId,
      quantityGroup: quantityGroup,
      stepCalledFrom: ECartStep.DomainServices
    };

    const subject = this.http.put(this.createUrl("remove/quantity-group"), body, queryDef.getRequestConfigWithAuthorizationToken(token, this.translateService.languageCode));

    return subject;

  }

  public updateSuggestionUpgradeCoverage(shoppingCartItemId: number, suggestionId: string, coverageId: number): Observable<boolean>
  {
    const dto: UpdateSuggestionUpgradeCoverageDto = {
      shoppingCartId: this.getCartId(),
      shoppingCartItemId: shoppingCartItemId,
      suggestionId: suggestionId,
      coverageId: coverageId
    };

    const subject = this.http.put<boolean>(this.createUrl("update/suggestion-coverage"), dto);

    return subject;
  }

  public updateSuggestionUpgradeQuantity(shoppingCartItemId: number, suggestionId: string, quantity: number): Observable<boolean>
  {
    const dto: UpdateSuggestionUpgradeQuantityDto = {
      shoppingCartId: this.getCartId(),
      shoppingCartItemId: shoppingCartItemId,
      suggestionId: suggestionId,
      quantity: quantity
    };

    const subject = this.http.put<boolean>(this.createUrl("update/suggestion-quantity"), dto);

    return subject;
  }


}


