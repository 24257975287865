import { HttpErrorResponse } from "@angular/common/http";
import { Component, DestroyRef, OnInit, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EAppEventName } from "@core-constants/app-event-names.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { ELogoutType } from "@core-constants/logout-events.const";
import { PageHelper } from "@core-constants/page-helper.const";
import { ECartStep, StepConst } from "@core-constants/step.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { UserActions } from "@core-constants/user-actions.const";
import { ContactsDataService } from "@core-data-services/contacts.data-service";
import { CouponDataService } from "@core-data-services/coupons.data-service";
import { DomainDataService } from "@core-data-services/domains.data-service";
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { StepsDataService } from "@core-data-services/steps.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { EPurchaseSuggestionLocation, IPurchaseSuggestionItemAdded, IShoppingCartGeneralStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { IRecaptchaV3Response } from '@core-models/security.model';
import { GroupedShoppingCart } from "@core-models/shopping-cart-plus.model";
import { AppClientContext } from "@shared-base/client-context.base";
import { UserTokenStorage } from "@shared-base/generic-clases.base";
import { BroadcastService } from "@shared-services/broadcast.service";
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { ToastService } from "@shared-services/toast.service";

@Component({
  selector: "app-domain-services-step",
  templateUrl: "./domains-services.component.html",
  styleUrls: ["./domains-services.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class DomainServicesStepComponent implements OnInit
{
  private _storedPurchaseSuggestions: IShoppingCartGeneralStoredSuggestionPlus[] = [];
  public products: GroupedShoppingCart[];
  public purchaseSuggestions: IShoppingCartGeneralStoredSuggestionPlus[];

  public steps: StepConst.MainStep[] = StepConst.StepsArray;
  public disableCheckout: boolean = false;

  public actionRecaptchaV3: string = "domainsAndServices";

  constructor(protected couponDataService: CouponDataService,
    protected tokenManager: TokenManager,
    protected toastService: ToastService,
    protected domainService: DomainDataService,
    protected checkoutManager: CheckoutManager,
    protected stepsDataService: StepsDataService,
    protected shoppingCartDataService: ShoppingCartDataService,
    protected contactsDataService: ContactsDataService,
    protected captchaV3Service: CaptchaV3Service,
    protected userAccessDataService: UserAccessDataService,
    private destroyRef$: DestroyRef)
  {
    this.tokenManager.removeOrderId(); //on this step doesnt exist
  }

  public get showSuggestions(): boolean
  {
    return this.purchaseSuggestions && this.purchaseSuggestions.length > 0;
  }

  public get showLoadingMask(): boolean
  {
    return AppClientContext.Instance.isBusy || this.products.some(x => x.product.fullName == x.product.domainType);
  }

  public get isMobile(): boolean
  {
    return window.innerWidth <= PageHelper.TabletWidth;
  }

  public get total(): number
  {
    return this.checkoutManager.total;
  }

  public get hasCouponToApply(): boolean
  {
    return this.checkoutManager.hasCouponToApply;
  }

  public get hasProducts(): boolean
  {
    return this.products && this.products.length > 0;
  }

  public ngOnInit(): void
  {
    this.products = this.checkoutManager.groupedCartItems;
    this.userAccessDataService.getInitialHandshake().subscribe(
      {
        next: (response) =>
        {
          if (response)
          {
            this.registerEventListeners();
            this.getPurchaseSuggestions();
          }
        }
      });
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnUpdateCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.products = this.checkoutManager.groupedCartItems;
        this.showAccessMethodToast();
      }
    });

    this.contactsDataService.removeRedisContactDomain().subscribe({
      next: () => { }
    });
  }

  // ********************************************************************
  //#region Flags
  // ********************************************************************

  public hasPendingDomains(): boolean
  {
    return this.products?.find(x => x.product.name == '') != undefined;
  }

  public onShoppingCartItemChanges($event): void
  {
    this.disableCheckout = $event;
    this.handleGeneralSuggestionOnCatItemChanges();
  }

  private handleGeneralSuggestionOnCatItemChanges(): void
  {
    const cartItemCount = this.checkoutManager.cartItemsCount;
    const itemLimit = this.checkoutManager.itemsLimit;
    const hasStoredSuggestions = this._storedPurchaseSuggestions.length > 0;
    if (cartItemCount < itemLimit)
    {
      if(!hasStoredSuggestions)
      {
        this.getPurchaseSuggestions();
        return;
      }
      this.purchaseSuggestions = this._storedPurchaseSuggestions;
    }
    else
    {
      this.purchaseSuggestions = [];
    }
  }


  public isStepValid(): boolean
  {
    const user: UserTokenStorage = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();

    if (!user || !token)
    {
      this.toastService.setErrorToast(ErrorMessageConst.LoginRequired);

      const cart = this.tokenManager.getCartCookie();

      if (cart && cart != "")
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnOpenLoginPopup);
      }
      else
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.InvalidToken);
      }
      return false;
    }

    if (this.checkoutManager.cartItemsCount <= 0)
    {
      this.toastService.setErrorToast(ErrorMessageConst.EmptyCart);
      return false;
    }

    if (!this.checkoutManager.isCloseDomainValid)
    {
      this.toastService.setErrorToast(ErrorMessageConst.OnlyOneCloseDomain);
      return false;
    }

    return true;
  }
  // #endregion

  // #endregion

  // ********************************************************************
  //#region Purchase suggestions
  // ********************************************************************

  private getPurchaseSuggestions(): void
  {
    this.shoppingCartDataService.getPurchaseSuggestions(EPurchaseSuggestionLocation.PopupShoppingCart).subscribe({
      next: (response) =>
      {
        this._storedPurchaseSuggestions = [];
        this.purchaseSuggestions = response.map(suggestion => this.purchaseSuggestionAdapter(suggestion));
        this._storedPurchaseSuggestions = this.purchaseSuggestions;
      }
    });
  }

  private purchaseSuggestionAdapter(purchaseSuggestion: IShoppingCartGeneralStoredSuggestionPlus): IShoppingCartGeneralStoredSuggestionPlus
  {
    return {
      ...purchaseSuggestion,
      coverage: purchaseSuggestion.coverage[0]
    };
  }

  public onAddSuggestion($event: IPurchaseSuggestionItemAdded): void
  {
    this.purchaseSuggestions = this.purchaseSuggestions.filter(x => x.suggestionId != $event.suggestionId);
    this.getPurchaseSuggestions();

    BroadcastService.Instance.broadcast(EAppEventName.OnAddToCart, $event.cartId);
    this.toastService.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
  }

  // #endregion
  // ********************************************************************
  //#region Remove Items
  // ********************************************************************

  public removeCoupon(): void
  {
    if (this.checkoutManager.coupon)
    {
      this.couponDataService.delete().subscribe({
        next: () =>
        {
          this.checkoutManager.clearCoupon();
        }
      });
    }
  }

  // #endregion


  // ********************************************************************
  //#region Events
  // ********************************************************************


  public onContinueClick(): void
  {
    this.disableCheckout = true;

    if (this.isStepValid())
    {
      this.onExecuteAndVerifyRecaptcha();
    }
    else
    {
      this.disableCheckout = false;
    }
  }

  public onExecuteAndVerifyRecaptcha(): void
  {
     this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.onValidateStepForward();
          }
          else
          {
            this.disableCheckout = false;
            this.toastService.setErrorToast(response.message);
          }
        },
        error: () =>
        {
          this.disableCheckout = false;
        }
      });
  }

  public onValidateStepForward(): void
  {
    this.stepsDataService.validateStepForward(ECartStep.DomainServices).subscribe({
      next: () =>
      {
        this.removeCoupon();
        BroadcastService.Instance.broadcast(EAppEventName.OnNextStep, ECartStep.DomainServices);
      },
      error: (response: HttpErrorResponse) =>
      {
        this.disableCheckout = false;
        const error: string = response?.error?.message;
        BroadcastService.Instance.broadcast(EAppEventName.OnValidCart, error);
      }
    });
  }

  public onBackClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnPreviousStep, ECartStep.DomainServices);
  }

  public errorMessage(response: HttpErrorResponse): void
  {
    if (response.status === 500 && response?.error?.message)
    {
      this.toastService.setErrorToast(response.error.message);
    }
    else
    {
      this.toastService.setErrorToast(ErrorMessageConst.OperationFailed);
    }
  }

  private showAccessMethodToast(): void
  {
    const action = this.tokenManager.getUserAccessMethod();

    if (action)
    {
      this.tokenManager.removeUserAccessMethod();

      const message = action === UserActions.Login ? SuccessMessageConst.LoginSuccess : SuccessMessageConst.AccountCreated;

      this.toastService.setSuccessToast(message);
    }
  }
  // #endregion
}
