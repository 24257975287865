import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EKeyCode } from '@core-constants/input-key.const';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { GroupedShoppingCart } from '@core-models/shopping-cart-plus.model';

@Directive({
  selector: '[itemQuantity]'
})
export class ItemQuantityDirective implements OnInit
{
  protected input: HTMLInputElement;

  @Input() public currentItem: GroupedShoppingCart;

  @Output() protected onValueChanges: EventEmitter<number>;

  constructor(protected elementRef: ElementRef,
    protected checkoutManager: CheckoutManager)
  {
    this.input = this.elementRef.nativeElement as HTMLInputElement;
    this.onValueChanges = new EventEmitter<number>();
  }

  public ngOnInit(): void { }

  public get length(): number
  {
    return this.input.value.length;
  }

  public get currentCartMaxQuantity(): number
  {
    return (this.checkoutManager.itemsLimit - this.checkoutManager.cartItemsCount);
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): boolean
  {
    const code = e.code?.toLowerCase();

    if (code === EKeyCode.Backspace || code === EKeyCode.Delete)
    {
      return true;
    }

    const value: RegExpMatchArray = !e.key ? null : e.key.match(/[\d]+/);

    return value != null;
  }

  @HostListener('keypress', ['$event'])
  @debounce()
  public onKeyPress(): boolean
  {
    this.updateValue();

    return this.length < 3;
  }

  @HostListener("focusout")
  public setInputFocus(): void
  {
    this.updateValue();

    this.onValueChanges.emit(+this.input.value);
  }

  @HostListener('keyup')
  @debounce()
  public onKeyUp(): boolean
  {
    this.updateValue();

    this.onValueChanges.emit(+this.input.value);

    return this.length < 3;
  }

  public updateValue(): void
  {
    this.input.value = this.input.value.replace(/^0+/, '');

    const quantity: number = +this.input.value;
    const currentMaxItems: number = this.currentCartMaxQuantity + this.currentItem.counter;
    if (quantity === 0)
    {
      this.input.value = "1";
    }

    if (quantity > currentMaxItems)
    {
      this.input.value = (currentMaxItems).toString();
    }
  }
}

export function debounce(delay: number = 1000): MethodDecorator
{
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor)
  {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args): void
    {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

