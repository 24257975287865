import { Injectable } from "@angular/core";
import { PaymentsDataService } from "@core-data-services/payments.data-service";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { ICartAndPaymentSettingsResolver } from "@shared-base/generic-clases.base";
import { Observable, forkJoin } from "rxjs";

@Injectable({ providedIn: 'root' })
export class PaymentDataResolver  
{
  constructor(private service: PaymentsDataService,
    private shoppingCartDataService: ShoppingCartDataService) { }

  public resolve(): any
  {
    const data: Observable<ICartAndPaymentSettingsResolver> = forkJoin({
      shoppingCart: this.shoppingCartDataService.getCartSubscription(),
      settings: this.service.getPaymentSettings()
    });

    return data;
  }
}
