import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { PageHelper } from '@core-constants/page-helper.const';
import { CouponDataService } from '@core-data-services/coupons.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { ErrorMessageConst } from '../../constants/error-message.const';

@Component({
  selector: 'app-coupon-input',
  templateUrl: './coupon-input.component.html',
  styleUrls: ['./coupon-input.component.css']
})
export class CouponInputComponent
{
  public coupon: string = "";

  @Input() public set couponToApply(value: string)
  {
    this.coupon = value;
  }

  constructor(protected couponDataService: CouponDataService,
    protected checkoutManager: CheckoutManager,
    protected translateService: TranslateService,
    protected toastService: ToastService) { }

  public get isMobile(): boolean
  {
    return window.innerWidth < PageHelper.MobileWidth;
  }

  public get couponApplied(): boolean
  {
    return this.checkoutManager.isCouponApplied;
  }

  public get couponName(): string
  {
    return this.checkoutManager.coupon ?? "";
  }

  public get isValidSavingCouponAmount(): boolean
  {
    return this.checkoutManager.hasSavingCouponAmount;
  }

  public get showError(): boolean
  {
    return this.checkoutManager.hasCouponError;
  }

  public get errorTitle(): string
  {
    return this.checkoutManager.hasCouponError ? "¡Cupón no válido!" : "";
  }

  public get errorMessage(): string
  {
    return this.checkoutManager.couponError;
  }

  public onRemoveCouponClicked(): void
  {
    this.couponDataService.delete().subscribe({
      next: () =>
      {
        this.coupon = this.checkoutManager.hasCouponToApply ? this.checkoutManager.couponToApply : "";
        this.checkoutManager.clearCoupon();
        this.checkoutManager.setCouponError("");
      },
      error: (response: HttpErrorResponse) =>
      {
        if (response && response.error)
        {
          this.toastService.setErrorToast(response.error.message);
        }
      }
    });
  }

  public onApplyCouponClick(): void
  {
    this.applyCoupon();
  }

  private applyCoupon(): void
  {
    if (this.coupon)
    {
      this.couponDataService.apply(this.coupon);
    }
    else
    {
      this.toastService.setErrorToast(ErrorMessageConst.CouponRequired);
    }
  }
}
