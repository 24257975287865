<!-- Main item display component -->
<div *ngIf="item" class="basic-card p-3 my-3 w-100">

  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

  <ng-container *ngTemplateOutlet="searchDomainTemplate"></ng-container>

  <!-- Main Service detail -->
  <ng-container *ngTemplateOutlet="serviceItemTemplate"></ng-container>

  <!-- Service features -->
  <ng-container *ngTemplateOutlet="serviceFeatures"></ng-container>

  <!-- Trash icon -->
  <div *ngIf="isRemovable" class="pt-3 d-flex w-100 alig-items-center justify-content-end">
    <span title="{{ 'Eliminar' | translate }}" class="clickable i-trash" role="button"
      (click)="onDeleteIconClick()"></span>
  </div>

  <!-- Service Suggestions -->
  <ng-container *ngTemplateOutlet="suggestionsTemplate"></ng-container>
</div>

<!----------------------------------- TEMPLATES  ----------------------------------->

<ng-template #headerTemplate>
  <!-- Title, offer message, and edit domain icon -->
  <div class="row p-0 m-0 align-items-start justify-content-between">
    <div [ngClass]="showRequirementsLabel === true ? 'col-6' : isDomainRenewalDeleted === true ? 'col-8' : 'col-9'"
      class="p-0 m-0 order-1">
      <div class="d-flex w-100 align-items-start justify-content-between">
        <div class="text-start flex-grow-1">
          <div class="font-weight-bold fs-l">
            <!-- Operation type only for domain item-->
            <span *ngIf="isDomain" class="d-block word-break">
              {{ getOperationTypeName() | translate }}
              {{ isEmtpyDomainName || isDomainNameEditing ? ('de dominio' | translate) + itemProduct.fullName : '' }}
            </span>
            <!-- Item name for ANY service-->
            <span class="d-block word-break-wrap">
              {{getProductName()}}
            </span>
          </div>
          <!-- Object Id when required-->
          <span class="d-block fs-m">{{ getObjectId() }}</span>
          <span class="d-block fs-m">{{ getAddonObjectId() }}</span>
        </div>
      </div>
    </div>

    <!-- Close domains requirement (only gob.mx, edu.mx, lat.mx) -->
    <div *ngIf="showRequirementsLabel && isDomainNameEditing === false"
      class="col-12 col-md-3 d-flex p-0 m-0 align-items-center justify-content-start order-3 order-md-2">
      <span class="text-break font-weight-bold fs-m text-green text-underline clickable py-3"
        (click)="onCloseDomainRequirementModalClick()">
        {{'Ver requisitos' | translate }}
      </span>
    </div>

    <!-- Add renewal button  -->
    <div class="p-0 m-0 mt-3 mt-md-0 col-12 col-md-4 order-2 d-flex align-items-start align-items-md-end flex-column"
      *ngIf="isDomainRenewalDeleted">
      <button class="btn btn-info text-uppercase f-md font-weight-bold" (click)="addDomainRenewal()">
        {{ "Renovar dominio" | translate }}
      </button>
    </div>

    <div class="p-0 m-0 d-flex flex-column align-items-end justify-content-center order-2 order-md-3 col-3">
      <!-- Offer message -->
      <div *ngIf="hasOffer(itemProduct.rate)" class="offer-radius bg-aqua white fs-xs px-2 py-1">
        {{ itemProduct.rate.message }}
      </div>

      <!-- Domain name edit -->
      <ng-container>
        <!-- Edit domain icon -->
        <span *ngIf="isValidDomainNameEdit === true" title="{{ 'Editar nombre de dominio' | translate }}"
          class="clickable i-edit mt-2" (click)="onEditDomainNameclicked()"></span>
        <!--Cancel edit icon -->
        <span *ngIf="isDomainNameEditing === true" (click)="cancelChangeDomainName()" class="clickable i-times"
          [class.mt-2]="hasOffer(itemProduct.rate)"></span>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Coverage Template -->
<ng-template #coverageTemplate>
  <div class="w-100">
    <app-dropdown [label]="getCoverageLabel()" [items]="itemProduct.coverageOptions | coverage:'array'"
      [isFullWidth]="true" [coverage]="true" [disabled]="disableCoverageDropdown" [parentId]="itemProduct.id"
      [selectedValue]="itemProduct.coverageId" (onItemSelectedChange)="onItemCoverageChange($event)">
    </app-dropdown>
  </div>
</ng-template>

<!-- Search Domain Template -->
<ng-template #searchDomainTemplate>
  <div *ngIf="isDomainNameEditing === true"
    class="py-3 d-flex flex-column flex-md-row w-100 alig-items-center justify-content-between">

    <div class="input-group floating-label w-100 m-auto my-3">
      <input [id]="'product' + itemProduct.id" [(ngModel)]="domainToSearch" (keyup.enter)="onSearchDomainClicked()"
        placeholder="{{'Busca tu dominio' | translate }} {{itemProduct.domainType}}" type="text" role="textbox"
        title="{{'Buscar' | translate}}" autocomplete="off" class="form-control form-input">

      <div class="input-group-append d-flex alig-items-center">
        <span class="icon i-search me-2 clickable" [class.disabled-icon]="!domainToSearch"
          title="{{'Buscar' | translate}}" (click)="onSearchDomainClicked()"></span>
      </div>
    </div>

    <div class="d-flex justify-content-end my-3">
      <button [disabled]="!confirmDomainNameEnabled || !domainToSearch" [id]="'confirm' + i" type="button"
        class="btn btn-primary ms-0 ms-md-2 text-uppercase" (click)="onConfirmSearchedDomain()">
        {{'Confirmar' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Price -->
<ng-template #priceTemplate let-rate="rate" let-quantity="quantity" let-counter="counter">
  <div *ngIf="rate && rate?.finalAmount !== 0" class="d-flex p-0 pt-3 pt-md-0 flex-column">
    <!-- Final price -->
    <span class="text-end price fs-m fw-bold p-0">
      {{ rate.finalAmount * quantity * counter | currency }}
      <span> MXN</span>
    </span>
    <!-- Offer -->
    <div *ngIf="hasOffer(rate)" class="d-flex flex-column alig-items-center justify-content-end">
      <!-- Offer amount -->
      <span class="gray fs-xs price fw-bold p-0 text-end">
        <del>{{ rate.offerAmount * quantity * counter | currency }}</del>
        <span> MXN</span>
      </span>
      <!-- Discount amount-->
      <span class="text-green fw-bold text-uppercase text-end fs-s">
        {{ 'Ahorra' | translate }}
        {{ ((rate.finalAmount * quantity * counter) - (rate.offerAmount * quantity * counter) ) | currency}} MXN
      </span>
    </div>
  </div>
  <span *ngIf="rate.finalAmount === 0 && !isRenewal" class="fs-m fw-bold text-end">
    {{ getDisplayMessageRate0() | translate }}
  </span>
</ng-template>

<!-- Addon quantity selector Template-->
<ng-template #addonQuantityTemplate>
  <div class="row w-100 justify-content-between alig-items-center p-0 m-0">
    <!-- Quantity selector -->
    <div class="col-7 col-md-5 p-0 m-0">
      <app-dropdown [label]="'CANTIDAD'" [items]="emailOXCapacityOptions" [isFullWidth]="true"
        [parentId]="itemProduct.id" [selectedValue]="selectedQuantity" [disabled]="disabledCapacityButtons"
        (onItemSelectedChange)="onAddonCapacitySelectedChanges($event)">
      </app-dropdown>
    </div>
    <!-- GB input -->
    <div *ngIf="isPersonalizedQuantity === true" class="col-5 pe-0 me-0">
      <div class="w-100 d-flex justify-content-end">
        <app-input-number [label]="'GB'" [value]="itemProduct.quantity" [disabled]="disabledCapacityButtons"
          [parentId]="itemProduct.id" [min]="1" (onValueChanges)="onAddonItemValueChanges($event)">
        </app-input-number>
      </div>
    </div>
  </div>
</ng-template>

<!-- All services template-->
<ng-template #serviceItemTemplate>
  <div *ngIf="isValidCoverage()" class="row pt-3 w-100 p-0 m-0 align-items-center justify-content-between">

    <!-- Addon quantity selectors -->
    <ng-container *ngIf="isAddonCumulative; then addonQuantityTemplate"></ng-container>

    <!-- Coverage dropdown -->
    <div *ngIf="!isNotChargableDomain && showCoverageDropdown() === true" class="col-12 col-md-5 p-0 m-0 order-1">
      <ng-container *ngTemplateOutlet="coverageTemplate"></ng-container>
    </div>

    <!-- Price -->
    <div *ngIf="!isNotChargableDomain" class="col-5 order-3 pe-0 order-md-2">
      <div class="d-flex justify-content-end">
        <ng-container
          *ngTemplateOutlet="priceTemplate; context: { rate: itemProduct.rate, quantity: itemProduct.quantity, counter: item.counter }"></ng-container>
      </div>
    </div>

    <!-- Service quantity selector -->
    <div class="col-7 col-md-4 p-0 m-0 order-2"
      [ngClass]="showCoverageDropdown() === true ? 'order-md-3' : 'order-md-1'">
      <div *ngIf="isUserServiceRegistration" class="qnty-selector-container">
        <app-quantity-selector [currentItem]="item" [show]="true" [disabled]="disableQuantityGroupButtons"
          [quantity]="item.counter" (onQuantityChanges)="onUpdateServiceByQuantityGroup($event)">
        </app-quantity-selector>
      </div>
    </div>
  </div>

  <!-- Various Services -->
  <ng-container *ngTemplateOutlet="variousServicesTemplate"></ng-container>

  <!-- Domain Services -->
  <ng-container *ngTemplateOutlet="domainServicesTemplate"></ng-container>

</ng-template>

<!-- Various services Template -->
<ng-template #variousServicesTemplate>
  <ng-container *ngIf="hasVariousServices">
    <div *ngFor="let service of itemProduct.variousServices" class="row p-0 m-0 pt-3 align-items-center">
      <!-- Service name -->
      <div class="col-12 text-break font-weight-bold fs-m p-0">
        {{service.description}}
      </div>
      <div class="col-12 p-0 pt-3 m-0">
        <ng-container
          *ngTemplateOutlet="priceTemplate; context: { rate: service.coverage.rate, quantity: itemProduct.quantity, counter: 1 }">
        </ng-container>
      </div>
      <div *ngIf="isAddonCumulative === false" class="col-12 p-0 pt-3 m-0 text-end price fs-m fw-bold p-0">
        {{ calculateTotalByProduct() | currency }} MXN
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Domain services Template -->
<ng-template #domainServicesTemplate>
  <div *ngIf="hasDomainServices" class="pt-3">
    <app-domain-services-accordion [domainServices]="itemProduct.domainServices" [parentId]="itemProduct.id"
      [parentEDomainType]="itemProduct.domainType" [isDomainAdded]="!(itemProduct.isNotChargeableDomain)"
      [parentCoverage]="coverageByItem()">
    </app-domain-services-accordion>

    <div class="pt-3 text-end font-weight-bold">
      <span class="fs-m price">{{ calculateTotalByProduct() | currency }} MXN</span>
    </div>
  </div>
</ng-template>

<!-- Service suggestions Template -->
<ng-template #suggestionsTemplate>
  <ng-container *ngIf="hasSuggestions()">
    <div *ngFor="let suggestion of suggestions; trackBy: trackById" class="pt-3">
     <app-shopping-cart-suggestion-item
        [parentId]="itemProduct.id"
        [suggestion]="suggestion"
        [itemProduct]="itemProductSuggestionLite"
        (onSuggestionUpgradeItem)="onSuggestionUpgradeItem($event)"
      ></app-shopping-cart-suggestion-item>
    </div>
  </ng-container>
</ng-template>

<!-- Service features Template -->
<ng-template #serviceFeatures>
  <ng-container *ngIf="hasValidFeatures()">
    <div class="d-flex mt-3 me-3" *ngFor="let feature of features">
      <div class="d-flex align-items-center me-2">
        <span class="i-green-check"></span>
      </div>
      <div>
        <span class="text-break fs-m">{{feature}}</span>
      </div>
    </div>
  </ng-container>
</ng-template>

<!----------------------------------- Delete modal ----------------------------------->
<app-dialog [enableCloseDialogButton]="false" [show]="showDeleteItemModal" [preventCloseFromBackDrop]="true">
  <div class="p-4 bg-white delete-item-modal">
    <!-- Back icon -->
    <div class="clickable" (click)="onCloseConfirmationModalClick()">
      <img loading="lazy" data-src="{{ '/icons/Colores/arrow-left-light-gray.svg' | blobstorage }}" alt="Arrow left"
        src="{{ '/icons/Colores/arrow-left-light-gray.svg' | blobstorage }}">
    </div>
    <!-- Delete message confirmation -->
    <div class="text-center my-3 my-md-4">
      <h4 class="font-weight-bold black my-2 p-0">
        {{ '¡Ya encontraste tu dominio ideal!' | translate }}

      </h4>
      <p class="fs-l black p-0 my-2">
        {{ '¿Seguro que quieres eliminarlo del carrito?' | translate }}
      </p>
    </div>
    <!-- Buttons -->
    <div class="row p-0 m-0">
      <div class="col-12 col-md-5 order-2 order-md-1 mt-3 mt-md-0">
        <button class="btn btn-primary me-0 me-md-3 w-100 text-uppercase" type="button"
          (click)="onCloseConfirmationModalClick()">
          {{ 'Cancelar' | translate }}
        </button>
      </div>
      <div class="col-12 col-md-7 order-1 order-md-2">
        <button class="btn btn-info w-100" (click)="onRemoveCartItemClick()">
          {{'Sí, estoy seguro' | translate}}
        </button>
      </div>
    </div>
  </div>
</app-dialog>

<!-- Close domain requirements modal -->
<app-dialog [enableCloseDialogButton]="false" [show]="showRequirementModal" [preventCloseFromBackDrop]="true">
  <div class="p-4 bg-white dialog-auto m-auto">
    <div class="d-flex justify-content-end">
      <span class="icon i-times-solid-red clickable" (click)="showRequirementModal = false"></span>
    </div>
    <div [innerHTML]="requirementHTML | safe: 'html'"></div>
    <div class="w-100 text-center">
      <button class="btn btn-primary" (click)="showRequirementModal = false">
        {{'Continuar' | translate}}
      </button>
    </div>
  </div>
</app-dialog>
