import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetsService
{
  private _blobStorageUrl: string = "";
  private blobURLChanges$: BehaviorSubject<string> = new BehaviorSubject<string>(this._blobStorageUrl);

  constructor() { }

  public onBlobURLChanges(): Observable<string>
  {
    return this.blobURLChanges$.asObservable();
  }

  public set setBlobUrl(value: string)
  {
    this._blobStorageUrl = value ?? "";
    this.blobURLChanges$.next(this._blobStorageUrl);
  }

  public getAssetFromBlob(name: string = ""): string
  {
    return this._blobStorageUrl + name;
  }
}
