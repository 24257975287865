export const Environment = {
        C_MS_ApiKey: 'lfdrxAab|9UWV+p%QsCJ=.7;crR:0d|g5Z>QTt3Mn*40A0mxy%g|eE4ddbc3U6&',
        
        Production: false,
  Local: false,
  ChatBot: true,

  PortalAkkyURL: "https://extranet.rar.test2.akky.proyectos.dvl.nic.com.mx/",
  MicroserviceURL: "https://app-cartmsvc-uat-01.azurewebsites.net/",

  AzureStorageConfigAssetsContainer: "statictest",
  AzureStorageConfigAccountName: "https://cdne-stakkyshared01-shared-01.azureedge.net/",

  SchedulerURL: "https://outlook.office365.com/owa/calendar/AKKY@iar.mx/bookings",

  ChatBotProd: false,
  UrlAvatarChatBot: "https://nic20--uat.sandbox.my.salesforce-sites.com/postChat/resource/AkkyAvatarChat",

  RecaptchaV3SiteKey: "6LcKpRAqAAAAADmJRTFfbBJ_Fi2V4gfVSAQ-TXvw"
    };
    