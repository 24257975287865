import { Injectable } from "@angular/core";
import { UserAccessDataService } from "@core-data-services/security/user-access.data-service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserFiscalDataResolver  
{
  constructor(private service: UserAccessDataService) { }

  public resolve(): Observable<any> | Promise<any> | any
  {
    return this.service.rfcList();
  }
}
