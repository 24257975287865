import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { LanguageConst } from '@core-constants/language.const';
import { LocalStorageConst } from '@core-constants/storage.const';
import { BehaviorSubject, Observable } from "rxjs";
import { BroadcastService } from './broadcast.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class TranslateService
{
  private _lang: string = LanguageConst.ES;
  private _data: Record<string, string> = undefined;
  private _langChangeSource$: BehaviorSubject<string> = new BehaviorSubject<string>(LanguageConst.ES);

  constructor(protected http: HttpClient,
    protected localStorageService: LocalStorageService) { }

  public onTranslateChange(): Observable<string>
  {
    return this._langChangeSource$.asObservable();
  }

  public get languageCode(): string
  {
    if (this._lang !== undefined && this._lang !== null && this._lang)
    {
      return this._lang;
    }
    return LanguageConst.ES;
  }

  private initTranslation(): Promise<unknown>
  {
    return new Promise<unknown>((resolve, reject) =>
    {
      this.http.get(`../../../assets/translations/translations.json`).subscribe({
        next: (response: Record<string, string>) => 
        {
          this._data = Object.assign({}, response || {});
          resolve(this._data);
        },
        error: () =>
        {
          this._data = undefined;
          resolve(this._data);
        }
      });
    });
  }

  public initService(): void
  {
    this.initTranslation();
    this.initLanguage();
  }

  private initLanguage(): void
  {
    const lang = this.localStorageService.getItem(LocalStorageConst.SelectedLang);

    if (lang)
    {
      this._lang = lang;
    }
  }

  public use(lang: string): void
  {
    let _stLang = this._lang;

    if (lang !== _stLang)
    {
      _stLang = (lang !== null && lang !== undefined) ? lang : _stLang = LanguageConst.ES;
      this.localStorageService.setItem(LocalStorageConst.SelectedLang, _stLang);
      this._lang = _stLang;
      this._langChangeSource$.next(_stLang);
      BroadcastService.Instance.broadcast(EAppEventName.OnLanguageChange);
    }
  }

  public getElement(key: string): string
  {
    let message = key;

    if (this._lang != LanguageConst.ES && this._data != undefined && key)
    {
      const lkey = key.toLowerCase();
      message = this._data[lkey] || key;
    }

    return message;
  }
}


