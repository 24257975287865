import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { EGtmEvent } from '@core-constants/gtm-const';
import { PageHelper } from '@core-constants/page-helper.const';
import { URLParamConst } from '@core-constants/url-param.const';
import { UserActions } from '@core-constants/user-actions.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { UTMOperationHelper } from '@core-helpers/utm-operation.route';
import { TokenManager } from '@core-managers/token.manager';
import { IRecaptchaV3Response } from '@core-models/security.model';
import { Environment } from '@environments';
import { LoginDto, UserData, UserDto, UserExtranetModel } from '@shared-base/generic-clases.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { GtmTrackingService } from '@shared-services/gtm-tracking.service';
import { TranslateService } from '@shared-services/translate.service';
import Validation from '@shared-utils/form-control-validation.util';
import { Tools } from '@shared-utils/tools.util';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.css']
})
export class UserAccessComponent implements OnInit, OnChanges
{
  public isLoginLoadingVisible: boolean = false;
  public isSignUpLoadingVisible: boolean = false;
  public showSignUpView: boolean = false;
  public showPasswordLogin: boolean = false;
  public showPasswordSignUp: boolean = false;
  public termsChecked: boolean = false;
  public showSignUpErrorMessge: boolean = false;
  public showLoginErrorMessge: boolean = false;
  public notificationsChecked: boolean = true;

  public actionRecaptchaV3: string = "loginCartWeb";

  // Login Form
  public loginForm: FormGroup;
  public loginSubmitted: boolean = false;

  // Singup Form
  public signupForm: FormGroup | undefined;
  public signupSubmitted: boolean = false;
  public isReCaptchaSolved: boolean = false;

  public signUpErrors: any[] = [];
  public loginErrors: any[] = [];

  @Input() public params: any = undefined;
  @Input() public enabled: boolean;
  @Output() public onCloseForm = new EventEmitter<boolean>();

  constructor(protected translateService: TranslateService,
    private formBuilder: FormBuilder,
    private routeHelperService: RouteHelper,
    private userAccessDataService: UserAccessDataService,
    private tokenManager: TokenManager,
    private cookieService: CookieService,
    private routeHelper: RouteHelper,
    private utmOperationService: UTMOperationHelper,
    protected captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef) { }

  // convenience getter for easy access to form fields
  public get loginFormControls(): any
  {
    return this.loginForm.controls;
  }
  public get signUpFormControls(): any
  {
    return this.signupForm.controls;
  }

  public get isMobile(): boolean
  {
    return window.innerWidth <= PageHelper.MobileWidth;
  }

  public get noticeOfPrivacyURL(): string
  {
    return this.routeHelper.noticeOfPrivacyURL;
  }

  public ngOnInit(): void
  {
    this.showSignUpView = false;

    this.loginForm = this.formBuilder.group({
      loginUsername: ['', Validators.required],
      loginPassword: ['', [Validators.required]]
    });

    this.signupForm = this.formBuilder.group({
      signupUsername: ['', Validators.required],
      signupFirstname: ['', Validators.required],
      signupLastname1: ['', Validators.required],
      signupEmail: ['', Validators.required],
      signupConfirmEmail: ['', Validators.required],
      signupPassword: ['', [Validators.required, Validators.minLength(8)]]
    },
      {
        validators: [Validation.match('signupEmail', 'signupConfirmEmail')]
      });

    this.setDefaultError();
  }

  public ngOnChanges(): void
  {
    this.showSignUpView = false;
  }

  // ********************************************************
  // #region Login
  // ********************************************************

  public onLoginSubmit(): void
  {
    this.loginSubmitted = true;

    if (this.loginForm.invalid)
    {
      return;
    }

    this.showLoadingLogin();

    this.onExecuteAndVerifyRecaptcha();
  }

  public onLogin() : void
  {
    const dto = this.getLoginDto();

    this.userAccessDataService.login(dto).subscribe({
      next: (response: UserData) =>
      {
        GtmTrackingService.loginEvent(EGtmEvent.Login, response.userInfoModel.email);
        this.setUserAccessMethod(response, UserActions.Login);
        this.onOperationSuccess(response);
        this.publishLoginSuccess();
        this.hideLoadingLogin();
      },
      error: (response: HttpErrorResponse) =>
      {
        if (response?.error?.message)
        {
          this.setLoginError(response?.error?.message);
        }
        this.showLoginErrorMessge = true;
        this.hideLoadingLogin();
      }
    });

  }

  public getLoginDto(): LoginDto
  {
    const cartId = this.tokenManager.getCartCookie();

    const loginDto: LoginDto =
    {
      id: String(this.loginForm.value['loginUsername']).toLowerCase(),

      password: Tools.converteStringToBase64(String(this.loginForm.value['loginPassword'])),
      cartId: cartId ?? '',
      utmSource: '',
      utmMedium: '',
      utmCampaign: ''
    };

    return this.utmOperationService.updateUTMData(loginDto);
  }

  public openLogin(): void
  {
    this.showSignUpView = false;
  }

  public publishLoginSuccess(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnLoginSuccess);
  }

  public setLoginError(message: string): void
  {
    this.loginErrors = [message];
  }

  public showLoadingLogin(): void
  {
    this.isLoginLoadingVisible = true;
  }

  public hideLoadingLogin(): void
  {
    this.isLoginLoadingVisible = false;
  }

  // #endregion

  // ********************************************************
  // #region Signup
  // ********************************************************

  public onSignupSubmit(): void
  {
    this.signupSubmitted = true;

    if (this.isSignupValid())
    {
      this.showLoadingSignup();

      const dto = this.getSignupUserDto();

      this.userAccessDataService.signUp(dto).subscribe({
        next: (response: any) =>
        {
          this.setUserAccessMethod(response, UserActions.CreateAccount);
          this.onOperationSuccess(response);
          this.hideLoadingSignup();
        },
        error: ({ error: errors }: any) =>
        {
          this.signUpFormControls.signupPassword.reset();
          this.hideLoadingSignup();
          this.setSignupErrors(errors);
          this.showSignUpErrorMessge = true;
        }
      });
    }
  }

  public isSignupValid(): boolean
  {
    this.showSignUpErrorMessge = false;
    this.signUpErrors = undefined;
    this.signUpErrors = [];

    if (!this.termsChecked)
    {
      this.signUpErrors.push('Para continuar, debes aceptar los Términos y Condiciones y el Aviso de Privacidad.');
      this.signUpFormControls.signupPassword.reset();
      this.showSignUpErrorMessge = true;
    }

    if (!this.isReCaptchaSolved)
    {
      this.signUpErrors.push('El valor del captcha introducido es incorrecto.');
      this.signUpFormControls.signupPassword.reset();
      this.showSignUpErrorMessge = true;
    }

    if (this.signupForm.invalid || !this.isReCaptchaSolved || !this.termsChecked)
    {
      this.showSignUpErrorMessge = true;
    }

    return !this.showSignUpErrorMessge;
  }

  public getSignupDto(): UserExtranetModel
  {
    const userExtranetModel: UserExtranetModel =
    {
      id: this.signupForm.value['signupUsername'],
      firstName: this.signupForm.value['signupFirstname'],
      lastName1: this.signupForm.value['signupLastname1'],
      email: this.signupForm.value['signupEmail'],
      password: Tools.converteStringToBase64(String(this.signupForm.value['signupPassword'])),
      hubSpotSubscribe: this.notificationsChecked
    };

    return userExtranetModel;
  }

  public getSignupUserDto(): UserDto
  {
    const extranetDto = this.getSignupDto();
    const cartId = this.tokenManager.getCartCookie();

    const userDto: UserDto =
    {
      userExtranetModel: extranetDto,
      cartId: cartId ?? '',
      hubspotTrackingToken: this.tokenManager.getHubspotutk(),
      language: this.translateService.languageCode,
      utmSource: '',
      utmMedium: '',
      utmCampaign: ''
    };

    return this.utmOperationService.updateUTMData(userDto);
  }

  public openSignup(): void
  {
    this.showSignUpView = true;
  }

  public showLoadingSignup(): void
  {
    this.isSignUpLoadingVisible = true;
  }

  public hideLoadingSignup(): void
  {
    this.isSignUpLoadingVisible = false;
  }

  public setSignupErrors(errorsRaw: any[]): void
  {
    const errors = this.deserializeSignupErrors(errorsRaw);

    this.signUpErrors = Array.isArray(errors) ? errors.map(a => a.message) : [errors.message];
  }

  public deserializeSignupErrors(errors: any): any
  {
    const initialStateError = errors;

    try
    {
      const deserialized = JSON.parse(errors.message);

      return deserialized;
    }
    catch (error)
    {
      return [initialStateError];
    }
  }
  // #endregion

  public onOperationSuccess(userData: UserData): void
  {
    this.cookieService.delete(URLParamConst.UtmParams, '/');
    this.onBackClicked(false);
    this.tokenManager.saveUserAuthenticationData(userData);
    this.goToCart();
  }

  public goToCart(): void
  {
    this.userAccessDataService.redirectToAkkyOnLogin(this.routeHelperService.shoppingCartWebURL);
  }

  public setDefaultError(): void
  {
    const hasError = this.params != undefined && this.params["isError"];

    if (hasError && (hasError == "true" || hasError == true))
    {
      this.loginErrors = [];
      this.loginErrors.push(this.translateService.getElement('Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.'));

      this.showLoginErrorMessge = true;
    }
  }

  public onBackClicked(closePopup: boolean = true): void
  {
    this.showSignUpView = false;

    this.loginForm.reset();
    this.signupForm.reset();

    this.loginSubmitted = false;
    this.signupSubmitted = false;

    this.termsChecked = false;

    this.signupForm.updateValueAndValidity();
    this.loginForm.updateValueAndValidity();

    this.showLoginErrorMessge = false;
    this.showSignUpErrorMessge = false;

    this.loginErrors = [];
    this.signUpErrors = [];

    if (closePopup)
    {
      this.onCloseForm.emit(true);
    }
  }

  public onForgetPasswordClick(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}login/password/solicitar-regeneracion.jsf`;
  }

  public onRecoverAccountClick(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}login/password/recuperar-cuenta.jsf`;
  }

  // ********************************************************************
  // #region Re-captcha Methods
  //

  public onResolveCaptcha(): void
  {
    this.isReCaptchaSolved = true;
  }

  public onExecuteAndVerifyRecaptcha(): void
  {
     this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.onLogin();
          }
          else
          {
            this.setLoginError(response.message);
          }
        }
      });
  }

  // #endregion

  public getErrorMessage(control: any, name: string, type: string = "text"): string
  {
    if (control)
    {
      if (control?.hasError('required'))
      {
        if (name)
        {
          return this.translateService.getElement('Por favor proporciona') + ': ' + this.translateService.getElement(name);
        }
        else
        {
          return this.translateService.getElement('Campo requerido');
        }

      }
      if (control?.hasError('email'))
      {
        return this.translateService.getElement('Por favor, ingresa un email válido');
      }
      if (control?.hasError('pattern'))
      {
        if (type.toLocaleLowerCase() == "email")
        {
          return this.translateService.getElement('Por favor, ingresa un email válido');
        }
        if (type.toLocaleLowerCase() == "password")
        {
          return this.translateService.getElement('De 8 a 16 caracteres, al menos (1) letra y (1) dígito');
        }

        return this.translateService.getElement('Por favor, ingresa un texto válido');
      }
      if (control?.hasError('invalid'))
      {
        return this.translateService.getElement('El dato ingresado es inválido');
      }
      if (control?.hasError('minlength'))
      {
        const length = control.errors.minlength?.requiredLength;
        const text = this.translateService.getElement("Longitud mínima de");
        const charWord = this.translateService.getElement("caracteres");

        return `${text} ${length} ${charWord}`;
      }
      if (control?.hasError('maxlength'))
      {
        return this.translateService.getElement('Ha superado la longitud de caracteres permitidos');
      }
      if (control?.hasError('matching'))
      {
        return this.translateService.getElement('El email no coincide');
      }
    }
  }

  private setUserAccessMethod(data: any, method: string): void
  {
    data.accessMethod = method;
  }
}
