import { Component, DestroyRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { CouponDataService } from '@core-data-services/coupons.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { GroupedShoppingCart } from '@core-models/shopping-cart-plus.model';
import { AppClientContext } from '@shared-base/client-context.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { TranslateService } from '@shared-services/translate.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.css']
})
export class PurchaseSummaryComponent implements OnInit
{
  public isMobile: boolean = false;
  public products: GroupedShoppingCart[];

  @Input("openServices") public showServices: boolean = false;
  @Input("button") public nameButton: string = "Continuar";
  @Input("coupon") public isCouponEnabled: boolean = true;
  @Input("edit") public isEditEnabled: boolean = true;
  @Input("buttonEnabled") public isButtonEnabled: boolean = true;

  @Output() public nextStep: EventEmitter<boolean>;

  @HostListener('window:resize', ['$event'])
  public onResize(): void
  {
    this.isMobile = window.innerWidth < 991;
  }

  constructor(private translateService: TranslateService,
    protected checkoutManager: CheckoutManager,
    public couponDataService: CouponDataService,
    private destroyRef$: DestroyRef)
  {
    this.nextStep = new EventEmitter<boolean>();
    this.isMobile = window.innerWidth < 991;
  }

  public get showLoadingMask(): boolean
  {
    return AppClientContext.Instance.isBusy;
  }

  public get couponApplied(): boolean
  {
    return this.checkoutManager.isCouponApplied;
  }

  public get total(): number
  {
    const total: number = this.checkoutManager.total;
    return this.isCouponEnabled ? total - this.saving : total;
  }

  public get subtotal(): number
  {
    return this.checkoutManager.total - this.vat;
  }

  public get vat(): number
  {
    return this.checkoutManager.vat;
  }

  public get isCouponApplied(): boolean
  {
    return this.checkoutManager.isCouponApplied;
  }

  public get saving(): number
  {
    return this.checkoutManager.saving;
  }

  public get couponToApply(): string
  {
    return this.checkoutManager.couponToApply;
  }

  public get currentCoupon(): string
  {
    return this.checkoutManager.currentCoupon;
  }

  public get showSummaryDetail(): boolean
  {
    return this.checkoutManager.cartItemsCount > 0;
  }

  public get servicesLabel(): string
  {
    const count = this.checkoutManager.cartItemsCount;
    const text = count > 1 || count == 0 ? "Servicios" : "Servicio";

    return count + " " + this.translateService.getElement(text);
  }

  public get hasCouponToApply(): boolean
  {
    return this.checkoutManager.hasCouponToApply;
  }

  public ngOnInit(): void
  {
    this.products = this.checkoutManager.groupedCartItems;
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnUpdateCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.products = this.checkoutManager.groupedCartItems;
      }
    });
  }

  public onEditClicked(): void
  {
    window.location.href = "/servicios";
  }

  public onContinueClick(): void
  {
    this.nextStep.emit(true);
  }
}
