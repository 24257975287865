import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { EKeyCode } from '@core-constants/input-key.const';
import { ECreditCardError } from '@core-constants/payment-data.const';
import { CreditCardUtils } from '@shared-utils/credit-card.util';

@Directive({
  selector: '[expiryDate]'
})
export class ExpiryDateDirective implements OnInit
{
  protected input: HTMLInputElement;

  @Output() public onExpiryDate: EventEmitter<string>;

  @Output() public onExpiryDateError: EventEmitter<ECreditCardError>;

  constructor(protected elementRef: ElementRef)
  {
    this.input = this.elementRef.nativeElement as HTMLInputElement;
    this.onExpiryDate = new EventEmitter<string>();
    this.onExpiryDateError = new EventEmitter<ECreditCardError>();
  }

  public ngOnInit(): void { }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): boolean
  {
    const code = e.code?.toLowerCase();
    if (code === EKeyCode.Backspace || code === EKeyCode.Delete || code === EKeyCode.Tab)
    {
      return true;
    }

    const value: RegExpMatchArray = e.key.match(/[\d /]+/);

    return value != null;
  }

  @HostListener('keypress', ['$event'])
  public onKeyPress(): boolean
  {
    const date = CreditCardUtils.unmask(this.input.value);

    const selectedText = window.getSelection().toString();

    if (selectedText.length == this.input.value.length || selectedText.length == 4)
    {
      return true;
    }

    return !(date.length >= 4);
  }

  @HostListener('keyup')
  @debounce()
  public onKeyUp(): boolean
  {
    const date = this.input.value;

    const expiryDate = date.split('/');
    const month = parseInt(expiryDate[0]);
    const year = parseInt(expiryDate[1]);

    if (!CreditCardUtils.isValidExpDate(month, year))
    {
      this.onExpiryDateError.emit(ECreditCardError.InvalidDate);
      return false;
    }

    this.onExpiryDateError.emit(ECreditCardError.None);
    return true;
  }
}

export function debounce(delay: number = 200): MethodDecorator
{
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor)
  {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args): void
    {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
