import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

//TODO: Arreglar este servicio al igual que el broadcast, las emisiones de cambios las deberían hacer los servicios que
// controlan los datos, no un servicio genérico
@Injectable({providedIn: 'root'})
export class RouterEventService
{
  private eventCompletedSource = new Subject<void>();
  public eventCompleted$ = this.eventCompletedSource.asObservable();

  public emitEventCompleted(): void
  {
    this.eventCompletedSource.next();
  }
}
