import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContact } from '@core-models/contacts.model';
import { Subject, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contact-selection',
  templateUrl: './contact-selection.component.html',
  styleUrls: ['./contact-selection.component.css']
})
export class ContactSelectionComponent implements OnInit, OnChanges, OnDestroy
{
  public hasResult: boolean = true;
  public searchResult: IContact[];
  public searchInput = new FormControl();
  public selectedContact: IContact | undefined = undefined;

  @Input() public contacts: IContact[];
  @Input() public contactId: number;

  @Output() public onOpenFormClicked = new EventEmitter<number>();
  @Output() public onSelectedContactChange = new EventEmitter<number>();

  private destroy: Subject<void> = new Subject<void>();

  constructor()
  {
    this.onSelectedContactChange = new EventEmitter<number>();
    this.onOpenFormClicked = new EventEmitter<number>();
  }

  public ngOnInit(): void { }

  public ngOnChanges(): void
  {
    if (this.contacts && this.contactId)
    {
      this.selectedContact = this.contacts.find(x => x.id == this.contactId.toString());
    }
  }

  public onAddClick(): void
  {
    this.searchInput.setValue("");
    this.onOpenFormClicked.emit();
  }

  public onEditClick(): void
  {
    this.searchInput.setValue("");
    const id = this.selectedContact.id;
    this.onOpenFormClicked.emit(+id);
  }

  public onSelectContact(contactId: number | undefined): void
  {
    this.searchInput.setValue("");
    this.onSelectedContactChange.emit(contactId);
  }

  public $searchContacts = this.searchInput.valueChanges.pipe(
    startWith(null),
    takeUntil(this.destroy),
    switchMap((text) =>
    {
      if (!text) { return of(this.contacts); }

      text = text.toLowerCase();

      const filtered = this.contacts.filter(x => x.name.toLowerCase().indexOf(text) >= 0);

      return of(filtered);
    })
  );

  public ngOnDestroy(): void
  {
    this.destroy.next();
    this.destroy.complete();
  }
}
