import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ENumericKeyCode } from "@core-constants/input-key.const";

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit
{
  private _currentValue: number;
  private _timeoutId = undefined;
  public inputId: string;

  @Output() public onValueChanges = new EventEmitter<number>();

  @Input() public label: string = "";
  @Input() public parentId: string = "";
  @Input() public isFullWidth: boolean = false;
  @Input() public placeholder: string = "";
  @Input() public min: number = 0;
  @Input() public max: number;
  @Input() public maxLength: number = 4;

  @Input() public set value(newValue)
  {
    this._currentValue = newValue;
  }

  public get value(): number
  {
    return this._currentValue;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnInit(): void
  {
    this.inputId = "input_" + this.parentId;
  }

  public onChange(): void
  {
    this.emitEvent();
  }

  public onKeydown(event: any): boolean
  {
    if ((event.keyCode >= ENumericKeyCode.Zero && event.keyCode <= ENumericKeyCode.Nine) ||
      (event.keyCode >= ENumericKeyCode.Numpad_0 && event.keyCode <= ENumericKeyCode.Numpad_9) ||
      event.keyCode <= ENumericKeyCode.Backspace)
    {
      this.emitEvent();
    }
    else
    {
      return false;
    }
  }

  public emitEvent(): void
  {
    this.changeDetectorRef.detectChanges();

    if (this._timeoutId != undefined)
    {
      clearTimeout(this._timeoutId);
      this._timeoutId = undefined;
    }

    //setear nuevo valor
    this._currentValue = this.value;
    this._timeoutId = setTimeout(() =>
    {
      this.onValueChanges.emit(this.value);
    }, 500);

  }
}
