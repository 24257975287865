import { IAddressTemplate, ICountry, IState } from "@shared-base/generic-clases.base";

export namespace LocationConst
{
  export const Countries: ICountry[] = [
    { cou_id: 'XX', value: 'XX', label: 'No hay información', cou_order: '4', cou_visible: 'N', cou_phone_code: '' },
    { cou_id: 'AX', value: 'AX', label: 'Islas de Aland', cou_order: '4', cou_visible: 'Y', cou_phone_code: '358' },
    { cou_id: 'BL', value: 'BL', label: 'San Bartolomé', cou_order: '4', cou_visible: 'Y', cou_phone_code: '590' },
    { cou_id: 'EH', value: 'EH', label: 'Sahara Occidental', cou_order: '4', cou_visible: 'Y', cou_phone_code: '212' },
    { cou_id: 'GB', value: 'GB', label: 'Reino Unido', cou_order: '4', cou_visible: 'Y', cou_phone_code: '44' },
    { cou_id: 'GG', value: 'GG', label: 'Guernsey', cou_order: '4', cou_visible: 'Y', cou_phone_code: '44' },
    { cou_id: 'IM', value: 'IM', label: 'Isla de Man', cou_order: '4', cou_visible: 'Y', cou_phone_code: '44' },
    { cou_id: 'JE', value: 'JE', label: 'Jersey', cou_order: '4', cou_visible: 'Y', cou_phone_code: '44' },
    { cou_id: 'ME', value: 'ME', label: 'Montenegro', cou_order: '4', cou_visible: 'Y', cou_phone_code: '382' },
    { cou_id: 'MF', value: 'MF', label: 'San Martín', cou_order: '4', cou_visible: 'Y', cou_phone_code: '599' },
    { cou_id: 'PS', value: 'PS', label: 'Territorios Palestinos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '970' },
    { cou_id: 'RS', value: 'RS', label: 'Serbia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '381' },
    { cou_id: 'TL', value: 'TL', label: 'Timor Oriental', cou_order: '4', cou_visible: 'Y', cou_phone_code: '670' },
    { cou_id: 'AF', value: 'AF', label: 'Afganistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '93' },
    { cou_id: 'AL', value: 'AL', label: 'Albania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '355' },
    { cou_id: 'DE', value: 'DE', label: 'Alemania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '49' },
    { cou_id: 'AD', value: 'AD', label: 'Andorra', cou_order: '4', cou_visible: 'Y', cou_phone_code: '376' },
    { cou_id: 'AO', value: 'AO', label: 'Angola', cou_order: '4', cou_visible: 'Y', cou_phone_code: '244' },
    { cou_id: 'AI', value: 'AI', label: 'Anguila', cou_order: '4', cou_visible: 'Y', cou_phone_code: '264' },
    { cou_id: 'AQ', value: 'AQ', label: 'Antártida', cou_order: '4', cou_visible: 'Y', cou_phone_code: '672' },
    { cou_id: 'AG', value: 'AG', label: 'Antigua y Barbuda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '268' },
    { cou_id: 'AN', value: 'AN', label: 'Antillas holandesas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '599' },
    { cou_id: 'SA', value: 'SA', label: 'Arabia Saudí', cou_order: '4', cou_visible: 'Y', cou_phone_code: '966' },
    { cou_id: 'DZ', value: 'DZ', label: 'Argelia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '213' },
    { cou_id: 'AR', value: 'AR', label: 'Argentina', cou_order: '4', cou_visible: 'Y', cou_phone_code: '54' },
    { cou_id: 'AM', value: 'AM', label: 'Armenia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '374' },
    { cou_id: 'AW', value: 'AW', label: 'Aruba', cou_order: '4', cou_visible: 'Y', cou_phone_code: '297' },
    { cou_id: 'AU', value: 'AU', label: 'Australia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '61' },
    { cou_id: 'AT', value: 'AT', label: 'Austria', cou_order: '4', cou_visible: 'Y', cou_phone_code: '43' },
    { cou_id: 'AZ', value: 'AZ', label: 'Azerbaiyán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '994' },
    { cou_id: 'BS', value: 'BS', label: 'Bahamas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '242' },
    { cou_id: 'BH', value: 'BH', label: 'Bahrein', cou_order: '4', cou_visible: 'Y', cou_phone_code: '973' },
    { cou_id: 'BD', value: 'BD', label: 'Bangladesh', cou_order: '4', cou_visible: 'Y', cou_phone_code: '880' },
    { cou_id: 'BB', value: 'BB', label: 'Barbados', cou_order: '4', cou_visible: 'Y', cou_phone_code: '246' },
    { cou_id: 'BE', value: 'BE', label: 'Bélgica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '32' },
    { cou_id: 'BZ', value: 'BZ', label: 'Belice', cou_order: '4', cou_visible: 'Y', cou_phone_code: '501' },
    { cou_id: 'BJ', value: 'BJ', label: 'Benín', cou_order: '4', cou_visible: 'Y', cou_phone_code: '229' },
    { cou_id: 'BM', value: 'BM', label: 'Bermudas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '441' },
    { cou_id: 'BT', value: 'BT', label: 'Bhután', cou_order: '4', cou_visible: 'Y', cou_phone_code: '975' },
    { cou_id: 'BY', value: 'BY', label: 'Bielorrusia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '375' },
    { cou_id: 'MM', value: 'MM', label: 'Birmania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '95' },
    { cou_id: 'BO', value: 'BO', label: 'Bolivia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '591' },
    { cou_id: 'BA', value: 'BA', label: 'Bosnia y Herzegovina', cou_order: '4', cou_visible: 'Y', cou_phone_code: '387' },
    { cou_id: 'BW', value: 'BW', label: 'Botsuana', cou_order: '4', cou_visible: 'Y', cou_phone_code: '267' },
    { cou_id: 'BR', value: 'BR', label: 'Brasil', cou_order: '4', cou_visible: 'Y', cou_phone_code: '55' },
    { cou_id: 'BN', value: 'BN', label: 'Brunei', cou_order: '4', cou_visible: 'Y', cou_phone_code: '673' },
    { cou_id: 'BG', value: 'BG', label: 'Bulgaria', cou_order: '4', cou_visible: 'Y', cou_phone_code: '359' },
    { cou_id: 'BF', value: 'BF', label: 'Burkina Faso', cou_order: '4', cou_visible: 'Y', cou_phone_code: '226' },
    { cou_id: 'BI', value: 'BI', label: 'Burundi', cou_order: '4', cou_visible: 'Y', cou_phone_code: '257' },
    { cou_id: 'CV', value: 'CV', label: 'Cabo Verde', cou_order: '4', cou_visible: 'Y', cou_phone_code: '238' },
    { cou_id: 'KH', value: 'KH', label: 'Camboya', cou_order: '4', cou_visible: 'Y', cou_phone_code: '855' },
    { cou_id: 'CM', value: 'CM', label: 'Camerún', cou_order: '4', cou_visible: 'Y', cou_phone_code: '237' },
    { cou_id: 'CA', value: 'CA', label: 'Canadá', cou_order: '3', cou_visible: 'Y', cou_phone_code: '1' },
    { cou_id: 'TD', value: 'TD', label: 'Chad', cou_order: '4', cou_visible: 'Y', cou_phone_code: '235' },
    { cou_id: 'CL', value: 'CL', label: 'Chile', cou_order: '4', cou_visible: 'Y', cou_phone_code: '56' },
    { cou_id: 'CN', value: 'CN', label: 'China', cou_order: '4', cou_visible: 'Y', cou_phone_code: '86' },
    { cou_id: 'CY', value: 'CY', label: 'Chipre', cou_order: '4', cou_visible: 'Y', cou_phone_code: '357' },
    { cou_id: 'VA', value: 'VA', label: 'Ciudad estado del Vaticano (Santa Sede)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '39' },
    { cou_id: 'CO', value: 'CO', label: 'Colombia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '57' },
    { cou_id: 'KM', value: 'KM', label: 'Comores', cou_order: '4', cou_visible: 'Y', cou_phone_code: '269' },
    { cou_id: 'CG', value: 'CG', label: 'Congo', cou_order: '4', cou_visible: 'Y', cou_phone_code: '242' },
    { cou_id: 'KR', value: 'KR', label: 'Corea', cou_order: '4', cou_visible: 'Y', cou_phone_code: '82' },
    { cou_id: 'KP', value: 'KP', label: 'Corea del Norte', cou_order: '4', cou_visible: 'Y', cou_phone_code: '850' },
    { cou_id: 'CI', value: 'CI', label: 'Costa del Marfíl', cou_order: '4', cou_visible: 'Y', cou_phone_code: '225' },
    { cou_id: 'CR', value: 'CR', label: 'Costa Rica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '506' },
    { cou_id: 'HR', value: 'HR', label: 'Croacia (Hrvatska)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '385' },
    { cou_id: 'CU', value: 'CU', label: 'Cuba', cou_order: '4', cou_visible: 'Y', cou_phone_code: '53' },
    { cou_id: 'DK', value: 'DK', label: 'Dinamarca', cou_order: '4', cou_visible: 'Y', cou_phone_code: '45' },
    { cou_id: 'DJ', value: 'DJ', label: 'Djibouri', cou_order: '4', cou_visible: 'Y', cou_phone_code: '253' },
    { cou_id: 'DM', value: 'DM', label: 'Dominica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '767' },
    { cou_id: 'EC', value: 'EC', label: 'Ecuador', cou_order: '4', cou_visible: 'Y', cou_phone_code: '593' },
    { cou_id: 'EG', value: 'EG', label: 'Egipto', cou_order: '4', cou_visible: 'Y', cou_phone_code: '20' },
    { cou_id: 'SV', value: 'SV', label: 'El Salvador', cou_order: '4', cou_visible: 'Y', cou_phone_code: '503' },
    { cou_id: 'AE', value: 'AE', label: 'Emiratos Árabes Unidos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '971' },
    { cou_id: 'ER', value: 'ER', label: 'Eritrea', cou_order: '4', cou_visible: 'Y', cou_phone_code: '291' },
    { cou_id: 'SK', value: 'SK', label: 'Eslovaquia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '421' },
    { cou_id: 'SI', value: 'SI', label: 'Eslovenia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '386' },
    { cou_id: 'ES', value: 'ES', label: 'España', cou_order: '4', cou_visible: 'Y', cou_phone_code: '34' },
    { cou_id: 'US', value: 'US', label: 'Estados Unidos', cou_order: '2', cou_visible: 'Y', cou_phone_code: '1' },
    { cou_id: 'EE', value: 'EE', label: 'Estonia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '372' },
    { cou_id: 'ET', value: 'ET', label: 'Etiopía', cou_order: '4', cou_visible: 'Y', cou_phone_code: '251' },
    { cou_id: 'MK', value: 'MK', label: 'Ex-República Yugoslava de Macedonia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '389' },
    { cou_id: 'PH', value: 'PH', label: 'Filipinas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '63' },
    { cou_id: 'FI', value: 'FI', label: 'Finlandia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '358' },
    { cou_id: 'FR', value: 'FR', label: 'Francia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '33' },
    { cou_id: 'GA', value: 'GA', label: 'Gabón', cou_order: '4', cou_visible: 'Y', cou_phone_code: '241' },
    { cou_id: 'GM', value: 'GM', label: 'Gambia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '220' },
    { cou_id: 'GE', value: 'GE', label: 'Georgia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '995' },
    { cou_id: 'GS', value: 'GS', label: 'Georgia del Sur y las Islas Sandwich del Sur', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'GH', value: 'GH', label: 'Ghana', cou_order: '4', cou_visible: 'Y', cou_phone_code: '233' },
    { cou_id: 'GI', value: 'GI', label: 'Gibraltar', cou_order: '4', cou_visible: 'Y', cou_phone_code: '350' },
    { cou_id: 'GD', value: 'GD', label: 'Granada', cou_order: '4', cou_visible: 'Y', cou_phone_code: '473' },
    { cou_id: 'GR', value: 'GR', label: 'Grecia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '30' },
    { cou_id: 'GL', value: 'GL', label: 'Groenlandia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '299' },
    { cou_id: 'GP', value: 'GP', label: 'Guadalupe', cou_order: '4', cou_visible: 'Y', cou_phone_code: '590' },
    { cou_id: 'GU', value: 'GU', label: 'Guam', cou_order: '4', cou_visible: 'Y', cou_phone_code: '671' },
    { cou_id: 'GT', value: 'GT', label: 'Guatemala', cou_order: '4', cou_visible: 'Y', cou_phone_code: '502' },
    { cou_id: 'GY', value: 'GY', label: 'Guayana', cou_order: '4', cou_visible: 'Y', cou_phone_code: '592' },
    { cou_id: 'GF', value: 'GF', label: 'Guayana francesa', cou_order: '4', cou_visible: 'Y', cou_phone_code: '594' },
    { cou_id: 'GN', value: 'GN', label: 'Guinea', cou_order: '4', cou_visible: 'Y', cou_phone_code: '224' },
    { cou_id: 'GQ', value: 'GQ', label: 'Guinea Ecuatorial', cou_order: '4', cou_visible: 'Y', cou_phone_code: '240' },
    { cou_id: 'GW', value: 'GW', label: 'Guinea-Bissau', cou_order: '4', cou_visible: 'Y', cou_phone_code: '245' },
    { cou_id: 'HT', value: 'HT', label: 'Haití', cou_order: '4', cou_visible: 'Y', cou_phone_code: '509' },
    { cou_id: 'NL', value: 'NL', label: 'Holanda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '31' },
    { cou_id: 'HN', value: 'HN', label: 'Honduras', cou_order: '4', cou_visible: 'Y', cou_phone_code: '504' },
    { cou_id: 'HK', value: 'HK', label: 'Hong Kong R. A. E', cou_order: '4', cou_visible: 'Y', cou_phone_code: '852' },
    { cou_id: 'HU', value: 'HU', label: 'Hungría', cou_order: '4', cou_visible: 'Y', cou_phone_code: '36' },
    { cou_id: 'IN', value: 'IN', label: 'India', cou_order: '4', cou_visible: 'Y', cou_phone_code: '91' },
    { cou_id: 'ID', value: 'ID', label: 'Indonesia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '62' },
    { cou_id: 'IQ', value: 'IQ', label: 'Irak', cou_order: '4', cou_visible: 'Y', cou_phone_code: '964' },
    { cou_id: 'IR', value: 'IR', label: 'Irán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '98' },
    { cou_id: 'IE', value: 'IE', label: 'Irlanda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '353' },
    { cou_id: 'BV', value: 'BV', label: 'Isla Bouvet', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'CX', value: 'CX', label: 'Isla Christmas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '61' },
    { cou_id: 'HM', value: 'HM', label: 'Isla Heard e Islas McDonald', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'IS', value: 'IS', label: 'Islandia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '354' },
    { cou_id: 'KY', value: 'KY', label: 'Islas Caimán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '345' },
    { cou_id: 'CK', value: 'CK', label: 'Islas Cook', cou_order: '4', cou_visible: 'Y', cou_phone_code: '682' },
    { cou_id: 'CC', value: 'CC', label: 'Islas de Cocos o Keeling', cou_order: '4', cou_visible: 'Y', cou_phone_code: '61' },
    { cou_id: 'FO', value: 'FO', label: 'Islas Faroe', cou_order: '4', cou_visible: 'Y', cou_phone_code: '298' },
    { cou_id: 'FJ', value: 'FJ', label: 'Islas Fiyi', cou_order: '4', cou_visible: 'Y', cou_phone_code: '679' },
    { cou_id: 'FK', value: 'FK', label: 'Islas Malvinas (Islas Falkland)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '500' },
    { cou_id: 'MP', value: 'MP', label: 'Islas Marianas del norte', cou_order: '4', cou_visible: 'Y', cou_phone_code: '670' },
    { cou_id: 'MH', value: 'MH', label: 'Islas Marshall', cou_order: '4', cou_visible: 'Y', cou_phone_code: '692' },
    { cou_id: 'UM', value: 'UM', label: 'Islas menores de Estados Unidos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'PW', value: 'PW', label: 'Islas Palau', cou_order: '4', cou_visible: 'Y', cou_phone_code: '680' },
    { cou_id: 'SB', value: 'SB', label: 'Islas Salomón', cou_order: '4', cou_visible: 'Y', cou_phone_code: '677' },
    { cou_id: 'TK', value: 'TK', label: 'Islas Tokelau', cou_order: '4', cou_visible: 'Y', cou_phone_code: '690' },
    { cou_id: 'TC', value: 'TC', label: 'Islas Turks y Caicos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '649' },
    { cou_id: 'VI', value: 'VI', label: 'Islas Vírgenes (EE.UU.)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '340' },
    { cou_id: 'VG', value: 'VG', label: 'Islas Vírgenes (Reino Unido)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '284' },
    { cou_id: 'IL', value: 'IL', label: 'Israel', cou_order: '4', cou_visible: 'Y', cou_phone_code: '972' },
    { cou_id: 'IT', value: 'IT', label: 'Italia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '39' },
    { cou_id: 'JM', value: 'JM', label: 'Jamaica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '876' },
    { cou_id: 'JP', value: 'JP', label: 'Japón', cou_order: '4', cou_visible: 'Y', cou_phone_code: '81' },
    { cou_id: 'JO', value: 'JO', label: 'Jordania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '962' },
    { cou_id: 'KZ', value: 'KZ', label: 'Kazajistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '7' },
    { cou_id: 'KE', value: 'KE', label: 'Kenia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '254' },
    { cou_id: 'KG', value: 'KG', label: 'Kirguizistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '996' },
    { cou_id: 'KI', value: 'KI', label: 'Kiribati', cou_order: '4', cou_visible: 'Y', cou_phone_code: '686' },
    { cou_id: 'KW', value: 'KW', label: 'Kuwait', cou_order: '4', cou_visible: 'Y', cou_phone_code: '965' },
    { cou_id: 'LA', value: 'LA', label: 'Laos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '856' },
    { cou_id: 'LS', value: 'LS', label: 'Lesoto', cou_order: '4', cou_visible: 'Y', cou_phone_code: '266' },
    { cou_id: 'LV', value: 'LV', label: 'Letonia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '371' },
    { cou_id: 'LB', value: 'LB', label: 'Líbano', cou_order: '4', cou_visible: 'Y', cou_phone_code: '961' },
    { cou_id: 'LR', value: 'LR', label: 'Liberia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '231' },
    { cou_id: 'LY', value: 'LY', label: 'Libia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '218' },
    { cou_id: 'LI', value: 'LI', label: 'Liechtenstein', cou_order: '4', cou_visible: 'Y', cou_phone_code: '423' },
    { cou_id: 'LT', value: 'LT', label: 'Lituania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '370' },
    { cou_id: 'LU', value: 'LU', label: 'Luxemburgo', cou_order: '4', cou_visible: 'Y', cou_phone_code: '352' },
    { cou_id: 'MO', value: 'MO', label: 'Macao R. A. E', cou_order: '4', cou_visible: 'Y', cou_phone_code: '853' },
    { cou_id: 'MG', value: 'MG', label: 'Madagascar', cou_order: '4', cou_visible: 'Y', cou_phone_code: '261' },
    { cou_id: 'MY', value: 'MY', label: 'Malasia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '60' },
    { cou_id: 'MW', value: 'MW', label: 'Malawi', cou_order: '4', cou_visible: 'Y', cou_phone_code: '265' },
    { cou_id: 'MV', value: 'MV', label: 'Maldivas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '960' },
    { cou_id: 'ML', value: 'ML', label: 'Malí', cou_order: '4', cou_visible: 'Y', cou_phone_code: '223' },
    { cou_id: 'MT', value: 'MT', label: 'Malta', cou_order: '4', cou_visible: 'Y', cou_phone_code: '356' },
    { cou_id: 'MA', value: 'MA', label: 'Marruecos', cou_order: '4', cou_visible: 'Y', cou_phone_code: '212' },
    { cou_id: 'MQ', value: 'MQ', label: 'Martinica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '596' },
    { cou_id: 'MU', value: 'MU', label: 'Mauricio', cou_order: '4', cou_visible: 'Y', cou_phone_code: '230' },
    { cou_id: 'MR', value: 'MR', label: 'Mauritania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '222' },
    { cou_id: 'YT', value: 'YT', label: 'Mayotte', cou_order: '4', cou_visible: 'Y', cou_phone_code: '262' },
    { cou_id: 'MX', value: 'MX', label: 'México', cou_order: '1', cou_visible: 'Y', cou_phone_code: '52' },
    { cou_id: 'FM', value: 'FM', label: 'Micronesia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '691' },
    { cou_id: 'MD', value: 'MD', label: 'Moldavia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '373' },
    { cou_id: 'MC', value: 'MC', label: 'Mónaco', cou_order: '4', cou_visible: 'Y', cou_phone_code: '377' },
    { cou_id: 'MN', value: 'MN', label: 'Mongolia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '976' },
    { cou_id: 'MS', value: 'MS', label: 'Montserrat', cou_order: '4', cou_visible: 'Y', cou_phone_code: '664' },
    { cou_id: 'MZ', value: 'MZ', label: 'Mozambique', cou_order: '4', cou_visible: 'Y', cou_phone_code: '258' },
    { cou_id: 'NA', value: 'NA', label: 'Namibia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '264' },
    { cou_id: 'NR', value: 'NR', label: 'Nauru', cou_order: '4', cou_visible: 'Y', cou_phone_code: '674' },
    { cou_id: 'NP', value: 'NP', label: 'Nepal', cou_order: '4', cou_visible: 'Y', cou_phone_code: '977' },
    { cou_id: 'NI', value: 'NI', label: 'Nicaragua', cou_order: '4', cou_visible: 'Y', cou_phone_code: '505' },
    { cou_id: 'NE', value: 'NE', label: 'Níger', cou_order: '4', cou_visible: 'Y', cou_phone_code: '227' },
    { cou_id: 'NG', value: 'NG', label: 'Nigeria', cou_order: '4', cou_visible: 'Y', cou_phone_code: '234' },
    { cou_id: 'NU', value: 'NU', label: 'Niue', cou_order: '4', cou_visible: 'Y', cou_phone_code: '683' },
    { cou_id: 'NF', value: 'NF', label: 'Norfolk', cou_order: '4', cou_visible: 'Y', cou_phone_code: '672' },
    { cou_id: 'NO', value: 'NO', label: 'Noruega', cou_order: '4', cou_visible: 'Y', cou_phone_code: '47' },
    { cou_id: 'NC', value: 'NC', label: 'Nueva Caledonia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '687' },
    { cou_id: 'NZ', value: 'NZ', label: 'Nueva Zelanda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '64' },
    { cou_id: 'OM', value: 'OM', label: 'Omán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '968' },
    { cou_id: 'PA', value: 'PA', label: 'Panamá', cou_order: '4', cou_visible: 'Y', cou_phone_code: '507' },
    { cou_id: 'PG', value: 'PG', label: 'Papúa Nueva Guinea', cou_order: '4', cou_visible: 'Y', cou_phone_code: '675' },
    { cou_id: 'PK', value: 'PK', label: 'Paquistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '92' },
    { cou_id: 'PY', value: 'PY', label: 'Paraguay', cou_order: '4', cou_visible: 'Y', cou_phone_code: '595' },
    { cou_id: 'PE', value: 'PE', label: 'Perú', cou_order: '4', cou_visible: 'Y', cou_phone_code: '51' },
    { cou_id: 'PN', value: 'PN', label: 'Pitcairn', cou_order: '4', cou_visible: 'Y', cou_phone_code: '870' },
    { cou_id: 'PF', value: 'PF', label: 'Polinesia francesa', cou_order: '4', cou_visible: 'Y', cou_phone_code: '689' },
    { cou_id: 'PL', value: 'PL', label: 'Polonia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '48' },
    { cou_id: 'PT', value: 'PT', label: 'Portugal', cou_order: '4', cou_visible: 'Y', cou_phone_code: '351' },
    { cou_id: 'PR', value: 'PR', label: 'Puerto Rico', cou_order: '4', cou_visible: 'Y', cou_phone_code: '787' },
    { cou_id: 'QA', value: 'QA', label: 'Qatar', cou_order: '4', cou_visible: 'Y', cou_phone_code: '974' },
    { cou_id: 'CF', value: 'CF', label: 'República Centroafricana', cou_order: '4', cou_visible: 'Y', cou_phone_code: '236' },
    { cou_id: 'CZ', value: 'CZ', label: 'República Checa', cou_order: '4', cou_visible: 'Y', cou_phone_code: '420' },
    { cou_id: 'ZA', value: 'ZA', label: 'República de Sudáfrica', cou_order: '4', cou_visible: 'Y', cou_phone_code: '27' },
    { cou_id: 'CD', value: 'CD', label: 'República Democrática del Congo (Zaire)', cou_order: '4', cou_visible: 'Y', cou_phone_code: '243' },
    { cou_id: 'DO', value: 'DO', label: 'República Dominicana', cou_order: '4', cou_visible: 'Y', cou_phone_code: '809' },
    { cou_id: 'RE', value: 'RE', label: 'Reunión', cou_order: '4', cou_visible: 'Y', cou_phone_code: '262' },
    { cou_id: 'RW', value: 'RW', label: 'Ruanda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '250' },
    { cou_id: 'RO', value: 'RO', label: 'Rumania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '40' },
    { cou_id: 'RU', value: 'RU', label: 'Rusia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '7' },
    { cou_id: 'WS', value: 'WS', label: 'Samoa', cou_order: '4', cou_visible: 'Y', cou_phone_code: '685' },
    { cou_id: 'AS', value: 'AS', label: 'Samoa occidental', cou_order: '4', cou_visible: 'Y', cou_phone_code: '684' },
    { cou_id: 'KN', value: 'KN', label: 'San Kitts y Nevis', cou_order: '4', cou_visible: 'Y', cou_phone_code: '869' },
    { cou_id: 'SM', value: 'SM', label: 'San Marino', cou_order: '4', cou_visible: 'Y', cou_phone_code: '378' },
    { cou_id: 'PM', value: 'PM', label: 'San Pierre y Miquelon', cou_order: '4', cou_visible: 'Y', cou_phone_code: '508' },
    { cou_id: 'VC', value: 'VC', label: 'San Vicente e Islas Granadinas', cou_order: '4', cou_visible: 'Y', cou_phone_code: '784' },
    { cou_id: 'SH', value: 'SH', label: 'Santa Helena', cou_order: '4', cou_visible: 'Y', cou_phone_code: '290' },
    { cou_id: 'LC', value: 'LC', label: 'Santa Lucía', cou_order: '4', cou_visible: 'Y', cou_phone_code: '758' },
    { cou_id: 'ST', value: 'ST', label: 'Santo Tomé y Príncipe', cou_order: '4', cou_visible: 'Y', cou_phone_code: '239' },
    { cou_id: 'SN', value: 'SN', label: 'Senegal', cou_order: '4', cou_visible: 'Y', cou_phone_code: '221' },
    { cou_id: 'SC', value: 'SC', label: 'Seychelles', cou_order: '4', cou_visible: 'Y', cou_phone_code: '248' },
    { cou_id: 'SL', value: 'SL', label: 'Sierra Leona', cou_order: '4', cou_visible: 'Y', cou_phone_code: '232' },
    { cou_id: 'SG', value: 'SG', label: 'Singapur', cou_order: '4', cou_visible: 'Y', cou_phone_code: '65' },
    { cou_id: 'SY', value: 'SY', label: 'Siria', cou_order: '4', cou_visible: 'Y', cou_phone_code: '963' },
    { cou_id: 'SO', value: 'SO', label: 'Somalia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '252' },
    { cou_id: 'LK', value: 'LK', label: 'Sri Lanka', cou_order: '4', cou_visible: 'Y', cou_phone_code: '94' },
    { cou_id: 'SZ', value: 'SZ', label: 'Suazilandia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '268' },
    { cou_id: 'SD', value: 'SD', label: 'Sudán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '249' },
    { cou_id: 'SE', value: 'SE', label: 'Suecia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '46' },
    { cou_id: 'CH', value: 'CH', label: 'Suiza', cou_order: '4', cou_visible: 'Y', cou_phone_code: '41' },
    { cou_id: 'SR', value: 'SR', label: 'Surinam', cou_order: '4', cou_visible: 'Y', cou_phone_code: '597' },
    { cou_id: 'SJ', value: 'SJ', label: 'Svalbard', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'TH', value: 'TH', label: 'Tailandia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '66' },
    { cou_id: 'TW', value: 'TW', label: 'Taiwán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '886' },
    { cou_id: 'TZ', value: 'TZ', label: 'Tanzania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '255' },
    { cou_id: 'TJ', value: 'TJ', label: 'Tayikistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '992' },
    { cou_id: 'IO', value: 'IO', label: 'Territorios británicos del océano Índico', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'TF', value: 'TF', label: 'Territorios franceses del sur', cou_order: '4', cou_visible: 'Y', cou_phone_code: '' },
    { cou_id: 'TG', value: 'TG', label: 'Togo', cou_order: '4', cou_visible: 'Y', cou_phone_code: '228' },
    { cou_id: 'TO', value: 'TO', label: 'Tonga', cou_order: '4', cou_visible: 'Y', cou_phone_code: '676' },
    { cou_id: 'TT', value: 'TT', label: 'Trinidad y Tobago', cou_order: '4', cou_visible: 'Y', cou_phone_code: '868' },
    { cou_id: 'TN', value: 'TN', label: 'Túnez', cou_order: '4', cou_visible: 'Y', cou_phone_code: '216' },
    { cou_id: 'TM', value: 'TM', label: 'Turkmenistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '993' },
    { cou_id: 'TR', value: 'TR', label: 'Turquía', cou_order: '4', cou_visible: 'Y', cou_phone_code: '90' },
    { cou_id: 'TV', value: 'TV', label: 'Tuvalu', cou_order: '4', cou_visible: 'Y', cou_phone_code: '688' },
    { cou_id: 'UA', value: 'UA', label: 'Ucrania', cou_order: '4', cou_visible: 'Y', cou_phone_code: '380' },
    { cou_id: 'UG', value: 'UG', label: 'Uganda', cou_order: '4', cou_visible: 'Y', cou_phone_code: '256' },
    { cou_id: 'UY', value: 'UY', label: 'Uruguay', cou_order: '4', cou_visible: 'Y', cou_phone_code: '598' },
    { cou_id: 'UZ', value: 'UZ', label: 'Uzbekistán', cou_order: '4', cou_visible: 'Y', cou_phone_code: '998' },
    { cou_id: 'VU', value: 'VU', label: 'Vanuatu', cou_order: '4', cou_visible: 'Y', cou_phone_code: '678' },
    { cou_id: 'VE', value: 'VE', label: 'Venezuela', cou_order: '4', cou_visible: 'Y', cou_phone_code: '58' },
    { cou_id: 'VN', value: 'VN', label: 'Vietnam', cou_order: '4', cou_visible: 'Y', cou_phone_code: '84' },
    { cou_id: 'WF', value: 'WF', label: 'Wallis y Futuna', cou_order: '4', cou_visible: 'Y', cou_phone_code: '681' },
    { cou_id: 'YE', value: 'YE', label: 'Yemen', cou_order: '4', cou_visible: 'Y', cou_phone_code: '967' },
    { cou_id: 'ZM', value: 'ZM', label: 'Zambia', cou_order: '4', cou_visible: 'Y', cou_phone_code: '260' },
    { cou_id: 'ZW', value: 'ZW', label: 'Zimbabue', cou_order: '4', cou_visible: 'Y', cou_phone_code: '263' }
  ];

  export const States: IState[] = [
    { id: '1', cou_id: 'MX', value: 'Ciudad de México', label: 'Ciudad de México', sta_order: '1', sta_visible: 'Y' },
    { id: '2', cou_id: 'MX', value: 'Aguascalientes', label: 'Aguascalientes', sta_order: '1', sta_visible: 'Y' },
    { id: '3', cou_id: 'MX', value: 'Baja California', label: 'Baja California', sta_order: '1', sta_visible: 'Y' },
    { id: '4', cou_id: 'MX', value: 'Baja California Sur', label: 'Baja California Sur', sta_order: '1', sta_visible: 'Y' },
    { id: '5', cou_id: 'MX', value: 'Campeche', label: 'Campeche', sta_order: '1', sta_visible: 'Y' },
    { id: '6', cou_id: 'MX', value: 'Chihuahua', label: 'Chihuahua', sta_order: '1', sta_visible: 'Y' },
    { id: '7', cou_id: 'MX', value: 'Chiapas', label: 'Chiapas', sta_order: '1', sta_visible: 'Y' },
    { id: '8', cou_id: 'MX', value: 'Coahuila', label: 'Coahuila', sta_order: '1', sta_visible: 'Y' },
    { id: '9', cou_id: 'MX', value: 'Colima', label: 'Colima', sta_order: '1', sta_visible: 'Y' },
    { id: '10', cou_id: 'MX', value: 'Distrito Federal', label: 'Distrito Federal', sta_order: '1', sta_visible: 'N' },
    { id: '11', cou_id: 'MX', value: 'Durango', label: 'Durango', sta_order: '1', sta_visible: 'Y' },
    { id: '12', cou_id: 'MX', value: 'Guerrero', label: 'Guerrero', sta_order: '1', sta_visible: 'Y' },
    { id: '13', cou_id: 'MX', value: 'Guanajuato', label: 'Guanajuato', sta_order: '1', sta_visible: 'Y' },
    { id: '14', cou_id: 'MX', value: 'Hidalgo', label: 'Hidalgo', sta_order: '1', sta_visible: 'Y' },
    { id: '15', cou_id: 'MX', value: 'Jalisco', label: 'Jalisco', sta_order: '1', sta_visible: 'Y' },
    { id: '16', cou_id: 'MX', value: 'México', label: 'México', sta_order: '1', sta_visible: 'Y' },
    { id: '17', cou_id: 'MX', value: 'Michoacán', label: 'Michoacán', sta_order: '1', sta_visible: 'Y' },
    { id: '18', cou_id: 'MX', value: 'Morelos', label: 'Morelos', sta_order: '1', sta_visible: 'Y' },
    { id: '19', cou_id: 'MX', value: 'Nayarit', label: 'Nayarit', sta_order: '1', sta_visible: 'Y' },
    { id: '20', cou_id: 'MX', value: 'Nuevo León', label: 'Nuevo León', sta_order: '1', sta_visible: 'Y' },
    { id: '21', cou_id: 'MX', value: 'Oaxaca', label: 'Oaxaca', sta_order: '1', sta_visible: 'Y' },
    { id: '22', cou_id: 'MX', value: 'Puebla', label: 'Puebla', sta_order: '1', sta_visible: 'Y' },
    { id: '23', cou_id: 'MX', value: 'Querétaro', label: 'Querétaro', sta_order: '1', sta_visible: 'Y' },
    { id: '24', cou_id: 'MX', value: 'Quintana Roo', label: 'Quintana Roo', sta_order: '1', sta_visible: 'Y' },
    { id: '25', cou_id: 'MX', value: 'Sinaloa', label: 'Sinaloa', sta_order: '1', sta_visible: 'Y' },
    { id: '26', cou_id: 'MX', value: 'San Luis Potosí', label: 'San Luis Potosí', sta_order: '1', sta_visible: 'Y' },
    { id: '27', cou_id: 'MX', value: 'Sonora', label: 'Sonora', sta_order: '1', sta_visible: 'Y' },
    { id: '28', cou_id: 'MX', value: 'Tabasco', label: 'Tabasco', sta_order: '1', sta_visible: 'Y' },
    { id: '29', cou_id: 'MX', value: 'Tamaulipas', label: 'Tamaulipas', sta_order: '1', sta_visible: 'Y' },
    { id: '30', cou_id: 'MX', value: 'Tlaxcala', label: 'Tlaxcala', sta_order: '1', sta_visible: 'Y' },
    { id: '31', cou_id: 'MX', value: 'Veracruz', label: 'Veracruz', sta_order: '1', sta_visible: 'Y' },
    { id: '32', cou_id: 'MX', value: 'Yucatán', label: 'Yucatán', sta_order: '1', sta_visible: 'Y' },
    { id: '33', cou_id: 'MX', value: 'Zacatecas', label: 'Zacatecas', sta_order: '1', sta_visible: 'Y' },
    { id: '34', cou_id: 'US', value: 'Alaska', label: 'Alaska', sta_order: '2', sta_visible: 'Y' },
    { id: '35', cou_id: 'US', value: 'Alabama', label: 'Alabama', sta_order: '2', sta_visible: 'Y' },
    { id: '36', cou_id: 'US', value: 'Arkansas', label: 'Arkansas', sta_order: '2', sta_visible: 'Y' },
    { id: '37', cou_id: 'US', value: 'Arizona', label: 'Arizona', sta_order: '2', sta_visible: 'Y' },
    { id: '38', cou_id: 'US', value: 'California', label: 'California', sta_order: '2', sta_visible: 'Y' },
    { id: '39', cou_id: 'US', value: 'Colorado', label: 'Colorado', sta_order: '2', sta_visible: 'Y' },
    { id: '40', cou_id: 'US', value: 'Connecticut', label: 'Connecticut', sta_order: '2', sta_visible: 'Y' },
    { id: '41', cou_id: 'US', value: 'District of Columbia', label: 'District of Columbia', sta_order: '2', sta_visible: 'Y' },
    { id: '42', cou_id: 'US', value: 'Delaware', label: 'Delaware', sta_order: '2', sta_visible: 'Y' },
    { id: '43', cou_id: 'US', value: 'Florida', label: 'Florida', sta_order: '2', sta_visible: 'Y' },
    { id: '44', cou_id: 'US', value: 'Georgia', label: 'Georgia', sta_order: '2', sta_visible: 'Y' },
    { id: '45', cou_id: 'US', value: 'Hawaii', label: 'Hawaii', sta_order: '2', sta_visible: 'Y' },
    { id: '46', cou_id: 'US', value: 'Iowa', label: 'Iowa', sta_order: '2', sta_visible: 'Y' },
    { id: '47', cou_id: 'US', value: 'Idaho', label: 'Idaho', sta_order: '2', sta_visible: 'Y' },
    { id: '48', cou_id: 'US', value: 'Illinois', label: 'Illinois', sta_order: '2', sta_visible: 'Y' },
    { id: '49', cou_id: 'US', value: 'Indiana', label: 'Indiana', sta_order: '2', sta_visible: 'Y' },
    { id: '50', cou_id: 'US', value: 'Kansas', label: 'Kansas', sta_order: '2', sta_visible: 'Y' },
    { id: '51', cou_id: 'US', value: 'Kentucky', label: 'Kentucky', sta_order: '2', sta_visible: 'Y' },
    { id: '52', cou_id: 'US', value: 'Louisiana', label: 'Louisiana', sta_order: '2', sta_visible: 'Y' },
    { id: '53', cou_id: 'US', value: 'Massachusetts', label: 'Massachusetts', sta_order: '2', sta_visible: 'Y' },
    { id: '54', cou_id: 'US', value: 'Maryland', label: 'Maryland', sta_order: '2', sta_visible: 'Y' },
    { id: '55', cou_id: 'US', value: 'Maine', label: 'Maine', sta_order: '2', sta_visible: 'Y' },
    { id: '56', cou_id: 'US', value: 'Michigan', label: 'Michigan', sta_order: '2', sta_visible: 'Y' },
    { id: '57', cou_id: 'US', value: 'Minnesota', label: 'Minnesota', sta_order: '2', sta_visible: 'Y' },
    { id: '58', cou_id: 'US', value: 'Missouri', label: 'Missouri', sta_order: '2', sta_visible: 'Y' },
    { id: '59', cou_id: 'US', value: 'Mississippi', label: 'Mississippi', sta_order: '2', sta_visible: 'Y' },
    { id: '60', cou_id: 'US', value: 'Montana', label: 'Montana', sta_order: '2', sta_visible: 'Y' },
    { id: '61', cou_id: 'US', value: 'North Carolina', label: 'North Carolina', sta_order: '2', sta_visible: 'Y' },
    { id: '62', cou_id: 'US', value: 'North Dakota', label: 'North Dakota', sta_order: '2', sta_visible: 'Y' },
    { id: '63', cou_id: 'US', value: 'Nebraska', label: 'Nebraska', sta_order: '2', sta_visible: 'Y' },
    { id: '64', cou_id: 'US', value: 'New Hampshire', label: 'New Hampshire', sta_order: '2', sta_visible: 'Y' },
    { id: '65', cou_id: 'US', value: 'New Jersey', label: 'New Jersey', sta_order: '2', sta_visible: 'Y' },
    { id: '66', cou_id: 'US', value: 'New Mexico', label: 'New Mexico', sta_order: '2', sta_visible: 'Y' },
    { id: '67', cou_id: 'US', value: 'Nevada', label: 'Nevada', sta_order: '2', sta_visible: 'Y' },
    { id: '68', cou_id: 'US', value: 'New York', label: 'New York', sta_order: '2', sta_visible: 'Y' },
    { id: '69', cou_id: 'US', value: 'Ohio', label: 'Ohio', sta_order: '2', sta_visible: 'Y' },
    { id: '70', cou_id: 'US', value: 'Oklahoma', label: 'Oklahoma', sta_order: '2', sta_visible: 'Y' },
    { id: '71', cou_id: 'US', value: 'Oregon', label: 'Oregon', sta_order: '2', sta_visible: 'Y' },
    { id: '72', cou_id: 'US', value: 'Pennsylvania', label: 'Pennsylvania', sta_order: '2', sta_visible: 'Y' },
    { id: '73', cou_id: 'US', value: 'Rhode Island', label: 'Rhode Island', sta_order: '2', sta_visible: 'Y' },
    { id: '74', cou_id: 'US', value: 'South Carolina', label: 'South Carolina', sta_order: '2', sta_visible: 'Y' },
    { id: '75', cou_id: 'US', value: 'South Dakota', label: 'South Dakota', sta_order: '2', sta_visible: 'Y' },
    { id: '76', cou_id: 'US', value: 'Tennessee', label: 'Tennessee', sta_order: '2', sta_visible: 'Y' },
    { id: '77', cou_id: 'US', value: 'Texas', label: 'Texas', sta_order: '2', sta_visible: 'Y' },
    { id: '78', cou_id: 'US', value: 'Utah', label: 'Utah', sta_order: '2', sta_visible: 'Y' },
    { id: '79', cou_id: 'US', value: 'Virginia', label: 'Virginia', sta_order: '2', sta_visible: 'Y' },
    { id: '80', cou_id: 'US', value: 'Vermont', label: 'Vermont', sta_order: '2', sta_visible: 'Y' },
    { id: '81', cou_id: 'US', value: 'Washington', label: 'Washington', sta_order: '2', sta_visible: 'Y' },
    { id: '82', cou_id: 'US', value: 'Wisconsin', label: 'Wisconsin', sta_order: '2', sta_visible: 'Y' },
    { id: '83', cou_id: 'US', value: 'West Virginia', label: 'West Virginia', sta_order: '2', sta_visible: 'Y' },
    { id: '84', cou_id: 'US', value: 'Wyoming', label: 'Wyoming', sta_order: '2', sta_visible: 'Y' },
    { id: '85', cou_id: 'CA', value: 'Alberta', label: 'Alberta', sta_order: '3', sta_visible: 'Y' },
    { id: '86', cou_id: 'CA', value: 'British Columbia', label: 'British Columbia', sta_order: '3', sta_visible: 'Y' },
    { id: '87', cou_id: 'CA', value: 'Manitoba', label: 'Manitoba', sta_order: '3', sta_visible: 'Y' },
    { id: '88', cou_id: 'CA', value: 'New Brunswick', label: 'New Brunswick', sta_order: '3', sta_visible: 'Y' },
    { id: '89', cou_id: 'CA', value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador', sta_order: '3', sta_visible: 'Y' },
    { id: '90', cou_id: 'CA', value: 'Nova Scotia', label: 'Nova Scotia', sta_order: '3', sta_visible: 'Y' },
    { id: '91', cou_id: 'CA', value: 'Northwest Territories', label: 'Northwest Territories', sta_order: '3', sta_visible: 'Y' },
    { id: '92', cou_id: 'CA', value: 'Nunavut', label: 'Nunavut', sta_order: '3', sta_visible: 'Y' },
    { id: '93', cou_id: 'CA', value: 'Ontario', label: 'Ontario', sta_order: '3', sta_visible: 'Y' },
    { id: '94', cou_id: 'CA', value: 'Prince Edward Island', label: 'Prince Edward Island', sta_order: '3', sta_visible: 'Y' },
    { id: '95', cou_id: 'CA', value: 'Quebec', label: 'Quebec', sta_order: '3', sta_visible: 'Y' },
    { id: '96', cou_id: 'CA', value: 'Saskatchewan', label: 'Saskatchewan', sta_order: '3', sta_visible: 'Y' },
    { id: '97', cou_id: 'CA', value: 'Yukon Territory', label: 'Yukon Territory', sta_order: '3', sta_visible: 'Y' }
  ];

  export const AddressTemplates: IAddressTemplate[] = [
    { adt_id: "1", cou_id: "MX", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "4", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "2", cou_id: "MX", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "25", adt_separator: "", adt_default_value: "" },
    { adt_id: "3", cou_id: "MX", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "24", adt_separator: "", adt_default_value: "" },
    { adt_id: "4", cou_id: "MX", adv_id: "4", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "5", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "5", cou_id: "MX", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "1", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "6", cou_id: "MX", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "6", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "7", cou_id: "US", adv_id: "7", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "8", cou_id: "US", adv_id: "8", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "9", cou_id: "US", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "3", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "10", cou_id: "US", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^(([0-9]{5}[-]{1}[0-9]{4})|([0-9]{5}))$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "11", cou_id: "ES", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "12", cou_id: "ES", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "13", cou_id: "ES", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "14", cou_id: "ES", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "15", cou_id: "ES", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "16", cou_id: "AR", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "17", cou_id: "AR", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "18", cou_id: "AR", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "19", cou_id: "AR", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[A-Za-z]{1}[0-9]{4}[A-Za-z]{3}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "20", cou_id: "AR", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "21", cou_id: "DE", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "22", cou_id: "DE", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "23", cou_id: "DE", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "24", cou_id: "DE", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "25", cou_id: "DE", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "26", cou_id: "FR", adv_id: "8", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "27", cou_id: "FR", adv_id: "7", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "28", cou_id: "FR", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "3", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "29", cou_id: "FR", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "30", cou_id: "BR", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "31", cou_id: "BR", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "32", cou_id: "BR", adv_id: "9", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "33", cou_id: "BR", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "34", cou_id: "BR", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}[-]{1}[0-9]{3}$", adt_order: "5", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "35", cou_id: "CL", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "36", cou_id: "CL", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "37", cou_id: "CL", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "38", cou_id: "CL", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{7}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "39", cou_id: "CL", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "40", cou_id: "CA", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "41", cou_id: "CA", adv_id: "7", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "42", cou_id: "CA", adv_id: "8", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "43", cou_id: "CA", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "44", cou_id: "CA", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[\s|-][0-9]{1}[A-Za-z]{1}[0-9]{1}$", adt_order: "5", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "45", cou_id: "GB", adv_id: "8", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "46", cou_id: "GB", adv_id: "7", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "47", cou_id: "GB", adv_id: "10", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "48", cou_id: "GB", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "49", cou_id: "GB", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[A-Za-z0-9]{2,4}[\s]{1}[A-Za-z0-9]{3}$", adt_order: "5", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "50", cou_id: "NL", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "51", cou_id: "NL", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "52", cou_id: "NL", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{4}[\s]{1}[A-Za-z]{2}$", adt_order: "3", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "53", cou_id: "NL", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "54", cou_id: "CH", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "55", cou_id: "CH", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "56", cou_id: "CH", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "57", cou_id: "CH", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{4}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "58", cou_id: "CH", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "59", cou_id: "SE", adv_id: "1", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "60", cou_id: "SE", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "61", cou_id: "SE", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^((s|S)(e|E)-){0,1}[0-9]{3}[\s]{1}[0-9]{2}$", adt_order: "3", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "62", cou_id: "SE", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "63", cou_id: "IT", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "64", cou_id: "IT", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "65", cou_id: "IT", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "66", cou_id: "IT", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^((i|I-)){0,1}[0-9]{5}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "67", cou_id: "IT", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "68", cou_id: "BE", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "69", cou_id: "BE", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "70", cou_id: "BE", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "71", cou_id: "BE", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{4}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "72", cou_id: "BE", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "73", cou_id: "UY", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "74", cou_id: "UY", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "75", cou_id: "UY", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "76", cou_id: "UY", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "77", cou_id: "UY", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "78", cou_id: "CO", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "79", cou_id: "CO", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "25", adt_separator: "", adt_default_value: "" },
    { adt_id: "80", cou_id: "CO", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "24", adt_separator: "", adt_default_value: "" },
    { adt_id: "81", cou_id: "CO", adv_id: "8", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "4", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "82", cou_id: "CO", adv_id: "5", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "5", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "83", cou_id: "CO", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "6", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "84", cou_id: "MT", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "85", cou_id: "MT", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "86", cou_id: "MT", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "3", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "87", cou_id: "MT", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[A-Za-z0-9]{3}[\s]{1}[0-9]{4}$", adt_order: "4", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "88", cou_id: "PE", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "89", cou_id: "PE", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "90", cou_id: "PE", adv_id: "9", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "91", cou_id: "PE", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "4", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "92", cou_id: "PE", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{5}$", adt_order: "5", adt_length: "10", adt_separator: "", adt_default_value: "" },
    { adt_id: "93", cou_id: "JP", adv_id: "3", adm_id: "", adt_required: "N", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "1", adt_length: "25", adt_separator: "", adt_default_value: "" },
    { adt_id: "94", cou_id: "JP", adv_id: "2", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "2", adt_length: "24", adt_separator: "", adt_default_value: "" },
    { adt_id: "95", cou_id: "JP", adv_id: "1", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "3", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "96", cou_id: "JP", adv_id: "10", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ#&',-./:;+]*$", adt_order: "4", adt_length: "50", adt_separator: "", adt_default_value: "" },
    { adt_id: "97", cou_id: "JP", adv_id: "6", adm_id: "", adt_required: "Y", adt_expression: "^[a-zA-Z0-9 ñÑüÜáÁéÉíÍóÓúÚ'.-]*$", adt_order: "5", adt_length: "35", adt_separator: "", adt_default_value: "" },
    { adt_id: "98", cou_id: "JP", adv_id: "5", adm_id: "", adt_required: "Y", adt_expression: "^[0-9]{3}[-]{1}[0-9]{4}$", adt_order: "6", adt_length: "10", adt_separator: "", adt_default_value: "" }
  ];

  export const AddressVariable = [
    { adv_id: '1', adv_name: 'Calle', control: 'street' },
    { adv_id: '2', adv_name: 'Número exterior', control: 'number' },
    { adv_id: '3', adv_name: 'Número interior', control: 'interiorNumber' },
    { adv_id: '4', adv_name: 'Colonia', control: 'neighborhood' },
    { adv_id: '5', adv_name: 'Código postal', control: 'postalCode' },
    { adv_id: '6', adv_name: 'Ciudad', control: 'city' },
    { adv_id: '7', adv_name: 'Número y calle', control: 'streetNumberAndName' },
    { adv_id: '8', adv_name: 'Dirección adicional', control: 'additionalAddress' },
    { adv_id: '9', adv_name: 'Distrito', control: 'district' },
    { adv_id: '10', adv_name: 'Localidad dependiente', control: 'dependantLocality' },
    { adv_id: '11', adv_name: 'Comunidad autónoma', control: 'autonomusCommunity' },
    { adv_id: '12', adv_name: 'Estado', control: 'state' }
  ];

  export const DefaultAddress = [
    'state',
    'city',
    'postalCode',
    'address1'
  ];

  export const TextInputsRegistrant = [
    'additionalAddress',
    'autonomusCommunity',
    'city',
    'dependantLocality',
    'district',
    'interiorNumber',
    'neighborhood',
    'number',
    'postalCode',
    'street',
    'streetNumberAndName'
  ];

  export class Region
  {
    public static readonly MX = "MX";
    public static readonly US = "US";
    public static readonly CA = "CA";
  }

}
