<!-- Main coupon display -->
<div class="w-100 px-0 my-3">
  <section id="coupon-title" class="px-3">
    <span class="d-block fs-l">{{'Cupón de descuento' | translate }}</span>
  </section>

  <section id="coupon-subtitle" class="px-3">
    <span *ngIf="!couponApplied && !showError" class="d-block fs-md my-2 gray fw-bold">
      {{'Si tienes un cupón de descuento, introdúcelo en el campo a continuación' | translate }}.
    </span>
  </section>

  <section id="coupon-message">
    <ng-container *ngIf="couponApplied && couponName && isValidSavingCouponAmount; then successMessageTemplate"></ng-container>
    <ng-container *ngIf="showError; then errorMessageTemplate"></ng-container>
  </section>

  <section id="coupon-input" class="px-3">
    <!-- coupon apply -->
    <div *ngIf="!couponApplied">
      <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </div>
  </section>
</div>

<!-- Coupon input -->
<ng-template #inputTemplate>
  <div class="input-group floating-label w-100 m-auto my-3">
    <input [(ngModel)]="coupon" placeholder="{{'Introduce tu cupón aquí' | translate }}" type="text" role="textbox"
      value="" autocomplete="off" class="form-control form-input" maxlength="25" (keyup.enter)="onApplyCouponClick()">

    <div class="input-group-append">
      <button type="submit" name="applyCupon" role="button" class="btn-sm btn-secondary py-2 sufix"
        [disabled]="applyClicked" (click)="onApplyCouponClick()">
        <span class="d-md-block">{{ 'Aplicar' | translate}}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #successMessageTemplate>
  <div class="my-2 py-3 w-100 d-flex align-items-center bg-green" *ngIf="isValidSavingCouponAmount">
    <span class="text-white text-center w-100 fw-bold fs-m">{{ '¡Cupón agregado exitosamente!' | translate }}
    </span>
  </div>

  <ng-container *ngTemplateOutlet="appliedCoupon"></ng-container>
</ng-template>

<ng-template #errorMessageTemplate>
  <div class="my-2 py-3 w-100 d-flex flex-column align-items-center bg-yellow">
    <span class="d-block text-black text-center w-100 fw-bold fs-m">
      {{ errorTitle | translate }}
    </span>
    <span class="d-block text-black text-center w-100 fs-m">
      {{ errorMessage | translate }}
    </span>
  </div>

  <ng-container *ngIf="couponName && !isValidSavingCouponAmount">
    <ng-container *ngTemplateOutlet="appliedCoupon"></ng-container>
  </ng-container>
</ng-template>


<ng-template #appliedCoupon>
  <div class="px-3 pt-2 my-2 w-100 d-flex justify-content-end">
    <span class="fs-l fw-bold me-2">{{coupon}}</span>
    <div class="clickable" (click)="onRemoveCouponClicked()" title="{{ 'Eliminar cupón' | translate }}">
      <span class="icon i-trash"></span>
    </div>
  </div>
</ng-template>