import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { ELogoutType } from '@core-constants/logout-events.const';
import { SesionStorageConst } from '@core-constants/storage.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { TokenManager } from '@core-managers/token.manager';
import { RefreshToken, RefreshTokenDto } from '@shared-base/generic-clases.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { TranslateService } from '@shared-services/translate.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationInterceptor implements HttpInterceptor
{
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private tokenService: TokenManager,
    private translateService: TranslateService,
    private userAccessDataService: UserAccessDataService) { }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    let authReq = req;
    const token = this.tokenService.getToken();

    if (token && token != "")
    {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq)
      .pipe(catchError(error =>
      {
        if (error instanceof HttpErrorResponse &&
          !(authReq.url.includes('user-access/logout') ||
            authReq.url.includes('user-access/login') ||
            authReq.url.includes('user-access/refresh') ||
            authReq.url.includes('session')) &&
          error.status === 401)
        {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if (!this.isRefreshing)
    {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.tokenService.getRefreshToken();

      if (refreshToken && refreshToken != "")
      {
        const refreshTokenDto: RefreshTokenDto =
        {
          language: this.translateService.languageCode,
          refreshToken: refreshToken
        };

        return this.userAccessDataService.refreshToken(refreshTokenDto).pipe(
          switchMap((response: RefreshToken) =>
          {
            this.tokenService.saveToken(response.bearerToken);
            this.tokenService.saveRefreshToken(response.refreshToken);
            this.isRefreshing = false;

            this.refreshTokenSubject.next(response.refreshToken);
            return next.handle(this.addTokenHeader(request, response.bearerToken));
          }),
          catchError((err) =>
          {
            this.isRefreshing = false;
            BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.ExpiredSession);
            throw (err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any>
  {
    return request.clone({ headers: request.headers.set(SesionStorageConst.TokenHeaderKey, token) });
  }
}

