<div class="p-0 m-0" [class.d-none]="!mobile" [class.d-lg-none]="!desktop">

  <div class="d-flex align-items-center justify-content-center font-weight-bold fs-m my-3">
    <span class="icon i-lock-solid me-2"></span>
    <span>{{ 'Compra Segura con Akky' | translate }}</span>
  </div>
  <div class="text-center my-3">
    <a href="https://sealserver.trustwave.com/cert.php?customerId=d2781281211249f9a17a599b0cb9581b&size=105x54&style="
      target="_blank">
      <img [src]="'/images/secure_trust.svg' | blobstorage" data-src="{{ '/images/secure_trust.svg' | blobstorage }}"
        loading="lazy" class="mx-auto height-logo" alt="SecureTrust logo">
    </a>
    <app-payment-methods-images></app-payment-methods-images>
  </div>
</div>