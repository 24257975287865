import { ECreditCardTypeName, PaymentConst } from "@core-constants/payment-data.const";
import { RegexConst } from "@core-constants/regex.const";
import { CreditCardData, ICreditCard } from "@core-models/payment.model";
import { Tools } from "./tools.util";

export class CreditCardUtils
{
  public static fixCCardLength(cardNumber: string): string
  {
    cardNumber = cardNumber.replace(/ /g, '').replace(/-/g, '');
    let numberFixed: string = cardNumber;

    if (numberFixed.length > 5)
    {
      const cardType = this.getCardType(cardNumber);

      const data: Partial<ICreditCard> = PaymentConst.CreditCardConfig[cardType];

      if (numberFixed.length > data.maxLength)
      {
        numberFixed = cardNumber.substr(0, data.maxLength);
      }
    }

    return numberFixed;
  }

  public static getCardType(cardNumber: string): ECreditCardTypeName
  {
    const number: string = cardNumber.replace(/[ -]/g, '');

    for (const key in ECreditCardTypeName)
    {
      const pattern = PaymentConst.CreditCardConfig[ECreditCardTypeName[key]].pattern;

      if (pattern !== null && number.match(pattern))
      {
        return ECreditCardTypeName[key];
      }
    }

    return ECreditCardTypeName.Unknown;
  }

  public static isMaxCCLength(cardNumber: string, cardType: string): boolean
  {
    if (!Tools.isValidEnumValue(cardType, ECreditCardTypeName))
    {
      return false;
    }

    const data: Partial<ICreditCard> = PaymentConst.CreditCardConfig[cardType];
    const digits: number = cardNumber.length;

    return digits >= data.maxLength;
  }

  public static isValidCreditCardLength(cardNumber: string, cardType: string): boolean
  {
    if (!Tools.isValidEnumValue(cardType, ECreditCardTypeName))
    {
      return false;
    }

    const data: Partial<ICreditCard> = PaymentConst.CreditCardConfig[cardType];
    const digits: number = cardNumber.length;

    return digits >= data.minLength && digits <= data.maxLength;
  }

  public static isValidLuhn(cardNumber: string): boolean
  {
    const numbers: Array<number> = cardNumber.split('').reverse().map((val: string): number => +val);

    let n: number = 0;
    let sum: number = 0;
    let i: number = 0;

    let digit: number;

    while (i < numbers.length)
    {
      digit = numbers[n];
      digit = +digit;

      if (n % 2)
      {
        digit *= 2;
        sum += (digit < 10) ? digit : digit - 9;
      }
      else
      {
        sum += digit;
      }

      n = ++i;
    }

    return sum % 10 === 0;
  }

  public static isMaxCVVLength(cvv: string, cardType: string): boolean
  {
    if (!Tools.isValidEnumValue(cardType, ECreditCardTypeName))
    {
      return false;
    }

    const data: Partial<ICreditCard> = PaymentConst.CreditCardConfig[cardType];
    const digits: number = cvv.length;

    return digits >= data.cvvDigits;
  }


  public static isValidCVVLength(cvv: string, cardType: string): boolean
  {
    if (!Tools.isValidEnumValue(cardType, ECreditCardTypeName))
    {
      return false;
    }

    const data: Partial<ICreditCard> = PaymentConst.CreditCardConfig[cardType];
    const digits: number = cvv.length;

    return digits == data.cvvDigits;
  }

  public static isValidCvv(cvv: string, cardType: string): boolean
  {
    if (!Tools.isValidEnumValue(cardType, ECreditCardTypeName))
    {
      return false;
    }

    const regexCvv = new RegExp('^[+ 0-9]{' + PaymentConst.CreditCardConfig[cardType].cvvDigits + '}$');
    return regexCvv.test(cvv);
  }

  public static validateCardData(data: CreditCardData): boolean
  {
    if (data == undefined || data == null)
    {
      return false;
    }

    return this.validate(data.cardNumber, data.holder, data.expiryMonth, data.expiryYear, data.cvv);
  }


  public static validate(cardNumber: string, cardHolder: string, expMonth: number, expYear: number, cvv: string): boolean
  {
    if (cardHolder == null || cardHolder == undefined || cardHolder == "")
    {
      return false;
    }

    if (cardNumber == null || cardNumber == undefined || cardNumber == "")
    {
      return false;
    }

    const cleanCardNumber = CreditCardUtils.unmask(cardNumber);

    const cardType: ECreditCardTypeName = this.getCardType(cleanCardNumber);

    if (!this.isValidCreditCardLength(cleanCardNumber, cardType))
    {
      return false;
    }

    if (!this.isValidLuhn(cleanCardNumber))
    {
      return false;
    }

    if (!this.isValidExpDate(expMonth, expYear))
    {
      return false;
    }

    if (!this.isValidCvv(cvv, cardType))
    {
      return false;
    }

    return true;
  }

  public static isValidExpDate(expMonth: number, expYear: number): boolean
  {
    const date: Date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currrentYear = parseInt(date.getFullYear().toString().substr(-2));

    const isMonthValid = RegexConst.CardExpiryDateMonth.test(expMonth.toString());
    const isYearValid = RegexConst.CardExpiryDateYear.test(expYear.toString());

    if (!(expYear > currrentYear || (currrentYear == expYear && expMonth >= currentMonth)) || !isMonthValid || !isYearValid)
    {
      return false;
    }

    return true;
  }

  public static maskExpDate(expiryDate: string): string 
  {
    if (expiryDate == "")
    {
      return expiryDate;
    }

    expiryDate = expiryDate.replace(/([\D])/g, '');
    expiryDate = expiryDate.substring(0, 5);

    return expiryDate.match(/.{1,2}/g).join('/');
  }

  public static mask(cardNumber: string): string
  {
    return cardNumber;
  }

  public static unmask(cardNumber: string): string
  {
    cardNumber = cardNumber.replace(/([\D])/g, '');

    const cardType: ECreditCardTypeName = this.getCardType(cardNumber);
    cardNumber = cardNumber.substring(0, PaymentConst.CreditCardConfig[cardType].maxLength);

    return cardNumber;
  }
}
