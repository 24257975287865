<div class="p-contact bg-contact p-3 contact-form-w">
  <div class="clickable py-3" (click)="onCancelClick()">
    <span class="icon i-arrow-left"></span>
  </div>

  <div class="row" *ngIf="showPersonTypeInformation">

    <!--PERSON TYPE-->
    <div class="d-flex w-100  py-1">
      <label for="lblPersonType" class="text-uppercase text-green fs-m p-0 m-0">
        <b>{{'Persona física o moral' | translate}}</b>
      </label>
    </div>
    <div class="col-md-6 col-12">
      <app-dropdown id="personType" [label]="'TIPO DE PERSONA'" [items]="personTypes" [selectedValue]="personType"
        (onItemSelectedChange)="onPersonTypeChanges($event)" [isFullWidth]="true"></app-dropdown>
    </div>
  </div>

  <section id="contactFormSection" [formGroup]="contactForm">
    <!-- ORGANIZATION DROPDOWNS -->
    <section *ngIf="isMoralPerson && showPersonTypeInformation"> 
      <div class="row">
        <div class="col-md-6 dropdown-required py-2 dropdown-item-error">
          <app-dropdown
            [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.idContactOrganizationIndustry?.errors }"
            [selectedValue]="selectedOrganization" [label]="'GIRO DE ORGANIZACIÓN'"
            (onItemSelectedChange)="onOrganizationTypeSelected($event)" [items]="organizationTypes" [isFullWidth]="true"
            required></app-dropdown>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.idContactOrganizationIndustry, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
        <div class="col-md-6">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.industryOther?.errors }"
              formControlName="industryOther" placeholder=" " type="text" id="industryOther"
              class="form-control form-input white-space" name="industryOther" role="textbox" maxlength="100"
              [required]="requiredIndustryOther">
            <small *ngIf="!requiredIndustryOther" class="description-field" for="industryOther">({{'Opcional' |
              translate}})</small>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.industryOther, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 dropdown-required py-2 dropdown-item-error">
          <app-dropdown
            [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.idContactOrganizationSize?.errors }"
            [selectedValue]="selectedOrganizationSize" [label]="'TAMAÑO DE ORGANIZACIÓN'"
            (onItemSelectedChange)="onOrganizationSizeSelected($event)" [items]="organizationSizes" [isFullWidth]="true"
            required></app-dropdown>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.idContactOrganizationSize, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
        <div class="col-md-6 pb-2 pt-3 align-self-start" *ngIf="hasRFC">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.rfc?.errors }"
              formControlName="rfc" placeholder=" " type="text" id="rfc" class="form-control form-input" name="rfc"
              role="textbox" maxlength="13" (input)="onRFCChanged($event)" required>
            <label for="rfc">{{'RFC' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.rfc, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-12 py-2">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.name?.errors }"
              formControlName="name" placeholder=" " type="text" id="name" class="form-control form-input mt-1"
              name="name" role="textbox" maxlength="100" required>
            <label for="name">{{'NOMBRE DE ORGANIZACIÓN' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.name, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
        <div class="col-md-6 col-12 py-2" *ngIf="isCCT">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.cctName?.errors }"
              formControlName="cctName" placeholder=" " type="text" id="cctName" class="form-control form-input mt-1"
              name="cctName" role="textbox" maxlength="13" required>
            <label for="cctName">{{'CCT' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.cctName, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>
    </section>

    <div class="p-0 m-0">
      <div class="row m-0 p-0 my-2">
        <label for="lblModalForm" class="text-uppercase text-green fs-m p-0 m-0"><b>{{'Datos' | translate}}</b></label>
      </div>

      <div class="row" *ngIf="isMoralPerson">
        <div class="col-md-6">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.contactPersonName?.errors }"
              formControlName="contactPersonName" placeholder=" " type="text" id="contactPersonName"
              class="form-control form-input default-form-input" name="contactPersonName" role="textbox" maxlength="100"
              required>
            <label for="contactPersonName">{{'PERSONA DE CONTACTO' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.contactPersonName, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
        <div class="col-md-6">
          <div class="form-group w-100 floating-label">
            <input
              [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.contactPersonPosition?.errors }"
              formControlName="contactPersonPosition" placeholder=" " type="text" id="contactPersonPosition"
              class="form-control form-input default-form-input" name="contactPersonPosition" role="textbox"
              maxlength="100" required>
            <label for="contactPersonPosition">{{'PUESTO' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.contactPersonPosition, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <!-- BASIC INFORMATION-->
      <div class="my-3">
        <div class="form-group w-100 floating-label">
          <input formControlName="shortName" placeholder=" " type="text" id="shortName" class="form-control form-input"
            name="shortName" role="textbox" maxlength="20">
          <label for="shortName">{{'Alias' | translate}} <small> ( {{'Cómo prefieres que te llamen' | translate}} )
            </small></label>
        </div>
      </div>

      <div class="my-3" *ngIf="isNaturalPerson">
        <div class="form-group w-100 floating-label">
          <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.name?.errors }"
            formControlName="name" placeholder=" " type="text" id="fullname" class="form-control form-input" name="name"
            role="textbox" maxlength="95" required>
          <label for="name">{{'Nombre completo' | translate}}</label>
        </div>
        <ng-container
          *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.name, name: '', submitted: contactFormSubmitted}"></ng-container>
      </div>

      <div class="row m-0 p-0 my-3">
        <div class="col-md-6 col-12 m-0 p-0">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.email?.errors }"
              formControlName="email" placeholder=" " type="email" id="email" class="form-control form-input"
              name="email" role="textbox" maxlength="100" required>
            <label for="email">{{'Email' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.email, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <!-- ADDRESS INFORMATION -->
      <div class="d-flex w-100 m-0 p-0 py-1">
        <label for="lblAddress" class="text-uppercase text-green fs-m p-0 m-0"><b>{{'Dirección' |
            translate}}</b></label>
      </div>
      <!-- COUNTRY / STATE DROPDOWNS -->
      <div class="row p-0 m-0">
        <div class="col-md-6 col-12 p-0 m-0 pe-md-2 py-2">
          <app-dropdown [label]="'PAÍS'" id="countriesDropDown" [selectedValue]="selectedCountry" [items]="countries"
            (onItemSelectedChange)="onCountryChanges($event);" [isFullWidth]="true"></app-dropdown>
        </div>
        <!-- State dropdown -->
        <div *ngIf="countryStates.length"
          class="col-md-6 col-12 p-0 m-0 ps-md-2 py-2 dropdown-required dropdown-item-error">
          <app-dropdown [label]="'ESTADO'" id="statesDropDown" [selectedValue]="selectedState" [items]="countryStates"
            (onItemSelectedChange)="onStateChanges($event)" [isFullWidth]="true" required></app-dropdown>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.state, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
        <!-- State input-->
        <div *ngIf="!countryStates.length" class="col-md-6 col-12 p-0 m-0 ps-md-2 state-input">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.state?.errors }"
              formControlName="state" placeholder=" " type="text" id="state" class="form-control form-input"
              name="state" role="textbox" maxlength="135" required>
            <label for="state">{{'Estado' | translate}}</label>
          </div>
          <small class="description-field" for="state">{{'O equivalente: Región, Provincia, Localidad' |
            translate}}</small>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.state, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <!-- DEFAULT ADDRESS COUNTRIES -->
      <div class="row" *ngIf="showDefaultFields">
        <div class="col-md-6 col-12">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.city?.errors }"
              formControlName="city" placeholder=" " type="text" id="city"
              class="form-control form-input default-form-input" name="city" role="textbox" maxlength="100" required>
            <label for="city">{{'Ciudad' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.city, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.postalCode?.errors }"
              formControlName="postalCode" placeholder=" " type="text" id="postalCode"
              class="form-control form-input default-form-input" name="postalCode" role="textbox" maxlength="100"
              required>
            <label for="postalCode">{{'Código postal' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.postalCode, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>
      </div>

      <div class="row" *ngIf="showDefaultFields">
        <div class="col-md-6 col-12">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.address1?.errors }"
              formControlName="address1" placeholder=" " type="text" id="address1"
              class="form-control form-input default-form-input" name="address1" role="textbox" maxlength="100"
              required>
            <label for="address1">{{'Dirección' | translate}} 1</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.address1, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group w-100 floating-label">
            <input formControlName="address2" placeholder=" " type="text" id="address2"
              class="form-control form-input default-form-input" name="address2" role="textbox" maxlength="100">
            <label for="address2">{{'Dirección' | translate}} 2</label>
          </div>
          <small class="description-field" for="address2">({{'Opcional' | translate}})</small>
        </div>
      </div>
      <div class="row" *ngIf="showDefaultFields">
        <div class="col-md-6 col-12">
          <div class="form-group w-100 floating-label">
            <input formControlName="address3" placeholder=" " type="text" id="address3"
              class="form-control form-input default-form-input" name="address3" role="textbox" maxlength="100">
            <label for="address3">{{'Dirección' | translate}} 3</label>
          </div>
          <small class="description-field" for="address3">({{'Opcional' | translate}})</small>
        </div>
      </div>

      <!-- DYNAMIC FILEDS -->
      <section *ngIf="!showDefaultFields">
        <div class="row m-0 p-0">
          <div class="col-md-6 col-12 m-0 p-0 my-2" [ngClass]="{'ps-md-2': isOdd, 'pe-md-2' : !isOdd }"
            *ngFor="let item of template; index as i; odd as isOdd;">
            <div class="form-group w-100 floating-label">
              <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls[item.control]?.errors }"
                [formControlName]="item.control" placeholder=" "
                type="{{ item.control === 'email' ? 'email' : 'text' }}" [id]="item.control" [name]="item.control"
                class="form-control form-input" role="textbox" [maxlength]="item.adt_length"
                [attr.required]="item.adt_required === 'Y'">
              <label [for]="item.control">{{item.adv_name | translate}}</label>
            </div>
            <ng-container
              *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls[item.control], name: '', submitted: contactFormSubmitted}"></ng-container>
          </div>
        </div>
      </section>

      <!-- PHONE INFOMATION -->
      <div class="row p-0 m-0">
        <div class="col-3 col-md-3 p-0 m-0 pe-2 mt-3">
          <div class="form-group w-100 floating-label">
            <input value=" " formControlName="phoneCode" pattern="" type="text" id="phoneCode"
              class="form-control form-input" name="phoneCode" role="textbox" maxlength="20" [attr.disabled]="true">
            <label for="phoneCode">{{'Clave' | translate}}</label>
          </div>
        </div>

        <div class="col-9 col-md-6 p-0 m-0 mt-3">
          <div class="form-group w-100 floating-label">
            <input [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormControls.voiceNumber?.errors }"
              formControlName="voiceNumber" placeholder=" " type="text" id="voiceNumber" class="form-control form-input"
              name="voiceNumber" role="textbox" minlength="2" maxlength="13"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" required>
            <label for="voiceNumber">{{'Teléfono' | translate}}</label>
          </div>
          <ng-container
            *ngTemplateOutlet="tooltipErrorTemplate; context: {control: contactFormControls.voiceNumber, name: '', submitted: contactFormSubmitted}"></ng-container>
        </div>

        <div class="col-md-3 col-12 p-0 m-0 ps-md-2 mt-3">
          <div class="form-group w-100 floating-label">
            <input formControlName="extension" placeholder="{{ 'Opcional' | translate }}" type="text" id="extension"
              class="form-control form-input" name="extension" maxlength="6" role="textbox">
            <label for="ext" class="up-label">{{'EXT'}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- END CONTACT FORM -->
  </section>

  <div class="row text-center align-items-center my-2">
    <div class="col-md-6 order-2 order-md-1 my-2">
      <button type="button" class="btn btn-secondary w-100 black text-uppercase" (click)="onCancelClick()">{{'Cancelar'
        | translate}}</button>
    </div>
    <div class="col-md-6 order-1 order-md-2 my-2">
      <button (click)="onSaveClick()" type="submit" class="btn btn-primary w-100">{{ buttonLabel | translate}}</button>
    </div>
  </div>
</div>

<!-- error tooltip -->
<ng-template #tooltipErrorTemplate let-control="control" let-submitted="submitted" let-type="type" let-name="name">
  <div class="error-container" *ngIf="submitted && control?.errors">
    <small>{{getErrorMessage(control)}}</small>
  </div>
</ng-template>
