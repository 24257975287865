import { Injectable } from '@angular/core';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { EContactType } from '@core-constants/contact-data.const';
import { DomainDataConst, EDomainType } from '@core-constants/domain-data.const';
import { EServiceType, EServiceTypeName } from '@core-constants/product-service.const';
import { ECartItemOperation, ECartItemStatus } from '@core-constants/shopping-cart.const';
import { ContactInfoHelper, DomainContactItem } from '@core-models/contacts.model';
import { ContactPaymentDto, InvoiceDto, ItemPaymentDto, PaymentInfoDto, PaymentOrderDto } from '@core-models/payment-order.model';
import { DomainServiceItemPlus, GroupedShoppingCart, ICoverageOption, ShoppingCarBaseContent, ShoppingCartPlus, ShoppingCartPlusItem, VariousServiceItemPlus } from '@core-models/shopping-cart-plus.model';
import { UpdateDomainService } from '@core-models/update-cart.model';
import { BroadcastService } from '@shared-services/broadcast.service';
import { Tools } from '@shared-utils/tools.util';
import { CookieService } from 'ngx-cookie-service';
import { SettingsManager } from './settings.manager';
import { TokenManager } from './token.manager';
import { IShoppingCartIndividualStoredSuggestionPlus } from '@core-models/purchase-suggestion.model';
import { IndividualSuggestionManager } from './individual-suggestion.manager';

@Injectable({ providedIn: 'root' })
export class CheckoutManager
{
  //coupon
  private _coupon: string | undefined = undefined;
  private _couponToApply: string = "";
  private _couponError: string = "";
  private _saving: number = 0;

  //Step flags
  private _isRegistrationDataEnabled: boolean = false; //step 2

  //Open domains checks
  private _allowShareContactInfo: boolean = false;
  private _allowShareRegistrantInfo: boolean = false;

  //Contacts
  private _registrantContact: ContactInfoHelper;
  private _administrativeContact: ContactInfoHelper;
  private _technicalContact: ContactInfoHelper;
  private _billingContact: ContactInfoHelper;

  private _redisContacts: DomainContactItem[];

  constructor(
    public tokenManager: TokenManager,
    public settingsManager: SettingsManager,
    public cookieService: CookieService,
    public individualSuggestionManager: IndividualSuggestionManager
  ) { }

  public initializeCartBaseData(data: ShoppingCarBaseContent = undefined): void
  {
    if (data)
    {
      this._couponToApply = data.couponToApply;
      this._couponError = "";
    }
  }

  public initializeData(data: ShoppingCartPlus = undefined): void
  {
    if (data)
    {
      this.setCartItems = data.items;
      this._isRegistrationDataEnabled = data.enableStep2;
      this._coupon = data.couponId ?? undefined;
      this._couponToApply = data.couponToApply;

      if (this._coupon)
      {
        this._saving = data.couponAmount ?? 0;
      }
    }
    else
    {
      //"Remove" method deletes cart cookie, "Reset" method doesn't
      this.tokenManager.removeAdditionalCartInformation();
    }
  }

  // ********************************************************************
  //#region PROPS
  // ********************************************************************

  public get hasCloseDomains(): boolean
  {
    if (this.hasNewDomains)
    {
      return this._cartDomains.find(x => x.domainType == EDomainType.edu_mx || x.domainType == EDomainType.gob_mx || x.domainType == EDomainType.net_mx) != undefined;
    }
    return false;
  }

  public get itemsLimit(): number
  {
    return this.settingsManager.itemsLimit;
  }

  public get hasNewDomains(): boolean
  {
    return this._cartDomains?.find(x => x.status == ECartItemStatus.Registration) != undefined;
  }

  public get isRegistrationDataStepEnabled(): boolean
  {
    return this._isRegistrationDataEnabled;
  }

  public set setRegistrationDataStepEnabled(value: boolean)
  {
    this._isRegistrationDataEnabled = value;
  }

  private get _cartDomains(): ShoppingCartPlusItem[]
  {
    return this.cartItems ? this.cartItems.filter(x => x.serviceType == EServiceType.Domain && !x.isNotChargeableDomain) : [];
  }

  public get isBankDepositEnabled(): boolean
  {
    return this._cartDomains.find(x => this.isGenericDomain(x.domainType) && x.operationId == ECartItemOperation.Registration) == undefined && !this.hasRecoveryDomains;
  }

  public get isPaypalEnabled(): boolean
  {
    return !this.hasRecoveryDomains;
  }

  public get isCreditCardEnabledForGenericDomainRegistration(): boolean
  {
    return this._cartDomains.find(x => this.isGenericDomain(x.domainType) && x.operationId == ECartItemOperation.Registration) == undefined || this.settingsManager.isCreditCardGenericDomainEnabled;
  }

  public get hasOpenDomains(): boolean
  {
    if (this.hasNewDomains)
    {
      return this._cartDomains.find(x => DomainDataConst.OpenDomains.includes(x.domainType)) != undefined;
    }

    return false;
  }

  public get hasRecoveryDomains(): boolean
  {
    return this._cartDomains.find(x => x.operationId == ECartItemOperation.RecoveryDeleted || x.operationId == ECartItemOperation.Recovery) != undefined;
  }

  public get coupon(): string
  {
    return this._coupon;
  }

  public get hasCouponToApply(): boolean
  {
    return this._couponToApply && this._couponToApply != "";
  }

  public get vat(): number
  {
    return this.tokenManager.getCartVAT() ?? 0;
  }

  public get hasCouponError(): boolean
  {
    return this._couponError && this._couponError != "";
  }

  public get couponToApply(): string
  {
    return this._couponToApply ?? "";
  }

  public get couponError(): string
  {
    return this._couponError ?? "";
  }

  public get isCouponApplied(): boolean
  {
    return this._coupon != undefined && this._coupon != null && this._coupon != "";
  }

  public get isCloseDomainValid(): boolean
  {
    if (this.hasCloseDomains)
    {
      const allCloseDomains = this._cartDomains.filter(x => x.domainType == EDomainType.edu_mx || x.domainType == EDomainType.gob_mx || x.domainType == EDomainType.net_mx);

      const closeDomainsGOB = allCloseDomains.filter(x => x.domainType == EDomainType.edu_mx).length;

      const closeDomainsEDU = allCloseDomains.filter(x => x.domainType == EDomainType.gob_mx).length;

      const closeDomainsMX = allCloseDomains.filter(x => x.domainType == EDomainType.net_mx).length;

      return allCloseDomains.length == closeDomainsGOB || allCloseDomains.length == closeDomainsEDU || allCloseDomains.length == closeDomainsMX;
    }

    return true;
  }

  public get hasDomainEduMX(): boolean
  {
    if (this.hasCloseDomains)
    {
      const domain = this._cartDomains.find(x => x.domainType == EDomainType.edu_mx);

      return domain ? true : false;
    }

    return false;
  }
  // #endregion

  // ********************************************************************
  //#region COUPONS
  // ********************************************************************

  public setCoupon(value: string, amount: number): void
  {
    this._coupon = value == "" ? undefined : value;
    this._saving = amount;
    this._couponError = "";

    BroadcastService.Instance.broadcast(EAppEventName.OnApplyCoupon, this._coupon);
  }

  public setAppliedCoupon(value: string): void
  {
    this._coupon = value;
  }

  public setCouponError(error: string = ""): void
  {
    this._couponError = error;
  }

  public clearCoupon(): void
  {
    this.cleanAppliedCoupon();
    BroadcastService.Instance.broadcast(EAppEventName.OnApplyCoupon, undefined);
  }

  public cleanAppliedCoupon(): void
  {
    this._coupon = undefined;
    this._saving = 0;
  }

  public cleanSavingAmount(): void
  {
    this._saving = 0;
  }

  public get currentCoupon(): string
  {
    return this.isCouponApplied ? this.coupon : (this.hasCouponToApply ? this.couponToApply : "");
  }

  public get hasSavingCouponAmount(): boolean
  {
    return this._saving > 0;
  }

  // #endregion

  // ********************************************************************
  //#region DOMAIN ITEM VALIDATIONS
  // ********************************************************************

  public isGenericDomain(type: EDomainType): boolean
  {
    return DomainDataConst.GenericDomains.find(x => x == type) != undefined;
  }

  // #endregion

  // ********************************************************************
  //#region CART ITEMS COUNT
  // ********************************************************************

  public get cartItemsCount(): number
  {
    return this.tokenManager.getCartCount() ?? 0;
  }

  public get cartItems(): ShoppingCartPlusItem[]
  {
    return this.tokenManager.getCartItems();
  }

  public get groupedCartItems(): GroupedShoppingCart[]
  {
    return this.tokenManager.getGroupedCartItems();
  }

  private counterItem(itemId: number) : number
  {
    const groupedItems = this.groupedCartItems.find(x => x.product.id == itemId);
    return groupedItems ? groupedItems.counter : 0;
  }

  private calculateTotalItems(items: ShoppingCartPlusItem[]): void
  {
    let count: number = 0;

    if (!Tools.isNullOrEmpty(items))
    {
      count = items
        .reduce((sum, current) =>
        {
          let currentCounter = this.counterItem(current.id);

          const isUpgraded = this.individualSuggestionManager.isUpgradeItem(current);

          if (!current.isNotChargeableDomain && current.coverageId != 0)
          {
             if(!isUpgraded)
             {
                currentCounter = isUpgraded ? 0 : currentCounter;
                sum += currentCounter;
             }
          }

          if (current.domainServices)
          {
            const domainServCount = current.domainServices.filter(x => x.isAdded && x.coverageId != 0).length;
            const domainServVariousServicesCount = current.domainServices.filter(x => x.isAdded && x.variousService != null).length;

            sum += (domainServCount + domainServVariousServicesCount);
          }

          if (current.variousServices)
          {
            sum+= current.variousServices.length;
          }

          if (current.suggestions)
          {
            // Individual suggestions + Upgrade suggestions
            sum += this.individualSuggestionManager.calculateTotalSuggestionsAdded(current);
          }
          return sum;
        }, 0);
    }

    this.tokenManager.saveCartCount(count);
  }

  // #endregion

  public createPaymentOrder(redisContacts: DomainContactItem[], invoiceInfo: InvoiceDto, paymentInfo: PaymentInfoDto, orderId: number = undefined): PaymentOrderDto
  {
    this._redisContacts = redisContacts;

    const paymentOrder: PaymentOrderDto = new PaymentOrderDto();

    paymentOrder.cartId = this.tokenManager.getCartCookie();
    paymentOrder.userId = this.tokenManager.getUser().id;
    paymentOrder.token = this.tokenManager.getToken();
    paymentOrder.couponId = this.coupon;
    paymentOrder.paoId = orderId;

    paymentOrder.rfc = invoiceInfo;
    paymentOrder.paymentInfo = paymentInfo;
    paymentOrder.items = this.generateItems();
    return paymentOrder;
  }

  private generateContactsArray(): ContactPaymentDto[]
  {
    let array: ContactPaymentDto[] | undefined = undefined;

    if (this.isRegistrationDataStepEnabled)
    {
      if (this.administrativeContact && this.technicalContact && this.billingContact && this.registrantContact)
      {
        array = [
          {
            id: this.administrativeContact.id.toString(),
            name: this.administrativeContact.name,
            contactType: EContactType.Administrative,
            contactPersonType: this.administrativeContact.contactPersonType,
            hasBeenUsedAsRegistrant: this.administrativeContact.hasBeenUsedAsRegistrant
          },
          {
            id: this.technicalContact.id.toString(),
            name: this.technicalContact.name,
            contactType: EContactType.Technical,
            contactPersonType: this.technicalContact.contactPersonType,
            hasBeenUsedAsRegistrant: this.technicalContact.hasBeenUsedAsRegistrant
          },
          {
            id: this.billingContact.id.toString(),
            name: this.billingContact.name,
            contactType: EContactType.Billing,
            contactPersonType: this.billingContact.contactPersonType,
            hasBeenUsedAsRegistrant: this.billingContact.hasBeenUsedAsRegistrant
          },
          {
            id: this.registrantContact.id.toString(),
            name: this.registrantContact.name,
            contactType: EContactType.Registrant,
            contactPersonType: this.registrantContact.contactPersonType,
            hasBeenUsedAsRegistrant: this.registrantContact.hasBeenUsedAsRegistrant
          }
        ];
      }
      else
      {
        array = this._redisContacts.map(x =>
        {
          const contact: ContactPaymentDto =
          {
            id: x.id,
            name: x.name,
            contactType: x.contactType,
            contactPersonType: x.contactPersonType,
            hasBeenUsedAsRegistrant: x.hasBeenUsedAsRegistrant
          };
          return contact;
        });
      }
    }
    return array;
  }

  private generateItems(): ItemPaymentDto[]
  {
    const isAllowToPublishContactInfo = this._allowShareContactInfo;
    const isAllowToPublishRegistrantInfo = this._allowShareRegistrantInfo;

    const contacts: ContactPaymentDto[] = this.generateContactsArray();
    const items: ShoppingCartPlusItem[] = this.cartItems;

    const converted = items.map((item: ShoppingCartPlusItem) =>
    {
      return {
        id: item.id,
        name: item.name,
        isAllowToPublishRegistrantInfo,
        isAllowToPublishContactInfo,
        coverageId: item.coverageId,
        quantity: item.quantity,
        type: item.serviceType,
        domainType: item.domainType,
        domainServices: this.getDomainServicesForPaymentOrder(item.domainServices),
        variousServices: this.getVariousServicesForPaymentOrder(item.variousServices),
        contacts
      };
    });

    return converted;
  }

  public getDomainServicesForPaymentOrder(domainServices: DomainServiceItemPlus[]): any[]
  {
    const converted = domainServices.map((item: any) =>
    {
      return {
        id: item.id,
        name: item.name,
        status: item.status,
        operationId: item.operationId,
        coverageId: item.coverageId,
        type: item.serviceType
      };
    });

    return converted;
  }

  public getVariousServicesForPaymentOrder(variousServices: VariousServiceItemPlus[]): any[]
  {
    const converted = variousServices.map((item: VariousServiceItemPlus) =>
    {
      return {
        id: item.id,
        name: item.name,
        status: item.status,
        operationId: item.operationId
      };
    });

    return converted;
  }

  // ********************************************************************
  //#region AMOUNTS
  // ********************************************************************

  private calculateAmounts(items: ShoppingCartPlusItem[]): void
  {
    let subtotal: number = 0;
    let total: number = 0;
    if (!Tools.isNullOrEmpty(items))
    {
      subtotal = items && items
        .reduce((sum, current) =>
        {
          return sum + this.calculateGroosSubtotalByProduct(current);
        }, 0);

      total = items
        .reduce((sum, current) =>
        {
          return sum + this.calculateTotalByProduct(current);
        }, 0);
    }

    this.tokenManager.saveCartTotal(total);
    this.tokenManager.saveCartVAT(Math.abs(total - subtotal));
  }

  // ********************************************************************
  //#region Total
  // ********************************************************************

  public get total(): number
  {
    return this.tokenManager.getCartTotal() ?? 0;
  }


  public calculateTotalByProduct(item: ShoppingCartPlusItem): number
  {
     let currentCount = this.counterItem(item.id);

     const isUpgrade = this.individualSuggestionManager.isUpgradeItem(item);

    const currentCoverage = item.coverageOptions.find(x => x.id == item.coverageId);
    // the final amount is defined by coverage
    let total: number = item.isNotChargeableDomain || isUpgrade
                        ? 0
                        : currentCoverage.rate.finalAmount * item.quantity;

    let suggestionsTotal: number = 0;

    currentCount = isUpgrade ? 0 : currentCount;

    //SUM added domain services amount
    if (!Tools.isNullOrEmpty(item.domainServices))
    {
      const domainServAdded = item.domainServices.filter(x => x.isAdded);

      const domainServTotal = domainServAdded.reduce((sum, current) =>
      {
        const amount = current.rate.finalAmount;
        return sum + (amount > 0 ? amount : 0);
      }, 0);

      total += domainServTotal;

      //SUM various service amount of added  domain services
      const domainServVariousTotal = domainServAdded.reduce((sum, current) =>
      {
        let amount = 0;

        if (current.variousService)
        {
          const final = current.variousService.coverage.rate.finalAmount;
          amount = final > 0 ? final : 0;
        }
        return sum + amount;
      }, 0);

      total += domainServVariousTotal;
    }

    //SUM added various services amount
    if (!Tools.isNullOrEmpty(item.variousServices))
    {
      const variousSerTotal = item.variousServices.reduce((sum, current) =>
      {
        let amount = current.coverage.rate.finalAmount;

        if (item.serviceType == EServiceType.Addon && item.isAccumulable)
        {
          amount = current.coverage.rate.finalAmount * item.quantity;
        }

        return sum + (amount > 0 ? amount : 0);
      }, 0);

      total += variousSerTotal;
    }

    if (!Tools.isNullOrEmpty(item.suggestions))
    {
      // Individual Suggestions + Upgrade Suggestions
      suggestionsTotal = this.individualSuggestionManager.calculateTotalAmountSuggestionsAdded(item.suggestions);
    }

    return ((total * currentCount) + suggestionsTotal);
  }



  // #endregion

  // ********************************************************************
  //#region Subtotal
  // ********************************************************************

  public calculateGroosSubtotalByProduct(item: ShoppingCartPlusItem): number
  {
    let currentCount = this.counterItem(item.id);
    const isUpgrade = this.individualSuggestionManager.isUpgradeItem(item);

    const currentCoverage = item.coverageOptions.find(x => x.id == item.coverageId);

    let grossSubtotal: number = item.isNotChargeableDomain || isUpgrade
                                  ? 0
                                  : currentCoverage.rate.grossAmount * item.quantity;

    let grossSuggestionsTotal: number = 0;

    currentCount = isUpgrade ? 0 : currentCount;

    //SUM added domain services amount
    if (!Tools.isNullOrEmpty(item.domainServices))
    {
      const added = item.domainServices.filter(x => x.isAdded);

      const domainServSubtotal = added.reduce((sum, current) =>
      {
        const amount = current.rate.grossAmount;
        return sum + (amount > 0 ? amount : 0);
      }, 0);

      grossSubtotal += domainServSubtotal;

      //SUM various service amount of added  domain services
      const domainServVariousSubtotal = added.reduce((sum, current) =>
      {
        let amount = 0;

        if (current.variousService)
        {
          const gross = current.variousService.coverage.rate.grossAmount;
          amount = gross > 0 ? gross : 0;
        }
        return sum + amount;
      }, 0);

      grossSubtotal += domainServVariousSubtotal;
    }

    //SUM added various services amount
    if (!Tools.isNullOrEmpty(item.variousServices))
    {
      const variousServSubtotal = item.variousServices.reduce((sum, current) =>
      {
        const amount = current.coverage.rate.grossAmount;

        return sum + (amount > 0 ? amount : 0);
      }, 0);

      grossSubtotal += variousServSubtotal;
    }

    //SUM suggestions amount
    if (!Tools.isNullOrEmpty(item.suggestions))
    {
      // Individual Suggestions + Upgrade Suggestions
      grossSuggestionsTotal = this.individualSuggestionManager.calculateGrossTotalAmountSuggestionsAdded(item.suggestions);
    }

    return ((grossSubtotal * currentCount) + grossSuggestionsTotal);
  }

  // #endregion

  // ********************************************************************
  //#region Saving
  // ********************************************************************

  public get saving(): number
  {
    return this._saving;
  }

  public set setSaving(value: number)
  {
    this._saving = value;
  }

  // #endregion

  // #endregion

  // ********************************************************************
  //#region CART ITEMS
  // ********************************************************************
  public set setCartItems(cartItems: ShoppingCartPlusItem[])
  {
    const count = 0;

    if (cartItems)
    {
      const groupedItems = this.preProcessCartItems(cartItems);
      this.tokenManager.saveCartItems(cartItems);
      this.tokenManager.saveGroupedCartItems(groupedItems);
      this.calculateTotalItems(cartItems);
      this.calculateAmounts(cartItems);
    }

    BroadcastService.Instance.broadcast(EAppEventName.OnUpdateCart, count);
  }

  private preProcessCartItems(shoppingCartItems: ShoppingCartPlusItem[]): GroupedShoppingCart[]
  {
    const groupedItems = Object.entries(shoppingCartItems.reduce((accumulator, currentItem) =>
    {
      const currentKey = currentItem.quantityGroup;
      (accumulator[currentKey] = accumulator[currentKey] || []).push(currentItem);
      return accumulator;
    }, {}));

    const result = groupedItems.map((item: unknown) => ({ product: item[1][0], quantityGroup: item[0], counter: item[1].length }));
    const mappedResult = result.map((groupedItem: GroupedShoppingCart) => this.productGroupedItemAdapter(groupedItem));
    return mappedResult;
  }

  private productGroupedItemAdapter(groupedItem: GroupedShoppingCart): GroupedShoppingCart
  {
    return {
      ...groupedItem,
      product: {
        ...groupedItem.product,
        suggestions: groupedItem.product.suggestions.map((suggestion: IShoppingCartIndividualStoredSuggestionPlus) => ({
          ...suggestion,
          coverage: suggestion.coverage
        }))
      }
    };
  }

  // #endregion

  // ********************************************************************
  //#region CART UPDATES
  // ********************************************************************

  public addDomainService(parentId: number, serviceId: number): void
  {
    this.updateDomainService(parentId, serviceId, true);
  }

  public addIndividualSuggestion(cartItemId: number, suggestionId: string): void
  {
    this.setCartItems = this.individualSuggestionManager.addIndividualSuggestion(this.cartItems, cartItemId, suggestionId);
  }

  public addIndividualSuggestionUpgrade(cartItemId: number, suggestionId: string, quantity: number = undefined, coverageId = undefined, coverageMonths: number = undefined): void
  {
    this.setCartItems = this.individualSuggestionManager.addIndividualSuggestionUpgrade(this.cartItems, cartItemId, suggestionId, quantity, coverageId, coverageMonths);
  }

  public removeDomainService(parentId: number, serviceId: number): void
  {
    this.updateDomainService(parentId, serviceId, false);
  }

  public removeDomainServices(parentId: number, serviceIds: number[]): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    const parent = cartItems.find(x => x.id == parentId);
    const services: DomainServiceItemPlus[] = parent?.domainServices?.filter(x => serviceIds.includes(x.id));

    if (services)
    {
      services.forEach(x => x.isAdded = false);
      this.setCartItems = cartItems;
    }
  }

  private updateDomainService(parentId: number, serviceId: number, added: boolean): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    const parent = cartItems.find(x => x.id == parentId);
    const item = parent?.domainServices?.find(x => x.id == serviceId);

    if (item)
    {
      item.isAdded = added;
      this.setCartItems = cartItems;
    }
  }

  public addDomainRenewal(domainId: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    const item = cartItems.find(x => x.id == domainId);

    if (item)
    {
      item.isNotChargeableDomain = false;
      item.status = ECartItemStatus.Renewal;
      this.setCartItems = cartItems;
    }
  }

  public removeIndividualSuggestion(cartItemId: number, suggestionId: string): void
  {
    this.setCartItems = this.individualSuggestionManager.removeIndividualSuggestion(this.cartItems, cartItemId, suggestionId);
  }

  public removeDomainRenewalCartItem(domainId: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);
    const item = cartItems.find(x => x.id == domainId);

    if (item)
    {
      item.isNotChargeableDomain = true;
      item.status = ECartItemStatus.Registration;
      this.setCartItems = cartItems;
    }
  }

  public removeCartItemQuantityGroup(quantityGroup: number): void
  {
    this.setCartItems = this.cartItems.filter(x => x.quantityGroup != quantityGroup);
  }

  public removeCartItem(id: number): void
  {
    this.setCartItems = this.cartItems.filter(i => i.id !== id);
  }

  public setQuantityGroupCounter(quantityGroup: number, added: number[], deleted: number[]): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    if (added && added.length > 0)
    {
      const originalItem: ShoppingCartPlusItem = cartItems.find(x => x.id == quantityGroup);

      added.forEach(x =>
      {
        const copyItem: ShoppingCartPlusItem = { ...originalItem, id: x };
        cartItems.push(copyItem);
      });

      this.setCartItems = cartItems;
    }
    if (deleted && deleted.length > 0)
    {
      const newItems = cartItems.filter(x => deleted.includes(x.id) === false);
      this.setCartItems = newItems;
    }
  }

  public setItem(item: ShoppingCartPlusItem): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);
    const index: number = cartItems.findIndex(x => x.id == item.id);

    if (index >= 0)
    {
      cartItems[index] = item;
      this.setCartItems = cartItems;
    }
  }

  public setCartItemCoverage(quantityGroup: number, coverageId: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    const filteredItems: ShoppingCartPlusItem[] = cartItems.filter(x => x.quantityGroup == quantityGroup);

    filteredItems.forEach(currentItem =>
    {
      const selectedCoverage: ICoverageOption = currentItem.coverageOptions?.find(x => x.id == coverageId);
      const maxCoverage = selectedCoverage?.value;

      currentItem.coverageId = coverageId;
      currentItem.rate = selectedCoverage?.rate;

      if (currentItem?.domainServices)
      {
        currentItem.domainServices
          .forEach(item =>
          {
            const selected = item.coverageOptions?.find(x => x.id <= item.coverageId);

            if (selected?.value >= maxCoverage)
            {
              const filtered = item.coverageOptions?.filter(x => x.value <= maxCoverage);
              item.coverageId = filtered.pop()?.id;
            }
          });
      }
    });

    this.setCartItems = cartItems;
  }

  public setCartItemAddonQuantity(id: number, quantity: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);
    const parent: ShoppingCartPlusItem = cartItems.find(x => x.id == id);

    if (parent)
    {
      parent.quantity = quantity;
      this.setCartItems = cartItems;
    }
  }

  public setCartDomainName(id: number, domainName: string): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

    const parent: ShoppingCartPlusItem = cartItems.find(x => x.id == id);

    if (parent)
    {
      parent.name = domainName;
      parent.fullName = domainName + parent.domainType;
    }

    this.setCartItems = cartItems;
  }

  public setDomainServicesCoverage(parentId: number, domainServices: UpdateDomainService[]): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);
    const parent = cartItems.find(x => x.id == parentId && x.type == EServiceTypeName.Domain);

    if (parent)
    {
      domainServices.forEach(service =>
      {
        const current = parent.domainServices.find(x => x.id == service.id);

        if (current)
        {
          current.coverageId = service.coverageId;
          current.rate = current.coverageOptions.find(x => x.id == service.coverageId)?.rate;
        }
      });

      this.setCartItems = cartItems;
    }
  }

  public setCartItemUpgradeCoverage(parentId: number, suggestionId: string, coverageId: number): void
  {
    this.setCartItems = this.individualSuggestionManager.setCartItemUpgradeCoverage(this.cartItems, parentId, suggestionId, coverageId);
  }

  public setDomainServiceCoverage(parentId: number, id: number, coverageId: number): void
  {
    const items = JSON.stringify(this.cartItems);
    const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);
    const parent = cartItems.find(x => x.id == parentId && x.type == EServiceTypeName.Domain);
    const domainService = parent.domainServices.find(x => x.id == id);

    domainService.coverageId = coverageId;
    domainService.rate = domainService.coverageOptions.find(x => x.id == coverageId)?.rate;

    this.setCartItems = cartItems;
  }

  public setCartItemUpgradeQuantity(parentId: number, suggestionId: string, quantity: number): void
  {
    this.setCartItems = this.individualSuggestionManager.setCartItemUpgradeQuantity(this.cartItems, parentId, suggestionId, quantity);
  }

  // #endregion

  // ********************************************************************
  //#region CONTACTS
  // ********************************************************************

  public set setShareOpenDomainContactInfo(value: boolean)
  {
    this._allowShareContactInfo = value;
  }

  public set setShareOpenDomainRegistrantInfo(value: boolean)
  {
    this._allowShareRegistrantInfo = value;
  }

  public set registrantContact(vlaue: ContactInfoHelper)
  {
    this._registrantContact = vlaue;
  }

  public get registrantContact(): ContactInfoHelper
  {
    return this._registrantContact;
  }

  public set administrativeContact(vlaue: ContactInfoHelper)
  {
    this._administrativeContact = vlaue;
  }

  public get administrativeContact(): ContactInfoHelper
  {
    return this._administrativeContact;
  }

  public set technicalContact(vlaue: ContactInfoHelper)
  {
    this._technicalContact = vlaue;
  }

  public get technicalContact(): ContactInfoHelper
  {
    return this._technicalContact;
  }

  public set billingContact(vlaue: ContactInfoHelper)
  {
    this._billingContact = vlaue;
  }

  public get billingContact(): ContactInfoHelper
  {
    return this._billingContact;
  }

  // #endregion
}
