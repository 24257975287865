import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownItem } from '@shared-base/generic-clases.base';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit
{
  public showOptions: boolean = false;

  @Output() public onItemSelectedChange = new EventEmitter<DropDownItem>();

  @Input() public label: string = "";
  @Input() public parentId: string = "";
  @Input() public isFullWidth: boolean = false;
  @Input() public showError: boolean = false;
  @Input() public coverage: boolean = false;
  @Input() public disabled: boolean = false;

  @Input('selectedValue') public selectedValue: number | string;
  @Input('items') public _items: DropDownItem[];

  public get items(): DropDownItem[]
  {
    return this._items;
  }

  public get selectedOption(): DropDownItem
  {
    let itemSelect: DropDownItem = this._items.find(x => x.value == this.selectedValue);
    if (itemSelect == undefined && this._items.length > 0)
    {
      itemSelect = this._items[0];
      this.onSelectOption(itemSelect);
    }
    return itemSelect;
  }

  public ngOnInit(): void { }

  public onSelectOption(item: DropDownItem): void
  {
    this.selectedValue = item.value;
    this.onItemSelectedChange.emit(item);
    this.showOptions = false;
  }
}
