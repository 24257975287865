import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EKeyCode } from '@core-constants/input-key.const';
import { ECreditCardError, ECreditCardType } from '@core-constants/payment-data.const';
import { CreditCardUtils } from '@shared-utils/credit-card.util';

@Directive({
  selector: '[cvvCard]'
})
export class CVVCardDirective implements OnInit, OnChanges
{
  protected input: HTMLInputElement;

  @Input() public creditCardType: any = ECreditCardType.Unknown;

  @Output() public onCVV: EventEmitter<string>;

  @Output() public onCVVError: EventEmitter<ECreditCardError>;

  constructor(protected elementRef: ElementRef)
  {
    this.input = this.elementRef.nativeElement as HTMLInputElement;
    this.onCVV = new EventEmitter<string>();
    this.onCVVError = new EventEmitter<ECreditCardError>();
  }

  public ngOnChanges(): void
  {
    const cvv: string = CreditCardUtils.unmask(this.input.value);

    if (!cvv || !CreditCardUtils.isValidCVVLength(cvv, this.creditCardType))
    {
      this.onCVVError.emit(ECreditCardError.InvalidLength);
    }
  }

  public ngOnInit(): void { }

  public get length(): number
  {
    return this.input.value.length;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): boolean
  {
    const code = e.code?.toLowerCase();

    if (code === EKeyCode.Backspace || code === EKeyCode.Delete || code === EKeyCode.Tab)
    {
      return true;
    }

    const value: RegExpMatchArray = !e.key ? null : e.key.match(/[\d -]+/);

    return value != null;
  }

  @HostListener('keypress', ['$event'])
  public onKeyPress(): boolean
  {
    const cvv = this.input.value;
    if (cvv.length > 2)
    {
      const selectedText = window.getSelection().toString();

      if (selectedText.length == cvv.length)
      {
        return true;
      }

      return !CreditCardUtils.isMaxCVVLength(cvv, this.creditCardType);
    }
    return true;
  }

  @HostListener('keyup')
  public onKeyUp(): boolean
  {
    const cvv: string = CreditCardUtils.unmask(this.input.value);
    this.input.value = CreditCardUtils.mask(cvv);

    this.onCVV.emit(cvv);

    if (!CreditCardUtils.isValidCVVLength(cvv, this.creditCardType))
    {
      this.onCVVError.emit(ECreditCardError.InvalidLength);
      return false;
    }

    this.onCVVError.emit(ECreditCardError.None);

    return true;
  }
}
