import { EDomainType } from '@core-constants/domain-data.const';

export interface IUpdateCart
{
  cartId: string;
}

export class UpdateDomainService
{
  public id: number;
  public coverageId: number;
  public domainName?: string;
}

export class UpdateDomainServicesDto implements IUpdateCart
{
  public token: string;
  public cartId: string;
  public domainId: number;
  public domainServices: UpdateDomainService[];
}
