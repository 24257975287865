<div class="container px-0 m-0 mt-3">
  <div class="row p-0 m-0 mb-3">
    <div class="col-12 col-lg-7 col-xl-8">

      <!-- TITLE -->
      <div class="ps-3 ps-sm-0 text-start">
        <h1 class="title black py-2 py-md-0"> {{ "Datos de Registro" | translate }} </h1>
        <h2 class="fs-m title-secondary d-none d-md-block mb-custom">
          {{
          "Para registrar el dominio es un requisito
          importante llenar el siguiente formulario." | translate
          }}
        </h2>
      </div>

      <div class="border-card p-3 my-3 my-lg-0">
        <div class="card-body fs-m p-0">

          <div class="subtitle-primary pb-3 text-uppercase"> {{ "Registrante (Titular)" | translate }} </div>
          <div class="pb-2 font-weight-normal subtitle-primary-regular">
            {{
            "Persona que tiene todos los derechos del
            dominio." | translate
            }}
          </div>
          <div class="py-1 text-start font-weight-bold">
            {{
            "Selecciona la persona o empresa registrante para tu
            dominio" | translate
            }}
          </div>

          <!-- Registrant section-->
          <section id="titularComponent">
            <app-contact-selection (onOpenFormClicked)="onShowContactFormClick($event, true, 0)"
              (onSelectedContactChange)="onSelectRegistrantContact($event, false)" [contactId]="registrantContactId"
              [contacts]="contacts"></app-contact-selection>
          </section>
        </div>
      </div>

      <!-- Has closed domains -->
      <section *ngIf="hasOpenDomains">
        <div class="col-md-12">
          <div class="subtitle-primary-regular p-3">
            {{
            "Por defecto los Datos del Registrante y Contactos no son
            públicos para los gTLDs (.COM, .NET, .LAT, .AUTOS, .LOL, .MOM y .XYZ) en el servicio de Directorio de
            Registro de
            Nombres de Dominio (RDDs). Posteriormente podrás hacer públicos estos datos para el Registrante con el
            Proceso de Autenticación y para los Contactos mediante el Panel de Control." | translate
            }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="subtitle-primary-regular p-3">
            {{
            "Si deseas hacer públicos los datos del registrante (Titular) y Contactos, indica tu consentimiento a
            continuación:" | translate
            }}
          </div>
        </div>

        <div class="col-md-12 gray fs-x p-2 px-3 pb-3">
          <div class="form-check">
            <div class="col">
              <input (change)="onAllowShareRegistrant($event.target)"
                [attr.checked]="allowShareRegistrantInfo ? true : null" id="allowShareRegistrantInfo"
                name="allowShareRegistrantInfo" class="form-check-input checkbox-accepted" type="checkbox"
                autocomplete="off" aria-hidden="true" aria-checked="false">
            </div>
            <div class="col-md-11 d-flex ms-2">
              <label class="form-check-label ui-chkbox-label green-hover font-weight-normal fs-md clickable"
                for="allowShareRegistrantInfo">
                {{ "Autorizo que se publique la información del Registrante en el RDDS." | translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-12 gray fs-x p-2 px-3 pb-3">
          <div class="form-check">
            <div class="col">
              <input (change)="onAllowShareContact($event.target)" [attr.checked]="allowShareContactInfo ? true : null"
                id="allowShareContactInfo" name="allowShareContactInfo" class="form-check-input checkbox-accepted"
                type="checkbox" autocomplete="off" aria-hidden="true" aria-checked="false">
            </div>
            <div class="col-md-11 d-flex ms-2">
              <label class="form-check-label ui-chkbox-label green-hover font-weight-normal fs-md clickable"
                for="allowShareContactInfo">
                {{ "Autorizo que se publique la información de los Contactos en el RDDS." | translate }}
              </label>
            </div>
          </div>
        </div>
      </section>

      <!-- Domain contacts -->
      <section id="sectionDomainContactsAccordion" class="my-3">
        <div class="border-card p-3 my-3 my-lg-0">
          <div class="card-body fs-m p-0">
            <div class="col-12 fs-m subtitle-primary pb-4 text-uppercase">
              {{ "Contactos de dominio" | translate }}
            </div>
            <section id="domainsContactComponent">
              <div class="d-flex align-items-center pb-3">
                <div class="msb fs-m font-weight-bold">
                  {{
                  "Colocar los mismos datos del Registrante para los datos del
                  Contacto Administrativo, Técnico y de Pago." | translate
                  }}
                </div>
                <div class="flex-shrink-1 msb align-self-center fs-l ps-4 pe-2 text-end d-flex">
                  <app-input-toggler parentId="same-contact-domain" [value]="isContactsDomainChecked"
                    (onValueChanges)="onCheckDomainContacts($event)"></app-input-toggler>
                </div>
              </div>
              <div class="row" *ngIf="!isContactsDomainChecked">
                <div class="col-md-12 subtitle-primary-regular pb-4">
                  {{
                  "En la administración de los Nombres de Dominio existen 3 tipos de contacto, dependiendo del TLD
                  (Top Level Domain) tienen responsabilidades específicas contenidas en las " | translate
                  }} <a
                    href="https://www.akky.mx/acerca/politicas-de-servicios?_ga=2.232542617.785140637.1650401724-335518686.1639770856"
                    target="_blank" class="policies-ref">{{ "Políticas de Nombre de Dominio Akky." | translate }}</a>
                </div>

                <!-- TABS CONTENT -->
                <ul class="nav nav-tabs setting-tab no-wrap p-3 fs-md" id="myTab" role="tablist">
                  <li class="nav-item li-40 d-contents" role="presentation">
                    <button class="btn-tab text-uppercase active" id="admin-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#admin-contact" type="button" role="tab" aria-controls="admin-contact"
                      aria-selected="true">
                      {{ "Contacto administrativo" | translate }}
                    </button>
                  </li>
                  <li class="nav-item li-40 d-contents" role="presentation">
                    <button class="btn-tab text-uppercase" id="tech-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#tech-contact" type="button" role="tab" aria-controls="tech-contact"
                      aria-selected="false">
                      {{ "Contacto técnico" | translate }}
                    </button>
                  </li>
                  <li class="nav-item li-40 d-contents" role="presentation">
                    <button class="btn-tab text-uppercase" id="billing-contact-tab" data-bs-toggle="tab"
                      data-bs-target="#billing-contact" type="button" role="tab" aria-controls="billing-contact"
                      aria-selected="false">
                      {{ "Contacto de pago" | translate }}
                    </button>
                  </li>
                </ul>

                <!-- Tab LINKS -->
                <div class="tab-content">
                  <div class="no-border-bottom tab-pane active" id="admin-contact" role="tabpanel"
                    aria-labelledby="home-tab">
                    <section id="administrativeComponent" class="p-3">
                      <app-contact-selection (onOpenFormClicked)="onShowContactFormClick($event, false, 1)"
                        (onSelectedContactChange)="onSelectAdministrativeContact($event, false)"
                        [contactId]="administrativeContactId" [contacts]="contacts"></app-contact-selection>
                    </section>
                  </div>
                  <div class="no-border-bottom tab-pane" id="tech-contact" role="tabpanel"
                    aria-labelledby="profile-tab">
                    <section id="technicalComponent" class="p-3">
                      <app-contact-selection (onOpenFormClicked)="onShowContactFormClick($event, false, 2)"
                        (onSelectedContactChange)="onSelectTechnicalContact($event, false)"
                        [contactId]="technicalContactId" [contacts]="contacts"></app-contact-selection>
                    </section>
                  </div>
                  <div class="no-border-bottom tab-pane" id="billing-contact" role="tabpanel"
                    aria-labelledby="messages-tab">
                    <section id="paymentComponent" class="p-3">
                      <app-contact-selection (onOpenFormClicked)="onShowContactFormClick($event, false, 3)"
                        (onSelectedContactChange)="onSelectBillingContact($event, false)" [contactId]="billingContactId"
                        [contacts]="contacts"></app-contact-selection>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

      </section>

      <!-- MobileCoupon -->
      <section id="coupon-registration-data-container" class="my-3">
        <div class="border-card d-lg-none py-3">
          <app-coupon-input [couponToApply]="currentCoupon"></app-coupon-input>
        </div>
      </section>

      <!-- Terms and conditions -->
      <section id="sectionAceptStep2">
        <div class="col-md-12 gray fs-x p-2 px-3 pb-3">
          <div class="form-check">
            <div class="col">
              <input [(ngModel)]="areTermsChecked" id="understandConditions" name="understandConditions"
                class="form-check-input checkbox-accepted" type="checkbox" autocomplete="off" aria-hidden="true"
                aria-checked="false">
            </div>
            <div class="col-md-11 d-flex ms-2">
              <label class="form-check-label ui-chkbox-label green-hover font-weight-normal fs-md clickable"
                for="understandConditions">
                {{
                "Entiendo la importancia de los datos relacionados al Registrante (Titular) y los Contactos, mismos
                que considero completos y correctos." | translate
                }}
              </label>
            </div>
          </div>
        </div>
      </section>

      <!-- Next Button -->
      <section id="sectionMobileFooter" class="d-lg-none">
        <div class="w-100 text-center p-2">
          <button (click)="onContinueClick()" id="continueWithPaymentForms" [disabled]="!isEnabledButtonContinue"
            class="btn btn-primary w-100 text-uppercase">
            {{ "Continuar" | translate }}
          </button>
        </div>
      </section>

      <!-- BACK -->
      <ng-container *ngTemplateOutlet="backButtonTemplate;"></ng-container>
      <app-secure-purchase-message [desktop]="false"></app-secure-purchase-message>
    </div>

    <!-- SUMMARY -->
    <div class="d-none d-lg-block col-12 col-lg-5 col-xl-4">
      <app-purchase-summary [button]="summaryBtn" [openServices]="true"
        (nextStep)="onContinueClick()" [buttonEnabled]="isEnabledButtonContinue"></app-purchase-summary>
      <app-secure-purchase-message></app-secure-purchase-message>
    </div>
  </div>
</div>

<!-- Contact data form -->
<app-dialog [enableCloseDialogButton]="false" [show]="showContactForm" [preventCloseFromBackDrop]="true">
  <ng-container *ngIf="showContactForm">
    <app-contact-form [showPersonTypeInformation]="isShowPersonTypeOptions" (onCancel)="showContactForm = false"
      (onSave)="onContactsUpdated($event)" [contactId]="selectedContactId" [isRegistrantTab]="activeContactTab"
      [contacts]="contacts"></app-contact-form>
  </ng-container>
</app-dialog>

<!-- Back button template-->
<ng-template #backButtonTemplate>
  <button class="btn btn-secondary black my-4" [class.w-100]="isMobile" (click)="onBackClicked()">
    <div class="d-flex align-items-center justify-content-center w-100">
      <span class="i-arrow-left me-2"></span>
      {{ 'Regresar' | translate }}
    </div>
  </button>
</ng-template>
