<div id="body-bg" class="p-0 m-0 d-flex flex-column">
  <div class="navbar navbar-background w-100 d-flex">
    <img loading="lazy" [src]="'/logo_akky_red_white.svg' | blobstorage"
         data-src="{{ '/logo_akky_red_white.svg' | blobstorage}}" title="Akky" width="100" alt="Akky">
  </div>
  <div class="container p-0 px-sm-3 px-md-3 px-lg-5 mt-4 d-flex justify-content-center align-items-center">
    <div class="col-12 px-4 py-0 m-0 d-flex flex-column text-center">
      <div class="p-0 my-4 mb-2 d-flex justify-content-center">
        <img loading="lazy" [src]="'/icons/Colores/circle-cross-purple.svg' | blobstorage"
             data-src="{{ '/icons/Colores/circle-cross-purple.svg' | blobstorage}}" width="110" height="110" title="Akky" alt="cross" class="img-fluid">
      </div>
      <h4 class="my-3 msb font-weight-bold">
        <span class="d-block">{{'Has cancelado pagar' | translate}}</span>
        <span class="d-block">{{'con PayPal' | translate}}</span>
      </h4>

      <div class="msb d-block my-3 fs-l font-weight-bold">
        {{'Cierra esta ventana y elige otro método de pago o intenta nuevamente con PayPal.' | translate}}
      </div>

      <div class="my-3 d-flex d-flex justify-content-center">
        <button class="btn btn-info py-3" (click)="closeWindow()" id="close" name="close" aria-label="" role="button" aria-disabled="false">
          <span class="d-md-block">{{ 'CERRAR VENTANA' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
