import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomainDataConst, EDomainStatus, EDomainType } from "@core-constants/domain-data.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { EGtmEvent } from "@core-constants/gtm-const";
import { EServiceType, EServiceTypeName, ServiceDataConst } from "@core-constants/product-service.const";
import { RegexConst } from "@core-constants/regex.const";
import { CartDataConst, ECartItemOperation, ECartItemStatus } from "@core-constants/shopping-cart.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { DomainDataService } from "@core-data-services/domains.data-service";
import { ShoppingCartDataService } from "@core-data-services/shopping-cart.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { DomainServiceItemPlus, GroupedShoppingCart, ICoverageOption, ICoverageRate, IItemProductLite, ShoppingCarBaseContent, ShoppingCartPlusItem, ShoppingCartUpdatedItemResponse, ShoppingCartUpdatedQuantityGroupItemResponse } from "@core-models/shopping-cart-plus.model";
import { UpdateDomainService } from '@core-models/update-cart.model';
import { DropDownItem } from "@shared-base/generic-clases.base";
import { GtmTrackingService } from "@shared-services/gtm-tracking.service";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";
import { finalize } from "rxjs";
import { CloseDomainRequirementTemplate } from "./templates/close-domain-requirement-template";

@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ["./shopping-cart-item.component.css"]
})
export class ShoppingCartItemComponent implements OnInit
{

  private _item: GroupedShoppingCart;
  private _index: number = -1;

  private _itemInMemory: GroupedShoppingCart;

  public readonly emailOXCapacityOptions: DropDownItem[] = ServiceDataConst.CapacityEmailOXOptionsGB;

  public disableQuantityGroupButtons: boolean = false; //this is not for Addons
  public disabledCapacityButtons: boolean = false;//this is only for Addons
  public disableCoverageDropdown: boolean = false;
  public showRequirementModal: boolean = false;
  public isPersonalizedQuantity: boolean = false;
  public showDeleteItemModal: boolean = false;
  public isDomainNameEditing: boolean = false;

  public requirementHTML: string = "";
  public domainToSearch: string = "";

  public confirmDomainNameEnabled: boolean = false;

  @Output() public onItemChanges = new EventEmitter<boolean>();

  constructor(protected shoppingCartDataService: ShoppingCartDataService,
    protected translateService: TranslateService,
    protected domainDataService: DomainDataService,
    protected toast: ToastService,
    protected tokenManager: TokenManager,
    protected checkoutManager: CheckoutManager) { }

  public ngOnInit(): void
  {
    this.isDomainNameEditing = this.isEmtpyDomainName;
  }

  public get index(): number
  {
    return this._index;
  }

  @Input() public set index(value: number)
  {
    this._index = value;
    this.setEmailOXSelectedCapacity();
  }

  @Input() public set item(value: GroupedShoppingCart)
  {
    this._item = value;
    this._itemInMemory = this.deepCopy(value);

    const isUpgrade = value.product.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
    if( isUpgrade )
    {
      const suggestion = value.product.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
      this.updateVisualItem(suggestion);
    }
  }

  public get item(): GroupedShoppingCart
  {
    return this._item;
  }

  public get itemProduct(): ShoppingCartPlusItem
  {
    return this._item.product;
  }

  public get itemProductInMemory(): ShoppingCartPlusItem
  {
    return this._itemInMemory.product;
  }

  public get itemProductSuggestionLite() : IItemProductLite
  {
    const obj = {
      id: this.itemProductInMemory.id,
      fullName: this.itemProductInMemory.fullName,
      rate: this.itemProductInMemory.rate,
      coverageOptions: this.itemProductInMemory.coverageOptions,
      finalAmount: this.itemProductInMemory.rate.finalAmount,
      coverageId: this.itemProductInMemory.coverageId,
      quantity: this.itemProductInMemory.quantity,
      itemCounter: this.item.counter
    };
    return { ...obj };
  }

  public get total(): number
  {
    return this.checkoutManager.total;
  }

  public get hasCouponToApply(): boolean
  {
    return this.checkoutManager.hasCouponToApply;
  }

  public get isRemovable(): boolean
  {
    return this.itemProduct.isRemovable === true;
  }

  public get isDomain(): boolean
  {
    return this.itemProduct.type === EServiceTypeName.Domain;
  }

  public get isRenewal(): boolean
  {
    return this.itemProduct.operationId == ECartItemOperation.Renewal;
  }

  public get features(): string[]
  {
    return this.itemProduct.featureDetail.features;
  }

  public get suggestions(): IShoppingCartIndividualStoredSuggestionPlus[]
  {
    return this.itemProduct.suggestions;
  }

  public get hasVariousServices(): boolean
  {
    return this.itemProduct.variousServices && this.itemProduct.variousServices.length > 0;
  }

  public get hasDomainServices(): boolean
  {
    return this.isDomain && this.itemProduct.domainServices && this.itemProduct.domainServices.length > 0 && this.itemProduct.operationId != ECartItemOperation.Recovery && this.itemProduct.operationId != ECartItemOperation.RecoveryDeleted && this.itemProduct.description != EDomainStatus.suspended && (!this._isCloseDomain || this.itemProduct.operationId == ECartItemOperation.Renewal);
  }

  public get isEmtpyDomainName(): boolean
  {
    return this.isDomain && this.itemProduct.name === '';
  }

  public get selectedQuantity(): number
  {
    return this.isPersonalizedQuantity ? -1 : this.itemProduct.quantity;
  }

  public get isUserServiceRegistration(): boolean
  {
    return this.itemProduct.type == EServiceTypeName.Service && this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  private get _isGobEduCloseDomain(): boolean
  {
    return this.itemProduct.domainType == EDomainType.gob_mx || this.itemProduct.domainType == EDomainType.edu_mx;
  }

  public get isNotChargableDomain(): boolean
  {
    return this.isDomain && this.itemProduct.isNotChargeableDomain && !this._isGobEduCloseDomain;
  }

  private get _isAddon(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Addon;
  }

  private get _isSynchronization(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.Service && this.itemProduct.synchronization === true;
  }

  private get _isCoverageExtension(): boolean
  {
    return this.itemProduct.synchronization || this.itemProduct.operationId === ECartItemOperation.Transfer;
  }

  public get isValidDomainNameEdit(): boolean
  {
    return this.isDomain && !this.isDomainNameEditing &&
      this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  public get isDomainRenewalDeleted(): boolean
  {
    return this.isDomain && this.itemProduct.isNotChargeableDomain && this.itemProduct.synchronization;
  }

  // ********************************************************************
  //#region Flags
  // ********************************************************************


  public hasValidFeatures(): boolean
  {
    return !Tools.isNullOrEmpty(this.itemProduct.featureDetail?.features);
  }

  public hasSuggestions(): boolean
  {
    return !Tools.isNullOrEmpty(this.itemProduct.suggestions);
  }

  public isValidCoverage(): boolean
  {
    return this.itemProduct.coverageOptions.find(x => x.id != 0) != undefined;
  }

  public showCoverageDropdown(): boolean
  {
    if (this.itemProduct.coverageOptions?.length > 0 && !this._isGobEduCloseDomain)
    {
      const coverageId = this.itemProduct.coverageId;
      const selectedCoverage: number = this.itemProduct.coverageOptions.find((x: ICoverageOption) => x.id == coverageId)?.value;

      return this._isSynchronization ? true : selectedCoverage > 0 ? true : selectedCoverage == -1;
    }

    return false;
  }

  private get _isCloseDomain(): boolean
  {
    return this.isDomain && DomainDataConst.CloseDomains.includes(this.itemProduct.domainType) === true;
  }

  public isDomainOrServiceUserDiverseService(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.DiverseService && (this.itemProduct.serviceData?.domainId != null && this.itemProduct.serviceData?.domainId > 0 || this.itemProduct.serviceData?.diverseServiceId != null && this.itemProduct.serviceData?.diverseServiceId > 0);
  }

  public get showRequirementsLabel(): boolean
  {
    return this.isDomain && this._isCloseDomain && this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  public showDomainServicesAccordion(): boolean
  {
    return this.isDomain && !Tools.isNullOrEmpty(this.itemProduct.domainServices) && (this._isCloseDomain == false || this.itemProduct.operationId == ECartItemOperation.Renewal) && this.itemProduct.operationId != ECartItemOperation.Recovery && this.itemProduct.operationId != ECartItemOperation.RecoveryDeleted && this.itemProduct.description != EDomainStatus.suspended;
  }

  public get isAddonCumulative(): boolean
  {
    return this._isAddon && this.itemProduct.isAccumulable && (this.itemProduct.operationId == ECartItemOperation.Renewal || this.itemProduct.operationId == ECartItemOperation.Registration);
  }

  // #endregion

  public getOperationTypeName(): string
  {
    const operationId: ECartItemOperation = this.itemProduct.operationId;
    return CartDataConst.CartItemOperationStep1Labels.get(operationId) + " ";
  }

  public getProductName(): string
  {
    if (this.isAddonCumulative)
    {
      return `${this.itemProduct.fullName} (x ${this.itemProduct.quantity})`;
    }

    if (this.isDomain)
    {
      return this.isEmtpyDomainName || this.isDomainNameEditing ? "" : this.itemProduct.fullName;

    }

    if (this.itemProduct.name)
    {
      return this.itemProduct.fullName;
    }

    return '';
  }

  public isDomainDiverseService(): boolean
  {
    return this.itemProduct.serviceType == EServiceType.DiverseService && this.itemProduct.serviceData?.domainId != null && this.itemProduct.serviceData?.domainId > 0;
  }

  public hasOffer(rate: ICoverageRate): boolean
  {
    return rate.offerAmount && rate.offerAmount > 0;
  }
  // #endregion

  // ********************************************************************
  //#region Remove Items
  // ********************************************************************

  public onRemoveCartItemClick(): void
  {
    this.shoppingCartDataService.removeItem(this.itemProduct.id, this.itemProduct.serviceType).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        this.checkoutManager.removeCartItem(this.itemProduct.id);
        this.checkoutManager.initializeCartBaseData(response);
        this.showDeleteItemModal = false;
        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [this.item], this.itemProduct.rate.finalAmount);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  public removeDomainRenewaCartItem(): void
  {
    this.shoppingCartDataService.removeDomainRenewal(this.itemProduct.name, this.itemProduct.domainType).subscribe({
      next: () =>
      {
        this.checkoutManager.removeDomainRenewalCartItem(this.itemProduct.id);

        this.resetDomainServicesCoverages();

        const renewalServices: number[] = this.itemProduct.domainServices.filter((x: DomainServiceItemPlus) => x.operationId == ECartItemOperation.Renewal || x.maxCoverage == 0).map((x: DomainServiceItemPlus) => x.id);

        if (!Tools.isNullOrEmpty(renewalServices))
        {
          this.checkoutManager.removeDomainServices(this.itemProduct.id, renewalServices);
        }

        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
        GtmTrackingService.removeFromCartEvent(EGtmEvent.RemoveFromCart, [this.item], this.itemProduct.rate.finalAmount);
      },
      complete: () =>
      {
        this.showDeleteItemModal = false;
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  public onRemoveItemQuantityGroupClick(quantityGroup: number): void
  {
    this.onItemChanges.emit(true);

    this.shoppingCartDataService.deleteServiceByQuantityGroup(quantityGroup).subscribe({
      next: (response: ShoppingCarBaseContent) =>
      {
        this.checkoutManager.removeCartItemQuantityGroup(quantityGroup);
        this.checkoutManager.initializeCartBaseData(response);
        this.toast.setSuccessToast(SuccessMessageConst.ItemDeletedSuccessfully);
      },
      complete: () =>
      {
        this.onItemChanges.emit(false);
      },
      error: () =>
      {
        this.toast.setErrorToast(ErrorMessageConst.ErrorDeletingItem);
      }
    });
  }

  // #endregion

  // ********************************************************************
  //#region Domain services
  // ********************************************************************

  private searchDomainName(domainToSearch: string): void
  {
    if (domainToSearch)
    {
      this.onItemChanges.emit(true);
      this.domainDataService.search(domainToSearch).subscribe({
        next: () =>
        {
          this.confirmDomainNameEnabled = true;
          this.itemProduct.name = domainToSearch.split('.')[0];
          this.toast.setSuccessToast("está disponible", domainToSearch);
        },
        complete: () =>
        {
          this.onItemChanges.emit(false);
        },
        error: (response: HttpErrorResponse) =>
        {
          this.resetSearchDoman();
          this.toast.setErrorToast(response.error[0].error.message);
        }
      });
    }
  }

  public onSearchDomainClicked(): void
  {
    this.confirmDomainNameEnabled = false;

    this.domainToSearch = this.domainToSearch.replace(/ /g, '');

    if (this.domainToSearch === '')
    {
      this.toast.setErrorToast(ErrorMessageConst.InvalidDomainName);
      return;
    }

    let domainToSearch: string = this.domainToSearch.replace(RegexConst.Domain, '').toLocaleLowerCase();
    let tldvalid: string = this.itemProduct.domainType;

    const dotPosition: number = domainToSearch.indexOf('.');

    if (dotPosition == -1)
    {
      domainToSearch += this.itemProduct.domainType;
    }
    else
    {
      tldvalid = domainToSearch.substring(dotPosition);
    }

    if (this.itemProduct.domainType == tldvalid)
    {
      if (!this.checkoutManager.cartItems.some(x => x.fullName == domainToSearch))
      {
        this.searchDomainName(domainToSearch);
      }
      else
      {
        this.resetSearchDoman();
      }
    }
    else
    {
      this.resetSearchDoman();
      this.toast.setWarningToast("El dominio debe ser:", "", this.itemProduct.domainType);
    }
  }

  public resetSearchDoman(): void
  {
    this.confirmDomainNameEnabled = false;
    this.domainToSearch = "";
    this.itemProduct.name = "";
  }

  public validDomainFullName(): boolean
  {
    return this.itemProduct.fullName == this.itemProduct.domainType && this.itemProduct.serviceType == EServiceType.Domain && this.itemProduct.operationId == ECartItemOperation.Registration;
  }

  public cancelChangeDomainName(): void
  {
    const cartItemCache = this.checkoutManager.cartItems.find(x => x.id == this.itemProduct.id);

    this.itemProduct.fullName = cartItemCache.fullName;
    this.itemProduct.name = cartItemCache.name;
    this.isDomainNameEditing = false;
    this.onItemChanges.emit(false);
  }

  public onConfirmSearchedDomain(): void
  {
    if (!this.checkoutManager.cartItems.some(x => x.fullName == (this.itemProduct.name + this.itemProduct.domainType)))
    {
      this.shoppingCartDataService.updateDomainName(this.itemProduct.id, this.itemProduct.serviceType, this.itemProduct.name).subscribe({
        next: () =>
        {
          this.itemProduct.fullName = this.itemProduct.name + this.itemProduct.domainType;
          this.toast.setSuccessToast(SuccessMessageConst.ItemAddedSuccessfully);
          this.checkoutManager.setCartDomainName(this.itemProduct.id, this.itemProduct.name);
        }
      });
    }
    else
    {
      const domainConfirm = <HTMLButtonElement>document.getElementById("confirm" + this.index);
      domainConfirm.disabled = true;
      this.itemProduct.name = "";
      this.toast.setErrorToast(ErrorMessageConst.ExistingDomainItem);
    }
  }

  public onEditDomainNameclicked(): void
  {
    this.itemProduct.name = "";
    this.itemProduct.fullName = this.itemProduct.domainType;
    this.isDomainNameEditing = true;
    this.onItemChanges.emit(true);
  }

  private resetDomainServicesCoverages(): void
  {
    const domainServices: UpdateDomainService[] = this.itemProduct.domainServices.map(x =>
    {
      const dtoItem = {
        id: x.id,
        coverageId: x.coverageId = x.coverageOptions[0].id
      };
      return dtoItem;
    });

    this.updateDomainServicesCoverages(domainServices);
  }

  private fixDomainServicesCoverages(domainCoverage: number): void
  {
    if (this.itemProduct.domainServices.find(x => x.isAdded) != undefined) // at least one added
    {
      const maxMonths = this.itemProduct.coverageOptions.find(x => x.id == domainCoverage).value;

      const domainServices: UpdateDomainService[] = this.itemProduct.domainServices
        .map(x =>
        {
          const currentCoverage = x.coverageOptions.find(c => c.id == x.coverageId).value;
          const dtoItem = new UpdateDomainService();

          dtoItem.id = x.id;
          dtoItem.coverageId = x.coverageId;

          if (currentCoverage > maxMonths)
          {
            dtoItem.coverageId = x.coverageOptions.filter(c => c.value <= maxMonths).pop().id;
            return dtoItem;
          }
        }, []).filter(x => x != undefined);

      if (!Tools.isNullOrEmpty(domainServices))
      {
        this.updateDomainServicesCoverages(domainServices);
      }
    }
  }

  private updateDomainServicesCoverages(domainServices: UpdateDomainService[]): void
  {
    this.shoppingCartDataService.updateDomainServices(this.itemProduct.id, domainServices)
      .subscribe({
        next: () =>
        {
          this.checkoutManager.setDomainServicesCoverage(this.itemProduct.id, domainServices);
        }
      });
  }
  // #endregion

  // ********************************************************************
  //#region Labels and names
  // ********************************************************************

  public getCoverageLabel(): string
  {
    return this._isCoverageExtension ? 'EXT. DE COBERTURA' : 'COBERTURA';
  }

  public getOperationName(): string
  {
    return CartDataConst.CartItemOperationStep1Labels.get(this.itemProduct.operationId as ECartItemOperation);
  }

  public getObjectId(): string
  {
    if (this.isRenewal && (this._isAddon || this._isSynchronization))
    {
      return "ID: " + this.itemProduct.objectId;
    }

    return "";
  }

  public getAddonObjectId(): string
  {
    if (this._isAddon)
    {
      if (ServiceDataConst.OxAddOn.includes(this.itemProduct.serviceData.addonType))
      {
        return this.translateService.getElement("Buzón") + ": " + this.itemProduct.serviceData.serviceId;
      }

      return this.translateService.getElement("Servicio") + " Id: " + this.itemProduct.serviceData.serviceId;
    }

    return "";
  }

  public getDiverseServiceName(): string
  {
    const item = this.itemProduct;

    if (item.serviceData?.diverseServiceId != null && item.serviceData?.diverseServiceId > 0)
    {
      return "ID: " + item.serviceData?.diverseServiceId;
    }
    else
    {
      return item.name;
    }
  }

  public getDisplayMessageRate0(): string
  {
    return this._isCloseDomain ? "Pendiente documentación" : this.isAddonCumulative == false ? "Sin costo" : "";
  }

  public showMessageRate0(): boolean
  {
    return this.itemProduct.rate.finalAmount == 0 && (this._isCloseDomain && this.itemProduct.operationId != ECartItemOperation.Renewal);
  }

  public coverageByItem(): number
  {
    return this.itemProduct.coverageOptions?.find(x => x.id == this.itemProduct.coverageId)?.value;
  }

  public calculateTotalByProduct(): number
  {
    return this.checkoutManager.calculateTotalByProduct(this.itemProduct);
  }

  // #endregion

  // ********************************************************************
  //#region Events
  // ********************************************************************

  private setEmailOXSelectedCapacity(): void
  {
    const capacity = this.emailOXCapacityOptions.find(x => x.value === this.itemProduct.quantity);

    this.isPersonalizedQuantity = capacity == undefined;
  }

  public onAddonCapacitySelectedChanges($event): void
  {
    const quantitySelected = $event?.value;

    this.isPersonalizedQuantity = quantitySelected == -1;

    if (this.isPersonalizedQuantity == false)
    {
      this.updateAddonQuantity(quantitySelected);
    }
  }

  public onAddonItemValueChanges($event: any): void
  {
    this.isPersonalizedQuantity = true;

    const quantitySelected = $event;
    const isMatch: boolean = quantitySelected.match(RegexConst.Numeric) !== null;

    if (quantitySelected > 0 && quantitySelected < 9999 && isMatch)
    {
      this.updateAddonQuantity(quantitySelected);
    }
  }

  private updateAddonQuantity(quantitySelected: number): void
  {
    this.disabledCapacityButtons = true;
    this.onItemChanges.emit(true);

    this.shoppingCartDataService.updateAddonQuantity(this.itemProduct.id, this.itemProduct.serviceType, quantitySelected)
      .subscribe({
        next: () =>
        {
          this.checkoutManager.setCartItemAddonQuantity(this.itemProduct.id, quantitySelected);
        },
        complete: () =>
        {
          this.disabledCapacityButtons = false;
          this.onItemChanges.emit(false);
        }
      });
  }

  public onUpdateServiceByQuantityGroup(quantity: number): void
  {
    this.onItemChanges.emit(true);
    this.disableQuantityGroupButtons = true;

    const lastCounterValue = this.item.counter;
    const quantityGroup = this.item.quantityGroup;

    this.isUpgradeItemSelected
      ? this.handleUpdateServiceByQuantityGroupUpgrade(quantity, lastCounterValue)
      : this.handleUpdateServiceByQuantityGroup(quantity, quantityGroup);
  }

  private handleUpdateServiceByQuantityGroup(quantity: number, quantityGroup: number): void
  {
     this.shoppingCartDataService.updateServiceByQuantityGroup(quantityGroup, this.itemProduct.coverageId, quantity)
     .pipe(finalize(() =>
      {
        this.onItemChanges.emit(false);
        this.disableQuantityGroupButtons = false;
      }))
      .subscribe({
        next: (response: ShoppingCartUpdatedQuantityGroupItemResponse) =>
        {
          if(response)
          {
            this.checkoutManager.initializeCartBaseData(response.baseCart);
            this.checkoutManager.setQuantityGroupCounter(quantityGroup, response.addedItems, response.deletedItems);
          }
        },
        error: () =>
        {
          const message = this.translateService.getElement(ErrorMessageConst.OperationFailed);
          this.toast.setErrorToast(message);
        }
      });
  }

  private handleUpdateServiceByQuantityGroupUpgrade(quantity: number, lastCounterValue: number): void
  {
    const suggestionId = this.itemProduct.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)?.suggestionId;

    this.shoppingCartDataService.updateSuggestionUpgradeQuantity(this.itemProduct.id, suggestionId, quantity)
    .pipe(finalize(() =>
      {
        this.disableQuantityGroupButtons = false;
        this.onItemChanges.emit(false);
      }))
      .subscribe({
        next: (value: boolean) =>
        {
          if(!value)
          {
            this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, lastCounterValue);
            this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
          }

          this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, quantity);
        },
        error: () =>
        {
          this.checkoutManager.setCartItemUpgradeQuantity(this.itemProduct.id, suggestionId, lastCounterValue);
          this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
        }
      });
  }

  // #endregion

  // ********************************************************************
  //#region Events
  // ********************************************************************

  public onCloseDomainRequirementModalClick(): void
  {
    const domainServiceTemplate = new CloseDomainRequirementTemplate(this.translateService);

    this.requirementHTML = domainServiceTemplate.CloseDomainRequirementMessage.get(this.itemProduct.domainType);

    if (this.requirementHTML != undefined)
    {
      this.showRequirementModal = true;
    }
  }

  private get isUpgradeItemSelected(): boolean
  {
    return this.itemProduct.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded);
  }

  public onItemCoverageChange($event: DropDownItem): void
  {
    const coverage = $event?.value;
    if(typeof coverage !== "number") { return; }

    this.disableCoverageDropdown = true;

     this.isUpgradeItemSelected
      ? this.handleCoverageUpgradeItem(coverage)
      : this.handleCoverageMainItem(coverage);
  }

  private handleCoverageMainItem(coverageId: number): void
  {
      this.onItemChanges.emit(this._isAddon);

      this.shoppingCartDataService.updateCoverage(this.itemProduct.id, this.itemProduct.serviceType, coverageId)
       .pipe(finalize(() =>
       {
         this.disableCoverageDropdown = false;
         this.onItemChanges.emit(false);
       }))
      .subscribe({
        next: (response: ShoppingCartUpdatedItemResponse) =>
        {
          if (this._isAddon)
          {
            this.checkoutManager.setItem(response.item);
          }
          else
          {
            this.checkoutManager.setCartItemCoverage(this.itemProduct.id, coverageId);
          }

          if (this.isDomain)
          {
             this.fixDomainServicesCoverages(coverageId);
          }
        }
      });
  }

  private handleCoverageUpgradeItem(coverageId: number): void
  {
    const suggestionId = this.itemProduct.suggestions.find(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)?.suggestionId;

    this.shoppingCartDataService.updateSuggestionUpgradeCoverage(this.itemProduct.id, suggestionId, coverageId)
    .pipe(finalize(() => this.disableCoverageDropdown = false))
    .subscribe({
      next: (value: boolean) =>
      {
        if(!value) { return; }

        this.checkoutManager.setCartItemUpgradeCoverage(this.itemProduct.id, suggestionId, coverageId);
      },
      error: () => this.toast.setErrorToast(ErrorMessageConst.OperationFailed)
    });
  }


  public onDeleteIconClick(): void
  {
    this.showDeleteItemModal = false;

    if (this.isRemovable)
    {
      if (this.itemProduct.serviceType == EServiceType.Domain)
      {
        if (this.itemProduct.status == ECartItemStatus.Renewal && this.isValidCoverage() && !this.itemProduct.isNotChargeableDomain && this.itemProduct.variousServices.length == 0)
        {
          this.removeDomainRenewaCartItem();
        }
        else
        {
          this.showDeleteItemModal = true;
        }
      }
      else if ((this.itemProduct.type == EServiceTypeName.Service) && (this.itemProduct.operationId == ECartItemOperation.Registration))
      {
        this.onRemoveItemQuantityGroupClick(this.item.quantityGroup);
      }
      else
      {
        this.onRemoveCartItemClick();
      }
    }
  }

  public addDomainRenewal(): void
  {
    this.shoppingCartDataService.addDomainRenewal(this.itemProduct.name, this.itemProduct.domainType, this.itemProduct.coverageId).subscribe({
      next: () =>
      {
        this.checkoutManager.addDomainRenewal(this.itemProduct.id);
        this.toast.setSuccessToast(SuccessMessageConst.DomainAddedSuccessfully);
      },
      error: (response: HttpErrorResponse) =>
      {
        this.errorMessage(response);
      }
    });
  }

  public onCloseConfirmationModalClick(): void
  {
    this.showDeleteItemModal = false;
  }

  public errorMessage(response: HttpErrorResponse): void
  {
    if (response.status === 500 && response?.error?.message)
    {
      this.toast.setErrorToast(response.error.message);
    }
    else
    {
      this.toast.setErrorToast(ErrorMessageConst.OperationFailed);
    }
  }
  // #endregion


  // ********************************************************************
  //#region Upgrade Suggestions
  // ********************************************************************

  public onSuggestionUpgradeItem(event: IShoppingCartIndividualStoredSuggestionPlus | undefined) : void
  {
    this.updateVisualItem(event);
  }

  private updateVisualItem(suggestion: IShoppingCartIndividualStoredSuggestionPlus | undefined): void
  {
    if(!suggestion)
    {
      this._item = this.deepCopy(this._itemInMemory);
      return;
    }
      const { coverage, coverageId } = suggestion;
      const { displayName, features, quantity } = suggestion.individualFeatures;

      const rate = suggestion.coverage.find(x => x.id === coverageId)?.rate;

      const newProduct: ShoppingCartPlusItem = {
          ...this.item.product,
          name: displayName,
          fullName: displayName,
          featureDetail: { imgRoute: '', iconRoute: '', features },
          coverageOptions: [...coverage],
          coverageId,
          rate
      };

      this._item.counter = quantity;

      this._item = { ...this.item, product: newProduct };
  }

  private deepCopy(obj: GroupedShoppingCart): GroupedShoppingCart
  {
    return structuredClone(obj);
  }


  public trackById(index: number, suggestion: IShoppingCartIndividualStoredSuggestionPlus): number
  {
    return suggestion.id;
  }

  // #endregion

}
