<div class="d-flex flex-row content-hover" #toastDiv>
  <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
    [attr.aria-label]="message" class="message-container d-flex">
    <span class="flex-shrink-0 mt-2"
      [ngClass]="{'success' : 'check-aqua', 'error' : 'i-error mt-3', 'warning' : 'i-warn'}[toastType]"></span>
    <div [ngClass]="{'success' : 'custom-message-success', 'error' : 'custom-message-error', 'warning' : 'custom-message-warning'}[toastType]">
      {{ message }}
    </div>
  </div>
  <div class="text-end" *ngIf="options.closeButton">
    <span (click)="remove()" class="toast-close-button">
      <span class="d-inline-block"
        [ngClass]="{'success' : 'times-white', 'error' : 'times-purple', 'warning' : 'times-yellow'}[toastType]">
      </span>
    </span>
  </div>
</div>