<div id="containerInfo:purchaseSummary" class=" w-100 p-0 m-0">
  <div *ngIf="hasCouponToApply && !isCouponEnabled"
    class="bg-green m-0 mb-4 mb-md-3 py-3 fs-m white font-weight-bold d-flex align-items-center justify-content-center">
    {{ 'El cupón se agregará automáticamente' | translate }}
  </div>

  <div id="purchaseSummaryAccordion" role="tablist" data-widget="widget_containerInfo_purchaseSummaryAccordion"
    class="w-100 summary-container">
    <div class="collapsed d-flex px-3 clickable black d-flex p-0 m-0 py-3" [class.accordion-button]="isMobile"
      type="button" [attr.data-bs-toggle]="isMobile ? 'collapse' : ''" data-bs-target="#collapseMobilePurchaseSummary"
      aria-expanded="true" aria-controls="collapseResume">

      <div class="d-flex w-100 align-items-center">
        <b class="flex-grow-1 fs-l">
          {{ "Resumen de compra" | translate}}
        </b>
        <img *ngIf="isEditEnabled" class="clickable me-2 me-lg-0" src="{{ '/icons/icon-pencil.svg' | blobstorage }}"
          loading="lazy" alt="editar" title="{{'Editar' | translate}}" (click)="onEditClicked()">
        <span class="fs-m price text-green me-2 fs-bold d-block d-lg-none">
          {{ total | currency }} MXN
        </span>
      </div>
    </div>

    <div id="collapseMobilePurchaseSummary" class="accordion-collapse bg-accordion-body" [class.collapse]="isMobile"
      aria-labelledby="headingOne" data-bs-parent="#accordionMobilePurchaseSummary">
      <div class="bg-light-gray3">

        <!-- Main Divider -->
        <div class="px-3 my-2">
          <hr class="custom-divider" />
        </div>

        <ng-container *ngIf="showSummaryDetail">
          <!-- ORDER SUMMARY -->
          <section id="summary-container">
            <div class="services-accordion b-none" role="tablist"
              data-widget="widget_containerInfo_purchaseSummaryAccordion">
              <div id="services-counter-label">
                <div class="px-3 py-2 fs-l accordion-button" type="button" data-bs-toggle="collapse"
                  [class.collapsed]="!showServices" data-bs-target="#acordion-items" aria-expanded="true"
                  aria-controls="collapse-summary-item">
                  <b class="text-green"> {{ servicesLabel }}</b>
                </div>
              </div>
              <div id="acordion-items" class="accordion-collapse collapse" [class.show]="showServices"
                aria-labelledby="services-counter-label" data-bs-parent="#accordionResume">
                <ng-container *ngFor="let item of products">
                  <div class="px-3 py-2">
                    <app-purchase-summary-item [item]="item"></app-purchase-summary-item>
                  </div>
                </ng-container>
              </div>
            </div>
          </section>

          <div class="px-3 my-2">
            <hr class="custom-divider" />
          </div>

          <!-- Coupon -->
          <section *ngIf="isCouponEnabled" id="coupon-container" class="p-0 m-0">
            <app-coupon-input [couponToApply]="currentCoupon"></app-coupon-input>
          </section>

          <!-- Subtotal -->
          <section id="vat-container">
            <div class="px-3 my-2 d-flex">
              <div class="flex-grow-1 fs-md fw-bold text-uppercase gray">
                {{ "Subtotal" | translate }}
              </div>
              <span class="fs-m price">
                {{ subtotal | currency }} MXN
              </span>
            </div>
          </section>

          <!-- IVA -->
          <section id="vat-container">
            <div class="px-3 my-2 d-flex">
              <div class="flex-grow-1 fs-md fw-bold text-uppercase gray">
                {{ "IVA" | translate }}
              </div>
              <span class="fs-m price">
                {{ vat | currency }} MXN
              </span>
            </div>
          </section>

          <!-- Saving  -->
          <section *ngIf="isCouponEnabled && isCouponApplied" id="saving-container">
            <div class="px-3 |my-2 d-flex">
              <div class="flex-grow-1 fs-md fw-bold text-uppercase text-green">
                {{ "Ahorro" | translate }}
              </div>
              <span class="fs-m price fw-bold">
                {{ saving | currency }} MXN
              </span>
            </div>
          </section>

          <div class="px-3 my-2">
            <hr class="custom-divider" />
          </div>
        </ng-container>

        <!-- Total -->
        <section id="total-container">
          <div class="px-3 py-3 d-flex">
            <div class="flex-grow-1 fs-l fw-bold text-uppercase black">
              {{ "Total con iva" | translate }}
            </div>
            <span class="fs-l price text-green fw-bold">
              {{ total | currency }} MXN
            </span>
          </div>
        </section>

        <!-- Action button -->
        <section id="action-button-container">
          <div class="px-3 my-3 d-flex d-none d-lg-block">
            <div *ngIf="showSummaryDetail" class="w-100">
              <button id="checkout-continue-button" (click)="onContinueClick()" class="btn btn-primary w-100 text-uppercase"
                [disabled]="showLoadingMask || !isButtonEnabled">
                {{ nameButton | translate }}
              </button>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</div>
