import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { ELogoutType } from '@core-constants/logout-events.const';
import { ECartStep, EStepValidationError, EStepsUrls, StepConst } from '@core-constants/step.const';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { ShoppingCartDataService } from '@core-data-services/shopping-cart.data-service';
import { StepsDataService } from '@core-data-services/steps.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { UTMOperationHelper } from '@core-helpers/utm-operation.route';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { ShoppingCartPlus } from '@core-models/shopping-cart-plus.model';
import { AppClientContext } from '@shared-base/client-context.base';
import { ValidSession } from '@shared-base/generic-clases.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { RouterEventService } from '@shared-services/router-event.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { HttpCartError } from '@shared-utils/http-cart-error.util';
import { Tools } from '@shared-utils/tools.util';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent
{
  private readonly _onInvalidItemsForCouponError : string = "Cupón no valido o no aplicable al carrito actual.";

  public isSiteEnabled: boolean = true;
  public isAValidatedSession: boolean = false;

  public steps: StepConst.MainStep[] = StepConst.StepsArray;

  constructor(private router: Router,
    protected activatedRoute: ActivatedRoute,
    protected utmOperationService: UTMOperationHelper,
    protected tokenManager: TokenManager,
    protected stepsDataService: StepsDataService,
    protected translateService: TranslateService,
    protected userAccessDataService: UserAccessDataService,
    protected routeHelperService: RouteHelper,
    protected checkoutManager: CheckoutManager,
    protected shoppingCartDataService: ShoppingCartDataService,
    protected routerEventService: RouterEventService,
    protected toastService: ToastService,
    private destroyRef$: DestroyRef)
  {
    this.userAccessDataService.getInitialHandshake().subscribe({
      next: (response) =>
      {
        if (response)
        {
          this.registerInitEvents();
        }
      }
    });
  }

  private registerInitEvents(): void
  {
    this.registerEventListeners();
    this.registerRouteEventListeners();
    this.registerShoppingCartStatusValidatorRegister();
    this.registerAkkyTrx();
  }

  public get selectedIndex(): number
  {
    const route = this.router.url;
    return this.tabOptions?.findIndex(x => x.url.startsWith(route)) ?? 0;
  }

  public get tabOptions(): StepConst.MainStep[]
  {
    if (this.checkoutManager.isRegistrationDataStepEnabled)
    {
      return this.steps;
    }
    else
    {
      return this.steps.filter(x => !x.domainValidation);
    }
  }

  public get showLoadingMask(): boolean
  {
    return AppClientContext.Instance.isBusy;
  }

  public get ecommerceURL(): string
  {
    return this.routeHelperService.ecommerceURL;
  }

  public get total(): number
  {
    return this.checkoutManager.total;
  }

  public get saving(): number
  {
    return this.checkoutManager.saving;
  }

  public get isConfirmationStep(): boolean
  {
    return this.selectedIndex == this.tabOptions.length - 1;
  }

  public get enabledCoupon(): boolean
  {
    return this.selectedIndex > 0 && this.selectedIndex < this.tabOptions.length - 1;
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnAddToCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown) =>
      {
        const cartId = event[0][0];
        this.tokenManager.saveCartCookie(cartId);
        this.getAndUpdateCart();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnLanguageChange)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.shoppingCartDataService.getCart();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnValidCart)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown) =>
      {
        const error: string = event[0][0];
        this.onInvalidCartError(error);
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnUserLogout)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown) =>
      {
        const data: ELogoutType = event[0][0];

        if (data === ELogoutType.ExpiredSession || data === ELogoutType.InvalidToken)
        {
          const error = ELogoutType.ExpiredSession ? EStepValidationError.InvalidSession : EStepValidationError.InvalidToken;
          const errorMessage = StepConst.StepValidationErroMessage.get(error);
          this.toastService.setErrorToast(errorMessage);
        }

        this.removeCartInfoOnLogout(data === ELogoutType.UserLogout);
        this.tokenManager.removeAkkyTrxId();
        BroadcastService.Instance.broadcast(EAppEventName.OnUpdateCart, 0);
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnNextStep)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown[]) =>
      {
        let step: ECartStep | number = event[0][0];
        this.goToStepForward(++step);
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnApplyCoupon)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.shoppingCartDataService.getCart();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnPreviousStep)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: (event: unknown[]) =>
      {
        let step: ECartStep | number = event[0][0];

        if (step > 1)
        {
          this.goToStepBackward(--step);
        }
        else
        {
          this.goToEcommerce();
        }
      }
    });
  }

  public registerRouteEventListeners(): void
  {
    this.activatedRoute.queryParams.subscribe({
      next: (params: Params) =>
      {
        this.validateSession();
        this.utmOperationService.buildUTMObject(params);
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        const url = this.router.url.split('?')[0];

        if (url === "/")
        {
          this.validateAkkyTrx();
          this.router.navigate([EStepsUrls.Step1]);
        }

        if (!EStepsUrls.Step4.startsWith(url) || (EStepsUrls.Step1.startsWith(url) && this.isAValidatedSession))
        {
          this.updateCartStepInfo(url);
        }
      }
    });
  }

  public registerShoppingCartStatusValidatorRegister(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnUpdateIndividualSuggestion)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (event: object) =>
        {
          const { isRemoved, currentStep, currentAppliedCoupon } = event[0][0];
          if (currentStep && typeof currentStep === 'number')
          {
            this.onUpdateStepInfo(currentStep);
            this.getAndUpdateCart(isRemoved, currentAppliedCoupon);
          }
        }
      });
  }

  public getAndUpdateCart(isSuggestionRemoved: boolean = false, currentAppliedCoupon: string = undefined): void
  {
    this.shoppingCartDataService.getCartSubscription().subscribe({
      next: (response: ShoppingCartPlus) =>
      {
          this.checkoutManager.initializeData(response);

          const activeUrl = this.router.url.split('?')[0] ?? EStepsUrls.Step1;
          this.updateCartStepInfo(activeUrl);

          if (Tools.isNullOrEmpty(response.couponId) && isSuggestionRemoved)
          {
            this.checkoutManager.cleanSavingAmount();
            this.checkoutManager.setAppliedCoupon(currentAppliedCoupon);
            this.checkoutManager.setCouponError(this._onInvalidItemsForCouponError);
          }
      }
    });
  }

  public updateCartStepInfo(url?: string): void
  {
    url = url ?? this.router.url.split('?')[0];

    const step: StepConst.MainStep = this.steps.find(x => x.url.startsWith(url));

    const token: string = this.tokenManager.getToken();
    const cartId: string = this.tokenManager.getCartCookie();

    if (cartId && token && token != "" && cartId != "")
    {
      this.onUpdateStepInfo(step.number);
    }
  }

  public onUpdateStepInfo(step: ECartStep): void
  {
    this.stepsDataService.updateStepInfo(step).subscribe({
      next: () =>
      {
        this.routerEventService.emitEventCompleted();
      },
      error: (response: HttpErrorResponse) =>
      {
        const error: string = response?.error?.message;
        this.onInvalidCartError(error);
      }
    });
  }

  public onInvalidCartError(error: string): void
  {
    const errorCode = error.toLocaleLowerCase();

    if (StepConst.StepValidationErrosArray.includes(errorCode))
    {
      const url = this.router.url.split('?')[0];

      const httpCartError = new HttpCartError(this.shoppingCartDataService, this.toastService);
      httpCartError.handleCartError(errorCode);

      if (EStepsUrls.Step1.startsWith(url) && this.isAValidatedSession)
      {
        this.updateCartStepInfo(url);
      }

      this.shoppingCartDataService.getCart();
      this.router.navigate([EStepsUrls.Step1]);

    }
    else
    {
      this.toastService.setErrorToast(error);
    }
  }

  public removeCartInfoOnLogout(redirect: boolean = false): void
  {
    this.tokenManager.signOut();

    if (redirect === true)
    {
      this.userAccessDataService.redirectToAkkyOnLogout(this.routeHelperService.shoppingCartWebURL);
    }
  }

  public isClickValid(index: number): boolean
  {
    return index <= this.selectedIndex;
  }

  public goToEcommerce(): void
  {
    window.location.href = this.routeHelperService.ecommerceURL;
  }

  public goToStepBackward(goToStep: ECartStep | number): void
  {
    const url = (!this.checkoutManager.isRegistrationDataStepEnabled && goToStep == ECartStep.RegistrationData) ?
      EStepsUrls.Step1 :
      this.tabOptions.find(x => x.number == goToStep)?.url;

    this.router.navigate([url]);
  }

  public goToStepForward(goToStep: ECartStep | number): void
  {
    const url = (!this.checkoutManager.isRegistrationDataStepEnabled && goToStep == ECartStep.RegistrationData) ?
      EStepsUrls.Step3 :
      this.tabOptions.find(x => x.number == goToStep)?.url;

    this.router.navigate([url]);
  }

  public validateSession(): boolean | void
  {
    const token: string = this.tokenManager.getToken();
    const cartId: string = this.tokenManager.getCartCookie();

    if (token)
    {
      if (!this.isAValidatedSession)
      {
        this.userAccessDataService.validateSession().subscribe({
          next: (response: ValidSession) =>
          {
            this.tokenManager.saveUser(response.userInfoModel);
            this.tokenManager.saveCartCookie(response.cartId);
            this.getAndUpdateCart();
            this.isAValidatedSession = true;
            BroadcastService.Instance.broadcast(EAppEventName.OnLoginSuccess, true);
          },
          error: () =>
          {
            BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.InvalidToken);
            return false;
          }
        });
      }
    }
    else
    {
      if (cartId && cartId != "")
      {
        this.getAndUpdateCart();
      }
      else
      {
        this.removeCartInfoOnLogout();
        return false;
      }
    }
  }

  public validateAkkyTrx(): void
  {
    const akkyTrxId = this.tokenManager.getAkkyTrxId();

    if (akkyTrxId == undefined || akkyTrxId == '')
    {
      this.registerAkkyTrx();
    }
  }

  public registerAkkyTrx(): void
  {
    this.tokenManager.saveAkkyTrxId(this.generateTransactionNumber());
  }

  public generateTransactionNumber(): string
  {
    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 10000);
    return `TRX-${timestamp}-${randomNumber}`;
  }
}
