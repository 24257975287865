import { IOrganizationSize, IOrganizationType } from "@shared-base/generic-clases.base";

export namespace OrganizationConst
{
  export const OrganizationTypes: IOrganizationType[] = [
    { id: '', value: 'ot0', label: 'Seleccionar' },
    { id: '1', value: 'ot1', label: 'Persona física' },
    { id: '2', value: 'ot2', label: 'Asociación sin fines de lucro' },
    { id: '3', value: 'ot3', label: 'Agricultura, ganadería, aprovechamiento forestal, pesca y caza' },
    { id: '4', value: 'ot4', label: 'Minería' },
    { id: '5', value: 'ot5', label: 'Construcción' },
    { id: '6', value: 'ot6', label: 'Industria manufacturera' },
    { id: '7', value: 'ot7', label: 'Comercio al por mayor' },
    { id: '8', value: 'ot8', label: 'Comercio al por menor' },
    { id: '9', value: 'ot9', label: 'Transportes, correos y almacenamiento' },
    { id: '10', value: 'ot10', label: 'Medios masivos, incluyendo Internet masivos, incluyendo Internetabank' },
    { id: '11', value: 'ot11', label: 'Servicios financieros y de seguros' },
    { id: '12', value: 'ot12', label: 'Servicios inmobilidarios y de alquiler de bienes muebles e intangibles' },
    { id: '13', value: 'ot13', label: 'Servicios profesionales, científicos y técnicos (incluyendo consultoría)' },
    { id: '14', value: 'ot14', label: 'Corporativos y empresas en múltiples giros' },
    { id: '15', value: 'ot15', label: 'Servicios de apoyo a los negocios y manejo de desechos' },
    { id: '16', value: 'ot16', label: 'Servicios educativos' },
    { id: '17', value: 'ot17', label: 'Servicios de salud y asistencia social' },
    { id: '18', value: 'ot18', label: 'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos' },
    { id: '19', value: 'ot19', label: 'Servicios de alojamiento temporal y de preparación de alimentos y bebidas' },
    { id: '20', value: 'ot20', label: 'Actividades del Gobierno y de organismos internacionales y extraterritoriales' },
    { id: '999', value: 'ot999', label: 'Otro' }
  ];

  export const OrganizationSize: IOrganizationSize[] = [
    { id: '', value: 'os0', label: 'Seleccionar' },
    { id: '1', value: 'os1', label: 'De 1 a 16 empleados' },
    { id: '2', value: 'os2', label: 'De 16 a 100 empleados' },
    { id: '3', value: 'os3', label: 'De 101 a 250 empleados' },
    { id: '4', value: 'os4', label: 'Más de 250 empleados' }
  ];
}
