import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ECreditCardError, ECreditCardTypeName, PaymentConst } from "@core-constants/payment-data.const";
import { CreditCardData } from "@core-models/payment.model";
import { TranslateService } from "@shared-services/translate.service";
import { CreditCardUtils } from "@shared-utils/credit-card.util";

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html"
})
export class CreditCardComponent implements OnInit, OnChanges
{
  @Input() public submitted: boolean = false;
  @Output() public onCreditCardDataChanges: EventEmitter<any>;

  public hideCardNumber: boolean = true;
  public creditCard: CreditCardData;

  public creditCardData = PaymentConst.CreditCardConfig;
  public creditCardForm: FormGroup;
  public cardType: ECreditCardTypeName;

  public cardError: ECreditCardError;
  public dateError: ECreditCardError;
  public cvvError: ECreditCardError;

  constructor(private formBuilder: FormBuilder,
    protected translateService: TranslateService)
  {
    this.onCreditCardDataChanges = new EventEmitter<any>();
    this.creditCard = new CreditCardData();

    this.cardError = ECreditCardError.None;
    this.dateError = ECreditCardError.None;
    this.cvvError = ECreditCardError.None;
  }

  public get creditCardFormControls(): { [key: string]: AbstractControl<any, any> }
  {
    return this.creditCardForm.controls;
  }

  public get creditCardErrors(): boolean
  {
    return this.cardError != ECreditCardError.None || this.cvvError != ECreditCardError.None || this.dateError != ECreditCardError.None;
  }

  public get unmaskedCreditCard(): string
  {
    return this.creditCardFormControls.cardNumber.value;
  }

  public ngOnInit(): void
  {
    this.creditCardForm = this.formBuilder.group({
      cardHolder: ['', Validators.required],
      cardNumber: ['', Validators.required],
      dateExpiry: ['', Validators.required],
      cvv: ['', Validators.required]
    });

    this.cardType = ECreditCardTypeName.Unknown;
  }

  public ngOnChanges(): void
  {
    if (this.submitted === true)
    {
      this.onCreditCardDataChanges.emit(this.creditCard);
    }
  }

  public onHideCardNumberClick(): void
  {
    const original = this.creditCardFormControls.cardNumber.value;
    //this is becasuse de value changes when unmask first time
    this.creditCardFormControls.cardNumber.setValue(original);

    this.hideCardNumber = !this.hideCardNumber;
  }

  // ********************************************************************
  //#region Holder
  // ********************************************************************

  public onCardHolderChanged(e: Event): void
  {
    this.creditCard.holder = (e.target as HTMLInputElement).value;
    this.onCreditCardDataChanges.emit(this.creditCard);
  }

  // #endregion

  // ********************************************************************
  //#region Card Number
  // ********************************************************************

  public onCardNumberChanged(): void
  {
    if (this.cardError == ECreditCardError.None)
    {
      this.creditCard.cardNumber = this.unmaskedCreditCard;
    }
    this.creditCard.type = PaymentConst.CreditCardIdentifier.get(this.cardType);
    this.onCreditCardDataChanges.emit(this.creditCard);
  }

  public onCardType(type: ECreditCardTypeName): void
  {
    this.cardType = type;
  }

  public onCardError(e: ECreditCardError): void
  {
    this.cardError = e;
    this.onCardNumberChanged();
  }

  // #endregion

  // ********************************************************************
  //#region Expiry Date
  // ********************************************************************

  public onExpiryDateChanged(data): void
  {
    if (this.dateError == ECreditCardError.None)
    {
      this.creditCard.expiryDate = CreditCardUtils.maskExpDate(data.replace("/", ""));

      setTimeout(() =>
      {
        const expiryDate = this.creditCard.expiryDate.split('/');

        this.creditCard.expiryMonth = parseInt(expiryDate[0]);
        this.creditCard.expiryYear = parseInt(expiryDate[1]);
        this.onCreditCardDataChanges.emit(this.creditCard);
      }, 500);
    }
    else
    {
      this.creditCard.expiryDate = "";
      this.onCreditCardDataChanges.emit(this.creditCard);
    }
  }

  public onDateExpiryError(e: ECreditCardError): void
  {
    this.dateError = e;
    this.onExpiryDateChanged(this.creditCardFormControls.dateExpiry.value);
  }

  // #endregion

  // ********************************************************************
  //#region Expiry Date
  // ********************************************************************

  public onCVVChanged(cvv: any): void
  {
    if (this.cvvError == ECreditCardError.None)
    {
      this.creditCard.cvv = cvv;
      this.onCreditCardDataChanges.emit(this.creditCard);
    }
  }

  public onCVVError(e: ECreditCardError): void
  {
    this.cvvError = e;
    this.onCVVChanged(this.creditCardFormControls.cvv.value);
  }

  // #endregion

  public getErrorMessage(control: any, name: string): string
  {
    if (control)
    {
      if (control?.hasError('required'))
      {
        return this.translateService.getElement('Campo requerido');
      }
    }

    if (name == "cardNumber" && this.cardError != ECreditCardError.None)
    {
      return this.translateService.getElement(PaymentConst.CreditCardErrorMessages.get(this.cardError));
    }

    if (name == "dateExpiry" && this.dateError != ECreditCardError.None)
    {
      return this.translateService.getElement(PaymentConst.CreditCardErrorMessages.get(this.dateError));
    }

    if (name == "cvv" && this.cvvError != ECreditCardError.None)
    {
      return this.translateService.getElement(PaymentConst.CreditCardErrorMessages.get(this.cvvError));
    }
    return "";
  }
}


