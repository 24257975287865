import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { EStepsUrls } from '@core-constants/step.const';
import { StepsDataService } from '@core-data-services/steps.data-service';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { UserTokenStorage } from '@shared-base/generic-clases.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ValidRegistrationDataStepGuard 
{
  constructor(private router: Router,
    public checkoutManager: CheckoutManager,
    public stepsDataService: StepsDataService,
    public tokenManager: TokenManager) { }

  public canActivate(): Observable<boolean>
  {
    const orderId: number = this.tokenManager.getOrderId();

    if (orderId)
    {
      return this.returnToStep1();
    }

    const currentRoute = this.router.url;

    if (!currentRoute.startsWith(EStepsUrls.Step3) && !currentRoute.startsWith(EStepsUrls.Step1))
    {
      return this.returnToStep1();
    }

    const cartItemCount: number = this.checkoutManager.cartItemsCount;
    const user: UserTokenStorage = this.tokenManager.getUser();
    const token: string = this.tokenManager.getToken();

    if (user && token && cartItemCount > 0)
    {
      return this.stepsDataService.$validateRegistrationData()
        .pipe(share(), catchError((fail: HttpErrorResponse) =>
        {
          const error: string = fail?.error?.message;

          BroadcastService.Instance.broadcast(EAppEventName.OnValidCart, error);

          return of(false);

        }),
          map((response) =>
          {
            if (response)
            {
              this.checkoutManager.setRegistrationDataStepEnabled = response.enableStep;
              return response.enableStep;
            }

            return this.returnToStep1();
          }));
    }
    else
    {
      return this.returnToStep1();
    }
  }

  public returnToStep1(): Observable<boolean>
  {
    this.router.navigate([EStepsUrls.Step1]);
    return of(false);
  }

}
