import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { ChatComponent } from "@core-components/chat/chat.component";
import { CouponInputComponent } from "@core-components/coupon-input/coupon-input.component";
import { DialogComponent } from "@core-components/dialog/dialog.component";
import { DropdownComponent } from "@core-components/dropdown/dropdown.component";
import { InputNumberComponent } from "@core-components/input-number/input-number.component";
import { InputTogglerComponent } from "@core-components/input-toggler/input-toggler.component";
import { LoadingComponent } from "@core-components/loading/loading.component";
import { PaymentMethodsComponent } from "@core-components/payment-methods/payment-methods.component";
import { ProcessingComponent } from "@core-components/processing/processing.component";
import { PurchaseSummaryItemComponent } from "@core-components/purchase-summary/purchase-summary-item/purchase-summary-item.component";
import { PurchaseSummarySuggestionItemComponent } from "@core-components/purchase-summary/purchase-summary-item/suggestion-item/purchase-summary-suggestion-item.component";
import { PurchaseSummaryComponent } from "@core-components/purchase-summary/purchase-summary.component";
import { QuantitySelectorComponent } from "@core-components/quantity-selector/quantity-selector.component";
import { SecurePurchaseComponent } from "@core-components/secure-purchase/secure-purchase.component";
import { ToasterComponent } from "@core-components/toaster/toaster.component";
import { AutocompleteDirective } from "@core-directives/autocomplete.directive";
import { CreditCardNumberDirective } from "@core-directives/credit-card-number.directive";
import { CVVCardDirective } from "@core-directives/cvv-card.directive";
import { ExpiryDateDirective } from "@core-directives/expiry-date.directive";
import { ItemQuantityDirective } from "@core-directives/item-quantity.directive";
import { RFCInputDirective } from "@core-directives/rfc-input.directive";
import { BlobStoragePipe } from "@core-pipes/blobstorage.pipe";
import { ContactAddressPipe } from "@core-pipes/contact-address.pipe";
import { CoveragePipe } from "@core-pipes/coverage.pipe";
import { SafePipe } from "@core-pipes/safe.pipe ";
import { TranslatePipe } from "@core-pipes/translate.pipe";
import { TranslateService } from "@shared-services/translate.service";
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { Environment } from "@environments";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { AuthenticationInterceptor } from "./interceptors/auth.interceptor";

export function setupTranslateFactory(service: TranslateService): any
{
  return () =>
  {
    service.initService();
  };
}

@NgModule({
  declarations: [
    DialogComponent,
    ToasterComponent,
    DropdownComponent,
    ChatComponent,
    LoadingComponent,
    InputTogglerComponent,
    ProcessingComponent,
    CouponInputComponent,
    SecurePurchaseComponent,
    InputNumberComponent,
    QuantitySelectorComponent,
    PaymentMethodsComponent,

    PurchaseSummaryComponent,
    PurchaseSummaryItemComponent,
    PurchaseSummarySuggestionItemComponent,

    TranslatePipe,
    CoveragePipe,
    BlobStoragePipe,
    SafePipe,
    ContactAddressPipe,

    CreditCardNumberDirective,
    CVVCardDirective,
    ItemQuantityDirective,
    ExpiryDateDirective,
    RFCInputDirective,
    AutocompleteDirective
  ],
  exports: [
    DialogComponent,
    ToasterComponent,
    DropdownComponent,
    ChatComponent,
    LoadingComponent,
    InputTogglerComponent,
    ProcessingComponent,
    CouponInputComponent,
    SecurePurchaseComponent,
    InputNumberComponent,
    QuantitySelectorComponent,
    PaymentMethodsComponent,
    PurchaseSummaryComponent,
    PurchaseSummaryItemComponent,

    TranslatePipe,
    CoveragePipe,
    BlobStoragePipe,
    SafePipe,
    ContactAddressPipe,

    CreditCardNumberDirective,
    CVVCardDirective,
    ItemQuantityDirective,
    ExpiryDateDirective,
    RFCInputDirective,
    AutocompleteDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaV3Module
  ],
  bootstrap: [AppComponent],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: Environment.RecaptchaV3SiteKey
    }
  ]
})

export class ShareModule { }

