import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from '@shared-services/local-storage.service';

@Component({
  selector: 'app-paypal-success',
  templateUrl: './paypal-success.component.html',
  styleUrls: ['./paypal-success.component.css']
})
export class PayPalSuccessComponent implements OnInit
{
  public pageTitle: string = "Pago pre-aprobado Akky";

  constructor(protected localStorageService: LocalStorageService,
    private titleService: Title) { }

  public ngOnInit(): void
  {
    this.initTitle();
  }

  private initTitle(): void
  {
    this.titleService.setTitle(this.pageTitle);
  }

  public closeWindow(): void
  {
    window.close();
  }
}
