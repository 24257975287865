export const enum EDomainServiceType
{
  Security = "Seguridad avanzada de dominio",
  SMS = "Alertas SMS",
  DNS = "DNS Personalizado",
}

export const enum EDomainType
{
  cc = '.cc',
  tv = '.tv',
  lat = '.lat',
  com_mx = '.com.mx',
  gob_mx = '.gob.mx',
  net_mx = '.net.mx',
  edu_mx = '.edu.mx',
  org_mx = '.org.mx',
  mx = '.mx',
  com = '.com',
  net = '.net',
  xyz = '.xyz',
  autos = '.autos',
  lol = '.lol',
  mom = '.mom'
}

export const enum EDomainCodeError
{
  TLDError = "errorTld",
  InvalidRequest = "invalidRequest",
  DomainMaintenance = "domainMaintenance"
}

export const enum EDomainStatus
{
  notAvailable = 'notAvailable',
  available = "available",
  invalidRequest = "invalidRequest",
  errorTld = "errorTld",
  suspended = "suspended"
}

export namespace DomainDataConst
{
  export const DomainServiceHelpMessge = new Map<EDomainServiceType, string>([
    [EDomainServiceType.Security, '<div class="fs-l text-justify gray pb-2">Al contratar este servicio proteges el dominio de Modificaciones, Transferencias y Eliminaciones no deseadas.</div><div class="fs-l text-justify gray pb-2"> Con este servicio, las operaciones de modificación, solicitud de transferencias y solicitudes de eliminación podrán realizarse solamente después de haber concluido el proceso de validación mediante <a href="https://wa-uat-ecommerceakky.azurewebsites.net/ayuda/temas-de-ayuda?tema=58">documentos</a>, cada vez que sea necesario realizar alguna de ellas te enviamos una clave que te permite realizarlas mientras se encuentre activa la sesión en nuestro sitio.</div>'],
    [EDomainServiceType.SMS, '<div class="col-12 font-weight-bold fs-l text-justify gray pb-2">A través de este servicio podrás estar informado de las operaciones realizadas a los nombres de dominios de tu elección mediante mensajes SMS, cada vez que sean realizadas cualquiera de las operaciones que a continuación mencionamos:</div><ul><li class="col-12 font-weight-bold fs-l text-justify gray pb-2">Modificación de dominios.</li><li class="col-12 font-weight-bold fs-l text-justify gray pb-2">Eliminación de dominios.</li><li class="col-12 font-weight-bold fs-l text-justify gray pb-2">Transferencias (Dominios de Akky hacia otros Registrars Acreditados).</li><li class="col-12 font-weight-bold fs-l text-justify gray ">Avisos de Pago.</li></ul><div class="col-12 font-weight-bold fs-l text-justify gray pb-2">El Servicio se encuentra disponible únicamente para números de teléfonos celulares registrados en la República Mexicana</div>'],
    [EDomainServiceType.DNS, 'Al contratar este servicio podrás realizar la configuración personalizada de los registros DNS de acuerdo a tus necesidades. Akky incluye de forma automática la protección de seguridad de DNS (DNSSEC). La cual verifica el proceso de consulta del dominio y asegura que los visitantes efectivamente llegan a tu sitio web. (Solamente para extensiones de dominio que lo soportan)']
  ]);

  export const EDomainCodeErrors = new Map<EDomainCodeError, string>([
    [EDomainCodeError.TLDError, 'TLD no válido, con Akky puedes registrar los siguientes TLDs: .MX y sus distintas clasificaciones, .COM, .NET, .CC, .TV, .LAT, .AUTOS, .LOL, .MOM  y .XYZ.'],
    [EDomainCodeError.InvalidRequest, 'Los caracteres válidos son números, letras del alfabeto inglés y el guión (-). No deben llevar guión al comienzo ni al final del nombre, ni dos guiones seguidos. La longitud del nombre no debe exceder de 63 caracteres.'],
    [EDomainCodeError.DomainMaintenance, 'Registro no disponible temporalmente.']
  ]);

  export const FreeDomains = [".edu.mx", ".org.mx", ".net.mx"];
  export const CloseDomains = [".edu.mx", ".gob.mx", ".net.mx"];

  export const GenericDomains = [
    EDomainType.com,
    EDomainType.lat,
    EDomainType.net,
    EDomainType.cc,
    EDomainType.tv,
    EDomainType.xyz,
    EDomainType.autos,
    EDomainType.lol,
    EDomainType.mom
  ];

  export const OpenDomains = [
    EDomainType.com,
    EDomainType.lat,
    EDomainType.net,
    EDomainType.xyz,
    EDomainType.autos,
    EDomainType.lol,
    EDomainType.mom
  ];

  export const EDomainTypes = [
    { name: EDomainType.cc, group: 3 },
    { name: EDomainType.tv, group: 3 },
    { name: EDomainType.lat, group: 4 },
    { name: EDomainType.com_mx, group: 1 },
    { name: EDomainType.gob_mx, group: 1 },
    { name: EDomainType.net_mx, group: 1 },
    { name: EDomainType.edu_mx, group: 1 },
    { name: EDomainType.org_mx, group: 1 },
    { name: EDomainType.mx, group: 1 },
    { name: EDomainType.com, group: 2 },
    { name: EDomainType.net, group: 2 },
    { name: EDomainType.xyz, group: 4 },
    { name: EDomainType.autos, group: 4 },
    { name: EDomainType.lol, group: 4 },
    { name: EDomainType.mom, group: 4 }
  ];
}
