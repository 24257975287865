import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, HostListener, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from '@angular/router';
import { EAppEventName } from "@core-constants/app-event-names.const";
import { EDomainType } from "@core-constants/domain-data.const";
import { PageHelper } from "@core-constants/page-helper.const";
import { EPaymentMethodType } from "@core-constants/payment-data.const";
import { EServiceType } from "@core-constants/product-service.const";
import { PurchaseConst } from "@core-constants/purchase.const";
import { ECartItemStatus } from "@core-constants/shopping-cart.const";
import { PaymentsDataService } from "@core-data-services/payments.data-service";
import { RouteHelper } from "@core-helpers/route.helper";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { PaymentOrder, PaymentOrderItem } from "@core-models/order-detail.model";
import { CoveragePipe } from "@core-pipes/coverage.pipe";
import { Environment } from '@environments';
import { BroadcastService } from "@shared-services/broadcast.service";
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { GtmTrackingService } from "@shared-services/gtm-tracking.service";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";

declare function clickChat(): void;

@Component({
  selector: "app-confirmation-step",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.css"]
})
export class ConfirmationStepComponent implements OnInit, AfterViewInit
{
  public toBePrinted: boolean = false;
  public user: any = {};
  public order: PaymentOrder;
  public cartItems: PaymentOrderItem[];
  public domains: PaymentOrderItem[];
  public services: PaymentOrderItem[];
  public openDomains: PaymentOrderItem[];
  public addons: PaymentOrderItem[];

  public total: number = 0;

  public hasCloseDomain: boolean = false;
  public hasOpenDomain: boolean = false;
  public hasServiceType: boolean = false;
  public hasAddonType: boolean = false;
  public hasAcquisitionItems: boolean = false;

  public actionRecaptchaV3: string = "confirmationLoad";

  @HostListener('window:resize', ['$event'])
  public onResize(): void
  {
    this.changeDetector.detectChanges();
  }

  constructor(private service: PaymentsDataService,
    private tokenManager: TokenManager,
    protected activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private routeHelperService: RouteHelper,
    protected translateService: TranslateService,
    protected checkoutManager: CheckoutManager,
    protected captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef
  ) { }

  public ngAfterViewInit(): void
  {
    if (this.cartItems && this.cartItems.length > 0)
    {
      const finalAmount: number = this.order.rate == null ? 0 : this.order.rate.finalAmount;
      const finalProductList: any[] = this.buildGoogleAnalyticsProductList(this.cartItems);

      GtmTrackingService.sendEcommerceEvent(this.order.id.toString(), finalAmount, this.order.paymentInfo.paymentTypeName, finalProductList);

      if (this.hasAcquisitionItems)
      {
        GtmTrackingService.sendAcquisitionEvent();
      }

      this.tokenManager.removeAkkyTrxId();
    }
  }

  public get isMobile(): boolean
  {
    return window.innerWidth < PageHelper.TabletWidth;
  }

  public get EnvironmentChat(): boolean
  {
    return Environment.ChatBot;
  }

  public get isPaymentEnabled(): boolean
  {
    return this.order?.paymentInfo?.paymentType != EPaymentMethodType.NA;
  }

  public get isCreditCardMethod(): boolean
  {
    return this.order?.paymentInfo?.paymentType == EPaymentMethodType.CreditCard;
  }

  public get isBankDepositMethod(): boolean
  {
    return this.order?.paymentInfo?.paymentType == EPaymentMethodType.BankDeposit;
  }

  public get accountHolder(): any | undefined
  {
    return this.tokenManager.getUser();
  }

  public get saving(): any
  {
    return this.checkoutManager.saving;
  }

  public get couponApplied(): boolean
  {
    return this.checkoutManager.isCouponApplied;
  }

  public ngOnInit(): void
  {
    this.user = this.accountHolder;
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.activatedRoute.data.subscribe({
      next: (response: any) =>
      {
        this.loadData(response.data); // the first time data comes inside activatedRoute
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnLanguageChange)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.service.getOrder().subscribe({
          next: (response: any) =>
          {
            this.loadData(response);
          }
        });
      }
    });

    this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe();
  }

  private loadData(order: any): void
  {
    this.order = order;

    if (this.order != undefined)
    {
      this.cartItems = this.order.items;
      this.tokenManager.saveOrderId(this.order.id);

      if (this.cartItems)
      {
        this.services = this.cartItems.filter(x => x.type == EServiceType.Service || x.type == EServiceType.DiverseService);
        this.domains = this.cartItems.filter(x => x.type == EServiceType.Domain);
        this.addons = this.cartItems.filter(x => x.type == EServiceType.Addon);
      }

      if (!Tools.isNullOrEmpty(this.domains))
      {
        this.hasOpenDomain = true;
        this.openDomains = this.domains.filter(x => x.domainType == EDomainType.com || x.domainType == EDomainType.lat || x.domainType == EDomainType.net);
      }

      this.hasCloseDomain = this.order.closedDomains != undefined;

      if (!Tools.isNullOrEmpty(this.services))
      {
        this.hasServiceType = true;
      }

      if (!Tools.isNullOrEmpty(this.addons))
      {
        this.hasAddonType = true;
      }

      if (this.order.paymentInfo != null && this.order.paymentInfo.paymentType != EPaymentMethodType.NA)
      {
        this.total = this.order.rate.finalAmount;
      }
    }
  }

  public onGeneratePDF(): void
  {
    this.toBePrinted = true;
    this.changeDetector.detectChanges();

    /* Hidden elements */
    const nav = document.getElementById('index-navbar');
    const steps = document.getElementById('checkout-steps');
    const buttons = document.getElementById("button-export");
    const footer = document.getElementById("footer");
    const chatButton = document.getElementById('liveagent_button_online_5738F000000GmdD');
    const toast = document.getElementById('toast-container');
    const preChat = document.getElementById('preChat');
    const whatsButton = document.getElementById('whatsbtn');
    const recaptchaBadges = document.getElementsByClassName('grecaptcha-badge');    

    /* Hide elements */
    window.onbeforeprint = (): void =>
    {
      nav?.classList.add('d-none');
      steps?.classList.add('d-none');
      footer?.classList.add('d-none');
      buttons?.classList.add('d-none');
      chatButton?.classList.add('d-none');
      toast?.classList.add('d-none');
      preChat?.classList.add('d-none');
      whatsButton?.classList.add('d-none');

      if (recaptchaBadges && recaptchaBadges[0])
      {
        recaptchaBadges[0].classList.add('d-none');
      }
      
    };
    /* Show elements */
    window.onafterprint = (): void =>
    {
      this.toBePrinted = false;
      nav?.classList.remove('d-none');
      steps?.classList.remove('d-none');
      footer?.classList.remove('d-none');
      buttons?.classList.remove('d-none');
      chatButton?.classList.remove('d-none');
      toast?.classList.remove('d-none');
      preChat?.classList.remove('d-none');
      whatsButton?.classList.remove('d-none');

      if (recaptchaBadges && recaptchaBadges[0])
      {
        recaptchaBadges[0].classList.remove('d-none');
      }
    };

    setTimeout(() =>
    {
      window.print();
    }, 500);
  }

  public redirectToPortal(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}jsf/control_panel/start.jsf`;
  }

  public redirectToEcommerce(): void
  {
    window.location.href = this.routeHelperService.ecommerceURL;
  }

  public openChat(): void
  {
    clickChat();
  }

  public isAddonCumulative(item: PaymentOrderItem): boolean
  {
    return item.type == EServiceType.Addon && item.isAccumulable;
  }

  public isAddonCumulativeToRenew(item: PaymentOrderItem): boolean
  {
    return item.type == EServiceType.Addon && item.isAccumulable && item.status == ECartItemStatus.Renewal;
  }

  public isSynchronizationMessage(orderItem: PaymentOrderItem): boolean
  {
    return orderItem.type != EServiceType.Domain && ((orderItem.synchronization == true && orderItem.message != null || orderItem.message != undefined) || (!orderItem.synchronization && orderItem.coverageMonths == 0));
  }
  public isSynchronization(orderItem: PaymentOrderItem): boolean
  {
    return orderItem.synchronization;
  }
  public isDomainDiverseService(orderItem: PaymentOrderItem): boolean
  {
    return (orderItem.type == EServiceType.DiverseService && orderItem.serviceData?.domainId != null && orderItem.serviceData?.domainId > 0);
  }

  public getItemMessage(orderItem: PaymentOrderItem): string
  {
    return orderItem.message != undefined && !this.isDomainDiverseService(orderItem) ? ' - ' + orderItem.message : '';
  }

  /**
   * Función que crea el listado de productos con sus propiedades para enviar a googleAnalytics
   */
  public buildGoogleAnalyticsProductList(cartItems: PaymentOrderItem[]): any[]
  {
    const filterPipe = new CoveragePipe(this.translateService);
    const allItems = [];
    cartItems.forEach(element =>
    {
      this.googleAnalyticsCollectData(filterPipe, element, allItems);
      if (element.items)
      {
        element.items.forEach(subElement =>
        {
          this.googleAnalyticsCollectData(filterPipe, subElement, allItems);
        });
      }
    });
    return allItems;
  }

  public googleAnalyticsCollectData(filterPipe: CoveragePipe, element: any, allItems: any[]): void
  {
    if (!this.hasAcquisitionItems && element.status === 0)
    {
      this.hasAcquisitionItems = true;
    }

    allItems.push({
      item_id: element.name,
      item_name: element.name.concat(' ').concat(filterPipe.transform(element.coverageMonths, '')),
      item_category: element.name.split('.')[0],
      item_category2: element.status === 0 ? PurchaseConst.PurchaseTypes.Acquisition : PurchaseConst.PurchaseTypes.Others,
      price: element.finalAmount,
      quantity: 1
    }
    );
  }
}


