import { Injectable } from "@angular/core";
import { IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { ICoverageOption, ShoppingCartPlusItem } from "@core-models/shopping-cart-plus.model";

@Injectable({ providedIn: 'root' })
export class IndividualSuggestionManager
{
  constructor() { }

    public isUpgradeItem(item: ShoppingCartPlusItem): boolean
    {
        return item.suggestions.length > 0
                                    ? item.suggestions.some(x => x.individualFeatures.isValidUpgrade && x.individualFeatures.isAdded)
                                    : false;
    }

    // ********************************************************************
    //#region CART UPDATES
    // ********************************************************************

    public addIndividualSuggestion(shoppingCartItems: ShoppingCartPlusItem[], cartItemId: number, suggestionId: string): ShoppingCartPlusItem[]
    {
        return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, true);
    }

    public addIndividualSuggestionUpgrade(shoppingCartItems: ShoppingCartPlusItem[], cartItemId: number, suggestionId: string, quantity: number = undefined, coverageId = undefined, coverageMonths: number = undefined): ShoppingCartPlusItem[]
    {
       return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, true, quantity, coverageId, coverageMonths);
    }

    public removeIndividualSuggestion(shoppingCartItems: ShoppingCartPlusItem[], cartItemId: number, suggestionId: string): ShoppingCartPlusItem[]
    {
       return this.updateItemSuggestions(shoppingCartItems, cartItemId, suggestionId, false);
    }

    private updateItemSuggestions(shoppingCartItems: ShoppingCartPlusItem[], cartItemId: number, suggestionId: string, added: boolean, quantity: number = undefined, coverageId: number = undefined, coverageMonths: number = undefined): ShoppingCartPlusItem[]
    {
        const items = JSON.stringify(shoppingCartItems);
        const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

        const itemIndex = cartItems?.findIndex(x => x.id == cartItemId);

        if (cartItems[itemIndex])
        {
            const suggestionIndex: number = cartItems[itemIndex].suggestions.findIndex(x => x.suggestionId == suggestionId);

            if (cartItems[itemIndex].suggestions[suggestionIndex])
            {
                cartItems[itemIndex].suggestions[suggestionIndex].individualFeatures.isAdded = added;

                if(quantity && coverageId && coverageMonths && added)
                {
                cartItems[itemIndex].suggestions[suggestionIndex].individualFeatures.quantity = quantity;
                cartItems[itemIndex].suggestions[suggestionIndex].coverageId = coverageId;
                cartItems[itemIndex].suggestions[suggestionIndex].coverageMonths = coverageMonths;
                }
            }
            return cartItems;
        }
        return cartItems;
    }


    public setCartItemUpgradeCoverage(shoppingCartItems: ShoppingCartPlusItem[], parentId: number, suggestionId: string, coverageId: number): ShoppingCartPlusItem[]
    {

        const items = JSON.stringify(shoppingCartItems);
        const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

        const parentItem: ShoppingCartPlusItem = cartItems.find((x: ShoppingCartPlusItem) => x.id == parentId);
        if (!parentItem) { return shoppingCartItems; }

        const selectedSuggestion = parentItem.suggestions.find((x: IShoppingCartIndividualStoredSuggestionPlus) => x.suggestionId == suggestionId);
        if (!selectedSuggestion) { return shoppingCartItems; }

        const modifiedSuggestions = parentItem.suggestions.map((x: IShoppingCartIndividualStoredSuggestionPlus) =>
        {
            if (x.suggestionId == suggestionId)
            {
                x.coverageId = coverageId;
                x.coverageMonths = x.coverage.find((x: ICoverageOption) => x.id == coverageId).value;
            }
            return x;
        });

        const updatedCartItems = shoppingCartItems.map((x: ShoppingCartPlusItem) =>
            (x.id === parentId ? { ...x, suggestions: modifiedSuggestions } : x)
        );

        return updatedCartItems;
    }

    public setCartItemUpgradeQuantity(shoppingCartItems: ShoppingCartPlusItem[], parentId: number, suggestionId: string, quantity: number): ShoppingCartPlusItem[]
    {
        const items = JSON.stringify(shoppingCartItems);
          const cartItems: ShoppingCartPlusItem[] = JSON.parse(items);

          const parentItem : ShoppingCartPlusItem = cartItems.find((x: ShoppingCartPlusItem) => x.id == parentId);

          if(!parentItem) { return; }

          const selectedSuggestion = parentItem.suggestions.find((x: IShoppingCartIndividualStoredSuggestionPlus) => x.suggestionId == suggestionId);
          if(!selectedSuggestion) { return; }

          const modifiedSuggestions = parentItem.suggestions.map((x: IShoppingCartIndividualStoredSuggestionPlus) =>
          {
            if(x.suggestionId == suggestionId)
            {
              x.individualFeatures.quantity = quantity;
            }
            return x;
          });

        const updatedCartItems = shoppingCartItems.map((x: ShoppingCartPlusItem) =>
            (x.id === parentId ? { ...x, suggestions: modifiedSuggestions } : x)
          );

        return updatedCartItems;
    }

    // ********************************************************************
    //#endregion
    // ********************************************************************


    // ********************************************************************
    //#region CALCULATIONS
    // ********************************************************************

    private calculateTotalIndividualSuggestionsAdded(product: ShoppingCartPlusItem): number
    {
        const individualSuggestions = product.suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);
        return individualSuggestions.length || 0;
    }

    private calculateTotalUpgradeSuggestionsAdded(product: ShoppingCartPlusItem) : number
    {
        const upgradeSuggestion = product.suggestions?.find(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);
        return upgradeSuggestion ? upgradeSuggestion.individualFeatures.quantity : 0;
    }

    public calculateTotalSuggestionsAdded(product: ShoppingCartPlusItem): number
    {
        return this.calculateTotalIndividualSuggestionsAdded(product) + this.calculateTotalUpgradeSuggestionsAdded(product);
    }

    private calculateTotalAmountIndividualSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        const suggestionsAddedFiltered = suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);

        const suggestionsAdded = suggestionsAddedFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const amount = currentCoverage?.rate?.finalAmount > 0
                ? currentCoverage.rate.finalAmount
                : 0;

            return sum + amount;
        }, 0);

        return suggestionsAdded;
    }

    private calculateTotalAmountUpgradeSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        const upgradeSuggestionsFiltered = suggestions.filter(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);

        const upgradeSuggestionAdded = upgradeSuggestionsFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const amount = currentCoverage?.rate?.finalAmount > 0
                ? currentCoverage.rate.finalAmount
                : 0;

            return sum + (amount * current.individualFeatures.quantity);
        }, 0);

        return upgradeSuggestionAdded;
    }

    public calculateTotalAmountSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        return this.calculateTotalAmountIndividualSuggestionsAdded(suggestions) + this.calculateTotalAmountUpgradeSuggestionsAdded(suggestions);
    }


    private calculateGrossTotalAmountIndividualSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        const suggestionsAddedFiltered = suggestions.filter(x => x.individualFeatures.isAdded && !x.individualFeatures.isValidUpgrade);

        const suggestionsAdded = suggestionsAddedFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const amount = currentCoverage?.rate?.grossAmount > 0
                ? currentCoverage.rate.grossAmount
                : 0;

            return sum + amount;
        }, 0);

        return suggestionsAdded;
    }

    private calculateGrossTotalAmountUpgradeSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        const upgradeSuggestionsFiltered = suggestions.filter(x => x.individualFeatures.isAdded && x.individualFeatures.isValidUpgrade);

        const upgradeSuggestionAdded = upgradeSuggestionsFiltered.reduce((sum, current) =>
        {
            const currentCoverage = current.coverage.find(x => x.id == current.coverageId);

            const upgradeAmount = currentCoverage?.rate?.grossAmount > 0
                ? currentCoverage.rate.grossAmount
                : 0;

            return sum + (upgradeAmount * current.individualFeatures.quantity);
        }, 0);

        return upgradeSuggestionAdded;
    }

    public calculateGrossTotalAmountSuggestionsAdded(suggestions: IShoppingCartIndividualStoredSuggestionPlus[]): number
    {
        return this.calculateGrossTotalAmountIndividualSuggestionsAdded(suggestions) + this.calculateGrossTotalAmountUpgradeSuggestionsAdded(suggestions);
    }

}