import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SettingsManager
{
  private _isChatIconEnabled: boolean = false;
  private _isWhatsAppIconEnabled: boolean = false;
  private _maxItems: number = 300;
  private _isCreditCardGenericDomainEnabled: boolean = false;

  constructor() { }

  // ********************************************************************
  // #region ChatIconEnabled
  // ********************************************************************

  public set setChatIconEnabled(value: boolean)
  {
    this._isChatIconEnabled = value;
  }

  public get isChatIconEnabled(): boolean
  {
    return this._isChatIconEnabled;
  }

  // ********************************************************************
  // #endregion ChatIconEnabled
  // ********************************************************************

  // ********************************************************************
  // #region CreditCardGenericDomainEnabled
  // ********************************************************************

  public set setCreditCardGenericDomainEnabled(value: boolean)
  {
    this._isCreditCardGenericDomainEnabled = value;
  }

  public get isCreditCardGenericDomainEnabled(): boolean
  {
    return this._isCreditCardGenericDomainEnabled;
  }

  // ********************************************************************
  // #endregion CreditCardGenericDomainEnabled
  // ********************************************************************


  // ********************************************************************
  // #region WhatsappIconEnabled
  // ********************************************************************

  public set setWhatsappIconEnabled(value: boolean)
  {
    this._isWhatsAppIconEnabled = value;
  }

  public get isWhatsAppIconEnabled(): boolean
  {
    return this._isWhatsAppIconEnabled;
  }

  // ********************************************************************
  // #endregion WhatsappIconEnabled
  // ********************************************************************

  // ********************************************************************
  // #region MaxItems
  // ********************************************************************
  public set setItemsLimit(value: number)
  {
    this._maxItems = value;
  }

  public get itemsLimit(): number
  {
    return this._maxItems;
  }

  // ********************************************************************
  // #endregion MaxItems
  // ********************************************************************
}
