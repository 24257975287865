import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EContactPersonType } from "@core-constants/contact-data.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { FiscalDataConst } from "@core-constants/fiscal-data.const";
import { LocationConst } from "@core-constants/location.const";
import { OrganizationConst } from "@core-constants/organization.const";
import { RegexConst } from "@core-constants/regex.const";
import { SuccessMessageConst } from "@core-constants/success-message.const";
import { ContactsDataService } from "@core-data-services/contacts.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { IAddressHelper, IContact } from "@core-models/contacts.model";
import { IAddressTemplate, ICountry, IOrganizationSize, IOrganizationType, IPersonType, IState } from "@shared-base/generic-clases.base";
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"]
})
export class ContactFormComponent implements OnInit
{
  public contactForm: FormGroup;
  public contactResetForm: FormGroup;
  public contactFormSubmitted: boolean = false;

  public isNewContact: boolean = false;
  public personType: string = FiscalDataConst.Person.Natural;
  public selectedContact: IContact | undefined = undefined;

  public countryStates: IState[] = [];
  public hasStates: boolean = false;

  public selectedCountry: string = '';
  public selectedState: string = '';
  public selectedOrganization: string = undefined;
  public selectedOrganizationSize: string = undefined;

  public isCCT: boolean = this.hasDomainEduMX;

  public template: any = [];
  public fieldsToValidate: string[] = [];
  public showDefaultFields: boolean = false;
  public requiredIndustryOther: boolean = false;

  //Catalogs
  public personTypes: IPersonType[] = FiscalDataConst.PersonTypes;
  public addressTemplates: IAddressTemplate[] = LocationConst.AddressTemplates;
  public addressVariables: any[] = LocationConst.AddressVariable;
  public organizationTypes: IOrganizationType[] = OrganizationConst.OrganizationTypes;
  public organizationSizes: IOrganizationSize[] = OrganizationConst.OrganizationSize;

  public countries: ICountry[] = this.filteredCountries;
  public states: IState[] = LocationConst.States;

  public moralOptionsRequired: any = FiscalDataConst.MoralOptionsRequired;
  public defaultAddress: any = LocationConst.DefaultAddress;

  @Input() public contacts: IContact[] | undefined = undefined;
  @Input() public contactId: string | undefined;
  @Input() public showPersonTypeInformation: boolean = true;
  @Input() public isRegistrantTab: number = 0;

  @Output() public onCancel: EventEmitter<boolean>;
  @Output() public onSave: EventEmitter<any>;

  constructor(private formBuilder: FormBuilder,
    protected contactsDataService: ContactsDataService,
    protected tokenManager: TokenManager,
    protected checkoutManager: CheckoutManager,
    protected translateService: TranslateService,
    protected changeDetector: ChangeDetectorRef,
    protected toastService: ToastService)
  {
    this.onCancel = new EventEmitter<boolean>();
    this.onSave = new EventEmitter<boolean>();
  }

  public get filteredCountries(): ICountry[]
  {
    const countries: ICountry[] = LocationConst.Countries.filter(country => country.cou_visible === 'Y');

    countries.sort((conuntryA, countryB) => conuntryA.label.localeCompare(countryB.label))
      .sort((conuntryA, countryB) => conuntryA.cou_order.localeCompare(countryB.cou_order));

    return countries;
  }

  public get contactFormControls(): any { return this.contactForm.controls; }

  public get isMoralPerson(): boolean { return this.personType == FiscalDataConst.Person.Organization; }

  public get isNaturalPerson(): boolean { return this.personType == FiscalDataConst.Person.Natural; }

  public get hasCloseDomain(): boolean { return this.checkoutManager.hasCloseDomains; }

  public get hasDomainEduMX(): boolean { return this.checkoutManager.hasDomainEduMX; }

  public get hasRFC(): boolean { return this.selectedCountry == LocationConst.Region.MX && this.isMoralPerson; }

  public get buttonLabel(): string
  {
    const label = this.isNewContact ? "Agregar" : "Editar";
    return label + " contacto";
  }

  public ngOnInit(): void
  {
    this.buildForm();
    this.setDefaultInfo();
    this.isCCT = this.hasDomainEduMX;
  }

  public buildForm(): void
  {
    this.contactForm = this.formBuilder.group({
      id: [''],
      idContactPersonType: [''],
      idContactOrganizationIndustry: [''],
      idContactOrganizationSize: [''],
      idCountry: [''],
      industryOther: [''],
      rfc: [''],
      shortName: [''],
      cctName: [''],
      contactPersonName: [''],
      contactPersonPosition: [''],
      name: ['', Validators.required],
      email: ['', Validators.email],
      voiceNumber: ['', Validators.required],
      street: [''],
      number: [''],
      interiorNumber: [''],
      neighborhood: [''],
      postalCode: [''],
      city: [''],
      phoneCode: [''],
      //HelpersAddress
      streetNumberAndName: [''],
      additionalAddress: [''],
      district: [''],
      dependantLocality: [''],
      autonomusCommunity: [''],
      extension: [''],
      state: [''],

      address1: [''],
      address2: [''],
      address3: ['']
    });
  }

  public setDefaultInfo(): void
  {
    if (this.contactId != undefined)// Is edit
    {
      this.isNewContact = false;
      this.selectedContact = this.contacts.find(x => x.id == this.contactId);
      this.loadContactInfo(this.selectedContact);
    }
    else
    {
      this.isNewContact = true;
      this.contactForm.reset();

      this.setNaturalPerson();
      this.setCountry(LocationConst.Region.MX);
    }
  }

  // ********************************************************************
  //#region Person Type
  // ********************************************************************

  public setMoralPerson(firstTime: boolean = false): void
  {
    this.personType = FiscalDataConst.Person.Organization;

    this.onPatchValue({ idContactPersonType: EContactPersonType.Organization });

    this.setPatternOrValidation(this.moralOptionsRequired);

    this.setIndustryOtherValidations();

    if (this.isNewContact || !firstTime) 
    {
      this.selectedOrganization = this.organizationTypes[0].value;
      this.selectedOrganizationSize = this.organizationSizes[0].value;
    }
    else // is edit
    {
      //org type
      const orgId = this.selectedContact.idContactOrganizationIndustry;
      this.selectedOrganization = this.organizationTypes.find(x => x.id == orgId).value;

      //org size
      const orgSizeId = this.selectedContact.idContactOrganizationSize;
      this.selectedOrganizationSize = this.organizationSizes.find(x => x.id == orgSizeId).value;
    }
  }

  public setNaturalPerson(): void
  {
    this.personType = FiscalDataConst.Person.Natural;

    this.onPatchValue({ idContactPersonType: EContactPersonType.Natural });

    this.clearPatternOrValidation([...this.moralOptionsRequired, ...['rfc', 'cctName']]);

    const cleanMoralOptions =
    {
      idContactOrganizationIndustry: null,
      idContactOrganizationSize: null,
      contactPersonName: null,
      contactPersonPosition: null,
      rfc: null,
      cctName: null
    };

    this.onPatchValue(cleanMoralOptions);
  }

  public onPersonTypeChanges(event: IPersonType): void
  {
    event?.value == FiscalDataConst.Person.Natural ? this.setNaturalPerson() : this.setMoralPerson();
  }

  // #endregion

  // ********************************************************************
  //#region Country and State
  // ********************************************************************

  public resetCountryInfo(): void
  {
    this.resetFormValidators();
    this.showDefaultFields = false;
    this.countryStates = [];
  }

  public setCountry(region: LocationConst.Region, isFirstTime: boolean = false): void
  {
    const country = this.countries.find(x => x.cou_id == region);
    this.updateSelectedCountry(country, isFirstTime);
  }

  public updateSelectedCountry(country: ICountry, isFirstTime: boolean = false): void
  {
    this.resetCountryInfo();

    this.selectedCountry = country.cou_id;

    this.onPatchValue({ idCountry: country.cou_id, phoneCode: country.cou_phone_code });

    if (this.isMoralPerson)
    {
      this.setPatternOrValidation(this.moralOptionsRequired);
    }

    if (this.isCCT && this.isMoralPerson && this.isRegistrantTab)
    {
      this.setPatternOrValidation(['cctName']);
    }

    this.setPatternOrValidation(['state']);

    const countryTemplate = this.addressTemplates.filter(template => template.cou_id == country.cou_id);

    if (!Tools.isNullOrEmpty(countryTemplate))
    {
      this.updateInputsToValidate(countryTemplate);

      //Update State
      this.countryStates = this.states.filter(state => state.cou_id == country.cou_id);

      this.hasStates = !Tools.isNullOrEmpty(this.countryStates);
      this.selectedState = "";

      if (this.hasStates)
      {
        this.selectedState = this.isNewContact || !isFirstTime ? this.countryStates[0].value : this.selectedContact.state;
        this.onPatchValue({ state: this.selectedState });
      }
    }
    else
    {
      this.contactFormControls.state.setValue('');
      this.showDefaultFields = true;
      this.setPatternOrValidation(this.defaultAddress);
    }
  }

  public onStateChanges(event): void
  {
    this.selectedState = event.value;
    this.onPatchValue({ state: event.label });
  }

  public onCountryChanges(event): void
  {
    const country: ICountry = event;

    this.updateSelectedCountry(country);
  }

  // #endregion

  // ********************************************************************
  //#region Organization
  // ********************************************************************

  public onOrganizationTypeSelected(event): void
  {
    this.selectedOrganization = event.value;
    this.setIndustryOtherValidations();
    this.onPatchValue({ idContactOrganizationIndustry: event.id });
  }

  public setIndustryOtherValidations(): void
  {
    this.requiredIndustryOther = false;
    this.contactFormControls.industryOther.setValue('');
    this.contactFormControls.industryOther.disable();

    if (this.selectedOrganization == 'ot999')
    {
      this.contactFormControls.industryOther.enable();
      this.requiredIndustryOther = true;
    }
  }

  public onOrganizationSizeSelected(event: any): void
  {
    this.selectedOrganizationSize = event.value;

    if (this.isMoralPerson)
    {
      this.setPatternOrValidation(['idContactOrganizationSize']);
    }
    this.onPatchValue({ idContactOrganizationSize: event.id });
  }

  // #endregion

  // ********************************************************************
  //#region Load info on edit
  // ********************************************************************

  public loadPersonType(contactInfo): void
  {
    this.personType = this.personTypes.find(x => x.id == contactInfo.idContactPersonType).value;
    this.personType == FiscalDataConst.Person.Natural ? this.setNaturalPerson() : this.setMoralPerson(true);
  }

  public loadContactInfo(contactInfo: any): void
  {
    if (contactInfo != undefined)
    {
      this.loadPersonType(contactInfo);
      this.setCountry(contactInfo.idCountry, true);

      const getCctNumber = (contact: any): any =>
      {
        const splitName = contact.name.split('-');
        const domaincct = Object.keys(splitName).length;

        if (domaincct > 1)
        {
          const cctNumberId: any = this.isCCT ? splitName : false;
          return cctNumberId;
        }
        else
        {
          return false;
        }
      };

      const contactWithCct = getCctNumber(contactInfo);

      const callOptionDropdown = (name?: string, keyElement?: string, delay?: number): void =>
      {
        if (name != null && delay != null && keyElement != null)
        {
          setTimeout(() =>
          {
            const item = document.getElementById(keyElement + contactInfo[name]);
            item != null || item != undefined ? item.click() : item;
          }, delay);
        }
      };

      const getFormControls = Object.entries(this.contactForm.controls).map(getKeyName => getKeyName[0]);

      getFormControls.map(controlName =>
      {
        if (contactWithCct && controlName == 'name' || contactWithCct && controlName == 'cctName')
        {
          controlName == 'name' ? this.contactFormControls[controlName].setValue(contactWithCct[0]) : this.contactFormControls[controlName].setValue(contactWithCct[1]);
        }
        else
        {
          this.contactFormControls[controlName].setValue(contactInfo[controlName]);
        }

        if (controlName == 'idContactOrganizationIndustry')
        {
          callOptionDropdown(controlName, '_ot', 200);
          this.setIndustryOtherValidations();
        }
        else if (controlName == 'idContactOrganizationSize')
        {
          callOptionDropdown(controlName, '_os', 200);
        }
        else if (controlName == 'idCountry')
        {
          callOptionDropdown(controlName, '_', 100);
        }
        else if (controlName == 'state')
        {
          if (this.hasStates)
          {
            callOptionDropdown(controlName, '_', 300);
          }
        }
        else if (contactInfo['addressHelper'] != null)
        {
          if (LocationConst.TextInputsRegistrant.includes(controlName))
          {
            this.contactFormControls[controlName].setValue(contactInfo['addressHelper'][controlName]);
          }
        }
      });
    }
  }

  // #endregion

  public onSaveClick(): void
  {
    this.contactFormSubmitted = true;

    if (this.contactForm.valid)
    {
      const user = this.tokenManager.getUser();

      const contact: IContact = this.setContactFormInformation(user);

      if (contact.id) //Edition
      {
        this.contactsDataService.update(contact).subscribe({
          next: () => 
          {
            this.emitSavedChanges(false);
            this.toastService.setSuccessToast(SuccessMessageConst.ContactAddedSuccessfully);
          },
          error: () => 
          {
            this.toastService.setErrorToast(ErrorMessageConst.UpdateContactFailed);
          }
        });
      }
      else //Add new
      {
        this.contactsDataService.create(contact).subscribe({
          next: () => 
          {
            this.emitSavedChanges(contact);
            this.toastService.setSuccessToast(SuccessMessageConst.ContactAddedSuccessfully);
          },
          error: () =>
          {
            this.toastService.setErrorToast(ErrorMessageConst.CreateContactFailed);
          }
        });
      }
    }
  }

  public onRFCChanged(event: any): void
  {
    event.target.value = event.target.value.toUpperCase();
    this.contactFormControls.rfc.setValue(event.target.value.toUpperCase());
  }

  public setContactFormInformation(user): IContact
  {
    const form = this.contactForm.value;

    const setAddresHelperForm = (form: IAddressHelper): IAddressHelper =>
    {
      return {
        street: form.street,
        number: form.number,
        interiorNumber: form.interiorNumber,
        neighborhood: form.neighborhood,
        streetNumberAndName: form.streetNumberAndName,
        additionalAddress: form.additionalAddress,
        district: form.district,
        dependantLocality: form.dependantLocality,
        autonomusCommunity: form.autonomusCommunity,
        postalCode: form.postalCode,
        city: form.city,
        state: form.state
      } as IAddressHelper;
    };

    const addresshelper = setAddresHelperForm(form);

    const setContactForm = (form: any, user: any): IContact =>
    {
      return {
        id: form.id ? form.id : null,
        idUser: user.id,
        language: user.lang,
        idContactPersonType: form.idContactPersonType.toString(),
        idContactOrganizationIndustry: form.idContactOrganizationIndustry,
        industryOther: form.industryOther,
        idContactOrganizationSize: form.idContactOrganizationSize,
        rfc: form.rfc,
        name: form.name,
        contactPersonName: form.contactPersonName,
        contactPersonPosition: form.contactPersonPosition,
        email: form.email,
        idCountry: form.idCountry,
        state: form.state,
        city: form.city,
        postalCode: form.postalCode,
        address1: form.address1,
        address2: form.address2,
        address3: form.address3,
        phoneCode: form.phoneCode,
        voiceNumber: form.voiceNumber ? form.voiceNumber : null,
        voiceExtension: form.extension ? form.extension : null,
        shortName: form.shortName,
        cctName: form.cctName,
        addressHelper: addresshelper
      } as IContact;
    };

    const contactForm = setContactForm(form, user);

    return contactForm;
  }

  public emitSavedChanges(contact: any): void
  {
    this.contactForm.reset();
    this.contactFormSubmitted = false;

    if (this.isNewContact)
    {
      this.onSave.emit(contact);
    }
    else
    {
      this.onSave.emit(false);
    }
  }

  public onCancelClick(): void
  {
    this.contactForm.reset;
    this.contactFormSubmitted = false;
    this.onCancel.emit(true);
  }

  public updateInputsToValidate(addressTemplateOfCountry: any[]): void
  {
    this.template = [];
    this.fieldsToValidate = [];
    const arrayAddressTemplate = addressTemplateOfCountry;

    arrayAddressTemplate.map(at =>
    {
      //Get name and control
      const variable = this.addressVariables.filter(av => av.adv_id == at.adv_id)[0];

      //Add property name and control to array address template
      at.adv_name = variable?.adv_name;
      at.control = variable?.control;
    });

    arrayAddressTemplate.sort((fieldA, fieldB) => fieldA.adt_order.localeCompare(fieldB.adt_order));

    this.template = arrayAddressTemplate.reverse();

    this.template.map(template =>
    {
      if (template.adt_required == 'Y')
      {
        this.fieldsToValidate.push(template.control);
      }
    });

    this.setPatternOrValidation(this.fieldsToValidate, addressTemplateOfCountry);
  }

  public resetFormValidators(): void
  {
    const keyFormNames = Object.entries(this.contactForm.controls).map(getKeyName => getKeyName[0]);

    keyFormNames.map(keyName =>
    {
      if (keyName !== 'name' && keyName !== 'email' && keyName !== 'voiceNumber')
      {
        this.clearPatternOrValidation(null, keyName);
      }
    });
  }

  public setPatternOrValidation(keyNames?: any, pattern?: any): void
  {
    /* Apply required */
    if (this.hasRFC && this.isMoralPerson && this.selectedCountry == LocationConst.Region.MX)
    {
      this.contactForm.get('rfc')?.addValidators([Validators.pattern(RegexConst.RFC)]);
      this.contactForm.get('rfc')?.updateValueAndValidity();
    }

    if (this.isCCT && this.isMoralPerson && !this.isRegistrantTab)
    {
      this.contactForm.get('cctName')?.addValidators([Validators.maxLength(13)]);
      this.contactForm.get('cctName')?.updateValueAndValidity();
    }

    if (typeof keyNames == 'object' && keyNames != null)
    {
      keyNames.map(key =>
      {
        this.contactForm.get(key)?.setValidators([Validators.required]);
        this.contactForm.get(key)?.updateValueAndValidity();
      });
    }

    /* Apply pattern */
    if (pattern && typeof pattern == 'object')
    {
      pattern.map(field =>
      {
        if (field.adt_expression)
        {
          this.contactForm.get(field.control)?.addValidators([Validators.pattern(field.adt_expression)]);
          this.contactForm.get(field.control)?.updateValueAndValidity();
        }
      });
    }
  }

  public clearPatternOrValidation(keyNames?: string[], keyNameStr?: string): void
  {
    if (typeof keyNames == 'object' && keyNames != null)
    {
      keyNames.map(key =>
      {
        this.contactForm.get(key)?.clearValidators();
        this.contactForm.get(key)?.updateValueAndValidity();
      });
    }

    if (keyNameStr != null)
    {
      this.contactForm.get(keyNameStr)?.clearValidators();
      this.contactForm.get(keyNameStr)?.updateValueAndValidity();
    }
  }

  public onPatchValue(value: object): void
  {
    this.contactForm.patchValue(value);
  }

  public getErrorMessage(control: any): string
  {
    if (control)
    {
      if (control?.hasError('required'))
      {
        return this.translateService.getElement('Campo requerido');
      }
      if (control?.hasError('pattern'))
      {
        return this.translateService.getElement('Por favor, ingresa un texto válido');
      }
      if (control?.hasError('invalid'))
      {
        return this.translateService.getElement('El dato ingresado es inválido');
      }
      if (control?.hasError('maxlength'))
      {
        return this.translateService.getElement('Ha superado la longitud de caracteres permitidos');
      }
      if (control?.hasError('email'))
      {
        return this.translateService.getElement('Formato de correo electrónico inválido');
      }
      if (control?.hasError('minlength'))
      {
        return this.translateService.getElement('Valor fuera de rango: Teléfono [Mínimo: 2, Máximo: 14]');
      }
    }
  }
}
