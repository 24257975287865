<div *ngIf="itemProduct.coverageId !== null" class="purchase-summary-item">
  <div class="m-0 p-2">
    <!-- main item-->
    <div class="row p-0 m-0 align-items-start">
      <ng-container *ngTemplateOutlet="generalItemTemplate"></ng-container>
    </div>
    <!-- main item (domain) domain service -->
    <div *ngIf="hasDomainServices" class="row p-0 m-0 align-items-start">
      <ng-container *ngFor="let item of getDomainServices()">
        <ng-container *ngTemplateOutlet="domainServiceTemplate; context: {item: item}"></ng-container>
      </ng-container>
    </div>
    <!-- main item various service -->
    <div *ngIf="hasVariousServices" class="row p-0 m-0 align-items-start">
      <ng-container *ngFor="let item of itemProduct.variousServices">
        <ng-container *ngTemplateOutlet="variousServiceTemplate; context: {item: item}"></ng-container>
      </ng-container>
    </div>

    <!-- Service Suggestions -->
     <ng-container *ngTemplateOutlet="suggestionsTemplate"></ng-container>
  </div>
</div>

<!-- Any type of service template -->
<ng-template #generalItemTemplate>

  <!-- Item name -->
  <div class="p-0 m-0 my-2 text-start black" [ngClass]=" isDomain ? 'col-12' : 'col-7'">
    <span class="d-block word-break-wrap fw-bold">
      {{getProductName()}}
    </span>
  </div>

  <ng-container *ngIf="!isDomainRenewalDeleted">
    <!-- Domain detail-->
    <div *ngIf="isDomain" class="col-7 p-0 m-0 my-2 text-start black">
      <span class="d-block word-break fw-bold">
        {{ getOperationTypeName() | translate }} {{ getOperationTypeDescription() | translate}}
      </span>
    </div>

    <!-- Item amount -->
    <div class="col-5 p-0 m-0 my-2 text-end fw-bold">
      <span *ngIf="isFinalAmount0 === true">
        {{ getDisplayMessageRate0() | translate}}
      </span>
      <span *ngIf="isFinalAmount0 === false" class="price">
        {{ finalAmount | currency }} MXN
      </span>
    </div>

    <!-- Coverage and quantity -->
    <div class="fs-md w-100 px-0" *ngIf="showCoverageMonths()">{{ getSelectedCoverage() | coverage }}</div>
    <div class="fs-md w-100 px-0" *ngIf="counter && isUserServiceRegistration">
      {{('Cantidad' | translate)}}: {{counter}}
    </div>
  </ng-container>
</ng-template>

<!-- Domain services template (just 1) -->
<ng-template #domainServiceTemplate let-item="item">

  <!-- domain service various service -->
  <div class="row p-0 m-0" *ngIf="item.variousService">
    <ng-container *ngTemplateOutlet="variousServiceTemplate; context: {item: item.variousService}"></ng-container>
  </div>

  <div *ngIf="getDomainServiceSelectedCoverage(item) > 0" class="col-7 p-0 m-0 my-2 text-start black">
    <span class="d-block word-break fw-bold">
      {{item.name | translate}}
    </span>
    <div class="fs-md w-100">{{ getDomainServiceSelectedCoverage(item) | coverage }}</div>
  </div>
  <div class="col-5 p-0 m-0 my-2 text-end fw-bold">
    <span *ngIf="item.rate.finalAmount" class="price">
      {{item.rate.finalAmount | currency}} MXN
    </span>
  </div>
</ng-template>

<!-- Various service template (just 1)-->
<ng-template #variousServiceTemplate let-item="item">
  <div class="col-7 p-0 m-0 mt-2 text-start black">
    <span class="d-block word-break fw-bold">
      {{item.name | translate}}
    </span>
  </div>
  <div class="col-5 p-0 m-0 mt-2 text-end fw-bold">
    <span *ngIf="getVariousServiceFinalAmount(item)" class="price">
      {{getVariousServiceFinalAmount(item) * itemProduct.quantity | currency }} MXN
    </span>
  </div>
  <div class="col-12 fs-md w-100 px-0" *ngIf="showCoverage(item)">
    {{ item.coverage?.value | coverage : getVariousServiceCoverageModifier(item) }}
  </div>
</ng-template>

<!-- Service suggestions Template -->
<ng-template #suggestionsTemplate>
  <ng-container *ngIf="hasSuggestions()">
    <div *ngFor="let suggestion of suggestions" class="pt-2">
      <app-purchase-summary-suggestion-item [item]="suggestion"
        [parentId]="itemProduct.id"
        ></app-purchase-summary-suggestion-item>
    </div>
  </ng-container>
</ng-template>
