<div class="d-flex align-items-center justify-content-center font-weight-bold fs-m my-3">
  <span>{{ 'Formas de Pago' | translate }}</span>
</div>
<div class="d-flex justify-content-center mt-4">
  <span class="mx-1 fas d-inline-block icon-style w-100" loading="lazy">
    <img [src]="'/cards/logo-visa.svg' | blobstorage" class="card-logo" [alt]="'Visa Logo'">
  </span>
  <span class="mx-1 fas d-inline-block icon-style w-100" loading="lazy">
    <img [src]="'/cards/logo-mastercard.svg' | blobstorage" class="card-logo" [alt]="'Mastercard Logo'">
  </span>
  <span class="mx-1 fas d-inline-block icon-style w-100" loading="lazy">
    <img [src]="'/cards/logo-amex.svg' | blobstorage" class="card-logo" [alt]="'American Express Logo'">
  </span>
  <span class="mx-1 fas d-inline-block icon-style w-100" loading="lazy">
    <img [src]="'/cards/logo-paypal-v2.svg' | blobstorage" class="card-logo" [alt]="'Paypal Logo'">
  </span>
  <span class="mx-1 fas d-inline-block icon-style w-100" loading="lazy">
    <img [src]="'/cards/logo-bbva.svg' | blobstorage" class="card-logo" [alt]="'BBVA Logo'">
  </span>
</div>