import { ErrorMessageConst } from "./error-message.const";

export const enum EStepValidationError
{
  InvalidStep = "shoppingcart_invalid_step",
  InvalidPristine = "shoppingcart_invalid_pristine",
  InvalidSession = "shoppingcart_login",
  InvalidToken = "invalid_token",
  InvalidItems = "shoppingcart_without_items",
  InvalidProducts = "shoppingcart_invalid_product",
  InvalidDomains = "shoppingcart_invalid_domain",
  InvalidCart = "shoppingcart_invalid_cart"
}

export const enum ECartStep
{
  Ecommerce = 0,
  DomainServices = 1,
  RegistrationData = 2,
  BillingPayment = 3,
  Confirmation = 4
}

export const enum EStepsUrls
{
  Step1 = "/servicios",
  Step2 = "/datos-registro",
  Step3 = "/facturacion-y-pagos",
  Step4 = "/confirmacion"
}


export namespace StepConst
{
  export const StepValidationErrosArray: string[] = [
    EStepValidationError.InvalidStep,
    EStepValidationError.InvalidPristine,
    EStepValidationError.InvalidSession,
    EStepValidationError.InvalidItems,
    EStepValidationError.InvalidProducts,
    EStepValidationError.InvalidDomains,
    EStepValidationError.InvalidCart
  ];

  export const StepValidationErroMessage = new Map<EStepValidationError, string>([
    [EStepValidationError.InvalidStep, ErrorMessageConst.InvalidStep],
    [EStepValidationError.InvalidPristine, ErrorMessageConst.InvalidPristine],
    [EStepValidationError.InvalidSession, ErrorMessageConst.ExpiredSession],
    [EStepValidationError.InvalidToken, ErrorMessageConst.InvalidToken],
    [EStepValidationError.InvalidItems, ErrorMessageConst.InvalidItems],
    [EStepValidationError.InvalidProducts, ErrorMessageConst.InvalidProducts],
    [EStepValidationError.InvalidDomains, ErrorMessageConst.InvalidDomains],
    [EStepValidationError.InvalidCart, ErrorMessageConst.InvalidCart]
  ]);


  export const CartStepNumber = new Map<string, ECartStep>([
    ["/", ECartStep.DomainServices],
    [EStepsUrls.Step1, ECartStep.DomainServices],
    [EStepsUrls.Step2, ECartStep.RegistrationData],
    [EStepsUrls.Step3, ECartStep.BillingPayment],
    [EStepsUrls.Step4, ECartStep.Confirmation]
  ]);

  export class MainStep
  {
    public number: ECartStep;
    public title: string;
    public url: EStepsUrls;
    public domainValidation: boolean;
  }

  export const StepsArray: MainStep[] =
    [
      {
        number: ECartStep.DomainServices,
        title: "Dominios y Servicios",
        url: EStepsUrls.Step1,
        domainValidation: false
      },
      {
        number: ECartStep.RegistrationData,
        title: "Datos de Registro",
        url: EStepsUrls.Step2,
        domainValidation: true
      },
      {
        number: ECartStep.BillingPayment,
        title: "Facturación y Pagos",
        url: EStepsUrls.Step3,
        domainValidation: false
      },
      {
        number: ECartStep.Confirmation,
        title: "Confirmación",
        url: EStepsUrls.Step4,
        domainValidation: false
      }
    ];
}
