import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { EAppEventName } from '@core-constants/app-event-names.const';
import { ErrorMessageConst } from '@core-constants/error-message.const';
import { FiscalDataConst } from '@core-constants/fiscal-data.const';
import { LanguageConst } from '@core-constants/language.const';
import { PageHelper } from '@core-constants/page-helper.const';
import { ECreditCardType, EPaymentMethodType, PaymentConst } from '@core-constants/payment-data.const';
import { ECartStep, StepConst } from '@core-constants/step.const';
import { AdditionalSettingsDataService } from '@core-data-services/additional-settings.data-service';
import { ContactsDataService } from '@core-data-services/contacts.data-service';
import { CouponDataService } from '@core-data-services/coupons.data-service';
import { PaymentsDataService } from '@core-data-services/payments.data-service';
import { UserAccessDataService } from '@core-data-services/security/user-access.data-service';
import { RouteHelper } from '@core-helpers/route.helper';
import { CheckoutManager } from '@core-managers/checkout.manager';
import { TokenManager } from '@core-managers/token.manager';
import { AdditionalSettings } from '@core-models/additional-settings.model';
import { DomainContactItem } from '@core-models/contacts.model';
import { CreditCartPaymentInfoDto, InvoiceDto, PayPalPaymentInfoDto, PaymentInfoDto } from '@core-models/payment-order.model';
import { CreditCardData, PayPalData } from '@core-models/payment.model';
import { IRecaptchaV3Response } from '@core-models/security.model';
import { Environment } from '@environments';
import { DropDownItem, IBillingAndPaymentInfo, IBillingAndPaymentResolverData, IFiscalRegimeType, IUserRfcItem, PayPalOrderDto, UTMOrderDataDto } from '@shared-base/generic-clases.base';
import { BroadcastService } from '@shared-services/broadcast.service';
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { RouterEventService } from '@shared-services/router-event.service';
import { ToastService } from '@shared-services/toast.service';
import { TranslateService } from '@shared-services/translate.service';
import { CreditCardUtils } from '@shared-utils/credit-card.util';
import { Tools } from '@shared-utils/tools.util';

/* eslint-disable */
declare let OpenPay: any;
/* eslint-disable */

@Component({
  selector: "app-billing-payment-step",
  templateUrl: "./billing-payment.component.html",
  styleUrls: ["./billing-payment.component.css"]
})
export class BillingPaymentStepComponent implements OnInit, AfterViewInit
{
  public invoiceForm: FormGroup;
  public summaryBtn: string = "Pagar";

  public requiresInvoice: boolean = true;
  public isNewRFC: boolean = false;
  public isAutomaticRenewalActivated: boolean = false;
  public isAutomaticRenewalDisabled: boolean = false;
  public isInterestFreeChecked: boolean = false;
  public acceptedPolicies: boolean = false;
  public continueClicked: boolean = false;
  public isEnabledButtonContinue: boolean = true;
  public deviceSessionId: string;
  public actionRecaptchaV3: string = "billingAndPayment";

  public creditCardData: CreditCardData | undefined = undefined;
  public payPalData: PayPalData | undefined = undefined;

  public creditCardMethod = EPaymentMethodType.CreditCard;
  public paypalMethod = EPaymentMethodType.Paypal;
  public bankDepositMethod = EPaymentMethodType.BankDeposit;
  public selectedPaymentMethod = EPaymentMethodType.CreditCard;

  public fiscalRegimes: IFiscalRegimeType[] = FiscalDataConst.FiscalRegimesTypes;
  public naturalPersonFiscalRegimes: IFiscalRegimeType[] = FiscalDataConst.FiscalRegimesTypes.filter(x => x.natural === true);
  public filteredFiscalRegimes: IFiscalRegimeType[] = FiscalDataConst.FiscalRegimesTypes;
  public selectedFiscalRegime: string = '';

  public userRFCList: IUserRfcItem[] = [];
  public userFilteredRFCList: DropDownItem[] = [];
  public selectedRFC: string = '';

  public paymentCards: any[] = [];
  public mifBankOptions: DropDownItem[] = [];
  public enabledPaymentTypes: EPaymentMethodType[] = [];
  public mifOptions = PaymentConst.MIFPayment.Months;

  public selectedMIFBank: string | undefined = undefined;
  public selectedMIF: number | undefined = undefined;

  public redisDomainContacts: DomainContactItem[];

  @ViewChild('billing') public billing: HTMLFormElement;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    protected checkoutManager: CheckoutManager,
    protected activatedRoute: ActivatedRoute,
    protected translateService: TranslateService,
    protected paymentsDataService: PaymentsDataService,
    protected additionalSettingsDataService: AdditionalSettingsDataService,
    protected toastService: ToastService,
    protected userAccessDataService: UserAccessDataService,
    protected tokenManager: TokenManager,
    protected couponDataService: CouponDataService,
    protected routeHelper: RouteHelper,
    protected contactsDataService: ContactsDataService,
    protected localStorageService: LocalStorageService,
    protected routerEventService: RouterEventService,
    protected captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef) { }

  // ********************************************************************
  //#region Params
  // ********************************************************************

  public get isMobile(): boolean
  {
    return window.innerWidth <= PageHelper.TabletWidth;
  }

  public get invoiceFormControls(): any { return this.invoiceForm.controls; }

  public get isPaymentEnabled(): boolean
  {
    const total = this.checkoutManager.isCouponApplied ? Math.round((this.checkoutManager.total - this.checkoutManager.saving) * 100) / 100 : this.checkoutManager.total;
    return total > 0;
  }

  public get couponToApply(): string
  {
    return this.checkoutManager.couponToApply;
  }

  public get currentCoupon(): string
  {
    return this.checkoutManager.currentCoupon;
  }

  public get isPaypalSelected(): boolean
  {
    return this.selectedPaymentMethod == EPaymentMethodType.Paypal;
  }

  public get isBankDepositEnabled(): boolean
  {
    return this.checkoutManager.isBankDepositEnabled;
  }

  public get isCreditCardEnabledForGenericDomainRegistration(): boolean
  {
    return this.checkoutManager.isCreditCardEnabledForGenericDomainRegistration;
  }

  public get isPaypalEnabled(): boolean
  {
    return this.checkoutManager.isPaypalEnabled;
  }

  public get isCreditCardSelected(): boolean
  {
    return this.selectedPaymentMethod == EPaymentMethodType.CreditCard;
  }

  public get isBankDepositSelected(): boolean
  {
    return this.selectedPaymentMethod == EPaymentMethodType.BankDeposit;
  }

  public get noticeOfPrivacyURL(): string
  {
    return `${this.routeHelper.noticeOfPrivacyURL}?lang=${this.translateService.languageCode}`;
  }

  // #endregion

  public ngAfterViewInit(): void
  {
    this.additionalSettingsDataService.getOpenpayConection().subscribe({
      next: (response: AdditionalSettings.IOpenPayResponse) =>
      {
        if (response && OpenPay)
        {
          OpenPay.setId(response.openPayMerchantId);
          OpenPay.setApiKey(response.openPayApiKey);
          OpenPay.setSandboxMode(!Environment.Production);
          this.deviceSessionId = OpenPay.deviceData.setup("creditCardForm", "deviceIdHiddenFieldName");
        }
      }
    });
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
    this.filterFiscalRegimes();

    this.invoiceForm = this.formBuilder.group({
      billingRfc: ['', Validators.required],
      zipCode: ['', Validators.required],
      tradeName: ['', Validators.required]
    });
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnApplyCoupon)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
      next: () =>
        {
        this.getSettings();
      }
    });

    this.activatedRoute.data.subscribe({
      next: (response: IBillingAndPaymentResolverData) =>
      {
        if (response)
        {
          this.setSettings(response.paymentData?.settings);
          this.setFiscalData(response.userData);
        }
      }
    });

    this.contactsDataService.getRedisContactDomain().subscribe({
      next: (response: any) =>
      {
        if (response != null)
        {
          this.redisDomainContacts = response.domainContacts;
        }
      }
    });

    this.routerEventService.eventCompleted$
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe(() =>
      {
      this.setPendingCouponAutomatically();
      });
  }

  private setPendingCouponAutomatically(): void
  {
    const couponToApply = this.checkoutManager.hasCouponToApply ? this.checkoutManager.couponToApply : "";

    if (!this.checkoutManager.setRegistrationDataStepEnabled && ( this.checkoutManager.coupon == undefined || this.checkoutManager.coupon == "" ) && couponToApply != "")
    {
      this.couponDataService.apply(couponToApply);
    }
  }
  // ********************************************************************
  //#region tax data
  // ********************************************************************

  public onInvoiceCheckChanged($event: boolean): void
  {
    this.continueClicked = false;
    this.isNewRFC = true;
    this.resetFiscalData();
    this.filterFiscalRegimes();
    this.requiresInvoice = $event;

  }

  public resetFiscalData(): void
  {
    this.invoiceForm.reset();
    this.selectedFiscalRegime = "";
    this.invoiceForm.updateValueAndValidity();
  }

  public setFiscalData(userFiscalData: any): void
  {
    this.isNewRFC = true;
    this.userFilteredRFCList = [];

    if (userFiscalData && !Tools.isNullOrEmpty(userFiscalData.records))
    {
      const list: IUserRfcItem[] = userFiscalData.records;
      this.userRFCList = list;
      const rfcDropDownList = list?.map(x => { return { value: x.rfc, label: x.rfc }; });

      this.userFilteredRFCList = rfcDropDownList;
    }

    if (!this.userFilteredRFCList.find(x => x.value === ""))
    {
      this.userFilteredRFCList.splice(0, 0, { value: '', label: 'Nuevo RFC' });
    }
  }

  public getFiscalRegimes(rfc: string = ""): void
  {
    if (rfc && rfc.length >= 12)
    {
      this.userAccessDataService.rfcFiscalRegimeList(rfc).subscribe({
        next: (response: any) =>
        {
          this.filterFiscalRegimes(response.records);
        }
      });
    }
    else
    {
      this.filterFiscalRegimes();
    }
  }

  public filterFiscalRegimes(regimesList: number[] = []): void
  {
    this.filteredFiscalRegimes = !regimesList || Tools.isNullOrEmpty(regimesList) ?
      this.naturalPersonFiscalRegimes :
      this.filteredFiscalRegimes = this.fiscalRegimes.filter(x => regimesList.includes(+x.value));

    if (!this.filteredFiscalRegimes.find(x => x.value === ""))
    {
      const firstOption: IFiscalRegimeType = { value: "", label: "Seleccione una opción", natural: true, organization: true };
      this.filteredFiscalRegimes.splice(0, 0, firstOption);
    }

    if (this.selectedFiscalRegime !== "" && !this.filteredFiscalRegimes.find(x => x.value === this.selectedFiscalRegime))
    {
      this.selectedFiscalRegime = "";
    }
  }

  public onRFCChanged(event: any): void
  {
    event.target.value = event.target.value.replace(/\s/g, "").toUpperCase();
    this.invoiceFormControls.billingRfc.setValue(event.target.value.toUpperCase());
  }

  public setSelectedRFC(event: DropDownItem): void
  {
    this.isNewRFC = event.value === '';

    if (this.isNewRFC)
    {
      this.resetFiscalData();
    }
    else
    {
      const data: IUserRfcItem = this.userRFCList.find(x => x.rfc == event.value);

      this.filterFiscalRegimes(data.rfcFiscalRegimeList);

      this.invoiceFormControls.billingRfc.setValue(data.rfc.toUpperCase());
      this.invoiceFormControls.zipCode.setValue(data.postalCode ?? '');
      this.invoiceFormControls.tradeName.setValue(data.name ?? '');

      this.selectedFiscalRegime = data.fiscalRegime ?? '';
    }
  }

  public setSelectedFiscalRegime(event): void
  {
    this.selectedFiscalRegime = event.value;
  }

  //#endregion

  // ********************************************************************
  //#region Payment Settings
  // ********************************************************************

  public getSettings(): void
  {
    this.paymentsDataService.getPaymentSettings().subscribe({
      next: (response: any) =>
      {
        this.setSettings(response);
      }
    });
  }

  public setSettings(settings: IBillingAndPaymentInfo): void
  {
    this.mifBankOptions = undefined;
    this.enabledPaymentTypes = [];
    this.paymentCards = [];
    this.isAutomaticRenewalActivated = false;

    if (settings)
    {
      const mifBanks: any[] = settings.mifBanks;

      if (!Tools.isNullOrEmpty(mifBanks))
      {
        this.mifBankOptions = mifBanks.map(x => { return { value: x, label: PaymentConst.MIFPayment.getBankName(x) }; });
        this.mifBankOptions.unshift({ value: undefined, label: "Seleccionar" });
      }

      if (!Tools.isNullOrEmpty(settings.paymentTypes))
      {
        this.enabledPaymentTypes = settings.paymentTypes;

        const paymentMethod = settings.paymentTypes.find(x => x == this.selectedPaymentMethod);

        if (!paymentMethod)
        {
          this.selectedPaymentMethod = EPaymentMethodType.CreditCard;
        }
      }

      this.isAutomaticRenewalActivated = settings.automaticRenewalEnabled;
      this.paymentCards = settings.cards;
    }
  }

  //#endregion

  // ********************************************************************
  //#region MIF (Months Interest Free)
  // ********************************************************************

  public onInterestFreeCheckChange(isChecked: boolean): void
  {
    this.isInterestFreeChecked = isChecked ?? this.isInterestFreeChecked;

    if (this.isInterestFreeChecked === false)
    {
      this.selectedMIFBank = undefined;
      this.selectedMIF = undefined;
    }
    this.continueClicked = false;
  }

  public setSelectedMifBank(item): void
  {
    this.selectedMIFBank = item.value;
  }

  public setSelectedMifNumber(item): void
  {
    this.selectedMIF = item.value;
  }

  //#endregion

  // ********************************************************************
  //#region Paypal
  // ********************************************************************

  private payPalErrorMessage: string = "El proceso de pago con PayPal no fue completado correctamente, favor de intentarlo de nuevo o seleccionar otro método de pago.";
  public paypalWindow: any;

  //paypal configuration

  public onOpenPayPalClick(): void
  {
    const entity: PayPalOrderDto =
    {
      cartId: this.tokenManager.getCartCookie()
    };

    this.paypalWindow = window.open('', '_blank', 'width=500,height=640');

    this.paymentsDataService.createPayPalOrder(entity).subscribe({
      next: (response: any) =>
      {
        try
        {
          if (response && response.code == '202')
          {
            const url = response.result.links.find(x => x.rel == 'approve');
            this.paypalWindow.location.href = url.href;
            this.payPalData = new PayPalData();
            this.payPalData.orderId = response.result.order_id;
            this.payPalData.incPaymentReference = response.result.inc_payment_reference;
            this.payPalData.status = response.result.status;
          }
          else
          {
            this.closePaypalWindow(this.payPalErrorMessage);
          }
        }
        catch (e)
        {
          this.closePaypalWindow(this.payPalErrorMessage);
        }
      },
      error: (response: HttpErrorResponse) =>
      {
        this.closePaypalWindow(response.error.message);
      }
    });
  }

  public closePaypalWindow(message: string): void
  {
    this.paypalWindow?.close();

    this.toastService.setWarningToast(message);
  }
  // #endregion

  public isPaymentTypeEnabled(type: EPaymentMethodType): boolean
  {
    return this.enabledPaymentTypes.includes(type);
  }

  public setCreditCardData(card: CreditCardData): void
  {
    const isAmex = card && card.type == ECreditCardType.AmericanExpress;

    if (isAmex)
    {
      this.isAutomaticRenewalActivated = false;
    }

    this.isAutomaticRenewalDisabled = isAmex;
    this.creditCardData = (this.isCreditCardSelected && card.holder != '' && card.cardNumber != '' && card.expiryDate != '' && card.cvv != '') ? card : undefined;
  }

  public onAutomaticRenewalButton(): void
  {
    const isAmex = this.creditCardData && this.creditCardData.type == ECreditCardType.AmericanExpress;

    if (!isAmex)
    {
      this.isAutomaticRenewalActivated = !this.isAutomaticRenewalActivated;
      return;
    }
    this.isAutomaticRenewalActivated = false;
  }

  public onSelectedPaymentMethodChanges($event): void
  {
    this.continueClicked = false;
    return this.selectedPaymentMethod = $event;
  }

  public getErrorMessage(control: any): string
  {
    if (control)
    {
      if (control?.hasError('required'))
      {
        return this.translateService.getElement('Campo requerido');
      }
      if (control?.hasError('pattern'))
      {
        return this.translateService.getElement('Por favor, ingresa un valor válido');
      }
      if (control?.hasError('invalid'))
      {
        return this.translateService.getElement('El dato ingresado es inválido');
      }
      if (control?.hasError('maxlength'))
      {
        return this.translateService.getElement('Ha superado la longitud de caracteres permitidos');
      }
    }
  }

  public getInvoiceData(): InvoiceDto
  {
    if (!this.requiresInvoice || !this.isPaymentEnabled)
    {
      return undefined;
    }

    return {
      billingRfc: this.invoiceForm?.value['billingRfc'],
      tradeName: this.invoiceForm?.value['tradeName'],
      postalCode: this.invoiceForm?.value['zipCode'],
      fiscalRegime: this.selectedFiscalRegime
    };
  }

  public updateUTMOrderData(orderId: any): void
  {
    if (orderId)
    {
      const data: UTMOrderDataDto = {
        userId: this.tokenManager.getUser()?.id,
        paymentOrderId: +orderId
      };
      this.paymentsDataService.updateUTMOrder(data).subscribe({
        next: () => { }
      });
    }
  }

  public getPaymentData(): PaymentInfoDto
  {
    const data: PaymentInfoDto = new PaymentInfoDto();

    if (this.isPaymentEnabled)
    {
      data.paymentType = this.selectedPaymentMethod;

      if (this.selectedPaymentMethod == EPaymentMethodType.CreditCard)
      {
        const card: CreditCartPaymentInfoDto = new CreditCartPaymentInfoDto();

        card.userName = this.creditCardData.holder;
        card.creditCardNumber = CreditCardUtils.unmask(this.creditCardData.cardNumber);
        card.cvv = this.creditCardData.cvv;
        card.expireMonth = this.creditCardData.expiryMonth.toString();
        card.expireYear = this.creditCardData.expiryYear.toString();
        card.automaticRenewal = this.isAutomaticRenewalActivated;
        card.creditCardType = this.creditCardData.type;
        card.deviceSessionId = this.deviceSessionId;

        if (this.isInterestFreeChecked)
        {
          card.mIF = +(this.selectedMIF);
          card.mIFBank = this.selectedMIFBank;
        }
        data.creditCartPaymentInfo = card;
      }

      if (this.selectedPaymentMethod == EPaymentMethodType.Paypal)
      {
        if (this.payPalData)
        {
          const payPal: PayPalPaymentInfoDto = new PayPalPaymentInfoDto();
          payPal.incPaymentReference = this.payPalData.incPaymentReference;
          payPal.orderId = this.payPalData.orderId;
          payPal.status = this.payPalData.status;

          data.payPalPaymentInfoDto = payPal;
        }
      }
    }
    else
    {
      data.paymentType = EPaymentMethodType.NA;
    }

    return data;
  }

  public isStepValid(): boolean
  {
    const user: any = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();

    if (!user || !token)
    {
      this.toastService.setErrorToast(ErrorMessageConst.LoginRequired);
      return false;
    }

    if (this.isPaymentEnabled)
    {
      if (this.isPaypalSelected && this.isInterestFreeChecked || this.isBankDepositSelected && this.isInterestFreeChecked)
      {
        this.isInterestFreeChecked = false;
        this.onInterestFreeCheckChange(this.isInterestFreeChecked);
      }

      if (!Tools.isValidEnumValue(this.selectedPaymentMethod, EPaymentMethodType))
      {
        return false;
      }

      if (this.requiresInvoice)
      {
        let hasInvalidDataError: boolean = false;

        if (!this.invoiceFormControls.tradeName.value)
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentTradeNameRequired);
          hasInvalidDataError = true;
        }

        if (!this.invoiceFormControls.billingRfc.value)
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentBillingRFCRequired);
          hasInvalidDataError = true;
        }

        if (!this.invoiceFormControls.zipCode.value)
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentZipCodeRequired);
          hasInvalidDataError = true;
        }

        if (this.selectedFiscalRegime == '')
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentFiscalRegimeRequired);
          hasInvalidDataError = true;
        }

        if (hasInvalidDataError === true)
        {
          return false;
        }

        if (this.invoiceForm.invalid)
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentInvoiceIncorrectData);
          return false;
        }
      }

      if (this.selectedPaymentMethod == EPaymentMethodType.CreditCard)
      {
        if (this.creditCardData == undefined)
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentCardIncompletetData);
          return false;
        }

        if (!CreditCardUtils.validateCardData(this.creditCardData))
        {
          this.toastService.setErrorToast(ErrorMessageConst.PaymentCardIncorrectData);
          return false;
        }
      }

      if (this.selectedPaymentMethod == EPaymentMethodType.Paypal)
      {
        if (this.payPalData == undefined)
        {
          this.closePaypalWindow(this.payPalErrorMessage);
          return false;
        }
      }

      if (this.isInterestFreeChecked && (!this.selectedMIF || !this.selectedMIFBank))
      {
        this.toastService.setErrorToast(ErrorMessageConst.InterestFreeInfoRequired);
        return false;
      }
    }

    if (!this.acceptedPolicies)
    {
      this.toastService.setErrorToast(ErrorMessageConst.BillingAndPamentPolicies);
      return false;
    }

    return true;
  }

  public onBackClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnPreviousStep, ECartStep.BillingPayment);
  }

  public onContinueClick(): void
  {
    this.continueClicked = true;
    this.isEnabledButtonContinue = false;

    if (this.isStepValid())
    {
      this.onExecuteAndVerifyRecaptcha();
    }
    else
    {
      this.isEnabledButtonContinue = true;
    }
  }

  public onExecuteAndVerifyRecaptcha(): void
  {
      this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.onConfirmAndPay();
          }
          else
          {
            this.isEnabledButtonContinue = true;
            this.toastService.setErrorToast(response.message);
          }
        },
        error: () =>
        {
          this.isEnabledButtonContinue = true;
        }
      });
  }

  public onConfirmAndPayFails(failureDetails: any): void
  {
    const error: string = failureDetails?.error?.message;

    if (StepConst.StepValidationErrosArray.includes(error.toLowerCase()))
    {
      BroadcastService.Instance.broadcast(EAppEventName.OnValidCart, error);
    }
    else
    {
      const message = this.translateService.languageCode == LanguageConst.EN ?
        this.translateService.getElement(this.payPalErrorMessage) : this.payPalErrorMessage;

      if (message == error)
      {
        this.toastService.setWarningToast(error);
      }
      else
      {
        this.toastService.setErrorToast(error);
      }
    }
  }

  public onConfirmAndPay(): void
  {
    const order = this.checkoutManager.createPaymentOrder(this.redisDomainContacts, this.getInvoiceData(), this.getPaymentData());
    this.paymentsDataService.confirmAndPay(order).subscribe({
      next: (response: any) =>
      {
        this.updateUTMOrderData(response);
        this.tokenManager.removeCartInformation();
        this.tokenManager.saveOrderId(response);
        BroadcastService.Instance.broadcast(EAppEventName.OnNextStep, ECartStep.BillingPayment);
      },
      error: (response: HttpErrorResponse) =>
      {
        this.onConfirmAndPayFails(response);
        this.isEnabledButtonContinue = true;
      }
    });
  }
}

