import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.css']
})
export class ProcessingComponent implements OnInit
{
  public _show: boolean;
  public _message: string = 'Procesando...';
  public _submessage: string = '';
  private firstMessage = undefined;
  private secondMessage = undefined;

  @Input() public set show(value: boolean) 
  {
    if (value) 
    {
      this.initTimeOuts();
    }
    else 
    {
      this.cancelTimeOuts();
    }

    this._show = value;
  }

  constructor(protected translateService: TranslateService) { }

  public ngOnInit(): void { }

  private initTimeOuts(): void
  {
    this.firstMessage = setTimeout(() =>
    {
      this._message = "Estamos procesando tu solicitud, favor de esperar…";
    }, 5000);
    this.secondMessage = setTimeout(() =>
    {
      this._message = 'Seguimos trabajando con tu solicitud, espera un poco más...';
      this._submessage = this.setSubmessage();
    }, 60000);
  }

  private setSubmessage(): string
  {
    let submessage = 'Cualquier duda contáctanos al +52 (81) 8864-2626 o al correo a pagos@akky.mx, con gusto te atenderemos.';
    submessage = this.translateService.getElement(submessage);
    submessage = submessage.replace('pagos@akky.mx', '<a style="color: white" href="mailto:pagos@akky.mx"><u>pagos@akky.mx</u></a>');
    return submessage.replace('+52 (81) 8864-2626', '<a style="color: white" href="tel:+528188642626">+52 (81) 8864-2626</a>');
  }

  private cancelTimeOuts(): void 
  {
    clearTimeout(this.firstMessage);
    clearTimeout(this.secondMessage);
    this._message = 'Procesando...';
    this._submessage = '';
  }
}
