import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenManager } from "@core-managers/token.manager";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { VerifyRecaptchaV3Dto } from '@core-models/security.model';
import { UrlQueryDef } from '@shared-base/url-query-definition.base';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class SecurityDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'security', translateService);
  }

  public verify(entity: VerifyRecaptchaV3Dto): Observable<any>
  {
    const queryDef = new UrlQueryDef();

    queryDef.addHeader(HttpHeadersConst.AcceptLanguage, this.translateService.languageCode);
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.post(this.createUrl("recaptchaV3/verify"), entity, queryDef.getRequestConfig()).pipe(share());

    this._loadingMaskHandleObservableResponse(subject);

    return subject;
  }

}


