import { Component, DestroyRef, HostListener, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { EAppEventName } from "@core-constants/app-event-names.const";
import { LanguageConst } from "@core-constants/language.const";
import { ELogoutType } from "@core-constants/logout-events.const";
import { LocalStorageConst } from "@core-constants/storage.const";
import { UserAccessDataService } from "@core-data-services/security/user-access.data-service";
import { RouteHelper } from "@core-helpers/route.helper";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { Environment } from '@environments';
import { BroadcastService } from "@shared-services/broadcast.service";
import { LocalStorageService } from "@shared-services/local-storage.service";
import { TranslateService } from "@shared-services/translate.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavBarResponsiveComponent implements OnInit
{
  public showMenuPanel: boolean = false;

  public selectedLanguage: any;
  public dropLanguages: boolean = false;
  public dropUserMenu: boolean = false;

  public languages = LanguageConst.Languages;

  public showUserAccessModal: boolean = false;
  public params: any = undefined;

  constructor(protected translateService: TranslateService,
    protected localStorageService: LocalStorageService,
    protected checkoutManager: CheckoutManager,
    private tokenManager: TokenManager,
    private userAccessDataService: UserAccessDataService,
    private routeHelperService: RouteHelper,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroyRef$: DestroyRef)
  {
    this.registerRouetEvents();

  }

  @HostListener('document:click', ['$event'])
  public clickout(event): void
  {
    if (event.target.title.toLowerCase() != 'idioma')
    {
      this.dropLanguages = false;
    }

    if (event.target.title.toLowerCase() != 'user')
    {
      this.dropUserMenu = false;
    }
  }

  public get cartItemsCount(): number
  {
    return this.checkoutManager.cartItemsCount ?? 0;
  }

  public get userNameInitials(): any
  {
    const user = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();

    if (user && token)
    {
      return user.initials;
    }
    return "";
  }

  public get isLogedIn(): boolean
  {
    return this.userNameInitials != "";
  }

  public ngOnInit(): void
  {
    this.initLanguage();
    this.registerEventListeners();
  }

  public initLanguage(): void
  {
    let lang = this.localStorageService.getItem(LocalStorageConst.SelectedLang);

    if (!lang)
    {
      lang = LanguageConst.ES;
    }

    this.selectedLanguage = this.languages?.find(l => l.code == lang);
    this.translateService.use(lang);
  }

  public registerRouetEvents(): void
  {
    this.activatedRoute.queryParams.subscribe({
      next: (params: any) =>
      {
        this.openLoginFromExternalRoute(params);
      }
    });
  }

  public registerEventListeners(): void
  {
    BroadcastService.Instance.on(EAppEventName.OnOpenLoginPopup)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.showUserAccessModal = true;
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnLoginSuccess)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        this.onLogin();
      }
    });

    BroadcastService.Instance.on(EAppEventName.OnOpenLogin)
    .pipe(takeUntilDestroyed(this.destroyRef$))
    .subscribe({
      next: () =>
      {
        const user = this.tokenManager.getUser();

        if (!user)
        {
          this.showUserAccessModal = true;
        }
      }
    });
  }

  public openLoginFromExternalRoute(params: any): void
  {
    const openLogin = params['isLogin'];

    if (openLogin && (openLogin == "true" || openLogin == true))
    {
      const user = this.tokenManager.getUser();

      this.showUserAccessModal = !user ? true : false;

      this.router.navigate(['./'], {
        relativeTo: this.activatedRoute,
        queryParams: {},
        queryParamsHandling: ''
      });
    }
  }

  public redirectToHome(): void
  {
    window.location.href = this.routeHelperService.ecommerceURL;
  }

  public redirectToMessages(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}jsf/email/show_emails.jsf`;
  }

  public redirectToHelp(): void
  {
    window.location.href = `${this.routeHelperService.ecommerceURL}/ayuda/ayuda`;
  }

  public onControlPanelClicked(): void
  {
    if (this.isLogedIn)
    {
      this.goToExternalControlPanel();
    }
  }

  public onMyProfileClicked(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}jsf/user_abc/account/update.jsf`;
  }

  public goToCart(): void
  {
    this.router.navigate(['/']);
  }

  public goToExternalControlPanel(): void
  {
    window.location.href = `${Environment.PortalAkkyURL}jsf/control_panel/start.jsf`;
  }

  public onSelectLanguage(lang: any): void
  {
    this.selectedLanguage = lang;
    this.translateService.use(this.selectedLanguage.code);
  }

  public onLogout(): void
  {
    this.userAccessDataService.logout().subscribe({
      next: () =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.UserLogout);
      },
      error: () =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnUserLogout, ELogoutType.UserLogout);
      }
    });

  }

  public onLogin(): void
  {
    this.showUserAccessModal = false;
    this.showMenuPanel = false;
    this.dropUserMenu = false;
    this.dropLanguages = false;
  }

  public identify(index: any, item: any): any
  {
    return item;
  }
}


