<div class="row p-0 m-0">
  <!-- Select contact dropdown -->
  <ng-container *ngIf="contacts !== undefined && contacts.length > 0; else addNewContactTemplate">
    <div class="col-md-6 p-0 m-0 pt-2">
      <div class="dropdown w-100">
        <div
          class="custom-dropdown dropdown-toggle p-4 d-flex align-items-center w-100 justify-content-center clickable black"
          type="button" id="dropdownContacts" data-bs-toggle="dropdown" aria-expanded="false">

          <span *ngIf="!selectedContact" class="d-flex py-3 text-center">
            {{ "Selecciona un contacto para llenar esta sección" | translate }}
          </span>

          <div class="row text-start fs-m" *ngIf="selectedContact !== undefined">
            <div class="col-md-12"> {{ selectedContact.name }} </div>
            <div class="col-md-12 subtitle-primary-regular">{{ selectedContact.email }}</div>
            <div class="col-md-12 subtitle-primary-regular">{{ selectedContact | contactAddress }}</div>
            <div class="col-md-12 subtitle-primary-regular"><small *ngIf="selectedContact.phoneCode">+</small> {{
              selectedContact.phoneCode ? selectedContact.phoneCode : ''}} {{ selectedContact.voiceNumber }}</div>
          </div>
        </div>

        <ul class="dropdown-menu custom-dd-menu" aria-labelledby="dropdownContacts">
          <li>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group w-100 floating-label p-4">
                  <input placeholder="{{'Buscar contacto...' | translate }}" [formControl]="searchInput" type="text"
                    id="searchInput" class="form-control form-input" name="searchContact" role="textbox">
                </div>
              </div>
            </div>
          </li>

          <li *ngIf="contacts?.length > 0">
            <div class="list-style-none pe-3 custom-dd-items ps-2">
              <div *ngFor="let contact of $searchContacts | async; let last = last"
                (click)="onSelectContact(contact.id)" class="clickable contactItems subtitle-primary-regular underline">
                <div class="text-uppercase black font-weight-bold fs-md">
                  <span> {{ contact.name ? contact.name : contact.organizationName }} </span>
                </div>
                <div class="text-uppercase fs-md">{{ contact.email }}</div>
                <div class="fs-m">{{ contact | contactAddress }}</div>
                <div class="fs-m"><small *ngIf="contact.phoneCode">+</small> {{contact.phoneCode}} {{
                  contact.voiceNumber }}</div>
                <hr *ngIf="!last" />
              </div>
            </div>
          </li>

          <li class="col-md-12 text-center" *ngIf="($searchContacts | async)?.length === 0">
            <p>{{ "No se encontraron contactos" | translate }}</p>
          </li>

          <li class="add-contact-optio">
            <div class="p-2 text-end">
              <hr class="m-0 mb-3" />
              <button class="dropdown-item text-uppercase font-weight-bold p-2" (click)="onAddClick()">
                {{ "Agregar contacto nuevo" | translate }}
                <i class="icon i-user-plus-solid vertical-align-middle ms-2"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </ng-container>

  <!-- Edit contact button -->
  <div class="col-md-6 p-0 m-0 pt-3 ps-md-3 d-flex align-items-end" *ngIf="selectedContact !== undefined && contacts">
    <span class="edit-contact-btn clickable black underline text-start p-2 text-nowrap" (click)="onEditClick();">
      {{ "Editar contacto seleccionado" | translate }}
      <i class="icon i-edit icon-18"></i>
    </span>
  </div>
</div>

<ng-template #addNewContactTemplate>
  <div class="col-md-6 p-0 m-0 py-2">
    <!-- add contacts -->
    <div class="text-center text-uppercase clickable font-weight-bold p-2 py-4 custom-button" (click)="onAddClick()">
      {{ "Agregar contacto nuevo" | translate }}
      <i class="icon i-user-plus-solid vertical-align-middle ms-2"></i>
    </div>
  </div>
</ng-template>