import { HttpErrorResponse } from "@angular/common/http";
import { Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { EAppEventName } from "@core-constants/app-event-names.const";
import { EContactPersonType, EContactType } from "@core-constants/contact-data.const";
import { ErrorMessageConst } from "@core-constants/error-message.const";
import { LocationConst } from "@core-constants/location.const";
import { PageHelper } from "@core-constants/page-helper.const";
import { ECartStep, StepConst } from "@core-constants/step.const";
import { ContactsDataService } from "@core-data-services/contacts.data-service";
import { UserAccessDataService } from "@core-data-services/security/user-access.data-service";
import { StepsDataService } from "@core-data-services/steps.data-service";
import { CheckoutManager } from "@core-managers/checkout.manager";
import { TokenManager } from "@core-managers/token.manager";
import { ContactInfoHelper, DomainContactInfo, DomainContactItem, IContact } from "@core-models/contacts.model";
import { IRecaptchaV3Response } from '@core-models/security.model';
import { BroadcastService } from "@shared-services/broadcast.service";
import { CaptchaV3Service } from '@shared-services/captchav3.service';
import { ToastService } from "@shared-services/toast.service";
import { TranslateService } from "@shared-services/translate.service";
import { Tools } from "@shared-utils/tools.util";

@Component({
  selector: "app-registration-data-step",
  templateUrl: "./registration-data.component.html",
  styleUrls: ["./registration-data.component.css"]
})
export class RegistrationDataStepComponent implements OnInit
{
  public contacts: IContact[];
  public redisDomainContacts: DomainContactInfo | undefined;
  public redisContactsReponse: DomainContactInfo | undefined;

  public allowShareContactInfo: boolean = false;
  public allowShareRegistrantInfo: boolean = false;
  public isRedisContactsLoaded: boolean = false;

  public isShowPersonTypeOptions: boolean = true;
  public activeContactTab: number = 0;
  public areTermsChecked: boolean = false;
  public isContactsDomainChecked: boolean = true;
  public showContactForm: boolean = false;
  public isEnabledButtonContinue: boolean = true;
  public summaryBtn: string = "Continuar";
  public editContact: any[];

  public registrantContactId: number | undefined = undefined;
  public administrativeContactId: number | undefined = undefined;
  public technicalContactId: number | undefined = undefined;
  public billingContactId: number | undefined = undefined;
  public selectedContactId: number | undefined = undefined;

  //Constants
  public addressTemplate: any = LocationConst.AddressTemplates;
  public addressVariables: any = LocationConst.AddressVariable;

  public actionRecaptchaV3: string = "registrationData";

  constructor(protected translateService: TranslateService,
    protected activatedRoute: ActivatedRoute,
    protected tokenManager: TokenManager,
    protected checkoutManager: CheckoutManager,
    protected userAccessDataService: UserAccessDataService,
    protected contactsDataService: ContactsDataService,
    protected stepsDataService: StepsDataService,
    protected toastService: ToastService,
    protected captchaV3Service: CaptchaV3Service,
    private destroyRef$: DestroyRef) { }

  public get hasOpenDomains(): boolean
  {
    return this.checkoutManager.hasOpenDomains;
  }

  public get areAllContactsSelected(): boolean
  {
    return this.administrativeContactId != undefined &&
      this.administrativeContactId > 0 &&
      this.technicalContactId != undefined &&
      this.technicalContactId > 0 &&
      this.billingContactId != undefined &&
      this.billingContactId > 0;
  }

  public get isMobile(): boolean
  {
    return window.innerWidth <= PageHelper.TabletWidth;
  }

  public get hasDomainEduMX(): boolean
  {
    return this.checkoutManager.hasDomainEduMX;
  }

  public get couponToApply(): string
  {
    return this.checkoutManager.couponToApply;
  }

  public get currentCoupon(): string
  {
    return this.checkoutManager.currentCoupon;
  }

  public ngOnInit(): void
  {
    this.registerEventListeners();
  }

  public registerEventListeners(): void
  {
    this.activatedRoute.data.subscribe({
      next: (response: any) =>
      {
        this.contacts = [];

        if (!Tools.isNullOrEmpty(response.data))
        {
          this.contacts = response?.data;

          const selected: any = this.checkoutManager.registrantContact != undefined ? this.checkoutManager.registrantContact : this.contacts.find(x => x.hasBeenUsedAsRegistrant);

          // all flags are set with regristant contact because is the default status
          this.registrantContactId = selected ? selected.id : undefined;
          this.administrativeContactId = this.checkoutManager.registrantContact != undefined ? this.checkoutManager.administrativeContact.id : undefined;
          this.technicalContactId = this.checkoutManager.technicalContact != undefined ? this.checkoutManager.technicalContact.id : undefined;
          this.billingContactId = this.checkoutManager.billingContact != undefined ? this.checkoutManager.billingContact.id : undefined;
        }
      }
    });

    this.contactsDataService.getRedisContactDomain().subscribe({
      next: (response: DomainContactInfo) =>
      {
        if (response)
        {
          this.isContactsDomainChecked = response.isCheckedDomainContacts;
          this.allowShareContactInfo = response.publishContactInfo;
          this.allowShareRegistrantInfo = response.publishRegistrantInfo;

          const contacts = response.domainContacts;

          if (!this.isContactsDomainChecked && contacts && !Tools.isNullOrEmpty(contacts))
          {
            this.redisContactsReponse = new DomainContactInfo();
            this.redisContactsReponse = response;

            this.isRedisContactsLoaded = true;
            const administrativeId = contacts.find(x => x.contactType == EContactType.Administrative)?.id;
            administrativeId ? this.onSelectAdministrativeContact(administrativeId, true) : undefined;

            const technicalId = contacts.find(x => x.contactType == EContactType.Technical)?.id;
            technicalId ? this.onSelectTechnicalContact(technicalId, true) : undefined;

            const billingId = contacts.find(x => x.contactType == EContactType.Billing)?.id;
            billingId ? this.onSelectBillingContact(billingId, true) : undefined;

            const registrantId = contacts.find(x => x.contactType == EContactType.Registrant)?.id;
            registrantId ? this.onSelectRegistrantContact(registrantId, true) : undefined;
          }
          else
          {
            this.isRedisContactsLoaded = true;
            const redisRegistrant = contacts.find(x => x.contactType == EContactType.Registrant).id;
            redisRegistrant ? this.onSelectRegistrantContact(redisRegistrant, true) : undefined;
          }
        }
        else
        {
          this.isRedisContactsLoaded = false;
        }
      }
    });
  }

  public createContactHelperFromContact(id: any): ContactInfoHelper
  {
    const contact: IContact = this.contacts?.find(x => x.id == id);
    let contactInfo: ContactInfoHelper = undefined;

    if (contact)
    {
      contactInfo = {
        id: +contact.id,
        name: contact.name,
        contactPersonType: +(contact.idContactPersonType),
        hasBeenUsedAsRegistrant: contact.hasBeenUsedAsRegistrant
      };
    }

    return contactInfo;
  }

  public setData(): void
  {
    const registrant = this.createContactHelperFromContact(this.registrantContactId);

    this.checkoutManager.registrantContact = registrant;

    if (this.isContactsDomainChecked)
    {
      this.checkoutManager.administrativeContact =
        this.checkoutManager.technicalContact =
        this.checkoutManager.billingContact = registrant;
    }
    else
    {
      if (this.administrativeContactId)
      {
        this.checkoutManager.administrativeContact = this.createContactHelperFromContact(this.administrativeContactId);
      }
      if (this.technicalContactId)
      {
        this.checkoutManager.technicalContact = this.createContactHelperFromContact(this.technicalContactId);
      }
      if (this.billingContactId)
      {
        this.checkoutManager.billingContact = this.createContactHelperFromContact(this.billingContactId);
      }
    }

    if (this.hasOpenDomains === true)
    {
      this.checkoutManager.setShareOpenDomainContactInfo = this.allowShareContactInfo;
      this.checkoutManager.setShareOpenDomainRegistrantInfo = this.allowShareRegistrantInfo;
    }
  }

  public onAllowShareRegistrant(target: EventTarget): void
  {
    const input = target as HTMLInputElement;
    this.allowShareRegistrantInfo = input.checked;
  }

  public onAllowShareContact(target: EventTarget): void
  {
    const input = target as HTMLInputElement;
    this.allowShareContactInfo = input.checked;
  }

  public setRegistrantAsDefaultDomainContact(): void
  {
    this.setRedisContact(this.registrantContactId, EContactType.Registrant);
  }

  public setRedisContact(idcontact: any, typeContact: EContactType): void
  {
    if (this.registrantContactId == undefined)
    {
      return;
    }

    if (this.isContactsDomainChecked)
    {
      const registrant = this.contacts.find(x => x.id == idcontact);

      /* CREATE CONTACTS ONLY WITH REGISTRANT */
      this.redisDomainContacts = new DomainContactInfo();
      this.redisDomainContacts.domainContacts = [];

      for (let i = 1; i <= 4; i++)
      {
        if (registrant != undefined)
        {
          const redisContact = this.newRedisContact(registrant.id, i);
          this.redisDomainContacts.domainContacts.push(redisContact);
        }
      }
    }
    else
    {
      /* CREATE CONTACT WITH DOMAIN CONTACTS */
      if (this.redisDomainContacts == undefined)
      {
        this.redisDomainContacts = new DomainContactInfo();
        this.redisDomainContacts.domainContacts = [];
      }
      const redisContact = this.newRedisContact(idcontact, typeContact);

      const wasAdded = this.redisDomainContacts.domainContacts.find(x => x.contactType == typeContact);

      if (wasAdded == undefined)
      {
        this.redisDomainContacts.domainContacts.push(redisContact);
      }
      else
      {
        /* REMOVE DUPLICATES */
        this.redisDomainContacts.domainContacts.map((x, index) =>
        {
          if (x.contactType == typeContact)
          {
            this.redisDomainContacts?.domainContacts.splice(index, 1);
          }
        });
        this.redisDomainContacts.domainContacts.push(redisContact);
      }
    }

    this.redisDomainContacts.publishRegistrantInfo = this.allowShareRegistrantInfo;
    this.redisDomainContacts.publishContactInfo = this.allowShareContactInfo;
    this.redisDomainContacts.isCheckedDomainContacts = this.isContactsDomainChecked;

    if (this.isRedisContactsLoaded && this.redisDomainContacts.domainContacts.length && this.redisContactsReponse && !this.isContactsDomainChecked)
    {
      /* apply latest changes when refresh the page and change contacts */
      this.redisContactsReponse.domainContacts = [...this.redisContactsReponse.domainContacts, ...this.redisDomainContacts.domainContacts];

      const arrayContacts: any = this.redisContactsReponse.domainContacts.map(item =>
      {
        return [item.contactType, item];
      });

      const dictionaryContacts = new Map(arrayContacts);

      const newContactsArray = [...dictionaryContacts.values()];

      this.redisContactsReponse.domainContacts = newContactsArray as DomainContactItem[];

      this.redisContactsReponse.isCheckedDomainContacts = this.isContactsDomainChecked;
      this.redisContactsReponse.publishContactInfo = this.allowShareContactInfo;
      this.redisContactsReponse.publishRegistrantInfo = this.allowShareRegistrantInfo;

      if (this.registrantContactId)
      {
        const hasRegistrant = this.redisContactsReponse.domainContacts.some(x => x.contactType == EContactType.Registrant);

        if (!hasRegistrant)
        {
          const redisRegistrant = this.newRedisContact(this.registrantContactId.toString(), EContactType.Registrant);

          this.redisContactsReponse.domainContacts.push(redisRegistrant);
        }
      }
      this.onSaveRedisContact(this.redisContactsReponse);
    }

    if (this.redisDomainContacts.domainContacts.length && !this.redisContactsReponse && !this.isContactsDomainChecked)
    {
      this.onSaveRedisContact(this.redisDomainContacts);
    }

    if (this.redisDomainContacts.domainContacts.length && this.isContactsDomainChecked)
    {
      this.onSaveRedisContact(this.redisDomainContacts);
    }
  }

  public newRedisContact(id: string, conType: EContactType): DomainContactItem
  {
    const dataContact = this.contacts.find(x => x.id == id);

    if (dataContact)
    {
      const newRedisConact: DomainContactItem = new DomainContactItem();
      newRedisConact.id = id;
      newRedisConact.name = dataContact.name;
      newRedisConact.contactPersonType = +dataContact.idContactPersonType;
      newRedisConact.hasBeenUsedAsRegistrant = dataContact.hasBeenUsedAsRegistrant;
      newRedisConact.contactType = conType;

      return newRedisConact;
    }
  }

  public onSaveRedisContact(redisToSave: DomainContactInfo): void
  {
    this.contactsDataService.addRedisContactDomain(redisToSave).subscribe({
      next: () => { }
    });
  }

  public isContactTemplateValid(): boolean
  {
    if (this.registrantContactId)
    {
      const failInputs: string[] = [];

      const reviewContacts = ((id: number | undefined): void =>
      {
        if (id)
        {
          const contactToReview: IContact = this.contacts.find(x => x.id == id.toString());

          const isCountryWithTemplate: boolean = this.addressTemplate.some(country => country.cou_id == contactToReview.idCountry);

          if (isCountryWithTemplate)
          {
            let inputsToValidate: any = [];
            const arrayAddressTemplateFromCountry = this.addressTemplate.filter(template => template.cou_id == contactToReview.idCountry);

            arrayAddressTemplateFromCountry.map(at =>
            {
              const firstOrDefault = this.addressVariables.find(av => av.adv_id == at.adv_id);
              at.adv_name = firstOrDefault.adv_name;
              at.control = firstOrDefault.control;
            });

            arrayAddressTemplateFromCountry.map(template =>
            {
              if (template.adt_required == 'Y')
              {
                inputsToValidate.push(template.control);
              }
            });

            inputsToValidate = [...inputsToValidate, ...['state']];

            if (contactToReview.addressHelper != null)
            {
              if (!contactToReview.state && !contactToReview.addressHelper.state || !contactToReview.postalCode && !contactToReview.addressHelper.postalCode || !contactToReview.city && !contactToReview.addressHelper.city)
              {
                failInputs.push('Fail input');
              }

              inputsToValidate.map(input =>
              {
                if (input != 'state' && input != 'postalCode' && input != 'city')
                {
                  if (!contactToReview.addressHelper[input])
                  {
                    failInputs.push('Fail input: ' + input);
                  }
                }
              });
            }
          }
        }
      });

      reviewContacts(this.registrantContactId);

      if (!this.isContactsDomainChecked && this.areAllContactsSelected)
      {
        reviewContacts(this.administrativeContactId);
        reviewContacts(this.technicalContactId);
        reviewContacts(this.billingContactId);
      }

      if (failInputs.length)
      {
        return false;
      }
    }
    return true;
  }

  public isStepValid(): boolean
  {
    const user: any = this.tokenManager.getUser();
    const token: any = this.tokenManager.getToken();

    if (!user || !token)
    {
      this.toastService.setErrorToast(ErrorMessageConst.LoginRequired);
      return false;
    }

    if (this.registrantContactId == 0 || !this.registrantContactId ||
      (!this.isContactsDomainChecked && !this.areAllContactsSelected))
    {
      this.toastService.setErrorToast(ErrorMessageConst.RegistrantRequired);
      return false;
    }

    if (this.registrantContactId != undefined && this.redisDomainContacts == undefined)
    {
      this.setRegistrantAsDefaultDomainContact();
    }

    if (this.checkoutManager.hasCloseDomains)
    {
      const selectedRegistrant: IContact = this.contacts.find(x => +x.id == this.registrantContactId && +x.idContactPersonType == EContactPersonType.Organization);

      if (!selectedRegistrant)
      {
        this.toastService.setErrorToast(ErrorMessageConst.MoralRegistratRequired);
        return false;
      }

      if (this.hasDomainEduMX)
      {
        const registrantName = selectedRegistrant.name;
        const hasCCT = registrantName.split('-');

        if (hasCCT.length == 1)
        {
          this.toastService.setErrorToast(ErrorMessageConst.CCTRequired);
          return false;
        }
      }
    }

    if (!this.areTermsChecked)
    {
      this.toastService.setErrorToast(ErrorMessageConst.RegitrationTermsCheck);
      return false;
    }

    if (!this.isContactTemplateValid())
    {
      this.toastService.setErrorToast(ErrorMessageConst.RegistrantRequired);
      return false;
    }

    return true;
  }

  public onSelectRegistrantContact($event, isRedisResponse: boolean): void
  {
    this.registrantContactId = $event;
    this.checkoutManager.registrantContact = this.createContactHelperFromContact(this.registrantContactId);
    if (!isRedisResponse)
    {
      this.setRedisContact($event, EContactType.Registrant);
    }
  }

  public onSelectAdministrativeContact($event, isRedisResponse: boolean): void
  {
    this.administrativeContactId = $event;
    this.checkoutManager.administrativeContact = this.createContactHelperFromContact($event);
    if (!isRedisResponse)
    {
      this.setRedisContact($event, EContactType.Administrative);
    }
  }

  public onSelectTechnicalContact($event, isRedisResponse: boolean): void
  {
    this.technicalContactId = $event;
    this.checkoutManager.technicalContact = this.createContactHelperFromContact($event);
    if (!isRedisResponse)
    {
      this.setRedisContact($event, EContactType.Technical);
    }
  }

  public onSelectBillingContact($event, isRedisResponse: boolean): void
  {
    this.billingContactId = $event;
    this.checkoutManager.billingContact = this.createContactHelperFromContact($event);
    if (!isRedisResponse)
    {
      this.setRedisContact($event, EContactType.Billing);
    }
  }

  public get toggleTitle(): string
  {
    return this.isContactsDomainChecked === true ? "Sí, compartir información de contacto" : "No, incluir información de contacto personalizada";
  }

  public onCheckDomainContacts(event: boolean): void
  {
    this.isContactsDomainChecked = event;
    this.redisDomainContacts = undefined;

    if (this.isContactsDomainChecked)
    {
      this.setRedisContact(this.registrantContactId, EContactType.Registrant);
    }
    else
    {
      this.administrativeContactId ? this.setRedisContact(this.administrativeContactId, EContactType.Administrative) : undefined;
      this.billingContactId ? this.setRedisContact(this.billingContactId, EContactType.Billing) : undefined;
      this.technicalContactId ? this.setRedisContact(this.technicalContactId, EContactType.Technical) : undefined;
      this.registrantContactId ? this.setRedisContact(this.registrantContactId, EContactType.Registrant) : undefined;
    }
  }

  public onShowContactFormClick($event, showOption: boolean, tab: number): void
  {
    this.selectedContactId = $event;
    this.showContactForm = true;
    this.isShowPersonTypeOptions = showOption;
    this.activeContactTab = tab;
  }

  public onContactsUpdated($event: any): void
  {
    this.showContactForm = false;

    this.contactsDataService.getList().subscribe({
      next: (res: any) =>
      {
        if ($event)
        {
          this.contacts = res;
          const id = res.reduce((prev, current) => ((prev.id > current.id) ? prev : current), 0).id;
          switch (this.activeContactTab)
          {
            case EContactType.Administrative:
              this.onSelectAdministrativeContact(id, false);
              break;

            case EContactType.Technical:
              this.onSelectTechnicalContact(id, false);
              break;

            case EContactType.Billing:
              this.onSelectBillingContact(id, false);
              break;

            default:
              this.registrantContactId = id;
              break;
          }
          this.setData();
        }
        else
        {
          this.contacts = res;
        }
      }
    });
  }

  public onBackClicked(): void
  {
    BroadcastService.Instance.broadcast(EAppEventName.OnPreviousStep, ECartStep.RegistrationData);
  }

  public onContinueClick(): void
  {
    this.isEnabledButtonContinue = false;

    if (this.isStepValid())
    {
      this.onExecuteAndVerifyRecaptcha();
    }
    else
    {
      this.isEnabledButtonContinue = true;
    }
  }

  public onExecuteAndVerifyRecaptcha(): void
  {
      this.captchaV3Service.executeAndVerify(this.actionRecaptchaV3)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (response: IRecaptchaV3Response) =>
        {
          if (response.isSuccess)
          {
            this.onValidateStepForward();
          }
          else
          {
            this.isEnabledButtonContinue = true;
            this.toastService.setErrorToast(response.message);
          }
        },
        error: () =>
        {
          this.isEnabledButtonContinue = true;
        }
      });
  }

  public onValidateStepForward(): void
  {
    this.setData();

    this.stepsDataService.validateStepForward(ECartStep.RegistrationData).subscribe({
      next: () =>
      {
        BroadcastService.Instance.broadcast(EAppEventName.OnNextStep, ECartStep.RegistrationData);
      },
      error: (response: HttpErrorResponse) =>
      {
        const error: string = response?.error?.message;

        if (StepConst.StepValidationErrosArray.includes(error.toLowerCase()))
        {
          BroadcastService.Instance.broadcast(EAppEventName.OnValidCart, error);
        }
        else
        {
          this.toastService.setErrorToast(response.message);
        }
        this.isEnabledButtonContinue = true;
      }
    });
  }
}
