<div class="bg-white p-3 p-sm-4 user-access-content">
  <span class="clickable" (click)="onBackClicked()">
    <span class="icon i-arrow-left"></span>
  </span>
  <div class="w-100 text-center py-4">
    <p class="font-user-access p-0 m-0">{{ (showSignUpView ? 'Crear Cuenta' : 'Iniciar Sesión') | translate}}</p>
  </div>

  <ng-container *ngIf="showSignUpView; then signUpFormTemplate else loginFormTemplate"></ng-container>
</div>

<!-- LOGIN TEMPLATE -->
<ng-template #loginFormTemplate>

  <div *ngIf="showLoginErrorMessge" class="mb-3">
    <ng-container *ngTemplateOutlet="errorTemplate; context: { errorList: loginErrors }"></ng-container>
  </div>

  <form [formGroup]="loginForm" class="my-2" (ngSubmit)="onLoginSubmit()">

    <!-- User name -->
    <div>
      <div class="form-group w-100 floating-label">
        <input [ngClass]="{ 'is-invalid': loginSubmitted && loginFormControls.loginUsername?.errors }" placeholder=" "
          formControlName="loginUsername" type="text" id="loginUsername" class="form-control form-input"
          name="loginUsername" role="textbox" maxlength="16" required>
        <label for="registerUsername">{{'USUARIO' | translate}}</label>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: loginFormControls.loginUsername, name: '', submitted: loginSubmitted}"></ng-container>
    </div>

    <!-- Password -->
    <div class="my-4">
      <div class="form-group w-100 floating-label ">
        <input [ngClass]="{ 'is-invalid': loginSubmitted && loginFormControls.loginPassword?.errors }" placeholder=" "
          id="loginPassword" formControlName="loginPassword" type="{{showPasswordLogin ? 'text': 'password'}}"
          role="textbox" class="form-control form-input" maxlength="16" required>
        <label for="registerPassword">{{'CONTRASEÑA' | translate}}</label>
        <!-- eye icon -->
        <div class="suffix-icon-input">
          <span class="clickable" (click)="showPasswordLogin = !showPasswordLogin">
            <span *ngIf="!showPasswordLogin" class="icon i-eye-slash"></span>
            <span *ngIf="showPasswordLogin" class="icon i-eye-on"></span>
          </span>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: loginFormControls.loginPassword, name: '', submitted: loginSubmitted}"></ng-container>
    </div>

    <!-- LOGIN AND SIGN UP BUTTONS -->
    <div class="my-3 mb-sm-0 mt-sm-5 text-center" [class.bottom-login-buttons]="isMobile">
      <button class="btn btn-primary w-100" id="loginButton" [disabled]="isLoginLoadingVisible">
        <ng-container *ngIf="!isLoginLoadingVisible; else loading">
          {{'Iniciar Sesión' | translate}}
        </ng-container>
      </button>
      <p class="my-4 w-100 font-weight-bold black">{{'o' | translate }}</p>
      <button class="btn btn-info w-100" (click)="openSignup()">
        {{'Crear Cuenta' | translate}}
      </button>
    </div>

    <!-- FORGOT USER AND PASSWORD -->
    <div class="mt-3 font-weight-bold text-center black">
      <span>{{'¿Necesita encontrar su ' | translate }}</span>
      <a class="underline-forgot clickable" (click)="onRecoverAccountClick()">{{'cuenta de usuario' | translate }}</a>
      <span> {{'o' | translate }} </span>
      <a class="underline-forgot clickable" (click)="onForgetPasswordClick()">{{'contraseña' | translate }}?</a>
    </div>

  </form>
</ng-template>

<!-- SINGUP TEMPLATE -->
<ng-template #signUpFormTemplate>
  <form [formGroup]="signupForm" (ngSubmit)="onSignupSubmit()">

    <div *ngIf="showSignUpErrorMessge" class="mb-3">
      <ng-container *ngTemplateOutlet="errorTemplate; context: { errorList: signUpErrors }"></ng-container>
    </div>

    <!-- User name -->
    <div class="form-group w-100 floating-label ">
      <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupUsername?.errors }" placeholder=" "
        blankSpacesValidator formControlName="signupUsername" name="signupUsername" type="text"
        class="form-control form-input" role="textbox" minlength="4" maxlength="16" required>
      <label for="registerUsername">{{'USUARIO' | translate}}</label>
    </div>
    <ng-container
      *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupUsername, name: 'Usuario', submitted: signupSubmitted }"></ng-container>

    <!-- Name -->
    <div class="my-3">
      <div class="form-group w-100 floating-label">
        <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupFirstname?.errors }"
          placeholder=" " formControlName="signupFirstname" type="text" minlength="1" role="textbox"
          class="form-control form-input" maxlength="30" required>
        <label for="registerName">{{'NOMBRE(S)' | translate}}</label>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupFirstname, name: 'Nombre', submitted: signupSubmitted }"></ng-container>
    </div>

    <!-- Last name -->
    <div class="form-group w-100 floating-label">
      <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupLastname1?.errors }" placeholder=" "
        formControlName="signupLastname1" type="text" minlength="1" role="textbox" class="form-control form-input"
        maxlength="30" required>
      <label for="registerLastName1">{{'APELLIDO(S)' | translate}}</label>
    </div>
    <ng-container
      *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupLastname1, name: 'Apellido(s)', submitted: signupSubmitted }"></ng-container>

    <!-- Email -->
    <div class="my-3">
      <div class="form-group w-100 floating-label">
        <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupEmail?.errors }" placeholder=" "
          blankSpacesValidator formControlName="signupEmail" type="email" role="textbox" minlength="6"
          class="form-control form-input" name="signupEmail" email maxlength="254"
          pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" required>
        <label for="registerEmail">{{'EMAIL' | translate}}</label>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupEmail, name: 'Email', submitted: signupSubmitted, type: 'email' }"></ng-container>
    </div>

    <!-- Confirm Email -->
    <div class="form-group w-100 floating-label">
      <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupConfirmEmail?.errors }"
        placeholder=" " blankSpacesValidator formControlName="signupConfirmEmail" type="email" role="textbox"
        minlength="6" class="form-control form-input" name="signupConfirmEmail" email maxlength="254"
        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" required>
      <label for="registerConfirmEmail">{{'CONFIRMA TU EMAIL' | translate}}</label>
    </div>
    <ng-container
      *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupConfirmEmail, name: 'Confirma tu Email', submitted: signupSubmitted, type: 'email' }"></ng-container>

    <!-- Password -->
    <div class="my-3">
      <div class="form-group w-100 floating-label">
        <input [ngClass]="{ 'is-invalid': signupSubmitted && signUpFormControls.signupPassword?.errors }"
          placeholder=" " minlength="8" formControlName="signupPassword"
          type="{{showPasswordSignUp ? 'text': 'password'}}" role="textbox" class="form-control form-input"
          pattern="^(?=[^A-Za-z]*[A-Za-z]).*(?=.*\d).*$" maxlength="16" required>
        <label for="registerPassword">{{'CONTRASEÑA' | translate}}</label>
        <!-- eye icon -->
        <div class="suffix-icon-input">
          <span class="mx-2"></span>
          <span class="clickable" (click)="showPasswordSignUp = !showPasswordSignUp">
            <span *ngIf="!showPasswordSignUp" class="icon i-eye-slash"></span>
            <span *ngIf="showPasswordSignUp" class="icon i-eye-on"></span>
          </span>
        </div>
      </div>
      <div *ngIf="!signupSubmitted" class="text-black px-2 fw-bold">
        <small>{{ 'De 8 a 16 caracteres, al menos (1) letra y (1) dígito.' | translate }}</small>
      </div>
      <ng-container
        *ngTemplateOutlet="tooltipErrorTemplate; context: {control: signUpFormControls.signupPassword, name: 'Contraseña', submitted: signupSubmitted, type:'password' }"></ng-container>
    </div>

    <div class="form-check d-flex m-2 p-0">
      <span class="clickable ckeck-icon icon pe-4" [ngClass]="termsChecked ? 'i-checkbox-on' : 'i-checkbox-off'"
        (click)="termsChecked = !termsChecked"></span>

      <label class="me-auto form-check-label font-weight-bold" for="termsCheck">
        {{'He leído y acepto los Terminos y Condiciones y el ' | translate}}
        <a class="terms-link" [href]="noticeOfPrivacyURL" target="_blank">
          {{'Aviso de Privacidad' | translate}}.
        </a>
      </label>
    </div>

    <div class="d-flex justify-content-center my-2">
      <re-captcha class="re-captcha-style" (resolved)="onResolveCaptcha($event)"
        siteKey="6LfCPQ0UAAAAADW-y4Kv5mS3myOWAeuirDJQciDC">
      </re-captcha>
    </div>

    <!-- LOGIN AND SIGN UP BUTTONS -->
    <div class="text-center">
      <button class="my-3 btn btn-primary w-100" id="loginButton" [disabled]="isSignUpLoadingVisible">
        <ng-container *ngIf="!isSignUpLoadingVisible; else loading">
          {{'Crear Cuenta' | translate}}
        </ng-container>
      </button>
      <p class="my-3 w-100 font-weight-bold">{{'o' | translate }}</p>
      <button class="btn btn-info w-100" (click)="openLogin()">
        {{'Iniciar sesión' | translate}}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #errorTemplate let-errorList="errorList">
  <div class="ui-messages-decorator">
    <div class="ui-messages">
      <div class="ui-messages-error">
        <div class="pb-2 purple">
          {{'La información proporcionada no es válida. Por favor, verifica e intenta de nuevo.' | translate}}
        </div>
        <div *ngIf="errorList && errorList.length > 0" class="ps-3">
          <ul>
            <li class="ui-messages-error-summary purple font-weight-bold text-wrap" *ngFor="let error of errorList">
              {{ error | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- error tooltip -->
<ng-template #tooltipErrorTemplate let-control="control" let-submitted="submitted" let-type="type" let-name="name">
  <div class="error-container" *ngIf="submitted && control?.errors">
    <small>{{getErrorMessage(control, name, type)}}</small>
  </div>
</ng-template>

<ng-template #loadingTemplate let-iconTemplate="iconTemplate" let-textTemplate="textTemplate">
  <div class="d-flex align-items-center justify-content-center loading-height">
    <div class="d-flex justify-content-center icon-scale">
      <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
    </div>
    <div class="d-flex justify-content-center mt-1 text-scale">
      <ng-template [ngTemplateOutlet]="textTemplate"> </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <app-loading [xlSize]="false" [loadingTemplate]="loadingTemplate" text="{{ 'Cargando' | translate}}"></app-loading>
</ng-template>