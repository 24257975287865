import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService
{
  public schedulerURL: string = "";

  constructor() { }

  [name: string]: any;
  public length: number;

  public clear(): void
  {
    localStorage.clear();
  }

  public getItem(key: string): string | null
  {
    return localStorage[key] ?? "";
  }

  public removeItem(key: string): void 
  {
    localStorage.removeItem(key);
  }

  public setItem(key: string, value: string): void
  {
    localStorage[key] = value;
  }
}
