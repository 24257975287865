import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReferenceConst } from '@core-constants/reference.const';
import { RouteHelper } from '@core-helpers/route.helper';
import { SettingsManager } from '@core-managers/settings.manager';
import { Environment } from '@environments';
import { TranslateService } from '@shared-services/translate.service';

declare function loadChatBotScripts(): void;
declare function goToChatBot(): void;
declare function loadChatScripts(): void;
declare function hideModal(): void;
declare function loadEvents();

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit, AfterViewInit
{
  public readonly chatBtnId: string = ReferenceConst.ChatButtonId;
  public readonly whatsappImage: string = ReferenceConst.WhatsappImage;
  public readonly whatsAppURL: string = ReferenceConst.Whatsappurl;

  @ViewChild(ReferenceConst.ChatButtonId) private buttonChat: ElementRef;

  constructor(protected translateService: TranslateService,
    protected routeHelper: RouteHelper,
    protected settingsManager: SettingsManager) { }

  public get isChatEnabled(): boolean
  {
    return this.settingsManager.isChatIconEnabled === true;
  }

  public get noticeOfPrivacyURL(): string
  {
    return this.routeHelper.noticeOfPrivacyURL;
  }

  public get isWhatsAppEnabled(): boolean
  {
    return this.settingsManager.isWhatsAppIconEnabled === true;
  }

  public get environmentChat(): boolean
  {
    return Environment.ChatBot;
  }

  public get chatBotProd(): boolean 
  {
    return Environment.ChatBotProd;
  }

  public get urlAvatarChatBot(): string 
  {
    return Environment.UrlAvatarChatBot;
  }

  public ngAfterViewInit(): void
  {
    if (this.environmentChat)
    {
      loadChatBotScripts();
    }
    else
    {
      loadEvents();
    }
  }

  public openChat(): void
  {
    if (this.environmentChat)
    {
      goToChatBot();
    }
    else
    {
      loadChatScripts();
    }
  }

  public ngOnInit(): void { }

  public onClosePrechat(): void
  {
    hideModal();
    this.buttonChat.nativeElement.style.display = "block";
  }

}
