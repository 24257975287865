<div class="container mt-3">
  <div class="row">

    <!-- Title -->
    <div class="text-start col-12 w-100 ">
      <h1 class="title black"> {{ "Tu carrito" | translate }} </h1>
    </div>

    <!-- Main content -->
    <div class="col-12 col-lg-7 col-xl-8">
      <ng-container *ngIf="hasProducts; else emptyCartTemplate">
        <ng-container *ngFor="let item of products; let i = index">
          <app-shopping-cart-item [item]="item" [index]="i" (onItemChanges)="onShoppingCartItemChanges($event)">
          </app-shopping-cart-item>
        </ng-container>
      </ng-container>

      <!-- Cart suggestions -->
      <ng-container *ngIf="showSuggestions">
        <div *ngFor="let item of purchaseSuggestions" class="m-3">
          <app-purchase-suggestion-item [item]="item"
                                        (onAddSuggestion)="onAddSuggestion($event)"></app-purchase-suggestion-item>
        </div>
      </ng-container>

      <!-- BACK -->
      <div class="d-none d-lg-block my-4">
        <ng-container *ngTemplateOutlet="backButtonTemplate;"></ng-container>
      </div>
    </div>

    <div class="col-12 col-lg-5 col-xl-4 pt-3">
      <div class="d-none d-lg-block ">
        <app-purchase-summary [button]="'Continuar al pago'" [edit]="false" [coupon]="false" [openServices]="false"
                              (nextStep)="onContinueClick()" [buttonEnabled]="!disableCheckout && !hasPendingDomains()">
        </app-purchase-summary>
      </div>

      <!-- Mobile buttons -->
      <section class="d-lg-none">
        <div class="w-100 text-center p-2">
          <button id="mobile-continue-button" *ngIf="hasProducts" (click)="onContinueClick()"  [disabled]="disableCheckout" class="btn btn-primary w-100 text-uppercase">
            {{ 'Continuar al pago' | translate }}
          </button>
        </div>
        <!-- BACK -->
        <ng-container *ngTemplateOutlet="backButtonTemplate;"></ng-container>
      </section>

      <app-secure-purchase-message></app-secure-purchase-message>
    </div>
  </div>
</div>

<!-- Empty Cart template-->
<ng-template #emptyCartTemplate>
  <div class="my-3 p-3 cart-empty-dashed">
    <div class="card-body p-0">
      <div class="row">
        <h1 class="font-weight-bold gray">
          {{ "Aún no tienes servicios" | translate }}
        </h1>
      </div>
      <div class="row">
        <h1 class="font-weight-bold gray">
          {{ "agregados al carrito" | translate }}
        </h1>
      </div>
      <div class="row pt-2">
        <p class="gray fs-l font-weight-bold">
          {{
          "Registra o transfiere un dominio, no olvides acompañarlo de un servicio de buzón de correo, un sitio web o
          una tienda en línea." | translate
          }}
        </p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <span class="icon icon-standard-size i-empty-cart"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Back button template-->
<ng-template #backButtonTemplate>
  <button class="btn btn-secondary black my-4" [class.w-100]="isMobile" (click)="onBackClicked()">
    <div class="d-flex align-items-center justify-content-center w-100">
      <span class="i-arrow-left me-2"></span>
      {{ 'Regresar' | translate }}
    </div>
  </button>
</ng-template>
