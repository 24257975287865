import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ECartStep } from '@core-constants/step.const';
import { TokenManager } from "@core-managers/token.manager";
import { EntityDataServiceBase } from "@shared-base/entity-data-service.base";
import { UrlQueryDef } from "@shared-base/url-query-definition.base";
import { TranslateService } from "@shared-services/translate.service";
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { HttpHeadersConst } from '@core-constants/http-headers.const';

@Injectable({ providedIn: 'root' })
export class StepsDataService extends EntityDataServiceBase
{
  private _currentStep: ECartStep = ECartStep.DomainServices;

  constructor(protected http: HttpClient,
    protected tokenManager: TokenManager,
    protected translateService: TranslateService)
  {
    super(http, 'steps', translateService);
  }

  public get currentStep(): ECartStep
  {
    return this._currentStep;
  }

  public $validateRegistrationData(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const token = this.tokenManager.getToken();

    const dto: any = {
      cartId: this.tokenManager.getCartCookie()
    };

    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.post(this.createUrl("validate/registration-info"), dto, queryDef.getRequestConfigWithAuthorizationToken(token));

    return subject;
  }

  public $validateBillingAndPayment(): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const token = this.tokenManager.getToken();

    const dto: { cartId: string } = {
      cartId: this.tokenManager.getCartCookie()
    };

    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const subject = this.http.post(this.createUrl("validate/billing-and-payment"), dto, queryDef.getRequestConfigWithAuthorizationToken(token));

    return subject;
  }

  public updateStepInfo(step: ECartStep): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    const token = this.tokenManager.getToken();

    const dto: { cartId: string, cartStep: ECartStep } = {
      cartId: this.tokenManager.getCartCookie(),
      cartStep: step
    };

    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const observable = this.http.put(this.createUrl("step-info"), dto, queryDef.getRequestConfigWithAuthorizationToken(token))
      .pipe(
        tap(() => this._currentStep = step),
        share()
      );

    this._handleObservableResponse(observable);

    return observable;
  }

  public validateStepForward(cartStep: ECartStep): Observable<any>
  {
    const queryDef = new UrlQueryDef();
    queryDef.addHeader(HttpHeadersConst.AkkyTrxId, this.tokenManager.getAkkyTrxId());

    const token = this.tokenManager.getToken();

    const dto: { cartId: string, cartStep: ECartStep } = {
      cartId: this.tokenManager.getCartCookie(),
      cartStep
    };

    const observable = this.http.post(this.createUrl("validate/step-forward"), dto, queryDef.getRequestConfigWithAuthorizationToken(token)).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }
}


