import { Pipe, PipeTransform } from '@angular/core';
import { PurchaseConst } from '@core-constants/purchase.const';
import { ICoverageOption } from '@core-models/shopping-cart-plus.model';
import { TranslateService } from '@shared-services/translate.service';

export const enum CoverageModifier
{
  Array = 'array',
  Months = 'months'
}
@Pipe({ name: 'coverage' })
export class CoveragePipe implements PipeTransform
{
  constructor(public translateService: TranslateService) { }

  public transform(value, modifier: string): any
  {
    if (value == undefined || value == null) { return ""; }

    if (!modifier)
    {
      return this.convertToYears(value);
    }
    if (modifier == CoverageModifier.Array)
    {
      return this.array(value);
    }
    if (modifier == CoverageModifier.Months)
    {
      return this.convertToMonths(value);
    }
    return "";
  }

  private array(value: ICoverageOption[]): any
  {
    const converted = value.map((item: ICoverageOption) =>
    {
      return {
        value: item.id,
        label: this.convertToYears(item.value),
        rate: item.rate
      };
    });

    return converted;
  }

  private convertToYears(monthValue: number): string
  {
    const andWord = " " + this.translateService.getElement("y") + " ";
    const result = [];
    const months = monthValue % 12;
    const years = monthValue == 0 ? 0 : Math.floor(monthValue / 12);

    if (years > 0 || (years == 0 && months == 0))
    {
      result.push(years + ' ' + this.getLabel(years, PurchaseConst.CoverageLabels.yearsLabels));
    }

    if (months > 0)
    {
      result.push(months + ' ' + this.getLabel(months, PurchaseConst.CoverageLabels.monthsLabels));
    }

    if (months == -1)
    {
      result.push(this.translateService.getElement("Sincronizar a Servicio"));
    }

    return result.join(andWord);
  }

  private convertToMonths(monthValue: number): string
  {
    if (monthValue && monthValue > 0)
    {
      return monthValue + ' ' + this.getLabel(monthValue, PurchaseConst.CoverageLabels.monthsLabels);
    }

    return "";
  }

  private getLabel(number: number, word: any): any
  {
    return this.translateService.getElement(number == 1 && word.one || word.many);
  }
}
