import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ECreditCardError, ECreditCardTypeName } from '@core-constants/payment-data.const';
import { CreditCardUtils } from '@shared-utils/credit-card.util';

@Directive({
  selector: '[creditCardNumber]'
})
export class CreditCardNumberDirective implements OnInit
{
  protected input: HTMLInputElement;

  @Input() public unmaskedValue: any;

  @Output()
  public onCreditCardTypeName: EventEmitter<ECreditCardTypeName>;

  @Output()
  public onCreditCardNumber: EventEmitter<string>;

  @Output()
  public onCreditCardError: EventEmitter<ECreditCardError>;

  constructor(protected elementRef: ElementRef)
  {
    this.input = this.elementRef.nativeElement as HTMLInputElement;
    this.onCreditCardTypeName = new EventEmitter<ECreditCardTypeName>();
    this.onCreditCardNumber = new EventEmitter<string>();
    this.onCreditCardError = new EventEmitter<ECreditCardError>();
  }

  public ngOnInit(): void
  {
    this.onCreditCardTypeName.emit(ECreditCardTypeName.Unknown);
  }

  public get length(): number
  {
    return this.input.value.length;
  }

  @HostListener('keyup')
  public onKeyUp(): boolean
  {
    const cardNumber = this.unmaskedValue;

    const cardType: ECreditCardTypeName = CreditCardUtils.getCardType(cardNumber);

    this.onCreditCardTypeName.emit(cardType);

    if (!CreditCardUtils.isValidCreditCardLength(cardNumber, cardType))
    {
      this.onCreditCardError.emit(ECreditCardError.InvalidLength);
      return false;
    }

    if (!CreditCardUtils.isValidLuhn(cardNumber))
    {
      this.onCreditCardError.emit(ECreditCardError.InvalidNumber);
      return false;
    }

    this.onCreditCardError.emit(ECreditCardError.None);

    return true;
  }
}
