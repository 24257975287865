import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Environment } from '@environments';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AppClientContext } from './client-context.base';

export class ApiServiceBase
{
  protected apiUrl: string = "";

  constructor(protected http: HttpClient) { }

  // ***********************************************************
  // Support Methods
  // ***********************************************************

  protected getApiURL(): string
  {
    return `${Environment.MicroserviceURL}api/${this.apiUrl}`;
  }

  protected createUrl(method: string = "", ...identifiers: any[]): string
  {
    let returnValue = `${Environment.MicroserviceURL}api/${this.apiUrl}`;

    if (method)
    {
      returnValue = `${returnValue}/${method}`;
    }

    identifiers.forEach(identifier => 
    {
      if (identifier != null)
      {
        returnValue += `/${identifier}`;
      }
    });

    return returnValue;
  }

  /** 
  * Takes an Observable - and handles the success and fail conditions - including logging the results.
  */
  protected _handleObservableResponse(subject: Observable<any>): void
  {
    subject.subscribe({
      next: (response: HttpErrorResponse) => 
      {
        // Not Found
        if (response && response.status == 404)
        {
          return;
        }

        // Unauthorized
        if (response && response.status == 401)
        {
          if (response.statusText != "Unauthorized")
          {
            window.location.href = "/";
          }

          return;
        }

        if (response && response.status == 500)
        {
          // Not in cache
          if (response.error && response.error.message == "User not in Cache... possibly timed out.")
          {
            window.location.href = "/";
          }

          return;
        }

        // Outage
        if (response && response.status == 503)
        {
          return;
        }
      }
    });
  }

  // handle errors
  protected handleError(response: Response): Observable<Response>
  {
    return observableThrowError(response);
  }

  /** 
  * Show Processing component when change tab steps of cart
  */
  protected _loadingMaskHandleObservableResponse(subject: Observable<any>): void
  {
    /* Call api context */
    const random = Math.random().toString().substring(2);
    const dataServiceTokenName = (<any>this.constructor).name + "_" + random;

    AppClientContext.Instance.setDataServiceBusy(dataServiceTokenName);

    /* suscribe response */
    subject.subscribe({
      next: () => 
      {
        AppClientContext.Instance.setDataServiceDone(dataServiceTokenName);
      },
      error: (response: HttpErrorResponse) =>
      {
        try
        {
          // Not Found
          if (response && response.status == 404)
          {
            return;
          }

          // Unauthorized
          if (response && response.status == 401)
          {
            if (response.statusText != "Unauthorized")
            {
              window.location.href = "/";
            }

            return;
          }

          if (response && response.status == 500)
          {
            // Not in cache
            if (response.error && response.error.message == "User not in Cache... possibly timed out.")
            {
              window.location.href = "/";
            }

            return;
          }

          // Outage
          if (response && response.status == 503)
          {
            return;
          }
        }
        finally
        {
          AppClientContext.Instance.setDataServiceDone(dataServiceTokenName);
        }
      }
    });
  }
}


