<ng-container>
  <div class="accordion">
    <!-- TITLE -->
    <h2 class="accordion-header" id="heading_{{parentId}}">
      <button class="accordion-button" [class.collapsed]="!hasDomainServicesAdded" type="button"
              data-bs-toggle="collapse" attr.data-bs-target="#collapse_{{parentId}}"
              [attr.aria-expanded]="hasDomainServicesAdded" attr.aria-controls="collapse_{{parentId}}">
        <span class="text-uppercase text-green font-weight-bold" [class.fs-m]="isMobile">
          {{ 'Servicios de dominio' | translate }}
        </span>
      </button>
    </h2>

    <div id="collapse_{{parentId}}" class="accordion-collapse" [class.collapse]="!hasDomainServicesAdded"
         [class.show]="hasDomainServicesAdded" attr.aria-labelledby="heading_{{parentId}}">
      <div class="accordion-body p-3 pt-2">
        <div *ngFor="let item of domainServices; let i = index; let last = last">

          <div class="row align-items-center">
            <div class="col-12 row p-0 m-0 align-items-center">
              <!-- Item name -->
              <div class="col-6 col-md-4 font-weight-bold fs-m">
                <div class="d-flex flex-column-reverse">
                  <span>{{ item.name | translate }}</span>
                  <p class="p-1 m-0 recommended-text" *ngIf="i === 0">{{ "Recomendado" | translate }}</p>
                </div>
              </div>

              <!-- Help -->
              <div class="col-1 text-start text-md-center px-0 py-md-0">
                <span class="icon i-question clickable" (click)="onShowHelpModalClick(item.type)"></span>
              </div>

              <!-- Price -->
              <ng-container *ngIf="!item.isAdded">
                <div class="col-5 col-md-3 col-lg-3 p-0 pe-2 pe-md-0 text-end text-md-center fs-m font-weight-bold">
                  <span class="price mt-5">
                    {{ getAmount(item) > 0 ? (getAmount(item) | currency) : ('Sin costo' | translate )}}
                    <span *ngIf="getAmount(item) > 0"> MXN</span>
                  </span>
                </div>

                <!-- Add or renewal button -->
                <div class="col-12 col-md-4 col-lg-4 text-end mt-3 mt-md-0 mt-lg-0 ps-0 pe-2">
                  <ng-container *ngIf="!item.objectId || item.objectId === 0; else renewalButtonTemplate">
                    <button class="btn btn-info text-uppercase f-md font-weight-bold"
                            [disabled]="!isRegisterActive(item)" [class.fs-m]="isMobile" type="button"
                            (click)="addService(item)">
                      {{ 'Agregar a carrito' | translate }}
                    </button>
                  </ng-container>
                  <ng-template #renewalButtonTemplate>
                    <button class="btn btn-info text-uppercase f-md font-weight-bold" [disabled]="!isRenewActive(item)"
                            [class.fs-m]="isMobile" type="button" (click)="addDomainServiceRenewal(item)">
                      {{ 'Renovar' | translate }}
                    </button>
                  </ng-template>
                </div>
              </ng-container>

              <!-- Delete icon -->
              <div class="col text-end" *ngIf="item.isAdded === true && item.isRemovable">
                <span title="{{ 'Eliminar' | translate }}" class="icon i-trash clickable" (click)="onRemoveClicked(item)"></span>
              </div>
            </div>

            <ng-container *ngIf="item.isAdded">
              <!--Various service item -->
              <div class="col-12 mt-3 mb-1 d-flex align-items-center gap-3" *ngIf="item.variousService">
                <!-- Description -->
                <div class="font-weight-bold fs-m flex-grow-1 word-break-all">{{ item.variousService.description}}</div>
                <!--Gross amount-->
                <div class="d-flex flex-column align-items-end font-weight-bold fs-m p-0 m-0">
                  <span class="price">
                    {{ item.variousService.coverage.rate.finalAmount | currency }}<span>
                      MXN
                    </span>
                  </span>
                  <!--Offer-->
                  <div *ngIf="item.variousService.coverage.rate.offerAmount && item.variousService.coverage.rate.offerAmount > 0">
                    <span class="aqua me-2 me-md-3">{{ item.variousService.coverage.rate.message }}</span>
                    <span class="gray price">
                      <del>{{ item.variousService.coverage.rate.offerAmount | currency }}</del>
                      <span> MXN</span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Coverage -->
              <div class="col-7 pr-0 col-sm-5 col-lg-5 justify-content-center">
                <app-dropdown [label]="item.synchronization ? 'EXT. DE COBERTURA' : 'COBERTURA'" [isFullWidth]="true"
                              [coverage]="true" [items]="filterCoverage(item) | coverage:'array'" [parentId]="item.id"
                              [selectedValue]="item.coverageId" (onItemSelectedChange)="onItemCoverageChange(item, $event)">
                </app-dropdown>
              </div>
              <!-- Price -->
              <div class="col-5 col-sm-7 col-lg-7 text-end mt-2 fs-m font-weight-bold">
                <span class="price">
                  {{ item.rate.finalAmount === 0 ? ('Sin costo' | translate ) : (item.rate.finalAmount | currency) }}
                  <span *ngIf="item.rate.finalAmount > 0"> MXN</span>
                </span>
              </div>
            </ng-container>
          </div>
          <hr *ngIf="!last">
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Help info MODAL -->
<app-dialog [enableCloseDialogButton]="false" [show]="showHelpModal" [preventCloseFromBackDrop]="true">
  <div class="p-4 bg-white dialog-auto m-auto">
    <div class="d-flex justify-content-end">
      <span class="icon i-times-solid-red clickable" (click)="closeHelpModal()"></span>
    </div>
    <div [innerHTML]="helpHTML | safe: 'html'"></div>
  </div>
</app-dialog>
