import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ShoppingCartSuggestionItemBase } from "./base/shopping-cart-suggestion-item";
import { IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { IItemProductLite } from "@core-models/shopping-cart-plus.model";

@Component({
  selector: 'app-shopping-cart-suggestion-item',
  templateUrl: './shopping-cart-suggestion-item.component.html',
  styleUrls: ["./shopping-cart-suggestion-item.component.css"]
})
export class ShoppingCartSuggestionItemComponent extends ShoppingCartSuggestionItemBase
{
  @Output() public onSuggestionUpgradeItem = new EventEmitter<IShoppingCartIndividualStoredSuggestionPlus>();

  constructor() { super(); }

  @Input('parentId') public set setParentId(value: number)
  {
    super.parentId = value;
  }

  @Input('suggestion') public set setSuggestion(value: IShoppingCartIndividualStoredSuggestionPlus)
  {
     super.suggestion = value;
  }

  @Input('itemProduct') public set setItemProduct(value: IItemProductLite)
  {
     super.itemProduct = value;
  }

  public onSuggestionUpgradeItemEvent(event: IShoppingCartIndividualStoredSuggestionPlus | undefined): void
  {
    this.onSuggestionUpgradeItem.emit(event);
  }

  public get isIndividualSuggestion(): boolean
  {
    return !super.isUpgradeSuggestion;
  }

  public get isUpgradeSuggestion(): boolean
  {
    return super.isUpgradeSuggestion;
  }
}
