import { IFiscalRegimeType, IPersonType } from "@shared-base/generic-clases.base";
import { EContactPersonType } from "./contact-data.const";

export namespace FiscalDataConst
{
  export const PersonTypes: IPersonType[] = [
    { id: EContactPersonType.Natural, value: 'fisica', label: 'Física' },
    { id: EContactPersonType.Organization, value: 'moral', label: 'Moral' }
  ];

  export const FiscalRegimesTypes: IFiscalRegimeType[] = [
    { value: "601", label: "General de Ley Personas Morales", natural: false, organization: true },
    { value: "603", label: "Personas Morales con Fines no Lucrativos", natural: false, organization: true },
    { value: "605", label: "Sueldos y Salarios e Ingresos Asimilados a Salarios", natural: true, organization: false },
    { value: "606", label: "Arrendamiento", natural: true, organization: false },
    { value: "607", label: "Régimen de Enajenación o Adquisición de Bienes", natural: true, organization: false },
    { value: "608", label: "Demás ingresos", natural: true, organization: false },
    { value: "610", label: "Residentes en el Extranjero sin Establecimiento Permanente en México", natural: true, organization: true },
    { value: "611", label: "Ingresos por Dividendos(socios y accionistas)", natural: true, organization: false },
    { value: "612", label: "Personas Físicas con Actividades Empresariales y Profesionales", natural: true, organization: false },
    { value: "614", label: "Ingresos por intereses", natural: true, organization: false },
    { value: "615", label: "Régimen de los ingresos por obtención de premios", natural: true, organization: false },
    { value: "616", label: "Sin obligaciones fiscales", natural: true, organization: false },
    { value: "620", label: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", natural: false, organization: true },
    { value: "621", label: "Incorporación Fiscal", natural: true, organization: false },
    { value: "622", label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", natural: false, organization: true },
    { value: "623", label: "Opcional para Grupos de Sociedades", natural: false, organization: true },
    { value: "624", label: "Coordinados", natural: false, organization: true },
    { value: "625", label: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas", natural: true, organization: false },
    { value: "626", label: "Régimen Simplificado de Confianza	", natural: true, organization: true }
  ];

  export const MoralOptionsRequired = [
    'idContactOrganizationIndustry',
    'idContactOrganizationSize',
    'contactPersonName',
    'contactPersonPosition'
  ];

  export class Person
  {
    public static readonly Natural: string = "fisica";
    public static readonly Organization: string = "moral";
  }
}
