import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadingComponent implements OnInit
{
  @Input() public xlSize: boolean = true;
  @Input() public text: string = "unspecified text";
  @Input() public loadingTemplate: TemplateRef<any>;
  @Input() public iconTemplate: TemplateRef<any>;


  public ngOnInit(): void { }

}
