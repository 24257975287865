import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidBillingAndPaymentStepGuard } from '@core-guards/valid-billing-and-payment-step.guard';
import { ValidConfirmationStepGuard } from '@core-guards/valid-confirmation-step.guard';
import { ValidRegistrationDataStepGuard } from '@core-guards/valid-registration-data-step.guard';
import { DomainServiceDataResolver } from '@core-resolvers/domain-services-data.resolver';
import { OrderDataResolver } from '@core-resolvers/order-data.resolver';
import { PaymentDataResolver } from '@core-resolvers/payment-data.resolver';
import { RegistrationDataResolver } from '@core-resolvers/registration-data.resolver';
import { UserFiscalDataResolver } from '@core-resolvers/user-fiscal-data.resolver';
import { MainComponent } from './layouts/main/main.component';
import { MaintenanceComponent } from './layouts/maintenance/maintenance.component';
import { PayPalCancelComponent } from './layouts/payment/paypal-cancel/paypal-cancel.component';
import { PayPalSuccessComponent } from './layouts/payment/paypal-success/paypal-success.component';
import { BillingPaymentStepComponent } from './steps/billing-payment/billing-payment.component';
import { ConfirmationStepComponent } from './steps/confirmation/confirmation.component';
import { DomainServicesStepComponent } from './steps/domains-services/domains-services.component';
import { RegistrationDataStepComponent } from './steps/registration-data/registration-data.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'servicios',
        component: DomainServicesStepComponent,
        resolve: {
          data: DomainServiceDataResolver
        }
      },
      {
        path: 'datos-registro',
        component: RegistrationDataStepComponent,
        resolve: {
          data: RegistrationDataResolver
        },
        canActivate: [ValidRegistrationDataStepGuard]
      },
      {
        path: 'facturacion-y-pagos',
        component: BillingPaymentStepComponent,
        resolve: {
          paymentData: PaymentDataResolver,
          userData: UserFiscalDataResolver
        },
        canActivate: [ValidBillingAndPaymentStepGuard]
      },
      {
        path: 'confirmacion',
        resolve: {
          data: OrderDataResolver
        },
        component: ConfirmationStepComponent,
        canActivate: [ValidConfirmationStepGuard]
      }
    ]
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'paypal-success',
    component: PayPalSuccessComponent
  },
  {
    path: 'paypal-cancel',
    component: PayPalCancelComponent
  },
  {
    path: "**",
    redirectTo: '/servicios'
  },
  { path: '', redirectTo: '/servicios', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

