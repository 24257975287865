export class Tools
{
  public static isNullOrEmpty<T>(array: T[]): boolean
  {
    return !(array !== undefined && array !== null && array.length > 0);
  }

  public static getOffset(): number
  {
    return (new Date()).getTimezoneOffset();
  }

  public static isValidEnumValue(value: any, enumeration: object): boolean
  {
    if (value === undefined || value == null || value === '')
    {
      return false;
    }

    for (const key in enumeration)
    {
      if (value === enumeration[key])
      {
        return true;
      }
    }

    return false;
  }

  public static converteStringToBase64(data: string): string
  {
    return btoa(data);
  }

  // ********************************************************************************
  // #region Filter Helpers
  // ********************************************************************************

  public static groupBy(date: any[], key: string): any
  {
    return date.reduce((result, currentValue) =>
    {
      (result[currentValue[key]] = result[currentValue[key]] || []).
        push(currentValue);
      return result;
    }, {});
  }

  public static sortBy(array: any[], key: string): any[]
  {
    try
    {
      return array.sort((a, b) =>
      {
        return (a[key] > b[key]) ? 1 : (a[key] < b[key]) ? -1 : 0;
      });
    }
    catch (error)
    {
      return array;
    }
  }

  public static compare(a: number | string, b: number | string, isAsc: boolean): number
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // #endregion Filters
}
