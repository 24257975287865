
export namespace GtmConst
{
  export const TrackingBrand: string = "Akky";
}

export const enum EGtmEvent
{
  RemoveFromCart = 'remove_from_cart',
  Login = 'ev_login',
  ErrorMessage = 'error_message',
  AddToCart = 'add_to_cart'
}

export const enum EGtmCurrency
{
  MXN = 'MXN',
  USD = 'USD'
}

export const enum EGtmLocationEvent
{
  GeneralSuggestion = "sugerencias_generales_checkout",
  IndividualSuggestion = "sugerencias_individuales_checkout"
}
