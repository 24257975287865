<div class="container px-0 m-0 mt-3">
  <div class="row p-0 m-0 mb-3">
    <div class="col-12 col-lg-7 col-xl-8">

      <!-- Title -->
      <div class="ps-3 ps-sm-0 text-start">
        <h1 class="title black py-2 py-md-0"> {{ "Facturación y Pagos" | translate }} </h1>
      </div>

      <!-- Main contetn -->
      <ng-container *ngIf="isPaymentEnabled; else paymentNotApplyTemplate">

        <h2 class="fs-xl py-3 d-none d-md-block"> {{ "Datos del Comprobante de Pago" | translate }} </h2>

        <!-- Invoice -->
        <div class="border-card font-weight-bold p-3 pb-2 mb-4">
          <div class="card-body p-0">
            <span class="text-uppercase fs-m"> {{'Datos de facturación' | translate }} </span>

            <!-- Invoice check-->
            <div class="d-flex w-100 my-2 align-items-center">
              <span class="fs-m flex-grow-1">
                {{ '¿Requieres emitir una factura?' | translate}}
              </span>
              <app-input-toggler [buttonId]="'invoice-toggle'" [value]="requiresInvoice"
                (onValueChanges)="onInvoiceCheckChanged($event)"></app-input-toggler>
            </div>

            <div class="row my-1" [formGroup]="invoiceForm" *ngIf="requiresInvoice">

              <!-- RFC Select -->
              <div class="col-md-6 col-12">
                <app-dropdown [label]="'RFC'" [selectedValue]="''" [items]="userFilteredRFCList"
                  (onItemSelectedChange)="setSelectedRFC($event)" [isFullWidth]="true"></app-dropdown>
              </div>

              <!-- New RFC -->
              <div class="col-12 col-md-6 my-2" [class.d-none]="!isNewRFC">
                <div class="form-group w-100 floating-label">
                  <input [ngClass]="{ 'is-invalid': continueClicked && invoiceFormControls.billingRfc?.errors }" rfc
                    formControlName="billingRfc" placeholder=" " type="text" id="billingRfc"
                    class="form-control form-input" name="billingRfc" role="textbox" maxlength="13"
                    pattern="[A-ZÑ&]{3,4}\d{6}(?:[A-Z\d]{3})?" (input)="onRFCChanged($event)"
                    (onRFC)="getFiscalRegimes($event)" required>
                  <label for="billingRfc" class="up-label">{{'Nuevo RFC' | translate}}</label>
                </div>
                <ng-container
                  *ngTemplateOutlet="tooltipErrorTemplate; context: {control: invoiceFormControls.billingRfc, name: 'billingRfc', submitted: continueClicked}"></ng-container>
              </div>
              <!-- Name -->
              <div class="col-12 col-md-6 my-2">
                <div class="form-group w-100 floating-label">
                  <input [ngClass]="{ 'is-invalid': continueClicked && invoiceFormControls.tradeName?.errors }"
                    formControlName="tradeName" placeholder=" " type="text" id="tradeName"
                    class="form-control form-input" name="tradeName" role="textbox" maxlength="250" required>
                  <label for="tradeName" class="up-label">{{'Nombre o Razón Social' | translate}}</label>
                </div>
                <ng-container
                  *ngTemplateOutlet="tooltipErrorTemplate; context: {control: invoiceFormControls.tradeName, name: 'tradeName', submitted: continueClicked}"></ng-container>
              </div>
              <!-- Zip code -->
              <div class="col-12 col-md-6 my-2">
                <div class="form-group w-100 floating-label">
                  <input [ngClass]="{ 'is-invalid': continueClicked && invoiceFormControls.zipCode?.errors }"
                    formControlName="zipCode" placeholder=" " type="text" id="zipCode" class="form-control form-input"
                    name="zipCode" role="textbox" maxlength="5" pattern="^[0-9]{5}$" required>
                  <label for="zipCode" class="up-label">{{'Código postal' | translate}}</label>
                </div>
                <ng-container
                  *ngTemplateOutlet="tooltipErrorTemplate; context: {control: invoiceFormControls.zipCode, name: 'zipCode', submitted: continueClicked}"></ng-container>
              </div>
              <!-- Select regimen fiscal -->
              <div class="col-md-6 col-12">
                <app-dropdown [label]="'Régimen fiscal'" [selectedValue]="selectedFiscalRegime"
                  [items]="filteredFiscalRegimes" (onItemSelectedChange)="setSelectedFiscalRegime($event)"
                  [isFullWidth]="true" [showError]="continueClicked && selectedFiscalRegime === ''"></app-dropdown>
              </div>
            </div>
          </div>
        </div>

        <!-- Payment methods -->
        <section>
          <h5 class="head-subtitle ps-3 ps-sm-0"> {{ 'Formas de pago' | translate }} </h5>

          <!-- Card payment -->
          <div class="border-card fw-bold p-3 my-4 fs-m" *ngIf="isCreditCardEnabledForGenericDomainRegistration && isPaymentTypeEnabled(creditCardMethod)">
            <div class="form-check">
              <input class="form-check-input clickable" [checked]="isCreditCardSelected" [value]="isCreditCardSelected"
                type="radio" name="paymentMethod" id="creditCard"
                (change)="onSelectedPaymentMethodChanges(creditCardMethod)">
              <label id="creditCard" class="form-check-label text-uppercase ps-2 clickable underline black"
                for="creditCard">
                {{ 'Tarjeta de crédito/débito' | translate}}
              </label>
            </div>

            <!-- Credit card content -->
            <ng-container *ngIf="isCreditCardSelected">

              <!-- Credit Card Form -->
              <app-credit-card [submitted]="continueClicked"
                (onCreditCardDataChanges)="setCreditCardData($event)"></app-credit-card>

              <!-- Renovation check -->

              <div class="d-flex align-items-center my-3 fs-m">
                <span class="flex-grow-1">
                  {{ 'Programar renovación automática 1 año más al termino de la cobertura' | translate }}
                </span>
                <app-input-toggler [buttonId]="'renewal-toggle'" [value]="isAutomaticRenewalActivated"
                  [disabled]="isAutomaticRenewalDisabled"
                  (onValueChanges)="onAutomaticRenewalButton()"></app-input-toggler>
              </div>

              <!-- Label -->
              <section>
                <div class="font-weight-normal fs-m my-3">
                  {{
                  '*Se pueden programar solo tarjetas Visa y MasterCard, y al agregarla o actualizarla haremos una
                  retención temporal de un monto mínimo para validar tu tarjeta.' | translate
                  }}
                </div>
              </section>

              <!-- Interest free-->
              <section *ngIf="mifBankOptions && mifBankOptions.length > 0">

                <div class="d-flex w-100 fs-m mt-3 mb-1">
                  <span class="flex-grow-1">
                    {{'Pago a meses sin intereses' | translate }}
                  </span>
                  <app-input-toggler [buttonId]="'interest-free-toggle'" [value]="isInterestFreeChecked"
                    (onValueChanges)="onInterestFreeCheckChange($event)"></app-input-toggler>
                </div>
                <div class="font-weight-normal fs-m py-2">
                  {{ '*Aplica para bancos participantes y American Express' | translate }}
                </div>

                <div *ngIf="isInterestFreeChecked" class="row m-0 p-0">
                  <div class="col-md-6 col-12 p-0 m-0 pe-md-2">
                    <app-dropdown [label]="'BANCO EMISOR'" [items]="mifBankOptions"
                      (onItemSelectedChange)="setSelectedMifBank($event)" [isFullWidth]="true"
                      [showError]="continueClicked && !selectedMIFBank"></app-dropdown>
                  </div>
                  <div class="col-md-6 col-12 p-0 m-0 ps-md-2">
                    <app-dropdown [label]="'MENSUALIDADES'" [items]="mifOptions"
                      (onItemSelectedChange)="setSelectedMifNumber($event)" [isFullWidth]="true"
                      [showError]="continueClicked && selectedMIF === undefined"></app-dropdown>
                  </div>
                </div>
              </section>
            </ng-container>
          </div>

          <!-- PAYPAL  -->
          <div class="border-card font-weight-bold p-3 my-4"
            *ngIf="isPaypalEnabled && isPaymentTypeEnabled(paypalMethod)">
            <div class="form-check paypal-height">
              <input class="form-check-input clickable" [checked]="isPaypalSelected" [value]="isPaypalSelected"
                type="radio" name="paymentMethod" id="paypalRadio"
                (change)="onSelectedPaymentMethodChanges(paypalMethod)">
              <label for="paypalRadio" class="clickable">
                <span class="d-inline-block i-paypal"></span>
              </label>
            </div>

            <div *ngIf="isPaypalSelected">
              <div class="font-weight-normal my-3 fs-m text-justify">
                {{
                'Procesa tu pago de forma fácil y segura ingresando con tu cuenta PayPal dando clic en el botón y
                validando tu forma de pago. Posteriormente da clic en confirmar y pagar.' | translate
                }}
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-warning col-10 col-md-6" (click)="onOpenPayPalClick()">
                  <span class="d-inline-block i-paypal"></span>
                </button>
              </div>
            </div>
          </div>

          <!-- BANK DEPOSIT -->
          <div class="border-card font-weight-bold p-3 my-4 fs-m"
            *ngIf="isBankDepositEnabled && isPaymentTypeEnabled(bankDepositMethod)">

            <div class="form-check">
              <input class="form-check-input clickable" [checked]="isBankDepositSelected"
                [value]="isBankDepositSelected" type="radio" name="paymentMethod" id="bankDeposit"
                (change)="onSelectedPaymentMethodChanges(bankDepositMethod)">
              <label class="form-check-label text-uppercase ps-2 clickable underline black" for="bankDeposit">
                {{ 'Depósito bancario' | translate}}
              </label>
            </div>

            <div *ngIf="isBankDepositSelected">
              <div class="font-weight-normal my-3 text-justify">
                {{
                'Los Pagos con Depósito Bancario son pagos referenciados, al finalizar la compra te mostraremos las
                referencias para BBVA. La activación de los servicios con este método de pago demora de 2 a 3 días
                hábiles.' | translate
                }}
              </div>
            </div>
          </div>
        </section>
      </ng-container>

      <!-- Mobile Coupon -->
      <section id="coupon-billing-container" class="my-3">
        <div class="border-card d-lg-none py-3">
          <app-coupon-input [couponToApply]="currentCoupon"></app-coupon-input>
        </div>
      </section>

      <!-- Accept policies -->
      <div class="d-flex flex-column">
        <span class="fs-m text-center text-sm-start p-0 px-2">
          {{ 'Al realizar compras en Akky es necesario indicar lo siguiente:' | translate }}
        </span>
        <div class="p-3 mx-3 form-check">
          <input [(ngModel)]="acceptedPolicies" class="form-check-input checkbox-accepted" type="checkbox" id="policy">
          <label class="form-check-label mx-2 fs-md" for="policy">
            {{
            'Acepto las políticas de cada servicio agregado a este carrito de compra y reconozco que el "Registrante"
            es el único titular del o los Nombre(s) de Dominio. Puedes consultar todas las políticas ' | translate
            }}
            <a [href]="noticeOfPrivacyURL" class="underline text-green anchor" target="_blank">
              {{ 'aquí.' | translate }}
            </a>
          </label>
        </div>
      </div>

      <!-- Pay -->
      <div class="d-lg-none d-block pb-3 pe-3">
        <button (click)="onContinueClick()" [disabled]="!isEnabledButtonContinue" class="btn btn-primary w-100">
          {{ "Pagar" | translate }}
        </button>
      </div>

      <!-- Back -->
      <ng-container *ngTemplateOutlet="backButtonTemplate;"></ng-container>

      <app-secure-purchase-message [desktop]="false"></app-secure-purchase-message>

    </div>

    <!-- Purchase summary -->
    <div class="d-none d-lg-block col-12 col-lg-5 col-xl-4">
      <app-purchase-summary [button]="summaryBtn" [openServices]="true" [buttonEnabled]="isEnabledButtonContinue"
        (nextStep)="onContinueClick()"></app-purchase-summary>
      <app-secure-purchase-message></app-secure-purchase-message>
    </div>

  </div>
</div>

<!-- Payment DOES NOT APLY template-->
<ng-template #paymentNotApplyTemplate>
  <div class="border-card font-weight-bold p-3 my-4 fs-m" *ngIf="!isPaymentEnabled">
    <div class="form-check">
      <input class="form-check-input clickable" type="radio" checked name="notAplyCheck" id="notAplyCheck">
      <label class="form-check-label text-uppercase ps-2 clickable underline black" for="notAplyCheck">
        {{ 'No aplica' | translate}}
      </label>
    </div>

    <div>
      <div class="font-weight-normal my-3 text-justify">
        {{
        'Los servicios incluidos en el carrito son gratuitos, por lo que no es necesario ingresar información de
        facturación y pagos.' | translate
        }}
      </div>
    </div>
  </div>
</ng-template>

<!-- error tooltip -->
<ng-template #tooltipErrorTemplate let-control="control" let-submitted="submitted" let-type="type" let-name="name">
  <div class="error-container" *ngIf="submitted && control?.errors">
    <small>{{getErrorMessage(control)}}</small>
  </div>
</ng-template>

<!-- Back button template-->
<ng-template #backButtonTemplate>
  <button class="btn btn-secondary black my-4" [class.w-100]="isMobile" (click)="onBackClicked()">
    <div class="d-flex align-items-center justify-content-center w-100">
      <span class="i-arrow-left me-2"></span>
      {{ 'Regresar' | translate }}
    </div>
  </button>
</ng-template>
