import { Component, Input } from "@angular/core";
import { IShoppingCartIndividualStoredSuggestionPlus } from "@core-models/purchase-suggestion.model";
import { TranslateService } from '@shared-services/translate.service';

@Component({
  selector: 'app-purchase-summary-suggestion-item',
  templateUrl: './purchase-summary-suggestion-item.component.html',
  styleUrls: ['./purchase-summary-suggestion-item.component.css']
})
export class PurchaseSummarySuggestionItemComponent
{
  private _item: IShoppingCartIndividualStoredSuggestionPlus;
  public isAdded: boolean = false;

  @Input() public parentId: number;

  constructor(
    protected translateService: TranslateService
  ) { }

  @Input() public set item(value: IShoppingCartIndividualStoredSuggestionPlus)
  {
    if (value)
    {
      this._item = value;
      this.isAdded = value.individualFeatures.isAdded;
    }
    else
    {
      this.item = undefined;
    }
  }

  public get item(): IShoppingCartIndividualStoredSuggestionPlus
  {
    return this._item;
  }

  public get buttonId(): string
  {
    return `individual-suggestion-${this.item.id}-${this.parentId}`;
  }

  public get title(): string
  {
    return this._item.individualFeatures.displayName ?? this._item.translations.title;
  }

  public get finalAmount(): number
  {
    return this._item.coverage[0].rate.finalAmount;
  }
}
